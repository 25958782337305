import React from "react";

const PaymentPolicy = () => {
  return (
    <section className="PaymentPolicy_wrapper wrapper pt-20 md:pt-40 mx-auto w-full px-10 md:px-36">
      <h1 className="h1-title">Chính sách thanh toán</h1>
      <h2 className="h2-title text-sm md:text-xl">I. THANH TOÁN</h2>
      <p>
        Khách hàng có thể lựa chọn 1 trong 3 hình thức thanh toán thuận tiện và
        phù hợp với mình nhất:
      </p>
      <h4 className="h4-title">1. Thanh toán tiền mặt</h4>
      <p>
        Khách hàng mua hàng tại địa điểm kinh doanh của chúng tôi, tại đây Quý
        khách hàng có thể thanh toán trực tiếp.
      </p>
      <h4 className="h4-title">2. Thanh toán khi nhận hàng (COD)</h4>
      <p>
        Với hình thức này khách hàng xem hàng tại nhà, thanh toán tiền mặt cho
        nhân viên giao nhận hàng
      </p>
      <h4 className="h4-title">3. Chuyển khoản trước</h4>
      <p>
        Quý khách chuyển khoản trước, sau đó chúng tôi tiến hành giao hàng theo
        thỏa thuận hoặc hợp đồng với Quý khách.
      </p>
      <h2 className="h2-title text-sm md:text-xl">II. THÔNG TIN THANH TOÁN</h2>
      <p>
        CHỦ TÀI KHOẢN: <span className="font-bold">Trần Thị Phương Thảo</span>
      </p>
      <li className="font-bold text-xs md:text-base">Ngân Hàng VietinBank</li>
      <li className="font-bold text-xs md:text-base">STK: 0971261199</li>
    </section>
  );
};

export default PaymentPolicy;
