import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="PrivacyPolicy_wrapper wrapper pt-20 md:pt-40 mx-auto w-full px-10 md:px-36">
      <h1 className="h1-title">Chính sách bảo mật thông tin</h1>
      <p>
        Chính sách Quyền riêng tư của {""}
        <span className="font-semibold">Deluxe Perfume</span> nhằm giúp Quý khách
        hiểu chúng tôi thu thập dữ liệu gì liên quan tới Quý khách, tại sao {""}
        <span className="font-semibold">Deluxe Perfume</span> thu thập và sẽ làm gì
        với dữ liệu đó.{" "}
      </p>
      <h2 className="h2-title text-sm md:text-xl">
        1. Mục đích và phạm vi thu thập thông tin
      </h2>
      <p>
        Quý khách đã tin tưởng mua hàng tại {""}
        <span className="font-semibold">deluxeperfume.com.vn</span> và chia sẻ thông
        tin với chúng tôi, nên chúng tôi xin cam kết chỉ sử dụng thông tin của
        Quý khách để tạo ra các dịch vụ tốt hơn cho Quý khách.
      </p>
      <p>
        {""}
        <span className="font-semibold">Deluxe Perfume</span> không bán, chia sẻ hay
        trao đổi thông tin cá nhân của khách hàng thu thập trên trang web cho
        một bên thứ ba nào khác.
      </p>
      <p>
        Thông tin cá nhân thu thập được sẽ chỉ được sử dụng trong nội bộ công
        ty. Khi bạn liên hệ đăng ký dịch vụ, thông tin cá nhân mà {""}
        <span className="font-semibold">Deluxe Perfume</span> thu thập bao gồm:
      </p>
      <li>Họ và tên</li>
      <li>Địa chỉ</li>
      <li>Điện thoại</li>
      <li>Email</li>
      <p>
        Những thông tin trên sẽ được sử dụng cho một hoặc tất cả các mục đích
        sau đây:
      </p>
      <li>
        Giao hàng khi bạn đã mua tại {""}
        <span className="font-semibold">deluxeperfume.com.vn</span>
      </li>
      <li>Thông báo về việc giao hàng và hỗ trợ khách hàng</li>
      <li>Cung cấp thông tin liên quan đến sản phẩm.</li>
      <li>
        Xử lý đơn đặt hàng và cung cấp dịch vụ và thông tin qua trang web của
        chúng tôi theo yêu cầu của Bạn.
      </li>
      <h2 className="h2-title text-base md:text-xl">
        2. Phạm vi sử dụng thông tin
      </h2>
      <p>
        {""}
        <span className="font-semibold">Deluxe Perfume</span> có quyền sử dụng hợp
        pháp các thông tin cá nhân của khách hàng trong các trường hợp để đảm
        bảo quyền lợi của quý khách như:
      </p>

      <li>
        Thông báo các thông tin về dịch vụ quảng cáo, các chương trình khuyến
        mãi… đến khách hàng theo nhu cầu và thói quen của quý khách khi truy cập
      </li>
      <li>Liên lạc và hỗ trợ khách hàng trong những trường hợp đặc biệt</li>
      <li>
        Phát hiện và ngăn chặn ngay lập tức các hành vi can thiệp hoặc phá hoại
        tài khoản của khách hàng
      </li>
      <h2 className="h2-title text-base md:text-xl">
        3. Thời gian lưu trữ thông tin
      </h2>
      <p>
        Đối với thông tin cá nhân, {""}
        <span className="font-semibold">Deluxe Perfume</span> chỉ xóa đi dữ liệu này
        nếu khách hàng có yêu cầu, khách hàng yêu cầu gửi mail về{" "}
        <span className="font-semibold">deluxeperfumestore@gmail.com</span>
      </p>
      <h2 className="h2-title text-base md:text-xl">
        4. Các đối tượng hoặc tổ chức có thể được tiếp cận với thông tin khách
        hàng
      </h2>
      <p>
        Chỉ duy nhất {""}
        <span className="font-semibold">Deluxe Perfume</span> được quyền tiếp cận
        thông tin khách hàng và chúng tôi cam kết không tiết lộ thông tin khách
        hàng cho bên thứ ba ngoại trừ các trường hợp sau:
      </p>
      <li>
        Thực hiện theo yêu cầu của các các cá nhân, tổ chức có thẩm quyền theo
        quy định của pháp luật
      </li>
      <li>
        Cần thiết phải sử dụng các thông tin để phục vụ cho việc cung cấp các
        dịch vụ/tiện ích cho khách hàng
      </li>
      <li>Nghiên cứu thị trường và đánh giá phân tích</li>
      <li>
        Trao đổi thông tin khách hàng với đối tác hoặc đại lí phân phối để phân
        tích nâng cao chất lượng dịch vụ
      </li>
      <li>
        Ngoài các trường hợp trên, khi cần phải trao đổi thông tin khách hàng
        cho bên thứ ba, {""}
        <span className="font-semibold">Deluxe Perfume</span> sẽ thông báo trực tiếp
        với khách hàng và sẽ chỉ thực hiện việc trao đổi thông tin khi có sự
        đồng thuận từ phía khách hàng.
      </li>
      <h2 className="h2-title text-base md:text-xl">
        5. Địa chỉ của đơn vị thu thập và quản lý thông tin, bao gồm cách thức
        liên lạc để người tiêu dùng có thể hỏi về hoạt động thu thập, xử lý
        thông tin liên quan đến cá nhân mình
      </h2>
      <p>
        {""}
        <span className="font-semibold">Deluxe Perfume</span> là đơn vị duy nhất
        được thu thập và quản lý thông tin khách hàng tại địa chỉ {""}
        <span className="font-semibold">deluxeperfume.com.vn</span>, mọi thắc mắc và
        cần sự hỗ trợ xin vui lòng liên hệ qua số hotline {""}
        <span className="font-semibold">097 126 11 99</span> hoặc email{" "}
        <span className="font-semibold">deluxeperfumestore@gmail.com</span> để chúng
        tôi được phục vụ bạn.
      </p>
      <h2 className="h2-title text-base md:text-xl">
        6. Cơ chế tiếp nhận và giải quyết khiếu nại liên quan đến việc thông tin
        cá nhân khách hàng
      </h2>
      <p>
        Tại {""}
        <span className="font-semibold">Deluxe Perfume</span> , việc bảo vệ thông
        tin cá nhân của bạn là rất quan trọng, bạn được đảm bảo rằng thông tin
        cung cấp cho chúng tôi sẽ được{" "}
        <span className="font-semibold">Deluxe Perfume</span> cam kết không chia sẻ,
        bán hoặc cho thuê thông tin cá nhân của bạn cho bất kỳ người nào khác.{" "}
        {""}
        <span className="font-semibold">Deluxe Perfume</span> cam kết chỉ sử dụng
        các thông tin của bạn vào các trường hợp sau:
      </p>
      <p>– Nâng cao chất lượng dịch vụ dành cho khách hàng</p>
      <p>– Giải quyết các tranh chấp, khiếu nại</p>
      <p>– Khi cơ quan pháp luật có yêu cầu.</p>
    </section>
  );
};

export default PrivacyPolicy;
