import { configureStore } from "@reduxjs/toolkit";
import LanguageReducer from "./features/LanguageSlice";
import NavbarReducer from "./features/NavbarSlice";
import ThemeReducer from './features/ThemeSlice';
import NewsReducer from './features/NewsSlice';
import ProductReducer from './features/ProductSlice';
import ResponsiveRuducer from './features/ResponsiveSlice'
import CartReducer from "./features/CartSlice";

const store = configureStore({
  reducer: {
    language: LanguageReducer,
    nav: NavbarReducer,
    theme: ThemeReducer,
    news: NewsReducer,
    product: ProductReducer,
    cart: CartReducer,
    responsive :ResponsiveRuducer,
  },
});

export default store;
