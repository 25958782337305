import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      // Check if product already in cart
      // const existingProduct = state.cartItems.find((x) => x.id === product.id);
      const existingProduct = state.cartItems.find(
        (x) => x.id === product.id && x.price === product.price && x.volume === product.volume
      );

      existingProduct
        ? (existingProduct.qty += 1)
        : state.cartItems.push({ ...product });

      // Lưu giỏ hàng hiện tại vào localStorage
      // const currentCart = [...state.cartItems, product];
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },

    removeFromCart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.cartItems.find((x) => x.id === product.id);
      existingProduct.qty === 1
        ? state.cartItems.splice(state.cartItems.indexOf(existingProduct), 1)
        : (existingProduct.qty -= 1);

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    // Trừ hết sản phẩm
    removeProductFromCart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.cartItems.find((x) => x.id === product.id);
      state.cartItems.splice(state.cartItems.indexOf(existingProduct), 1)

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    // Xoá tất cả sản phẩm trong giỏ hàng sau khi checkout
    clearCart: (state) => {
      state.cartItems = [];
      localStorage.removeItem("cartItems");
    },
  },
});

export default cartSlice.reducer;
export const { addToCart, setCartItems, removeFromCart, removeProductFromCart, clearCart } = cartSlice.actions;
