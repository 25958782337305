import React from "react";

const WarrantyPolicy = () => {
  return (
    <section className="WarrantyPolicy_wrapper wrapper pt-20 md:pt-40 mx-auto w-full px-10 md:px-36">
      <h1 className="h1-title">Chính sách bảo hành</h1>
      <ul className="mt-3" style={{ listStyle: "initial" }}>
        <p className="font-semibold">1. ĐIỀU KIỆN ĐỔI HÀNG</p>
        <li>
          Sản phẩm khách hàng chưa sử dụng: còn nguyên seal, tem niêm phong của{" "}
          <span className="font-semibold">Deluxe Perfume</span> hoặc hóa đơn mua
          hàng.
        </li>
        <li>Sản phẩm bị hư hỏng do lỗi của nhà sản xuất.</li>
        <li>
          Hư hỏng phần vòi xịt, thân chai nứt, bể trong quá trình vận chuyển.
        </li>
        <li>
          Nước hoa bị rò rỉ, giảm dung tích so với thực tế khi vừa nhận hàng.
        </li>
        <li>Nước hoa bị biến đổi màu hoặc mùi hương khi nhận hàng.</li>
        <li>Giao sai hoặc nhầm lẫn với mùi hương khác so với đơn đặt hàng.</li>
      </ul>
      <h2 className="h4-title underline font-bold">
        LƯU Ý: Chỉ nhận đổi hàng khi có video nhận hàng
      </h2>

      <ul className="mt-3" style={{ listStyle: "initial" }}>
        <p className="font-semibold">2. QUY TRÌNH ĐỔI HÀNG</p>
        <li>Đổi với sản phẩm khách hàng chưa sử dụng:</li>
        <li>
          Giá trị sản phẩm mới phải tương đương hoặc cao hơn giá trị đổi của sản
          phẩm cũ (nếu giá cao hơn khách hàng vui lòng thanh toán thêm phần
          chênh lệch).
        </li>
        <li>Đổi với các sản phẩm bị hư hỏng do lỗi của nhà sản xuất:</li>
        <li>
          <span className="font-semibold">Deluxe Perfume</span> sẽ hỗ trợ khách
          đổi sản phẩm, hỗ trợ 100% chi phí phát sinh.
        </li>
      </ul>

      <ul className="mt-3" style={{ listStyle: "initial" }}>
        <p className="font-semibold">3. CÁC TRƯỜNG HỢP KHÔNG HỖ TRỢ ĐỔI</p>
        <li>Không áp dụng đổi hàng cho các sản phẩm chiết, gốc chiết.</li>
        <li>
          Các sản phẩm không còn nguyên seal, seal có dấu hiệu bị rách, bẩn.
        </li>
        <li>
          Các sản phẩm không có tem niêm phong của{" "}
          <span className="font-semibold">Deluxe Perfume</span> hoặc tem có dấu
          hiệu bị cậy, rách.
        </li>
        <li>
          Các sản phẩm được{" "}
          <span className="font-semibold">Deluxe Perfume</span> tặng kèm khi mua
          hàng tại cửa hàng hoặc qua hình thức online.
        </li>
        <li>Các sản phẩm đã quá hạn đổi trả.</li>
      </ul>

      <ul className="mt-3" style={{ listStyle: "initial" }}>
        <p className="font-semibold">4. CÁC LƯU Ý ĐẶC BIỆT</p>
        <li>
          Khách hàng vui lòng trả phí vận chuyển, phí phát sinh trong trường hợp
          muốn đổi sản phẩm qua hình thức Online.
        </li>
        <li>Khách hàng mua Online vui lòng quay lại video khi nhận hàng.</li>
        <li>
          Trước khi đổi hàng qua hình thức Online, quý khách vui lòng quay chụp
          lại 6 mặt của sản phẩm gửi qua{" "}
          <a
            href="https://m.me/Deluxeperfume.auth"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary"
          >
            Fanpage: <span className="font-semibold">Deluxe Perfume </span>
          </a>{" "}
          hoặc{" "}
          <a
            href="https://zalo.me/0971261199"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary"
          >
            {" "}
            Zalo: <span className="font-semibold">097 126 11 99</span>
          </a>
        </li>
      </ul>
    </section>
  );
};

export default WarrantyPolicy;
