import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  setNewsActiveNumber,
  SwitchActiveMobileNav,
  updateActiveNav,
} from "../../redux/features/NavbarSlice";
// import { setActiveFilter } from "../../redux/features/NewsSlice";
import { CollectionProducts } from "../../data/data";
import logo_row from "../../assets/logo/logo_row.png";
import { generateProductURL } from "../../utils";

const menuDataList = [
  {
    id: 1,
    href: "/",
    text: "Trang Chủ",
  },
  {
    id: 2,
    href: "/ve-deluxe-perfume",
    text: "Về DELUX PERFUME",
  },
  {
    id: 3,
    href: "/bo-suu-tap-nuoc-hoa",
    text: "Nước Hoa",
    subMenu: [
      {
        id: 1,
        href: "/bo-suu-tap-nuoc-hoa/nam",
        text: "Nước Hoa Nam",
      },
      {
        id: 2,
        href: "/bo-suu-tap-nuoc-hoa/nu",
        text: "Nước Hoa Nữ",
      },
      {
        id: 3,
        href: "/bo-suu-tap-nuoc-hoa/unisex",
        text: "Nước Hoa Unisex",
      },
    ],
  },
  {
    id: 4,
    href: "/thuong-hieu",
    text: "Thương Hiệu",
  },
  {
    id: 5,
    href: "/tin-tuc",
    text: "Tin Tức",
    subMenu: [
      {
        id: 1,
        href: "/tin-tuc/review-nuoc-hoa",
        text: "Review Nước Hoa",
      },
      {
        id: 2,
        href: "/tin-tuc/kien-thuc-nuoc-hoa",
        text: "Kiến Thức Nước Hoa",
      },
    ],
  },
  {
    id: 6,
    href: "/lien-he",
    text: "Liên Hệ",
  },
];

const NavItem = ({ item, activeNav }) => {
  const dispatch = useDispatch();

  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleMouseEnter = () => {
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    setShowSubMenu(false);
  };

  const handleSetActiveMenu = (id) => {
    dispatch(updateActiveNav(id));
  };

  return (
    <li
      className={``}
      key={item.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <NavLink
        to={item.href}
        className={`${
          activeNav === item.id ? "bg-gray-200 text-primary" : "bg-transparent"
        } flex justify-center items-center capitalize py-2 px-3 text-black rounded md:bg-transparent md:p-0 hover:bg-gray-200 md:hover:bg-transparent hover:text-primary`}
        onClick={() => handleSetActiveMenu(item.id)}
        aria-current="page"
      >
        {item.text}{" "}
        {item?.subMenu ? (
          <svg
            className="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        ) : null}
      </NavLink>
      {item.subMenu && showSubMenu && (
        <DropdownMenu subMenuItems={item.subMenu} />
      )}
    </li>
  );
};

const DropdownMenu = ({ subMenuItems }) => {
  const dispatch = useDispatch();
  const navState = useSelector((state) => state.nav);
  const { newsActiveNumber } = navState;

  const handleSetNewsActiveNumber = (subItem) => {
    dispatch(setNewsActiveNumber(subItem.id));
  };

  return (
    <div className="absolute w-[80%] h-auto transform md:transform-none -translate-x-1/2 md:-translate-x-1/2 md:w-auto text-center bg-white rounded-lg shadow-lg mt-1 before:content-[''] before:absolute before:w-full before:top-[-8px] before:left-1/2 before:transform before:-translate-x-1/2 before:border-8 before:border-transparent">
      <ul className="py-1">
        {subMenuItems.map((subItem, index) => (
          <li key={index}>
            <NavLink
              onClick={() => handleSetNewsActiveNumber(subItem)}
              to={subItem.href}
              className={`block capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary ${
                newsActiveNumber === subItem.id ? "text-primary" : "text-black"
              } `}
            >
              {subItem.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

const MobileNavItem = () => {
  const dispatch = useDispatch();
  const navState = useSelector((state) => state.nav);
  const { activeMobileNav } = navState;

  const handleSwitchActiveMobileNav = () => {
    dispatch(SwitchActiveMobileNav());

    document.body.style.overflow = "auto";
  };

  return (
    <section
      className={` bg-white lg:hidden w-full h-screen absolute top-0 left-0 z-10 transform transition-all duration-500 flex flex-col justify-center items-center text-center space-y-2 ${
        activeMobileNav ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {menuDataList.map((item, index) => (
        <ul key={index} className="">
          <Link
            to={item.href}
            onClick={() => handleSwitchActiveMobileNav()}
            className="text-3xl font-light uppercase hover:text-primary"
          >
            {item.text}
          </Link>
          <ul className="py-2">
            {item?.subMenu?.map((subItem, indx) => (
              <li key={indx} className="font-light uppercase cursor-pointer hover:text-primary">
                <Link
                  to={subItem.href}
                  onClick={() => dispatch(SwitchActiveMobileNav())}
                >
                  {subItem.text}
                </Link>
              </li>
            ))}
          </ul>
        </ul>
      ))}
    </section>
  );
};

const Navbar = () => {
  const navState = useSelector((state) => state.nav);
  const { activeNav, activeMobileNav } = navState;
  const cartState = useSelector((state) => state.cart);
  const { cartItems } = cartState;
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  // Tìm Kiếm Sản Phẩm
  const [activeSearchBar, setActiveSearchBar] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const searchRef = useRef(null);

  useEffect(() => {
    const findActiveNav = (items, path) => {
      for (const item of items) {
        if (item.href === path) {
          return item.id;
        }
        if (item.subMenu) {
          const subItem = findActiveNav(item.subMenu, path);
          if (subItem) {
            return item.id; // Optionally return subItem.id if you want the exact submenu item ID
          }
        }
      }
      return null;
    };

    const activeNavId = findActiveNav(menuDataList, location.pathname);
    if (activeNavId !== null) {
      dispatch(updateActiveNav(activeNavId));
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 40) {
        // Tùy vào chiều cao mà bạn muốn nav stick khi scroll xuống
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSwitchActiveMobileNav = () => {
    dispatch(SwitchActiveMobileNav());

    if (activeMobileNav) {
      // document.body.classList.add('overflow-hidden'); // Chặn scroll
      document.body.style.overflow = "auto";
    } else {
      // document.body.classList.remove('overflow-hidden'); // Mở lại scroll
      document.body.style.overflow = "hidden";
    }
  };

  const handleActiveSearchBar = () => {
    setActiveSearchBar(!activeSearchBar);
  };

  const handleSearch = (event) => {
    const keywordInput = event.target.value;
    setKeyword(keywordInput);

    if (keywordInput) {
      // Lọc sản phẩm từ CollectionProducts dựa vào keyword
      const results = CollectionProducts.flatMap((brand) =>
        brand.products.filter((product) =>
          product.productName.toLowerCase().includes(keywordInput.toLowerCase())
        )
      );
      setFilteredProducts(results);
    } else {
      setFilteredProducts([]);
    }
  };

  const handleReset = () => {
    setKeyword("");
    setFilteredProducts([]);
  };

  const basePath = `/bo-suu-tap-nuoc-hoa`;

  const handleViewAllProductSearchBar = () => {
    setKeyword("");
    setFilteredProducts([]);
    setActiveSearchBar(false)
  }

  // useEffect để lắng nghe click bên ngoài search bar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setActiveSearchBar(false); // Ẩn thanh tìm kiếm nếu click bên ngoài
      }
    };

    // Thêm sự kiện click
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup event listener khi component bị unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav
      className={`Navbar_wrapper z-40 w-full top-0 start-0 bg-transparent border-gray-200 transition-all duration-500 ${
        isFixed ? "fixed bg-white" : "absolute backdrop-blur-md"
      } `}
    >
      <div className="max-w-screen-2xl flex flex-col flex-wrap items-center justify-between mx-auto p-4 lg:p-0">
        {/* Icon Nav & Logo & Giở Hàng */}
        <div
          className={`  w-full mx-auto flex justify-between items-center z-20 ${
            activeMobileNav ? "border-b border-gray-500 pb-5" : " border-none"
          }`}
        >
          {/* Button Cho mobile nav */}
          {/* <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-200 focus:outline-none border-black"
            onClick={() => dispatch(SwitchActiveMobileNav())}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button> */}

          <label className="lg:hidden">
            <div className="w-9 h-10 cursor-pointer flex flex-col items-center justify-center">
              <div className="w-[50%] h-[2px] bg-black rounded-sm transition-all duration-300 origin-left translate-y-[0.45rem] peer-checked:rotate-[-45deg]"></div>
              <div className="w-[50%] h-[2px] bg-black rounded-md transition-all duration-300 origin-center peer-checked:hidden"></div>
              <div className="w-[50%] h-[2px] bg-black rounded-md transition-all duration-300 origin-left -translate-y-[0.45rem] peer-checked:rotate-[45deg]"></div>
              <input
                className="hidden peer"
                type="checkbox"
                onClick={() => handleSwitchActiveMobileNav()}
              />
            </div>
          </label>

          <Link
            to="/"
            className={`flex items-center px-4 space-x-0 rtl:space-x-reverse`}
          >
            <img src={logo_row} className="w-60" alt="DELUXE PERFUME Logo" />
            {/* <span className="uppercase self-center text-2xl font-semibold whitespace-nowrap">
              DELUXE PERFUME
            </span> */}
          </Link>

          {/* Nav Item cho Web */}
          <div
            className={`${
              activeMobileNav ? "" : "hidden"
            } hidden lg:flex items-center justify-between w-full h-full md:w-auto md:order-0`}
            id="navbar-language"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent">
              {menuDataList.map((item) => (
                <NavItem key={item.id} item={item} activeNav={activeNav} />
              ))}
            </ul>
          </div>

          {/* Nav Item cho Tablet/Mobile */}
          {/* <MobileNavItem /> */}

          <div className="flex justify-center items-center relative">
            {/* Nút Tìm Kiếm */}
            <div
              className="cursor-pointer px-2"
              onClick={() => handleActiveSearchBar()}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11" cy="11" r="6" stroke="#000000" />
                <path
                  d="M20 20L17 17"
                  stroke="#000000"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            {/* Thanh Tìm Kiếm */}
            <div
              ref={searchRef}
              className={`absolute top-[120%] right-0 md:right-1/2 transform duration-500 ${
                activeSearchBar ? "translate-y-0" : "-translate-y-[200px]"
              }`}
            >
              <form className="form relative">
                <div className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-labelledby="search"
                    className="w-5 h-5 text-gray-700"
                  >
                    <path
                      d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      stroke="currentColor"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <input
                  className="input w-96 rounded-lg px-8 py-3 border-2 border-transparent focus:outline-none focus:border-primary placeholder-gray-400 transition-all duration-300 shadow-md"
                  placeholder="Tìm Kiếm..."
                  required=""
                  type="text"
                  value={keyword}
                  onChange={handleSearch}
                />
                <button
                  type="reset"
                  className="absolute right-3 -translate-y-1/2 top-1/2 p-1 bg-none border-none"
                  onClick={() => handleReset()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </form>

              {keyword && filteredProducts.length > 0 && (
                <div className="absolute w-full h-auto bg-white rounded-lg shadow-lg flex flex-col ">
                  <ul className="">
                    {filteredProducts.slice(0, 5).map((product, index) => (
                      <Link
                        key={index}
                        className="p-2 border-b-2 border-gray-100 flex gap-5 hover:bg-gray-100"
                        to={`${basePath}/${generateProductURL(
                          product.productName
                        )}`}
                        onClick={() => handleViewAllProductSearchBar()}
                      >
                        <div className="w-16 h-16 border p-1 rounded-md bg-white">
                          <img src={product.img[0]} alt={product.productName} />
                        </div>
                        <div className="flex flex-col justify-center items-start">
                          <h4 className="font-semibold text-sm">
                            {product.productName}
                          </h4>
                          <p className="text-primary font-semibold text-sm">
                            {!product.price[0] || !product.price[1]
                              ? "Liên hệ"
                              : `${product.price[0].toLocaleString()} - ${product.price[1].toLocaleString()} ₫`}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </ul>
                  {/* Xem tất cả sản phẩm */}
                  {filteredProducts.length > 5 && (
                    <Link
                      className="flex justify-center items-center text-center text-sm border-none p-2 hover:bg-gray-200 text-gray-400 cursor-pointer"
                      to={basePath}
                      onClick={() => handleViewAllProductSearchBar()}
                    >
                      Xem Tất Cả Sản Phẩm ({filteredProducts.length})
                    </Link>
                  )}
                </div>
              )}
            </div>

            {/* Nút Giỏ Hàng */}
            <Link to={"/gio-hang"} className="px-0 md:px-4 mb-3">
              {/* <ShoppingCartOutlined className="text-2xl" /> ({cartItems.length}) */}
              <div className="relative py-2">
                <div className="t-0 absolute left-3">
                  <p className="flex h-2 w-2 items-center justify-center rounded-full bg-primary p-3 text-xs text-white">
                    {cartItems.length}
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="file: mt-4 h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </div>

        {/* Thanh Tìm Kiếm */}

        {/* Nav Item */}
        <MobileNavItem />

        {/* <div
          className={`${
            activeMobileNav ? "" : "hidden"
          } md:hidden items-center justify-between w-full h-full md:w-auto md:order-1`}
          id="navbar-language"
        >
          <ul
            className={`flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent`}
          >
            {menuDataList.map((item) => (
              <NavItem key={item.id} item={item} activeNav={activeNav} />
            ))}
          </ul>
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
