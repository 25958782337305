import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { Modal, Form, Input, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import emailjs from "emailjs-com";

const MailButton = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendEmail = (values) => {
    emailjs
      .send(
        "service_oou0fxm",
        "template_pcfnkls",
        {
          name: values.name,
          phone: values.phone,
          email: values.email,
          message: values.message,
        },
        "ee_RhqRytUD0w9O6w"
      )
      .then(
        (result) => {
          message.success("Lời nhắn của Quý Khách đã được gửi!");
          form.resetFields();
          setIsModalOpen(false);
        },
        (error) => {
          message.error(
            "Lời nhắn của Quý Khách chưa không thành công, vui lòng thử lại!"
          );
        }
      );
  };

  return (
    <Modal
      title="Liên Hệ Với Chúng Tôi"
      open={isModalOpen}
      onOk={() => {
        form.submit();
      }}
      onCancel={handleCancel}
      okText="Gửi"
      cancelText="Huỷ"
    >
      <Form form={form} ref={form} layout="vertical" onFinish={sendEmail}>
        <Form.Item
          name="name"
          label="Tên"
          validateTrigger="onSubmit"
          rules={[{ required: true, message: "Hãy nhập tên của Quý Khách!" }]}
        >
          <Input placeholder={"Họ và tên của Quý Khách"} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Số Điện Thoại"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: "Hãy kiểm tra lại số điện thoại của Quý Khách!",
              pattern: /(0[3|5|7|8|9])+([0-9]{8})\b/,
            },
          ]}
        >
          <Input placeholder={"Số Điện Thoại của Quý Khách"} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: "Hãy kiểm tra email của Quý Khách!",
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            },
          ]}
        >
          <Input type="email" placeholder={"Email của Quý Khách"} />
        </Form.Item>
        <Form.Item name="message" label="Lời Nhắn">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ContactButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="fixed bottom-2 left-4 flex flex-col gap-0 z-50">
      <a
        href="https://m.me/Deluxeperfume.auth"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-black text-white p-3 rounded-full shadow-lg hover:bg-primary transition scale-75"
      >
        <FontAwesomeIcon icon={faFacebookMessenger} size="2x" />
      </a>
      <a
        href="https://zalo.me/0971261199"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-black text-white p-3 rounded-full shadow-lg hover:bg-primary transition scale-75"
      >
        {/* <FontAwesomeIcon icon={fa} size="2x" /> */}
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_220_246)">
            <path
              d="M9.70306 31.9707C7.80459 31.9707 5.89917 32.0366 4.0007 31.9633C1.75453 31.8681 0.0368652 29.8818 0.0368652 27.5144C0.0368652 19.8699 0.0507734 12.2254 0.0368652 4.57351C0.0368652 1.95693 1.97706 0.13192 4.20933 0.0659556C6.5459 -8.7048e-06 8.88943 0.0512968 11.233 0.0512968C11.2747 0.0512968 11.3303 0.0293087 11.3512 0.102602C11.3373 0.234531 11.2121 0.24919 11.1356 0.300495C9.7587 1.1507 8.5 2.16948 7.44298 3.43013C5.6975 5.51167 4.48749 7.90104 4.0772 10.6789C3.35397 15.5822 4.80738 19.7453 8.09667 23.2194C8.68081 23.8424 8.75731 24.3261 8.29138 25.1617C7.7281 26.1658 6.87275 26.8474 5.96176 27.4704C5.8644 27.5291 5.76704 27.6024 5.66969 27.6683C5.52365 27.8003 5.61405 27.8662 5.73923 27.9249C5.76704 27.9908 5.80181 28.0495 5.84354 28.1081C6.64326 28.8557 7.40126 29.6546 8.18707 30.4168C8.55564 30.776 8.9242 31.1498 9.27886 31.5236C9.4249 31.6628 9.66829 31.7068 9.70306 31.9707Z"
              fill="white"
            />
            <path
              d="M9.70306 31.9707C9.66829 31.7142 9.4249 31.6702 9.27886 31.5163C8.9242 31.1352 8.55564 30.7687 8.18707 30.4096C7.40126 29.6473 6.64326 28.8484 5.84354 28.1008C5.80181 28.0422 5.76704 27.9835 5.73923 27.9176C7.51252 28.284 9.24409 28.0348 10.9478 27.4851C11.525 27.3019 12.1022 27.1187 12.6864 26.9648C13.0828 26.8548 13.5 26.8768 13.8825 27.0234C18.2914 28.6212 22.6446 28.4746 26.9284 26.4957C28.6738 25.6821 30.2663 24.5387 31.6154 23.1095C31.685 23.0362 31.7337 22.9263 31.8588 22.9189C31.9214 23.0216 31.8866 23.1315 31.8866 23.2414V27.5291C31.9006 29.9771 30.0369 31.9707 27.7142 31.9927H27.6794C25.1759 32.0074 22.6725 31.9927 20.169 31.9927H10.1203C9.98123 31.978 9.84215 31.9707 9.70306 31.9707Z"
              fill="white"
            />
            <path
              d="M10.7879 15.5676C11.838 15.5676 12.8255 15.5602 13.806 15.5676C14.3554 15.5749 14.6544 15.8168 14.71 16.2785C14.7726 16.8575 14.4527 17.246 13.8547 17.2533C12.7281 17.268 11.6085 17.2606 10.4819 17.2606C10.1551 17.2606 9.83519 17.2753 9.50835 17.2533C9.10501 17.2313 8.70863 17.1434 8.51391 16.7036C8.3192 16.2638 8.45828 15.8681 8.72254 15.5089C9.79347 14.0724 10.8714 12.6285 11.9492 11.1919C12.0118 11.104 12.0744 11.016 12.137 10.9354C12.0675 10.8108 11.9701 10.8694 11.8867 10.8621C11.1356 10.8548 10.3776 10.8621 9.62657 10.8548C9.45272 10.8548 9.27886 10.8328 9.11197 10.7961C8.71558 10.7009 8.47219 10.2831 8.56259 9.87264C8.62518 9.59413 8.8338 9.36692 9.09806 9.30095C9.26496 9.25698 9.43881 9.23499 9.61266 9.23499C10.8505 9.22766 12.0953 9.22766 13.3331 9.23499C13.5556 9.22766 13.7712 9.25698 13.9868 9.31561C14.4597 9.48419 14.6613 9.94594 14.4736 10.4297C14.3067 10.8474 14.0424 11.2066 13.7782 11.5657C12.8672 12.7897 11.9562 14.0064 11.0452 15.2157C10.9687 15.311 10.8992 15.4063 10.7879 15.5676Z"
              fill="white"
            />
            <path
              d="M18.8547 11.7123C19.0216 11.4851 19.1954 11.2726 19.4805 11.2139C20.0299 11.0967 20.5445 11.4705 20.5515 12.0568C20.5723 13.5227 20.5654 14.9885 20.5515 16.4544C20.5515 16.8355 20.315 17.1727 19.9743 17.2826C19.6266 17.4219 19.2302 17.312 19.0007 16.9968C18.8825 16.8429 18.8338 16.8136 18.6669 16.9528C18.0341 17.4952 17.3178 17.5905 16.5459 17.3266C15.3081 16.9015 14.8004 15.8827 14.6613 14.6441C14.5153 13.3028 14.9395 12.1594 16.08 11.4558C17.0257 10.8621 17.9854 10.9134 18.8547 11.7123ZM16.3929 14.3875C16.4068 14.71 16.5042 15.0179 16.685 15.2744C17.0605 15.8021 17.7768 15.912 18.2844 15.5163C18.3679 15.4503 18.4444 15.3697 18.5139 15.2744C18.9033 14.7174 18.9033 13.8012 18.5139 13.2442C18.3192 12.9583 18.0132 12.7897 17.6864 12.7824C16.9214 12.7311 16.386 13.3541 16.3929 14.3875ZM23.6739 14.4315C23.6182 12.5479 24.7935 11.1406 26.4624 11.0893C28.2357 11.0307 29.5292 12.284 29.5848 14.1164C29.6405 15.9707 28.5626 17.2826 26.9006 17.4585C25.0855 17.6491 23.646 16.2639 23.6739 14.4315ZM25.4193 14.2556C25.4054 14.6221 25.5097 14.9812 25.7184 15.2817C26.1008 15.8094 26.8171 15.912 27.3178 15.5016C27.3943 15.443 27.4569 15.3697 27.5195 15.2964C27.9228 14.7393 27.9228 13.8012 27.5264 13.2442C27.3317 12.9656 27.0257 12.7897 26.6989 12.7824C25.9478 12.7384 25.4193 13.3394 25.4193 14.2556ZM23.0619 12.973C23.0619 14.109 23.0688 15.2451 23.0619 16.3811C23.0688 16.9015 22.6794 17.3339 22.1857 17.3486C22.1022 17.3486 22.0118 17.3413 21.9284 17.3193C21.5807 17.224 21.3164 16.8355 21.3164 16.3738V10.547C21.3164 10.2025 21.3095 9.86532 21.3164 9.52084C21.3234 8.95648 21.6641 8.59001 22.1787 8.59001C22.7072 8.58268 23.0619 8.94915 23.0619 9.5355C23.0688 10.6789 23.0619 11.8296 23.0619 12.973Z"
              fill="white"
            />
            <path
              d="M4.61213 0.0733032H27.5311C30.0389 0.0733032 32.0716 2.22388 32.0716 4.877V27.2696C32.0716 29.9227 30.0389 32.0733 27.5311 32.0733H4.61213C2.10438 32.0733 0.0716362 29.9227 0.0716362 27.2696V4.877C0.0716362 2.22388 2.10438 0.0733032 4.61213 0.0733032Z"
              stroke="white"
              strokeMiterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_220_246">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a
        href="tel:+84971261199"
        className="bg-black text-white p-3 rounded-full shadow-lg hover:bg-primary transition scale-75 pulse-circle"
      >
        <FontAwesomeIcon icon={faPhone} size="2x" className="ringing-phone" />
      </a>
      <div
        className="bg-black text-white p-3 flex justify-center items-center  rounded-full shadow-lg hover:bg-primary transition scale-75 pulse-circle"
        onClick={showModal}
      >
        <MailOutlined
          style={{ fontSize: "32px" }}
          className="text-white ringing-phone"
        />
      </div>

      <MailButton isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default ContactButtons;
