import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProduct: null,
};

const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
  },

  // option 2 for search hashtag and projectName
  // Example: http://localhost:3000/services/photograph/inducstrialarea/du-an-3
  // fetchProductByParams(state, action) {
  //   const { productURL } = action.payload;
  //   const foundProduct = ServicesPhotographBodyList.find(
  //     (product) =>
  //       product.productURL === productURL
  //   );
  //   state.selectedProduct = foundProduct;
  // },
});

export default ProductSlice.reducer;
export const { setSelectedProduct } = ProductSlice.actions;
