import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
// import Promotion from '../../components/Promotion'
import { Outlet } from 'react-router-dom'

const SharedLayout = () => {
  return (
    <section className='SharedLayout_wrapper'>
        <Navbar />
        <Outlet />
        <Footer/>
        {/* <Promotion /> */}
    </section>
  )
}

export default SharedLayout