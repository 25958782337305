import React, { Suspense, useEffect } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { setCartItems } from "./redux/features/CartSlice";
import Aos from "aos";
import "aos/dist/aos.css";

import LoadingFallback from "./components/LoadingFallback";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
import ContactButtons from "./components/ContactButtons";

import SharedLayout from "./pages/SharedLayout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PaymentPolicy from "./pages/PaymentPolicy";
import WarrantyPolicy from "./pages/WarrantyPolicy";

const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const Home = React.lazy(() => import("./pages/Home"));
const AboutUsPage = React.lazy(() => import("./pages/AboutUsPage"));
const CollectionsPage = React.lazy(() => import("./pages/CollectionsPage"));
const ProductDetailPage = React.lazy(() => import("./pages/ProductDetailPage"));
const BradingPage = React.lazy(() => import("./pages/BradingPage"));
const CartPage = React.lazy(() => import("./pages/CartPage"));
const CheckoutPage = React.lazy(() => import("./pages/CheckoutPage"));
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const ReviewPage = React.lazy(() => import("./pages/NewsPage/ReviewPage"));
const KnowledgePage = React.lazy(() => import("./pages/NewsPage/KnowledgePage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));

function App() {
  const dispatch = useDispatch();
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      dispatch(setCartItems(JSON.parse(savedCartItems)));
    }
  }, [dispatch]);

  return (
      <IntlProvider messages={messages} locale={locale}>
        <div className="App">
          <Suspense fallback={<LoadingFallback />}>
            <Router>
              <ScrollToTop />
              <Routes>
                {/* <Route> */}
                <Route element={<SharedLayout />}>
                  <Route index path="/" element={<Home />} />
                  <Route index path="/ve-deluxe-perfume" element={<AboutUsPage />} />
                  <Route index path="/bo-suu-tap-nuoc-hoa" element={<CollectionsPage />} />
                  <Route index path="/bo-suu-tap-nuoc-hoa/:productURL" element={<ProductDetailPage />} />
                  <Route index path="/bo-suu-tap-nuoc-hoa/nam" element={<CollectionsPage />} />
                  <Route index path="/bo-suu-tap-nuoc-hoa/nu" element={<CollectionsPage />} />
                  <Route index path="/bo-suu-tap-nuoc-hoa/unisex" element={<CollectionsPage />} />
                  <Route index path="/bo-suu-tap-nuoc-hoa/:gender/:productURL" element={<ProductDetailPage />} />
                  <Route index path="/tin-tuc/bo-suu-tap-nuoc-hoa/:productURL" element={<ProductDetailPage />} />
                  <Route index path="/thuong-hieu" element={<BradingPage />} />
                  <Route index path="/thuong-hieu/:brandName" element={<CollectionsPage />} />
                  <Route index path="/gio-hang" element={<CartPage />} />
                  <Route index path="/thanh-toan" element={<CheckoutPage />} />
                  <Route index path="/tin-tuc" element={<NewsPage />} />
                  <Route index path="/tin-tuc/review-nuoc-hoa" element={<NewsPage />} />
                  <Route index path="/tin-tuc/review-nuoc-hoa/:slug" element={<ReviewPage />} />
                  <Route index path="/tin-tuc/kien-thuc-nuoc-hoa" element={<NewsPage />} />
                  <Route index path="/tin-tuc/kien-thuc-nuoc-hoa/:slug" element={<KnowledgePage />} />
                  <Route index path="/lien-he" element={<ContactPage />} />
                  <Route index path="/chinh-sach-bao-mat" element={<PrivacyPolicy />} />
                  <Route index path="/chinh-sach-thanh-toan" element={<PaymentPolicy />} />
                  <Route index path="/chinh-sach-bao-hanh" element={<WarrantyPolicy />} />
                  
                  {/* 
                  <Route index path="/services/photograph" element={<Photograph />} />
                  <Route index path="/services/photograph/:hashtag/:projectName" element={<ProjectDetails />} />
                  <Route index path="/services/photo-editing" element={<PhotoEditing />} />
                   */}
                </Route>
                <Route path="*" element={<ErrorPage />} />
              </Routes>
              <ScrollToTopButton />
              <ContactButtons />

            </Router>
          </Suspense>
        </div>
      </IntlProvider>
  );
}

export default App;
