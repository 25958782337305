import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProject: null,
};

const NewsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
  },

  // option 2 for search hashtag and projectName
  // Example: http://localhost:3000/services/photograph/inducstrialarea/du-an-3
  // fetchProjectByParams(state, action) {
    // const { hashtag, projectName } = action.payload;
    // const foundProject = ServicesPhotographBodyList.find(
    //   (p) =>
    //     p.hashtag.includes(hashtag) &&
    //     p.projectname === projectName
    // );
    // state.selectedProject = foundProject;
  // },
});

export default NewsSlice.reducer;
export const { setSelectedProject } = NewsSlice.actions;
