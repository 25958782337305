// Logo
import paco_rabanne from "../assets/logo/brading/paco_rabanne.png";
import matiere_premiere from "../assets/logo/brading/matiere_premiere.png";
import nishane from "../assets/logo/brading/nishane.png";
import borntostandout from "../assets/logo/brading/borntostandout.png";
import diptyque from "../assets/logo/brading/diptyque.png";
import armaf from "../assets/logo/brading/armaf.png";
import attar_collection from "../assets/logo/brading/attar_collection.png";
import hermes from "../assets/logo/brading/hermes.png";
import bdk from "../assets/logo/brading/bdk.png";
import marie_jeanne from "../assets/logo/brading/marie_jeanne.png";
import chloe from "../assets/logo/brading/chloe.png";
import clive_christian from "../assets/logo/brading/clive_christian.png";
import dg from "../assets/logo/brading/dg.png";
import dior from "../assets/logo/brading/dior.png";
import mad_et_len from "../assets/logo/brading/mad_et_len.png";
import elizabeth_arden from "../assets/logo/brading/elizabeth_arden.png";
import Exnihilo from "../assets/logo/brading/Exnihilo.png";
import giardini from "../assets/logo/brading/giardini.png";
import giorgio_armani from "../assets/logo/brading/giorgio_armani.png";
import gucci from "../assets/logo/brading/gucci.png";
import jean_paul_gaultier from "../assets/logo/brading/jean_paul_gaultier.png";
import once from "../assets/logo/brading/once.png";
import juliette_has_a_gun from "../assets/logo/brading/juliette_has_a_gun.png";
import kilian from "../assets/logo/brading/kilian.png";
import le_labo from "../assets/logo/brading/le_labo.png";
import les_soeurs_de_noe from "../assets/logo/brading/les_soeurs_de_noe.png";
import atelier_des_ors from "../assets/logo/brading/atelier_des_ors.png";
import burberry from "../assets/logo/brading/burberry.png";
import afnan from "../assets/logo/brading/afnan.png";
import creed from "../assets/logo/brading/creed.png";
import liquides_imaginaires from "../assets/logo/brading/liquides_imaginaires.png";
import initio_parfums_prives from "../assets/logo/brading/initio_parfums_prives.png";
import lv from "../assets/logo/brading/lv.png";
import mfk from "../assets/logo/brading/mfk.png";
import mcm from "../assets/logo/brading/mcm.png";
import missoni from "../assets/logo/brading/missoni.png";
import montale from "../assets/logo/brading/montale.png";
import montblanc from "../assets/logo/brading/montblanc.png";
import lorchestre_parfum from "../assets/logo/brading/lorchestre_parfum.png";
import narciso_rodriguez from "../assets/logo/brading/narciso_rodriguez.png";
import nasomatto from "../assets/logo/brading/nasomatto.png";
import viktor_rolf from "../assets/logo/brading/viktor_rolf.png";
import valentino from "../assets/logo/brading/valentino.png";
import imaginary_authors from "../assets/logo/brading/imaginary_authors.png";
import loewe from "../assets/logo/brading/loewe.png";
import franck_boclet from "../assets/logo/brading/franck_boclet.png";
import alaia from "../assets/logo/brading/alaia.png";
import tamburins from "../assets/logo/brading/tamburins.png";
import orto_parisi from "../assets/logo/brading/orto_parisi.png";
import chanel from "../assets/logo/brading/chanel.png";
import prada from "../assets/logo/brading/prada.png";
import maison_margiela_replica from "../assets/logo/brading/maison_margiela_replica.png";
import roja_parfums from "../assets/logo/brading/roja_parfums.png";
import mugler from "../assets/logo/brading/mugler.png";
import alexandria_fragrances from "../assets/logo/brading/alexandria_fragrances.png";
import parfums_de_marly from "../assets/logo/brading/parfums_de_marly.png";
import thomas_kosmala from "../assets/logo/brading/thomas_kosmala.png";
import tom_ford from "../assets/logo/brading/tom_ford.png";
import van_cleef_arpels from "../assets/logo/brading/van_cleef_arpels.png";
import versace from "../assets/logo/brading/versace.png";
import xerjoff from "../assets/logo/brading/xerjoff.png";
import ysl from "../assets/logo/brading/ysl.png";
import al_haramain_perfumes from "../assets/logo/brading/al_haramain_perfumes.png";
import ds_durga from "../assets/logo/brading/ds_durga.png";
import marc_jacobs from "../assets/logo/brading/marc_jacobs.png";
import moschino from "../assets/logo/brading/moschino.png";
import signorina from "../assets/logo/brading/signorina.png";
import carolina_herrera from "../assets/logo/brading/carolina_herrera.png";
import bvlgari from "../assets/logo/brading/bvlgari.png";
import lancome from "../assets/logo/brading/lancome.png";
import maison_matine from "../assets/logo/brading/maison_matine.png";

// Ảnh Sản Phẩm
import pure_xs_for_her_1 from "../assets/images/product/pure_xs_for_her_1.png";
import pure_xs_for_her_2 from "../assets/images/product/pure_xs_for_her_2.png";
import paco_rabanne_phantom_1 from "../assets/images/product/paco_rabanne_phantom_1.png";
import paco_rabanne_phantom_2 from "../assets/images/product/paco_rabanne_phantom_2.png";
import million_1 from "../assets/images/product/1_million_1.png";
import million_2 from "../assets/images/product/1_million_2.png";
import million_lucky_1 from "../assets/images/product/1_million_lucky_1.png";
import million_lucky_2 from "../assets/images/product/1_million_lucky_2.png";
import pure_xs_for_him_1 from "../assets/images/product/pure_xs_for_him_1.png";
import pure_xs_for_him_2 from "../assets/images/product/pure_xs_for_him_2.png";
import paco_rabanne_fame_1 from "../assets/images/product/paco_rabanne_fame_1.png";
import paco_rabanne_fame_2 from "../assets/images/product/paco_rabanne_fame_2.png";
import cologne_cedrat_1 from "../assets/images/product/cologne_cedrat_1.png";
import cologne_cedrat_2 from "../assets/images/product/cologne_cedrat_2.png";
import santal_austral_1 from "../assets/images/product/santal_austral_1.png";
import santal_austral_2 from "../assets/images/product/santal_austral_2.png";
import french_flower_1 from "../assets/images/product/french_flower_1.png";
import french_flower_2 from "../assets/images/product/french_flower_2.png";
import crystal_saffron_1 from "../assets/images/product/crystal_saffron_1.png";
import crystal_saffron_2 from "../assets/images/product/crystal_saffron_2.png";
import radical_rose_1 from "../assets/images/product/radical_rose_1.png";
import radical_rose_2 from "../assets/images/product/radical_rose_2.png";
import ani_1 from "../assets/images/product/ani_1.png";
import ani_2 from "../assets/images/product/ani_2.png";
import hacivat_1 from "../assets/images/product/hacivat_1.png";
import hacivat_2 from "../assets/images/product/hacivat_2.png";
import wulong_cha_1 from "../assets/images/product/wulong_cha_1.png";
import wulong_cha_2 from "../assets/images/product/wulong_cha_2.png";
import hacivat_x_1 from "../assets/images/product/hacivat_x_1.png";
import hacivat_x_2 from "../assets/images/product/hacivat_x_2.png";
import dirty_heaven_1 from "../assets/images/product/dirty_heaven_1.png";
import dirty_heaven_2 from "../assets/images/product/dirty_heaven_2.png";
import dirty_rice_1 from "../assets/images/product/dirty_rice_1.png";
import dirty_rice_2 from "../assets/images/product/dirty_rice_2.png";
import fig_porn_1 from "../assets/images/product/fig_porn_1.png";
import fig_porn_2 from "../assets/images/product/fig_porn_2.png";
import naked_neroli_1 from "../assets/images/product/naked_neroli_1.png";
import naked_neroli_2 from "../assets/images/product/naked_neroli_2.png";
import sugar_addict_1 from "../assets/images/product/sugar_addict_1.png";
import sugar_addict_2 from "../assets/images/product/sugar_addict_2.png";
import sex_cognac_1 from "../assets/images/product/sex_cognac_1.png";
import sex_cognac_2 from "../assets/images/product/sex_cognac_2.png";
import ilio_edp_1 from "../assets/images/product/ilio_edp_1.png";
import ilio_edp_2 from "../assets/images/product/ilio_edp_2.png";
import ilio_limited_1 from "../assets/images/product/ilio_limited_1.png";
import ilio_limited_2 from "../assets/images/product/ilio_limited_2.png";
import eau_rose_1 from "../assets/images/product/eau_rose_1.png";
import eau_rose_2 from "../assets/images/product/eau_rose_2.png";
import leau_papier_1 from "../assets/images/product/leau_papier_1.png";
import leau_papier_2 from "../assets/images/product/leau_papier_2.png";
import tamdao_1 from "../assets/images/product/tamdao_1.png";
import tamdao_2 from "../assets/images/product/tamdao_2.png";
import fleur_de_peau_1 from "../assets/images/product/fleur_de_peau_1.png";
import fleur_de_peau_2 from "../assets/images/product/fleur_de_peau_2.png";
import do_son_1 from "../assets/images/product/do_son_1.png";
import do_son_2 from "../assets/images/product/do_son_2.png";
import club_de_nuit_women_1 from "../assets/images/product/club_de_nuit_women_1.png";
import club_de_nuit_women_2 from "../assets/images/product/club_de_nuit_women_2.png";
import club_de_nuit_intense_man_1 from "../assets/images/product/club_de_nuit_intense_man_1.png";
import club_de_nuit_intense_man_2 from "../assets/images/product/club_de_nuit_intense_man_2.png";
import club_de_nuit_intense_man_limited_edition_1 from "../assets/images/product/club_de_nuit_intense_man_limited_edition_1.png";
import club_de_nuit_intense_man_limited_edition_2 from "../assets/images/product/club_de_nuit_intense_man_limited_edition_2.png";
import club_de_nuit_urban_elixir_1 from "../assets/images/product/club_de_nuit_urban_elixir_1.png";
import club_de_nuit_urban_elixir_2 from "../assets/images/product/club_de_nuit_urban_elixir_2.png";
import club_de_nuit_untold_1 from "../assets/images/product/club_de_nuit_untold_1.png";
import club_de_nuit_untold_2 from "../assets/images/product/club_de_nuit_untold_2.png";
import club_de_nuit_imperiale_1 from "../assets/images/product/club_de_nuit_imperiale_1.png";
import club_de_nuit_imperiale_2 from "../assets/images/product/club_de_nuit_imperiale_2.png";
import club_de_nuit_blue_iconic_1 from "../assets/images/product/club_de_nuit_blue_iconic_1.png";
import club_de_nuit_blue_iconic_2 from "../assets/images/product/club_de_nuit_blue_iconic_2.png";
import ventana_1 from "../assets/images/product/ventana_1.png";
import ventana_2 from "../assets/images/product/ventana_2.png";
import la_rosa_1 from "../assets/images/product/la_rosa_1.png";
import la_rosa_2 from "../assets/images/product/la_rosa_2.png";
import musk_kashmir_1 from "../assets/images/product/musk_kashmir_1.png";
import musk_kashmir_2 from "../assets/images/product/musk_kashmir_2.png";
import queen_of_sheba_1 from "../assets/images/product/queen_of_sheba_1.png";
import queen_of_sheba_2 from "../assets/images/product/queen_of_sheba_2.png";
import eau_des_merveilles_bleue_1 from "../assets/images/product/eau_des_merveilles_bleue_1.png";
import eau_des_merveilles_bleue_2 from "../assets/images/product/eau_des_merveilles_bleue_2.png";
import eau_des_merveilles_1 from "../assets/images/product/eau_des_merveilles_1.png";
import eau_des_merveilles_2 from "../assets/images/product/eau_des_merveilles_2.png";
import un_jarrdin_sur_le_toit_1 from "../assets/images/product/un_jarrdin_sur_le_toit_1.png";
import un_jarrdin_sur_le_toit_2 from "../assets/images/product/un_jarrdin_sur_le_toit_2.png";
import un_jardin_sur_le_nil_1 from "../assets/images/product/un_jardin_sur_le_nil_1.png";
import un_jardin_sur_le_nil_2 from "../assets/images/product/un_jardin_sur_le_nil_2.png";
import h24_1 from "../assets/images/product/h24_1.png";
import h24_2 from "../assets/images/product/h24_2.png";
import twilly_dhermes_eau_poivree_1 from "../assets/images/product/twilly_dhermes_eau_poivree_1.png";
import twilly_dhermes_eau_poivree_2 from "../assets/images/product/twilly_dhermes_eau_poivree_2.png";
import terre_dhermes_1 from "../assets/images/product/terre_dhermes_1.png";
import terre_dhermes_2 from "../assets/images/product/terre_dhermes_2.png";
import twilly_dhermes_1 from "../assets/images/product/twilly_dhermes_1.png";
import twilly_dhermes_2 from "../assets/images/product/twilly_dhermes_2.png";
import gris_charnel_extrait_1 from "../assets/images/product/gris_charnel_extrait_1.png";
import gris_charnel_extrait_2 from "../assets/images/product/gris_charnel_extrait_2.png";
import rouge_smoking_1 from "../assets/images/product/rouge_smoking_1.png";
import rouge_smoking_2 from "../assets/images/product/rouge_smoking_2.png";
// import gris_charnel_extrait_1 from "../assets/images/product/gris_charnel_extrait_1.png";
// import gris_charnel_extrait_2 from "../assets/images/product/gris_charnel_extrait_2.png";
import vetiver_santal_1 from "../assets/images/product/vetiver_santal_1.png";
import vetiver_santal_2 from "../assets/images/product/vetiver_santal_2.png";
import chloe_1 from "../assets/images/product/chloe_1.png";
import chloe_2 from "../assets/images/product/chloe_2.png";
import love_story_1 from "../assets/images/product/love_story_1.png";
import love_story_2 from "../assets/images/product/love_story_2.png";
import nomade_1 from "../assets/images/product/nomade_1.png";
import nomade_2 from "../assets/images/product/nomade_2.png";
import feminine_1872_1 from "../assets/images/product/1872_feminine_1.png";
import feminine_1872_2 from "../assets/images/product/1872_feminine_2.png";
import masculine_1872_1 from "../assets/images/product/1872_masculine_1.png";
import masculine_1872_2 from "../assets/images/product/1872_masculine_2.png";
import town_country_1 from "../assets/images/product/town_country_1.png";
import town_country_2 from "../assets/images/product/town_country_2.png";
import e_cashmere_musk_1 from "../assets/images/product/e_cashmere_musk_1.png";
import e_cashmere_musk_2 from "../assets/images/product/e_cashmere_musk_2.png";
import hedonistic_1 from "../assets/images/product/hedonistic_1.png";
import hedonistic_2 from "../assets/images/product/hedonistic_2.png";
import crab_apple_blossom_1 from "../assets/images/product/crab_apple_blossom_1.png";
import crab_apple_blossom_2 from "../assets/images/product/crab_apple_blossom_2.png";
import _iconic_feminine_1 from "../assets/images/product/_iconic_feminine_1.png";
import _iconic_feminine_2 from "../assets/images/product/_iconic_feminine_2.png";
import sauvage_edt_1 from "../assets/images/product/sauvage_edt_1.png";
import sauvage_edt_2 from "../assets/images/product/sauvage_edt_2.png";
import sauvage_edp_1 from "../assets/images/product/sauvage_edp_1.png";
import sauvage_edp_2 from "../assets/images/product/sauvage_edp_2.png";
import sauvage_parfum_1 from "../assets/images/product/sauvage_parfum_1.png";
import sauvage_parfum_2 from "../assets/images/product/sauvage_parfum_2.png";
import sauvage_elixir_1 from "../assets/images/product/sauvage_elixir_1.png";
import sauvage_elixir_2 from "../assets/images/product/sauvage_elixir_2.png";
import miss_dior_1 from "../assets/images/product/miss_dior_1.png";
import miss_dior_2 from "../assets/images/product/miss_dior_2.png";
import rose_n_rose_1 from "../assets/images/product/rose_n_rose_1.png";
import rose_n_rose_2 from "../assets/images/product/rose_n_rose_2.png";
import blooming_bouquet_1 from "../assets/images/product/blooming_bouquet_1.png";
import blooming_bouquet_2 from "../assets/images/product/blooming_bouquet_2.png";
import dior_joy_1 from "../assets/images/product/dior_joy_1.png";
import dior_joy_2 from "../assets/images/product/dior_joy_2.png";
import dior_homme_intense_1 from "../assets/images/product/dior_homme_intense_1.png";
import dior_homme_intense_2 from "../assets/images/product/dior_homme_intense_2.png";
import jadore_1 from "../assets/images/product/jadore_1.png";
import jadore_2 from "../assets/images/product/jadore_2.png";
import holy_peony_1 from "../assets/images/product/holy_peony_1.png";
import holy_peony_2 from "../assets/images/product/holy_peony_2.png";
import light_blue_eau_intense_1 from "../assets/images/product/light_blue_eau_intense_1.png";
import light_blue_eau_intense_2 from "../assets/images/product/light_blue_eau_intense_2.png";
import light_blue_eau_intense_pour_homme_1 from "../assets/images/product/light_blue_eau_intense_pour_homme_1.png";
import light_blue_eau_intense_pour_homme_2 from "../assets/images/product/light_blue_eau_intense_pour_homme_2.png";
import the_one_for_men_1 from "../assets/images/product/the_one_for_men_1.png";
import the_one_for_men_2 from "../assets/images/product/the_one_for_men_2.png";
import limperatrice_3_1 from "../assets/images/product/limperatrice_3_1.png";
import limperatrice_3_2 from "../assets/images/product/limperatrice_3_2.png";
import limperatrice_3__limited_edition_1 from "../assets/images/product/limperatrice_3__limited_edition_1.png";
import limperatrice_3__limited_edition_2 from "../assets/images/product/limperatrice_3__limited_edition_2.png";
import pour_homme_intenso_1 from "../assets/images/product/pour_homme_intenso_1.png";
import pour_homme_intenso_2 from "../assets/images/product/pour_homme_intenso_2.png";
import red_musc_1 from "../assets/images/product/red_musc_1.png";
import red_musc_2 from "../assets/images/product/red_musc_2.png";
import black_musc_1 from "../assets/images/product/black_musc_1.png";
import black_musc_2 from "../assets/images/product/black_musc_2.png";
import terre_noire_1 from "../assets/images/product/terre_noire_1.png";
import terre_noire_2 from "../assets/images/product/terre_noire_2.png";
import white_tea_1 from "../assets/images/product/white_tea_1.png";
import white_tea_2 from "../assets/images/product/white_tea_2.png";
import fleur_narcotique_1 from "../assets/images/product/fleur_narcotique_1.png";
import fleur_narcotique_2 from "../assets/images/product/fleur_narcotique_2.png";
import fleur_narcotique_10years_limited_1 from "../assets/images/product/fleur_narcotique_10years_limited_1.png";
import fleur_narcotique_10years_limited_2 from "../assets/images/product/fleur_narcotique_10years_limited_2.png";
import santal_calling_1 from "../assets/images/product/santal_calling_1.png";
import santal_calling_2 from "../assets/images/product/santal_calling_2.png";
import di_toscana_bianco_latte_1 from "../assets/images/product/di_toscana_bianco_latte_1.png";
import di_toscana_bianco_latte_2 from "../assets/images/product/di_toscana_bianco_latte_2.png";
import acqua_di_gio_absolu_1 from "../assets/images/product/acqua_di_gio_absolu_1.png";
import acqua_di_gio_absolu_2 from "../assets/images/product/acqua_di_gio_absolu_2.png";
import di_gio_edt_1 from "../assets/images/product/di_gio_edt_1.png";
import di_gio_edt_2 from "../assets/images/product/di_gio_edt_2.png";
import acqua_di_gio_profumo_1 from "../assets/images/product/acqua_di_gio_profumo_1.png";
import acqua_di_gio_profumo_2 from "../assets/images/product/acqua_di_gio_profumo_2.png";
import acqua_di_gio_parfum_1 from "../assets/images/product/acqua_di_gio_parfum_1.png";
import acqua_di_gio_parfum_2 from "../assets/images/product/acqua_di_gio_parfum_2.png";
import acqua_di_gio_profondo_1 from "../assets/images/product/acqua_di_gio_profondo_1.png";
import acqua_di_gio_profondo_2 from "../assets/images/product/acqua_di_gio_profondo_2.png";
import acqua_di_gio_edp_1 from "../assets/images/product/acqua_di_gio_edp_1.png";
import acqua_di_gio_edp_2 from "../assets/images/product/acqua_di_gio_edp_2.png";
import acqua_di_gioia_1 from "../assets/images/product/acqua_di_gioia_1.png";
import acqua_di_gioia_2 from "../assets/images/product/acqua_di_gioia_2.png";
import my_way_edp_1 from "../assets/images/product/my_way_edp_1.png";
import my_way_edp_2 from "../assets/images/product/my_way_edp_2.png";
import si_edp_1 from "../assets/images/product/si_edp_1.png";
import si_edp_2 from "../assets/images/product/si_edp_2.png";
import si_passione_edp_1 from "../assets/images/product/si_passione_edp_1.png";
import si_passione_edp_2 from "../assets/images/product/si_passione_edp_2.png";
import prive_the_yulong_edt_1 from "../assets/images/product/prive_the_yulong_edt_1.png";
import prive_the_yulong_edt_2 from "../assets/images/product/prive_the_yulong_edt_2.png";
import prive_the_yulong_soie_de_nacre_edt_1 from "../assets/images/product/prive_the_yulong_soie_de_nacre_edt_1.png";
import prive_the_yulong_soie_de_nacre_edt_2 from "../assets/images/product/prive_the_yulong_soie_de_nacre_edt_2.png";
import rose_milano_edt_1 from "../assets/images/product/rose_milano_edt_1.png";
import rose_milano_edt_2 from "../assets/images/product/rose_milano_edt_2.png";
import rose_milano_soie_de_nacre_1 from "../assets/images/product/rose_milano_soie_de_nacre_1.png";
import rose_milano_soie_de_nacre_2 from "../assets/images/product/rose_milano_soie_de_nacre_2.png";
import memoire_dune_odeur_1 from "../assets/images/product/memoire_dune_odeur_1.png";
import memoire_dune_odeur_2 from "../assets/images/product/memoire_dune_odeur_2.png";
import bloom_edp_1 from "../assets/images/product/bloom_edp_1.png";
import bloom_edp_2 from "../assets/images/product/bloom_edp_2.png";
import flora_gorgeous_gardenia_1 from "../assets/images/product/flora_gorgeous_gardenia_1.png";
import flora_gorgeous_gardenia_2 from "../assets/images/product/flora_gorgeous_gardenia_2.png";
import flora_gorgeous_jasmine_1 from "../assets/images/product/flora_gorgeous_jasmine_1.png";
import flora_gorgeous_jasmine_2 from "../assets/images/product/flora_gorgeous_jasmine_2.png";
import flora_gorgeous_magnolia_1 from "../assets/images/product/flora_gorgeous_magnolia_1.png";
import flora_gorgeous_magnolia_2 from "../assets/images/product/flora_gorgeous_magnolia_2.png";
import bamboo_edp_1 from "../assets/images/product/bamboo_edp_1.png";
import bamboo_edp_2 from "../assets/images/product/bamboo_edp_2.png";
import guilty_elixir_pour_femme_1 from "../assets/images/product/guilty_elixir_pour_femme_1.png";
import guilty_elixir_pour_femme_2 from "../assets/images/product/guilty_elixir_pour_femme_2.png";
import le_male_pride_edition_1 from "../assets/images/product/le_male_pride_edition_1.png";
import le_male_pride_edition_2 from "../assets/images/product/le_male_pride_edition_2.png";
import scandal_by_night_edp_intense_1 from "../assets/images/product/scandal_by_night_edp_intense_1.png";
import scandal_by_night_edp_intense_2 from "../assets/images/product/scandal_by_night_edp_intense_2.png";
import scandal_edp_1 from "../assets/images/product/scandal_edp_1.png";
import scandal_edp_2 from "../assets/images/product/scandal_edp_2.png";
import jean_paul_gaultier_so_scandal_1 from "../assets/images/product/jean_paul_gaultier_so_scandal_1.png";
import jean_paul_gaultier_so_scandal_2 from "../assets/images/product/jean_paul_gaultier_so_scandal_2.png";
import jean_paul_gaultier_le_beau_edt_1 from "../assets/images/product/jean_paul_gaultier_le_beau_edt_1.png";
import jean_paul_gaultier_le_beau_edt_2 from "../assets/images/product/jean_paul_gaultier_le_beau_edt_2.png";
import scandal_pour_homme_edt_1 from "../assets/images/product/scandal_pour_homme_edt_1.png";
import scandal_pour_homme_edt_2 from "../assets/images/product/scandal_pour_homme_edt_2.png";
import jean_paul_gaultier_la_belle_le_parfum_1 from "../assets/images/product/jean_paul_gaultier_la_belle_le_parfum_1.png";
import jean_paul_gaultier_la_belle_le_parfum_2 from "../assets/images/product/jean_paul_gaultier_la_belle_le_parfum_2.png";
import jean_paul_gaultier_le_male_le_parfum_1 from "../assets/images/product/jean_paul_gaultier_le_male_le_parfum_1.png";
import jean_paul_gaultier_le_male_le_parfum_2 from "../assets/images/product/jean_paul_gaultier_le_male_le_parfum_2.png";
import jean_paul_gaultier_ultra_male_1 from "../assets/images/product/jean_paul_gaultier_ultra_male_1.png";
import jean_paul_gaultier_ultra_male_2 from "../assets/images/product/jean_paul_gaultier_ultra_male_2.png";
import le_male_lover_1 from "../assets/images/product/le_male_lover_1.png";
import le_male_lover_2 from "../assets/images/product/le_male_lover_2.png";
import le_male_elixir_edp_1 from "../assets/images/product/le_male_elixir_edp_1.png";
import le_male_elixir_edp_2 from "../assets/images/product/le_male_elixir_edp_1.png";
import lipsens_edp_1 from "../assets/images/product/lipsens_edp_1.png";
import lipsens_edp_2 from "../assets/images/product/lipsens_edp_2.png";
import juliette_has_a_gun_not_a_perfume_edp_1 from "../assets/images/product/juliette_has_a_gun_not_a_perfume_edp_1.png";
import juliette_has_a_gun_not_a_perfume_edp_2 from "../assets/images/product/juliette_has_a_gun_not_a_perfume_edp_2.png";
import kilian_voulez_vous_coucher_avec_moi_1 from "../assets/images/product/kilian_voulez_vous_coucher_avec_moi_1.png";
import kilian_voulez_vous_coucher_avec_moi_2 from "../assets/images/product/kilian_voulez_vous_coucher_avec_moi_2.png";
import kilian_good_girl_gone_bad_1 from "../assets/images/product/kilian_good_girl_gone_bad_1.png";
import kilian_good_girl_gone_bad_2 from "../assets/images/product/kilian_good_girl_gone_bad_2.png";
import kilian_moonlight_in_heaven_1 from "../assets/images/product/kilian_moonlight_in_heaven_1.png";
import kilian_moonlight_in_heaven_2 from "../assets/images/product/kilian_moonlight_in_heaven_2.png";
import kilian_rolling_in_love_1 from "../assets/images/product/kilian_rolling_in_love_1.png";
import kilian_rolling_in_love_2 from "../assets/images/product/kilian_rolling_in_love_2.png";
import kilian_black_phantom_memento_mori_1 from "../assets/images/product/kilian_black_phantom_memento_mori_1.png";
import kilian_black_phantom_memento_mori_2 from "../assets/images/product/kilian_black_phantom_memento_mori_2.png";
import kilian_roses_on_ice_1 from "../assets/images/product/kilian_roses_on_ice_1.png";
import kilian_roses_on_ice_2 from "../assets/images/product/kilian_roses_on_ice_2.png";
import kilian_angels_share_1 from "../assets/images/product/kilian_angels_share_1.png";
import kilian_angels_share_2 from "../assets/images/product/kilian_angels_share_2.png";
import kilian_apple_brandy_on_the_rock_1 from "../assets/images/product/kilian_apple_brandy_on_the_rock_1.png";
import kilian_apple_brandy_on_the_rock_2 from "../assets/images/product/kilian_apple_brandy_on_the_rock_2.png";
import le_labo_another_13_1 from "../assets/images/product/le_labo_another_13_1.png";
import le_labo_another_13_2 from "../assets/images/product/le_labo_another_13_2.png";
import bergamote_22_1 from "../assets/images/product/bergamote_22_1.png";
import bergamote_22_2 from "../assets/images/product/bergamote_22_2.png";
import santal_33_1 from "../assets/images/product/santal_33_1.png";
import santal_33_2 from "../assets/images/product/santal_33_2.png";
import rose_31_1 from "../assets/images/product/rose_31_1.png";
import rose_31_2 from "../assets/images/product/rose_31_2.png";
import call_me_iris_edp_1 from "../assets/images/product/call_me_iris_edp_1.png";
import call_me_iris_edp_2 from "../assets/images/product/call_me_iris_edp_2.png";
import clandestine_rendez_vous_edp_1 from "../assets/images/product/clandestine_rendez_vous_edp_1.png";
import clandestine_rendez_vous_edp_2 from "../assets/images/product/clandestine_rendez_vous_edp_2.png";
import nomad_soul_edp_1 from "../assets/images/product/nomad_soul_edp_1.png";
import nomad_soul_edp_2 from "../assets/images/product/nomad_soul_edp_2.png";
import nuda_veritas_edp_1 from "../assets/images/product/nuda_veritas_edp_1.png";
import nuda_veritas_edp_2 from "../assets/images/product/nuda_veritas_edp_2.png";
import pink_me_up_edp_1 from "../assets/images/product/pink_me_up_edp_1.png";
import pink_me_up_edp_2 from "../assets/images/product/pink_me_up_edp_2.png";
import noir_by_night_edp_1 from "../assets/images/product/noir_by_night_edp_1.png";
import noir_by_night_edp_2 from "../assets/images/product/noir_by_night_edp_2.png";
import burberry_her_edp_1 from "../assets/images/product/burberry_her_edp_1.png";
import burberry_her_edp_2 from "../assets/images/product/burberry_her_edp_2.png";
import burberry_london_for_women_1 from "../assets/images/product/burberry_london_for_women_1.png";
import burberry_london_for_women_2 from "../assets/images/product/burberry_london_for_women_2.png";
import burberry_london_for_men_1 from "../assets/images/product/burberry_london_for_men_1.png";
import burberry_london_for_men_2 from "../assets/images/product/burberry_london_for_men_2.png";
import afnan_9am_1 from "../assets/images/product/afnan_9am_1.png";
import afnan_9am_2 from "../assets/images/product/afnan_9am_2.png";
import afnan_9pm_1 from "../assets/images/product/afnan_9pm_1.png";
import afnan_9pm_2 from "../assets/images/product/afnan_9pm_2.png";
import afnan_supremacy_silver_1 from "../assets/images/product/afnan_supremacy_silver_1.png";
import afnan_supremacy_silver_2 from "../assets/images/product/afnan_supremacy_silver_2.png";
import spring_flower_edp_1 from "../assets/images/product/spring_flower_edp_1.png";
import spring_flower_edp_2 from "../assets/images/product/spring_flower_edp_2.png";
import wind_flowers_edp_1 from "../assets/images/product/wind_flowers_edp_1.png";
import wind_flowers_edp_2 from "../assets/images/product/wind_flowers_edp_2.png";
import silver_moutain_water_edp_1 from "../assets/images/product/silver_moutain_water_edp_1.png";
import silver_moutain_water_edp_2 from "../assets/images/product/silver_moutain_water_edp_2.png";
import creed_aventus_for_her_1 from "../assets/images/product/creed_aventus_for_her_1.png";
import creed_aventus_for_her_2 from "../assets/images/product/creed_aventus_for_her_2.png";
import aventus_for_men_edp_1 from "../assets/images/product/aventus_for_men_edp_1.png";
import aventus_for_men_edp_2 from "../assets/images/product/aventus_for_men_edp_2.png";
import tapis_volant_edp_1 from "../assets/images/product/tapis_volant_edp_1.png";
import tapis_volant_edp_2 from "../assets/images/product/tapis_volant_edp_2.png";
import phan_tasma_edp_1 from "../assets/images/product/phan_tasma_edp_1.png";
import phan_tasma_edp_2 from "../assets/images/product/phan_tasma_edp_2.png";
import blanche_bete_1 from "../assets/images/product/blanche_bete_1.png";
import blanche_bete_2 from "../assets/images/product/blanche_bete_2.png";
import musk_therapy_edp_1 from "../assets/images/product/musk_therapy_edp_1.png";
import musk_therapy_edp_2 from "../assets/images/product/musk_therapy_edp_2.png";
import rose_des_vents_eau_de_parfum_1 from "../assets/images/product/rose_des_vents_eau_de_parfum_1.png";
import rose_des_vents_eau_de_parfum_2 from "../assets/images/product/rose_des_vents_eau_de_parfum_2.png";
import parcific_chill_1 from "../assets/images/product/parcific_chill_1.png";
import parcific_chill_2 from "../assets/images/product/parcific_chill_2.png";
import louis_vuitton_california_dream_1 from "../assets/images/product/louis_vuitton_california_dream_1.png";
import louis_vuitton_california_dream_2 from "../assets/images/product/louis_vuitton_california_dream_2.png";
import louis_vuitton_attrape_reves_1 from "../assets/images/product/louis_vuitton_attrape_reves_1.png";
import louis_vuitton_attrape_reves_2 from "../assets/images/product/louis_vuitton_attrape_reves_2.png";
import imagination_1 from "../assets/images/product/imagination_1.png";
import imagination_2 from "../assets/images/product/imagination_2.png";
import spell_on_you_1 from "../assets/images/product/spell_on_you_1.png";
import spell_on_you_2 from "../assets/images/product/spell_on_you_2.png";
import _dancing_blossom_1 from "../assets/images/product/_dancing_blossom_1.png";
import _dancing_blossom_2 from "../assets/images/product/_dancing_blossom_2.png";
import mfk_baccarat_rouge_540_edp_1 from "../assets/images/product/mfk_baccarat_rouge_540_edp_1.png";
import mfk_baccarat_rouge_540_edp_2 from "../assets/images/product/mfk_baccarat_rouge_540_edp_2.png";
import baccarat_rouge_540_extrait_de_parfum_1 from "../assets/images/product/baccarat_rouge_540_extrait_de_parfum_1.png";
import baccarat_rouge_540_extrait_de_parfum_2 from "../assets/images/product/baccarat_rouge_540_extrait_de_parfum_2.png";
import maison_francis_kurkdjian_724_edp_1 from "../assets/images/product/maison_francis_kurkdjian_724_edp_1.png";
import maison_francis_kurkdjian_724_edp_2 from "../assets/images/product/maison_francis_kurkdjian_724_edp_2.png";
import mcm_edp_1 from "../assets/images/product/mcm_edp_1.png";
import mcm_edp_2 from "../assets/images/product/mcm_edp_2.png";
import missoni_wave_edt_1 from "../assets/images/product/missoni_wave_edt_1.png";
import missoni_wave_edt_2 from "../assets/images/product/missoni_wave_edt_2.png";
import warni_warni_edp_1 from "../assets/images/product/warni_warni_edp_1.png";
import warni_warni_edp_2 from "../assets/images/product/warni_warni_edp_2.png";
import nature_insolente_edp_1 from "../assets/images/product/nature_insolente_edp_1.png";
import nature_insolente_edp_2 from "../assets/images/product/nature_insolente_edp_2.png";
import chocolate_greedy_1 from "../assets/images/product/chocolate_greedy_1.png";
import chocolate_greedy_2 from "../assets/images/product/chocolate_greedy_2.png";
import intense_cafe_1 from "../assets/images/product/intense_cafe_1.png";
import intense_cafe_2 from "../assets/images/product/intense_cafe_2.png";
import montblanc_signature_1 from "../assets/images/product/montblanc_signature_1.png";
import montblanc_signature_2 from "../assets/images/product/montblanc_signature_2.png";
import montblanc_explorer_1 from "../assets/images/product/montblanc_explorer_1.png";
import montblanc_explorer_2 from "../assets/images/product/montblanc_explorer_2.png";
import vetiver_overdrive_1 from "../assets/images/product/vetiver_overdrive_1.png";
import vetiver_overdrive_2 from "../assets/images/product/vetiver_overdrive_2.png";
import piano_santal_1 from "../assets/images/product/piano_santal_1.png";
import piano_santal_2 from "../assets/images/product/piano_santal_2.png";
import flamenco_neroli_1 from "../assets/images/product/flamenco_neroli_1.png";
import flamenco_neroli_2 from "../assets/images/product/flamenco_neroli_2.png";
import nar_poudree_edp_1 from "../assets/images/product/nar_poudree_edp_1.png";
import nar_poudree_edp_2 from "../assets/images/product/nar_poudree_edp_2.png";
import nar_ambree_1 from "../assets/images/product/nar_ambree_1.png";
import nar_ambree_2 from "../assets/images/product/nar_ambree_2.png";
import nar_cristal_edp_1 from "../assets/images/product/nar_cristal_edp_1.png";
import nar_cristal_edp_2 from "../assets/images/product/nar_cristal_edp_2.png";
import nar_all_of_me_edp_1 from "../assets/images/product/nar_all_of_me_edp_1.png";
import nar_all_of_me_edp_2 from "../assets/images/product/nar_all_of_me_edp_2.png";
import narciso_musc_nude_1 from "../assets/images/product/narciso_musc_nude_1.png";
import narciso_musc_nude_2 from "../assets/images/product/narciso_musc_nude_2.png";
import nar_fleur_musc_1 from "../assets/images/product/nar_fleur_musc_1.png";
import nar_fleur_musc_2 from "../assets/images/product/nar_fleur_musc_2.png";
import narciso_rodriguez_for_her_edp_1 from "../assets/images/product/narciso_rodriguez_for_her_edp_1.png";
import narciso_rodriguez_for_her_edp_2 from "../assets/images/product/narciso_rodriguez_for_her_edp_2.png";
import nar_forever_edp_1 from "../assets/images/product/nar_forever_edp_1.png";
import nar_forever_edp_2 from "../assets/images/product/nar_forever_edp_2.png";
import nar_for_her_edt_1 from "../assets/images/product/nar_for_her_edt_1.png";
import nar_for_her_edt_2 from "../assets/images/product/nar_for_her_edt_2.png";
import nar_musc_noir_edp_1 from "../assets/images/product/nar_musc_noir_edp_1.png";
import nar_musc_noir_edp_2 from "../assets/images/product/nar_musc_noir_edp_2.png";
import nar_musc_noir_rose_edp_1 from "../assets/images/product/nar_musc_noir_rose_edp_1.png";
import nar_musc_noir_rose_edp_2 from "../assets/images/product/nar_musc_noir_rose_edp_2.png";
import nar_pure_musc_1 from "../assets/images/product/nar_pure_musc_1.png";
import nar_pure_musc_2 from "../assets/images/product/nar_pure_musc_2.png";
import black_afgano_1 from "../assets/images/product/black_afgano_1.png";
import black_afgano_2 from "../assets/images/product/black_afgano_2.png";
import silver_musk_1 from "../assets/images/product/silver_musk_1.png";
import silver_musk_2 from "../assets/images/product/silver_musk_2.png";
import spicebomb_extreme_pour_homme_1 from "../assets/images/product/spicebomb_extreme_pour_homme_1.png";
import spicebomb_extreme_pour_homme_2 from "../assets/images/product/spicebomb_extreme_pour_homme_2.png";
import donna_1 from "../assets/images/product/donna_1.png";
import donna_2 from "../assets/images/product/donna_2.png";
import uomo_intense_1 from "../assets/images/product/uomo_intense_1.png";
import uomo_intense_2 from "../assets/images/product/uomo_intense_2.png";
import uomo_born_in_roma_intense_1 from "../assets/images/product/uomo_born_in_roma_intense_1.png";
import uomo_born_in_roma_intense_2 from "../assets/images/product/uomo_born_in_roma_intense_2.png";
import uomo_born_in_roma_edt_1 from "../assets/images/product/uomo_born_in_roma_edt_1.png";
import uomo_born_in_roma_edt_2 from "../assets/images/product/uomo_born_in_roma_edt_2.png";
import ia_falling_into_the_sea_1 from "../assets/images/product/ia_falling_into_the_sea_1.png";
import ia_falling_into_the_sea_2 from "../assets/images/product/ia_falling_into_the_sea_2.png";
import loewe_001_woman_edt_1 from "../assets/images/product/loewe_001_woman_edt_1.png";
import loewe_001_woman_edt_2 from "../assets/images/product/loewe_001_woman_edt_2.png";
import cocaine_1 from "../assets/images/product/cocaine_1.png";
import cocaine_2 from "../assets/images/product/cocaine_2.png";
import alaia_edp_1 from "../assets/images/product/alaia_edp_1.png";
import alaia_edp_2 from "../assets/images/product/alaia_edp_2.png";
import chamo_1 from "../assets/images/product/chamo_1.png";
import chamo_2 from "../assets/images/product/chamo_2.png";
import bergamask_1 from "../assets/images/product/bergamask_1.png";
import bergamask_2 from "../assets/images/product/bergamask_2.png";
import megamare_1 from "../assets/images/product/megamare_1.png";
import megamare_2 from "../assets/images/product/megamare_2.png";
import seminalis_1 from "../assets/images/product/seminalis_1.png";
import seminalis_2 from "../assets/images/product/seminalis_2.png";
import chanel_coco_mademoiselle_edp_1 from "../assets/images/product/chanel_coco_mademoiselle_edp_1.png";
import chanel_coco_mademoiselle_edp_2 from "../assets/images/product/chanel_coco_mademoiselle_edp_2.png";
import chanel_no5_leau_edt_1 from "../assets/images/product/chanel_no5_leau_edt_1.png";
import chanel_no5_leau_edt_2 from "../assets/images/product/chanel_no5_leau_edt_2.png";
import chanel_coco_noir_1 from "../assets/images/product/chanel_coco_noir_1.png";
import chanel_coco_noir_2 from "../assets/images/product/chanel_coco_noir_2.png";
import chanel_gabrielle_edp_1 from "../assets/images/product/chanel_gabrielle_edp_1.png";
import chanel_gabrielle_edp_2 from "../assets/images/product/chanel_gabrielle_edp_2.png";
import chanel_chance_eau_tendre_edt_1 from "../assets/images/product/chanel_chance_eau_tendre_edt_1.png";
import chanel_chance_eau_tendre_edt_2 from "../assets/images/product/chanel_chance_eau_tendre_edt_2.png";
import chanel_allure_homme_sport_edt_1 from "../assets/images/product/chanel_allure_homme_sport_edt_1.png";
import chanel_allure_homme_sport_edt_2 from "../assets/images/product/chanel_allure_homme_sport_edt_2.png";
import chanel_allure_homme_sport_extreme_1 from "../assets/images/product/chanel_allure_homme_sport_extreme_1.png";
import chanel_allure_homme_sport_extreme_2 from "../assets/images/product/chanel_allure_homme_sport_extreme_2.png";
import chanel_bleu_de_chanel_edp_1 from "../assets/images/product/chanel_bleu_de_chanel_edp_1.png";
import chanel_bleu_de_chanel_edp_2 from "../assets/images/product/chanel_bleu_de_chanel_edp_2.png";
import chanel_bleu_de_chanel_parfum_1 from "../assets/images/product/chanel_bleu_de_chanel_parfum_1.png";
import chanel_bleu_de_chanel_parfum_2 from "../assets/images/product/chanel_bleu_de_chanel_parfum_2.png";
import prada_paradoxe_edp_1 from "../assets/images/product/prada_paradoxe_edp_1.png";
import prada_paradoxe_edp_2 from "../assets/images/product/prada_paradoxe_edp_2.png";
import matcha_edition_1 from "../assets/images/product/matcha_edition_1.png";
import matcha_edition_2 from "../assets/images/product/matcha_edition_2.png";
import jazz_club_1 from "../assets/images/product/jazz_club_1.png";
import jazz_club_2 from "../assets/images/product/jazz_club_2.png";
import flower_market_1 from "../assets/images/product/flower_market_1.png";
import flower_market_2 from "../assets/images/product/flower_market_2.png";
import lazy_sunday_morning_1 from "../assets/images/product/lazy_sunday_morning_1.png";
import lazy_sunday_morning_2 from "../assets/images/product/lazy_sunday_morning_2.png";
import when_the_rain_stops_1 from "../assets/images/product/when_the_rain_stops_1.png";
import when_the_rain_stops_2 from "../assets/images/product/when_the_rain_stops_2.png";
import elysium_pour_homme_parfum_cologne_1 from "../assets/images/product/elysium_pour_homme_parfum_cologne_1.png";
import elysium_pour_homme_parfum_cologne_2 from "../assets/images/product/elysium_pour_homme_parfum_cologne_2.png";
import elysium_eau_intense_1 from "../assets/images/product/elysium_eau_intense_1.png";
import elysium_eau_intense_2 from "../assets/images/product/elysium_eau_intense_2.png";
import elixir_1 from "../assets/images/product/elixir_1.png";
import elixir_2 from "../assets/images/product/elixir_2.png";
import burlington_1819_1 from "../assets/images/product/burlington_1819_1.png";
import burlington_1819_2 from "../assets/images/product/burlington_1819_2.png";
import oceania_1 from "../assets/images/product/oceania_1.png";
import oceania_2 from "../assets/images/product/oceania_2.png";
import roja_isola_blu_parfum_1 from "../assets/images/product/roja_isola_blu_parfum_1.png";
import roja_isola_blu_parfum_2 from "../assets/images/product/roja_isola_blu_parfum_2.png";
import roja_manhattan_edp_1 from "../assets/images/product/roja_manhattan_edp_1.png";
import roja_manhattan_edp_2 from "../assets/images/product/roja_manhattan_edp_2.png";
import roja_parfums_elixir_parfum_coffret_1 from "../assets/images/product/roja_parfums_elixir_parfum_coffret_1.png";
import roja_parfums_elixir_parfum_coffret_2 from "../assets/images/product/roja_parfums_elixir_parfum_coffret_2.png";
import elysium_parfum_pour_homme_1 from "../assets/images/product/elysium_parfum_pour_homme_1.png";
import elysium_parfum_pour_homme_2 from "../assets/images/product/elysium_parfum_pour_homme_2.png";
import roja_parfums_elixir_edp_2023_1 from "../assets/images/product/roja_parfums_elixir_edp_2023_1.png";
import roja_parfums_elixir_edp_2023_2 from "../assets/images/product/roja_parfums_elixir_edp_2023_2.png";
import roja_elysium_pour_femme_edp_1 from "../assets/images/product/roja_elysium_pour_femme_edp_1.png";
import roja_elysium_pour_femme_edp_2 from "../assets/images/product/roja_elysium_pour_femme_edp_2.png";
import mugler_alien_edp_1 from "../assets/images/product/mugler_alien_edp_1.png";
import mugler_alien_edp_2 from "../assets/images/product/mugler_alien_edp_2.png";
import other_13_1 from "../assets/images/product/other_13_1.png";
import other_13_2 from "../assets/images/product/other_13_2.png";
import visionary_1 from "../assets/images/product/visionary_1.png";
import visionary_2 from "../assets/images/product/visionary_2.png";
import cali_love_1 from "../assets/images/product/cali_love_1.png";
import cali_love_2 from "../assets/images/product/cali_love_2.png";
import galloway_edp_1 from "../assets/images/product/galloway_edp_1.png";
import galloway_edp_2 from "../assets/images/product/galloway_edp_2.png";
import valaya_edp_1 from "../assets/images/product/valaya_edp_1.png";
import valaya_edp_2 from "../assets/images/product/valaya_edp_2.png";
import parfums_de_marly_delina_edp_1 from "../assets/images/product/parfums_de_marly_delina_edp_1.png";
import parfums_de_marly_delina_edp_2 from "../assets/images/product/parfums_de_marly_delina_edp_2.png";
import parfums_de_marly_delina_la_rosee_1 from "../assets/images/product/parfums_de_marly_delina_la_rosee_1.png";
import parfums_de_marly_delina_la_rosee_2 from "../assets/images/product/parfums_de_marly_delina_la_rosee_2.png";
import parfums_de_marly_delina_exclusif_1 from "../assets/images/product/parfums_de_marly_delina_exclusif_1.png";
import parfums_de_marly_delina_exclusif_2 from "../assets/images/product/parfums_de_marly_delina_exclusif_2.png";
import no4_1 from "../assets/images/product/no4_1.png";
import no4_2 from "../assets/images/product/no4_2.png";
import tom_ford_ombre_leather_1 from "../assets/images/product/tom_ford_ombre_leather_1.png";
import tom_ford_ombre_leather_2 from "../assets/images/product/tom_ford_ombre_leather_2.png";
import black_orchid_1 from "../assets/images/product/black_orchid_1.png";
import black_orchid_2 from "../assets/images/product/black_orchid_2.png";
import grey_vetiver_1 from "../assets/images/product/grey_vetiver_1.png";
import grey_vetiver_2 from "../assets/images/product/grey_vetiver_2.png";
import neroli_portofino_1 from "../assets/images/product/neroli_portofino_1.png";
import neroli_portofino_2 from "../assets/images/product/neroli_portofino_2.png";
import oud_wood_1 from "../assets/images/product/oud_wood_1.png";
import oud_wood_2 from "../assets/images/product/oud_wood_2.png";
import bright_crystal_1 from "../assets/images/product/bright_crystal_1.png";
import bright_crystal_2 from "../assets/images/product/bright_crystal_2.png";
import bright_crystal_absolu_1 from "../assets/images/product/bright_crystal_absolu_1.png";
import bright_crystal_absolu_2 from "../assets/images/product/bright_crystal_absolu_2.png";
import pour_homme_1 from "../assets/images/product/pour_homme_1.png";
import pour_homme_2 from "../assets/images/product/pour_homme_2.png";
import eros_edt_1 from "../assets/images/product/eros_edt_1.png";
import eros_edt_2 from "../assets/images/product/eros_edt_2.png";
import dylan_blue_1 from "../assets/images/product/dylan_blue_1.png";
import dylan_blue_2 from "../assets/images/product/dylan_blue_2.png";
import renaissance_edp_1861_1 from "../assets/images/product/1861_renaissance_edp_1.png";
import renaissance_edp_1861_2 from "../assets/images/product/1861_renaissance_edp_2.png";
import xerjoff_naxos_edp_1 from "../assets/images/product/xerjoff_naxos_edp_1.png";
import xerjoff_naxos_edp_2 from "../assets/images/product/xerjoff_naxos_edp_2.png";
import xerjoff_casamorati_mefisto_1 from "../assets/images/product/xerjoff_casamorati_mefisto_1.png";
import xerjoff_casamorati_mefisto_2 from "../assets/images/product/xerjoff_casamorati_mefisto_2.png";
import xerjoff_torino_21_1 from "../assets/images/product/xerjoff_torino_21_1.png";
import xerjoff_torino_21_2 from "../assets/images/product/xerjoff_torino_21_2.png";
import ysl_black_opium_edp_1 from "../assets/images/product/ysl_black_opium_edp_1.png";
import ysl_black_opium_edp_2 from "../assets/images/product/ysl_black_opium_edp_2.png";
import over_red_1 from "../assets/images/product/over_red_1.png";
import over_red_2 from "../assets/images/product/over_red_2.png";
import ysl_la_nuit_de_lhomme_edp_1 from "../assets/images/product/ysl_la_nuit_de_lhomme_edp_1.png";
import ysl_la_nuit_de_lhomme_edp_2 from "../assets/images/product/ysl_la_nuit_de_lhomme_edp_2.png";
import ysl_y_edp_1 from "../assets/images/product/ysl_y_edp_1.png";
import ysl_y_edp_2 from "../assets/images/product/ysl_y_edp_2.png";
import ysl_myslf_edp_1 from "../assets/images/product/ysl_myslf_edp_1.png";
import ysl_myslf_edp_2 from "../assets/images/product/ysl_myslf_edp_2.png";
import ysl_mon_paris_edp_1 from "../assets/images/product/ysl_mon_paris_edp_1.png";
import ysl_mon_paris_edp_2 from "../assets/images/product/ysl_mon_paris_edp_2.png";
import ysl_libre_edp_1 from "../assets/images/product/ysl_libre_edp_1.png";
import ysl_libre_edp_2 from "../assets/images/product/ysl_libre_edp_2.png";
import amber_oud_ruby_edition_1 from "../assets/images/product/amber_oud_ruby_edition_1.png";
import amber_oud_ruby_edition_2 from "../assets/images/product/amber_oud_ruby_edition_2.png";
import i_dont_know_what_edp_1 from "../assets/images/product/i_dont_know_what_edp_1.png";
import i_dont_know_what_edp_2 from "../assets/images/product/i_dont_know_what_edp_2.png";
import eau_so_sweet_1 from "../assets/images/product/eau_so_sweet_1.png";
import eau_so_sweet_2 from "../assets/images/product/eau_so_sweet_2.png";
import eau_so_fresh_1 from "../assets/images/product/eau_so_fresh_1.png";
import eau_so_fresh_2 from "../assets/images/product/eau_so_fresh_2.png";
import daisy_dream_edt_1 from "../assets/images/product/daisy_dream_edt_1.png";
import daisy_dream_edt_2 from "../assets/images/product/daisy_dream_edt_2.png";
import perfect_1 from "../assets/images/product/perfect_1.png";
import perfect_2 from "../assets/images/product/perfect_2.png";
import toy_2_1 from "../assets/images/product/toy_2_1.png";
import toy_2_2 from "../assets/images/product/toy_2_2.png";
import toy_2_bubble_1 from "../assets/images/product/toy_2_bubble_1.png";
import toy_2_bubble_2 from "../assets/images/product/toy_2_bubble_2.png";
import _toy_boy_1 from "../assets/images/product/_toy_boy_1.png";
import _toy_boy_2 from "../assets/images/product/_toy_boy_2.png";
import eau_de_parfum_1 from "../assets/images/product/eau_de_parfum_1.png";
import eau_de_parfum_2 from "../assets/images/product/eau_de_parfum_2.png";
import good_girl_1 from "../assets/images/product/good_girl_1.png";
import good_girl_2 from "../assets/images/product/good_girl_2.png";
import men_nyc_212_1 from "../assets/images/product/212_men_nyc_1.png";
import men_nyc_212_2 from "../assets/images/product/212_men_nyc_2.png";
import aqva_pour_homme_1 from "../assets/images/product/aqva_pour_homme_1.png";
import aqva_pour_homme_2 from "../assets/images/product/aqva_pour_homme_2.png";
import man_in_black_eau_de_parfum_1 from "../assets/images/product/man_in_black_eau_de_parfum_1.png";
import man_in_black_eau_de_parfum_2 from "../assets/images/product/man_in_black_eau_de_parfum_2.png";
import lavie_est_belle_1 from "../assets/images/product/lavie_est_belle_1.png";
import lavie_est_belle_2 from "../assets/images/product/lavie_est_belle_2.png";
import lancome_idole_eau_de_parfum_1 from "../assets/images/product/lancome_idole_eau_de_parfum_1.png";
import lancome_idole_eau_de_parfum_2 from "../assets/images/product/lancome_idole_eau_de_parfum_2.png";
import maison_matine_poom_poom_1 from "../assets/images/product/maison_matine_poom_poom_1.png";
import maison_matine_poom_poom_2 from "../assets/images/product/maison_matine_poom_poom_2.png";
import maison_matine_avant_lorage_1 from "../assets/images/product/maison_matine_avant_lorage_1.png";
import maison_matine_avant_lorage_2 from "../assets/images/product/maison_matine_avant_lorage_2.png";
import mancera_cedrat_boise_edp_1 from "../assets/images/product/mancera_cedrat_boise_edp_1.png";
import mancera_cedrat_boise_edp_2 from "../assets/images/product/mancera_cedrat_boise_edp_2.png";

export const CollectionProducts = [
  {
    id: 1,
    brand: "Paco Rabanne",
    logo: paco_rabanne,
    products: [
      {
        id: 1,
        productName: "Pure XS For Her",
        price: [350000, 2600000],
        // dung tích
        volume: [10, 80],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [pure_xs_for_her_1, pure_xs_for_her_2],
        description: [
          "Pure XS For Her là một tuyệt tác nước hoa của Paco Rabanne, mang đến sự kết hợp đầy mê hoặc giữa sự gợi cảm và quyền lực. Được ra mắt vào năm 2018, nước hoa này nhanh chóng trở thành biểu tượng của vẻ đẹp hiện đại, với sự pha trộn độc đáo giữa hương hoa cỏ, gia vị và hổ phách.",
          "Với thiết kế chai độc đáo, lấy cảm hứng từ những đường cong quyến rũ của cơ thể phụ nữ, Pure XS For Her không chỉ là một mùi hương mà còn là một tác phẩm nghệ thuật thực sự. Đây là lựa chọn hoàn hảo cho những người phụ nữ tự tin, quyến rũ và không ngại thể hiện sự khác biệt.",
          "Hương đầu: Gừng, hương ngọt ngào của hoa cam.",
          "Hương giữa: Hoa nhài, ylang-ylang, vanilla.",
          "Hương cuối: Gỗ đàn hương, hổ phách, xạ hương.",
        ],
      },
      {
        id: 2,
        productName: "Paco Rabanne Phantom",
        price: [350000, 2700000],
        // dung tích
        volume: [10, 100],
        // gender: ["female"],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "trên 8",
        // độ toả hương
        scent_release: "xa",
        img: [paco_rabanne_phantom_1, paco_rabanne_phantom_2],
        description: [
          "Phantom là một trong những sáng tạo mới nhất của Paco Rabanne, ra mắt vào năm 2021. Đây là một hương thơm nam tính, hiện đại và tràn đầy năng lượng, kết hợp hoàn hảo giữa công nghệ và nghệ thuật chế tạo nước hoa. Phantom không chỉ là một nước hoa mà còn là một biểu tượng của tương lai, với thiết kế chai hình robot độc đáo, thể hiện tinh thần đổi mới và sự mạnh mẽ.",
          "Phantom được tạo ra từ sự kết hợp của nguyên liệu cao cấp và công nghệ tiên tiến, mang đến một mùi hương mạnh mẽ nhưng vẫn thanh lịch. Sự tươi mát của chanh và bạc hà hòa quyện cùng sự ngọt ngào của táo và vanilla tạo nên một trải nghiệm hương thơm đầy sảng khoái và cuốn hút. Đặc biệt, Phantom còn có khả năng kết nối với smartphone thông qua công nghệ NFC tích hợp trong chai, mang đến những trải nghiệm số hóa thú vị.",
          "Hương đầu: Chanh, oải hương, bạc hà.",
          "Hương giữa: Táo, hoắc hương, hoa nhài.",
          "Hương cuối: Hương vanilla, cỏ hương bài, gỗ.",
        ],
      },
      {
        id: 3,
        productName: "1 Million",
        price: [250000, 2300000],
        // dung tích
        volume: [10, 100],
        // gender: ["female"],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "trên 8",
        // độ toả hương
        scent_release: "Xa",
        img: [million_1, million_2],
        description: [
          "1 Million là một trong những dòng nước hoa biểu tượng của Paco Rabanne, được ra mắt vào năm 2008. Đây là một mùi hương nam tính đậm chất quyền lực và xa hoa, thể hiện sự tự tin và khát khao chinh phục. Với thiết kế chai vàng lấp lánh, lấy cảm hứng từ thỏi vàng quý giá, 1 Million đại diện cho sự giàu có và thành công.",
          "1 Million mang đến một sự kết hợp hoàn hảo giữa các tầng hương tươi mát, ngọt ngào và ấm áp. Hương đầu mở ra với sự tươi mát của bưởi và bạc hà, tiếp nối bằng hương giữa đầy quyến rũ của hoa hồng và gia vị. Cuối cùng, mùi hương kết thúc với sự ấm áp, sang trọng của hổ phách, da thuộc, và hoắc hương, tạo nên một ấn tượng mạnh mẽ và khó quên.",
          "Hương đầu: Bưởi, bạc hà, quýt đỏ.",
          "Hương giữa: Hoa hồng, quế, gia vị.",
          "Hương cuối: Hổ phách, da thuộc, gỗ trắng, hoắc hương.",
        ],
      },
      {
        id: 4,
        productName: "1 Million Lucky",
        price: [240000, 2150000],
        // dung tích
        volume: [10, 100],
        // gender: ["female"],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [million_lucky_1, million_lucky_2],
        description: [
          "1 Million Lucky là một phiên bản đặc biệt của dòng nước hoa 1 Million nổi tiếng, ra mắt vào năm 2018. Đây là mùi hương dành cho những người đàn ông tự tin, sống hết mình và luôn tìm kiếm niềm vui trong cuộc sống. Với sự pha trộn độc đáo giữa các hương vị tươi mới và gợi cảm, 1 Million Lucky mang đến một cảm giác vừa quen thuộc vừa mới lạ, đầy cuốn hút.",
          "1 Million Lucky mở đầu với hương vị ngọt ngào, tươi mát của quả mận và bưởi, kết hợp với các nốt ozonic mang lại cảm giác trong lành, tự nhiên. Hương giữa là sự kết hợp hài hòa giữa mật ong ngọt ngào và gỗ tuyết tùng mạnh mẽ, cùng với chút ấm áp từ quả hạch và hoa nhài. Cuối cùng, mùi hương được kết thúc bằng sự ấm áp, sâu lắng của hoắc hương, gỗ sồi, hổ phách, và vani, tạo nên một sự cân bằng hoàn hảo giữa sự tươi mới và gợi cảm.",
          "Hương đầu: Quả mận, bưởi, ozonic notes.",
          "Hương giữa: Mật ong, gỗ tuyết tùng, quả hạch, hoa nhài.",
          "Hương cuối: Hoắc hương, gỗ sồi, hổ phách, vani.",
        ],
      },
      {
        id: 5,
        productName: "Pure XS For Men",
        price: [260000, 2300000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [pure_xs_for_him_1, pure_xs_for_him_2],
        description: [
          "Pure XS For Men là một hương thơm đầy quyến rũ và táo bạo, được Paco Rabanne ra mắt vào năm 2017. Đây là một sự kết hợp hoàn hảo giữa sự tươi mát và nóng bỏng, tạo nên một mùi hương nam tính đầy lôi cuốn, gợi cảm và đầy bí ẩn. Pure XS For Men là sự lựa chọn hoàn hảo cho những người đàn ông muốn thể hiện sự tự tin và sức hấp dẫn tự nhiên của mình.",
          "Pure XS For Men mở đầu với sự tươi mát và bùng nổ của gừng, cam bergamot và cỏ roi ngựa, mang lại cảm giác tươi mới và đầy năng lượng. Hương giữa là sự hòa quyện đầy ngọt ngào và nồng ấm của quế, vanilla, và rượu mùi, tạo nên một sự quyến rũ khó cưỡng. Cuối cùng, mùi hương khép lại với sự ấm áp, sâu lắng của gỗ tuyết tùng, nhựa thơm myrrh và hoắc hương, tạo nên một ấn tượng mạnh mẽ và lâu dài.",
          "Hương đầu: Gừng, cam bergamot, cỏ roi ngựa.",
          "Hương giữa: Quế, vanilla, rượu mùi.",
          "Hương cuối: Gỗ tuyết tùng, nhựa thơm myrrh, hoắc hương.",
        ],
      },
      {
        id: 6,
        productName: "Paco Rabanne Fame",
        price: [410000, 3200000],
        // dung tích
        volume: [10, 80],
        // gender: ["male"],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [paco_rabanne_fame_1, paco_rabanne_fame_2],
        description: [
          "Fame là một hương thơm dành cho phái đẹp, được Paco Rabanne ra mắt vào năm 2022. Lấy cảm hứng từ sự lộng lẫy và quyến rũ của kinh đô thời trang Paris, Fame là hiện thân của sự tự do, sáng tạo, và phong cách không thể nhầm lẫn. Với thiết kế chai độc đáo, hình tượng một cô gái mang đậm chất thời trang, Fame không chỉ là một mùi hương mà còn là biểu tượng của sự nổi bật và đẳng cấp.",
          "Fame mở đầu với hương thơm ngọt ngào, tươi mát từ xoài và cam bergamot, mang đến cảm giác trẻ trung và đầy năng lượng. Hương giữa là sự hòa quyện của hoa nhài thanh khiết và hương trầm bí ẩn, tạo nên một lớp hương quyến rũ, đầy lôi cuốn. Hương cuối với sự ấm áp, mềm mại của gỗ tuyết tùng, hổ phách, và đàn hương, mang lại một kết thúc đầy sang trọng và lâu dài.",
          "Hương đầu: Xoài, cam bergamot.",
          "Hương giữa: Hoa nhài, hương trầm, vanilla.",
          "Hương cuối: Gỗ tuyết tùng, hổ phách, đàn hương.",
        ],
      },
    ],
  },
  {
    id: 2,
    brand: "Matiere Premiere",
    logo: matiere_premiere,
    products: [
      {
        id: 1,
        productName: "Cologne Cedrat",
        price: [700000, 6050000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [cologne_cedrat_1, cologne_cedrat_2],
        description: [
          "Cologne Cedrat là một hương thơm tinh tế và sảng khoái, được sáng tạo bởi Matiere Premiere, một thương hiệu nổi tiếng với việc sử dụng những nguyên liệu tự nhiên cao cấp. Được ra mắt vào năm 2019, Cologne Cedrat mang đến một sự kết hợp tươi mới giữa các thành phần citrus, tạo nên một mùi hương nhẹ nhàng, thanh khiết nhưng vẫn đầy sức sống.",
          "Cologne Cedrat mở ra với hương đầu tươi mát và rực rỡ của cam bergamot và quả thanh yên, mang lại cảm giác sảng khoái ngay lập tức. Hương giữa là sự kết hợp tinh tế của bạch đậu khấu và bạc hà, tạo nên một lớp hương vừa thanh mát vừa phảng phất chút ấm áp. Cuối cùng, hương cuối của gỗ tuyết tùng và cỏ hương bài mang lại sự cân bằng, thêm phần sâu lắng và thanh lịch cho tổng thể mùi hương.",
          "Hương đầu: Cam bergamot, quả thanh yên (citron).",
          "Hương giữa: Bạch đậu khấu, bạc hà.",
          "Hương cuối: Gỗ tuyết tùng, cỏ hương bài.",
        ],
      },
      {
        id: 2,
        productName: "Santal Austral",
        price: [700000, 6050000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [santal_austral_1, santal_austral_2],
        description: [
          "'Santal Austral' là một hương thơm tinh tế và đầy mê hoặc từ Matiere Premiere, ra mắt vào năm 2019. Lấy cảm hứng từ hương gỗ đàn hương mịn màng và ấm áp, 'Santal Austral' là một tác phẩm nghệ thuật được chế tác từ những nguyên liệu cao cấp, tạo nên một mùi hương vừa sang trọng vừa gợi cảm, thích hợp cho cả nam và nữ.",
          "'Santal Austral' mở đầu với sự kết hợp tươi sáng của cam bergamot và hương hạnh nhân, mang đến một cảm giác thanh nhã và cuốn hút. Hương giữa với sự pha trộn của hoa diên vĩ và hương sữa tạo ra một tầng hương mượt mà và êm dịu, như một cái ôm ấm áp. Cuối cùng, hương gỗ đàn hương từ Úc, kết hợp với xạ hương và đậu tonka, mang lại một kết thúc sâu lắng, gợi cảm và lâu dài.",
          "Hương đầu: Cam bergamot, hạnh nhân.",
          "Hương giữa: Hoa diên vĩ, hương sữa.",
          "Hương cuối: Gỗ đàn hương Úc, xạ hương, đậu tonka.",
        ],
      },
      {
        id: 3,
        productName: "French Flower",
        price: [700000, 6050000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [french_flower_1, french_flower_2],
        description: [
          "'French Flower' là một hương thơm tươi mới và lãng mạn, được Matiere Premiere giới thiệu. Lấy cảm hứng từ sự thanh lịch và quyến rũ của hoa huệ tây, 'French Flower' mang đến một mùi hương tự nhiên, đầy gợi cảm và tinh tế, phù hợp cho cả nam và nữ.",
          "'French Flower' mở đầu với hương thơm tươi mát và quyến rũ của hoa huệ tây, kết hợp với sự tươi sáng của cam bergamot. Hương giữa mang đến sự ấm áp và sảng khoái từ hoa cam và gừng, tạo nên một lớp hương đầy sự tự nhiên và tươi mới. Cuối cùng, sự kết hợp của gỗ đàn hương, xạ hương và hổ phách mang lại một kết thúc êm dịu, sâu lắng, và quyến rũ.",
          "Hương đầu: Hoa huệ tây, cam bergamot.",
          "Hương giữa: Hoa cam, gừng.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 4,
        productName: "Crystal Saffron",
        price: [700000, 6050000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [crystal_saffron_1, crystal_saffron_2],
        description: [
          "'Crystal Saffron' là một mùi hương độc đáo và sang trọng, được ra mắt bởi Matiere Premiere. Lấy cảm hứng từ sự quý phái của nghệ tây, 'Crystal Saffron' là sự hòa quyện hoàn hảo giữa các nốt hương ấm áp và cay nồng, tạo nên một mùi hương vừa bí ẩn vừa quyến rũ, phù hợp cho cả nam và nữ.",
          "'Crystal Saffron' mở đầu với hương thơm cay nồng và tinh tế của nghệ tây, kết hợp với sự tươi mát của cam bergamot. Hương giữa là sự kết hợp ngọt ngào và lôi cuốn của hoa hồng và nhựa thơm, tạo nên một lớp hương ấm áp và đầy lôi cuốn. Hương cuối của gỗ tuyết tùng, xạ hương, và hổ phách mang lại một kết thúc ấm áp, sang trọng và lâu dài.",
          "Hương đầu: Nghệ tây, cam bergamot.",
          "Hương giữa: Hoa hồng, nhựa thơm.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, hổ phách.",
        ],
      },
      {
        id: 5,
        productName: "Radical Rose",
        price: [700000, 6050000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [radical_rose_1, radical_rose_2],
        description: [
          "'Radical Rose' là một hương thơm đặc trưng và đầy sức mạnh, được Matiere Premiere giới thiệu. Tập trung vào hoa hồng Damask, 'Radical Rose' mang đến một mùi hương mạnh mẽ và quyến rũ, thể hiện sự hòa quyện hoàn hảo giữa truyền thống và hiện đại, phù hợp cho cả nam và nữ.",
          "'Radical Rose' mở đầu với sự bùng nổ của tiêu hồng và nghệ tây, mang đến một cảm giác tươi mới và cay nồng. Hương giữa với hoa hồng Damask làm trung tâm, tạo nên một lớp hương hoa lộng lẫy, quyến rũ và đầy mê hoặc. Cuối cùng, hương hoắc hương, cỏ hương bài và labdanum tạo nên một nền tảng mạnh mẽ, mang lại chiều sâu và sự ấm áp cho mùi hương.",
          "Hương đầu: Tiêu hồng, nghệ tây.",
          "Hương giữa: Hoa hồng Damask.",
          "Hương cuối: Hoắc hương, cỏ hương bài, labdanum.",
        ],
      },
    ],
  },
  {
    id: 3,
    brand: "Nishane",
    logo: nishane,
    products: [
      {
        id: 1,
        productName: "Ani",
        price: [550000, 4600000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [ani_1, ani_2],
        description: [
          "'Ani' là một trong những mùi hương nổi bật của Nishane, mang đến sự kết hợp tinh tế giữa các nốt hương ấm áp và ngọt ngào. Ra mắt vào năm 2019, 'Ani' nhanh chóng trở thành một trong những hương thơm được yêu thích nhất của thương hiệu này, phù hợp cho cả nam và nữ.",
          "'Ani' mở đầu với sự bùng nổ của các nốt hương tươi mát và cay nồng từ gừng, cam bergamot, hồng tiêu, và quả chanh, mang đến một khởi đầu sảng khoái và cuốn hút. Hương giữa là sự hòa quyện mềm mại và tinh tế của hoa nhài, hoa hồng và bạch chỉ, tạo nên một lớp hương đầy nữ tính và thanh lịch. Cuối cùng, hương vanilla ấm áp kết hợp với gỗ đàn hương, hoắc hương, và xạ hương mang đến một cảm giác ngọt ngào, gợi cảm và lưu luyến lâu dài.",
          "Hương đầu: Gừng, cam bergamot, hồng tiêu, quả chanh.",
          "Hương giữa: Hoa nhài, hồng, bạch chỉ, hương xanh.",
          "Hương cuối: Vanilla, gỗ đàn hương, hoắc hương, xạ hương, hổ phách, benzoin.",
        ],
      },
      {
        id: 2,
        productName: "Hacivat",
        price: [600000, 5100000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [hacivat_1, hacivat_2],
        description: [
          "'Hacivat' là một hương thơm đẳng cấp và đầy cuốn hút từ Nishane, ra mắt vào năm 2017. Lấy cảm hứng từ một nhân vật trong nghệ thuật rối bóng truyền thống của Thổ Nhĩ Kỳ, 'Hacivat' thể hiện sự hòa quyện hoàn hảo giữa sự tươi mát và sang trọng, mang đến một mùi hương đầy sức sống và tinh tế, phù hợp cho cả nam và nữ.",
          "'Hacivat' mở đầu với sự tươi mát và ngọt ngào của dứa, kết hợp với hương cam bergamot và bưởi, tạo nên một cảm giác sảng khoái và đầy sức sống. Hương giữa là sự kết hợp tinh tế của gỗ tuyết tùng, hoắc hương và hoa nhài, mang đến một lớp hương gỗ nhẹ nhàng nhưng đầy lôi cuốn. Cuối cùng, hương rêu sồi, gỗ đàn hương và hổ phách tạo nên một kết thúc sang trọng, ấm áp và lưu luyến lâu dài.",
          "Hương đầu: Dứa, cam bergamot, bưởi.",
          "Hương giữa: Gỗ tuyết tùng, hoắc hương, hoa nhài.",
          "Hương cuối: Rêu sồi, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Wulong Cha",
        price: [550000, 4600000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [wulong_cha_1, wulong_cha_2],
        description: [
          "'Wulong Cha' là một hương thơm tươi mát và thanh khiết từ Nishane, lấy cảm hứng từ trà ô long nổi tiếng. Ra mắt vào năm 2015, 'Wulong Cha' mang đến một trải nghiệm mùi hương nhẹ nhàng và thư giãn, phù hợp cho cả nam và nữ, lý tưởng để sử dụng trong những ngày hè.",
          "'Wulong Cha' mở đầu với sự tươi mát và sảng khoái của các nốt hương cam bergamot, cam quýt, chanh, và lychee, mang lại cảm giác nhẹ nhàng và tươi mới. Hương giữa là sự kết hợp đặc trưng của trà ô long và nhục đậu khấu, tạo nên một lớp hương thanh khiết và thư giãn, giống như một tách trà xanh dịu mát trong buổi chiều. Cuối cùng, hương xạ hương và quả sung mang đến một kết thúc mềm mại và ấm áp, lưu giữ sự thanh khiết và tươi mới của hương đầu.",
          "Hương đầu: Cam bergamot, cam quýt, chanh, lychee.",
          "Hương giữa: Trà ô long, nhục đậu khấu.",
          "Hương cuối: Xạ hương, quả sung.",
        ],
      },
      {
        id: 4,
        productName: "Hacivat X",
        price: [680000, 6000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [hacivat_x_1, hacivat_x_2],
        description: [
          "'Hacivat X' là phiên bản nâng cấp của mùi hương nổi tiếng 'Hacivat' từ Nishane, mang đến một trải nghiệm mới mẻ và mạnh mẽ hơn. Với sự pha trộn tinh tế giữa các nốt hương tươi mát và gỗ, 'Hacivat X' thể hiện sự sang trọng và cuốn hút, tạo nên một mùi hương đầy sức sống và bí ẩn, phù hợp cho cả nam và nữ.",
          "'Hacivat X' mở đầu với sự bùng nổ của các nốt hương tươi mát từ dứa, cam bergamot, và bưởi, mang lại cảm giác sảng khoái và đầy năng lượng. Hương giữa là sự kết hợp của gỗ tuyết tùng, hoắc hương và hoa nhài, tạo nên một lớp hương gỗ đầy lôi cuốn và mạnh mẽ. Cuối cùng, hương rêu sồi, gỗ đàn hương, hổ phách và vani mang lại một kết thúc sang trọng, ấm áp và lâu dài.",
          "Hương đầu: Dứa, cam bergamot, bưởi.",
          "Hương giữa: Gỗ tuyết tùng, hoắc hương, hoa nhài.",
          "Hương cuối: Rêu sồi, gỗ đàn hương, hổ phách, vani.",
        ],
      },
    ],
  },
  {
    id: 4,
    brand: "Borntostandout",
    logo: borntostandout,
    products: [
      {
        id: 1,
        productName: "Dirty Heaven",
        price: [990000, 5300000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [dirty_heaven_1, dirty_heaven_2],
        description: [
          "'Dirty Heaven' là một mùi hương quyến rũ và nổi bật từ Borntostandout, mang đến một trải nghiệm olfactory đầy sức sống và cá tính. Ra mắt gần đây, 'Dirty Heaven' được thiết kế để tạo nên sự khác biệt, phù hợp cho cả nam và nữ với phong cách độc đáo và mạnh mẽ.",
          "'Dirty Heaven' mở đầu với sự tươi mới và cay nồng của cam bergamot, chanh và tiêu đen, tạo nên một sự khởi đầu đầy năng lượng và kích thích. Hương giữa mang đến sự kết hợp quyến rũ của hoa hồng, nhục đậu khấu và quế, mang lại một lớp hương ấm áp và đầy lôi cuốn. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên một kết thúc sâu lắng và gợi cảm, lưu giữ sự ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, chanh, tiêu đen.",
          "Hương giữa: Hoa hồng, nhục đậu khấu, quế.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Dirty Rice",
        price: [990000, 5300000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [dirty_rice_1, dirty_rice_2],
        description: [
          "'Dirty Rice' là một mùi hương đặc trưng và phong phú từ Borntostandout, mang đến một trải nghiệm olfactory ấm áp và quyến rũ. Với tên gọi độc đáo, 'Dirty Rice' kết hợp sự táo bạo và tính độc quyền, phù hợp cho cả nam và nữ, lý tưởng để tạo nên dấu ấn mạnh mẽ.",
          "'Dirty Rice' mở đầu với sự kết hợp tươi mát và cay nồng của cam bergamot, quế và tiêu đen, mang lại một cảm giác khởi đầu ấn tượng và đầy năng lượng. Hương giữa của hoa nhài, hạt tiêu và cỏ vetiver tạo nên một lớp hương phong phú và lôi cuốn, thể hiện sự sang trọng và chiều sâu. Cuối cùng, sự kết hợp của gỗ đàn hương, xạ hương và hổ phách mang đến một kết thúc ấm áp, sâu lắng và lưu luyến lâu dài.",
          "Hương đầu: Cam bergamot, quế, tiêu đen.",
          "Hương giữa: Hoa nhài, hạt tiêu, cỏ vetiver.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Fig Porn",
        price: [990000, 5300000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [fig_porn_1, fig_porn_2],
        description: [
          "'Fig Porn' từ Borntostandout là một mùi hương độc đáo và quyến rũ, mang đến một trải nghiệm olfactory tươi mới và đầy phong phú. Với sự kết hợp tinh tế của các nốt hương trái cây và gỗ, 'Fig Porn' thể hiện sự sang trọng và sáng tạo, phù hợp cho cả nam và nữ, và là lựa chọn lý tưởng cho những ai muốn tạo dấu ấn mạnh mẽ.",
          "'Fig Porn' mở đầu với sự tươi mới và sảng khoái của dưa hấu, chanh và cam bergamot, mang đến một cảm giác dễ chịu và đầy năng lượng. Hương giữa với sự kết hợp của quả sung, hoa nhài và dưa lưới tạo nên một lớp hương phong phú và quyến rũ, thể hiện sự tinh tế và lôi cuốn. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách mang đến một kết thúc ấm áp, sâu lắng và lâu dài.",
          "Hương đầu: Dưa hấu, chanh, cam bergamot.",
          "Hương giữa: Quả sung, hoa nhài, dưa lưới.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 4,
        productName: "Naked Neroli",
        price: [990000, 5300000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [naked_neroli_1, naked_neroli_2],
        description: [
          "'Naked Neroli' từ Borntostandout là một mùi hương thanh khiết và tươi mới, mang đến một trải nghiệm olfactory đầy sức sống và quyến rũ. Được tạo ra để nổi bật với sự đơn giản và tinh tế của hoa nhài, 'Naked Neroli' phù hợp cho cả nam và nữ, lý tưởng để sử dụng trong những ngày hè hoặc khi bạn muốn tạo ấn tượng nhẹ nhàng nhưng đầy lôi cuốn.",
          "'Naked Neroli' mở đầu với sự tươi mới và sảng khoái từ cam bergamot, chanh và nho đen, mang lại cảm giác dễ chịu và đầy năng lượng. Hương giữa là sự hòa quyện quyến rũ của hoa nhài, hoa cam và húng quế, tạo nên một lớp hương thanh khiết và đầy mê hoặc. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp và lâu dài, mang lại sự hoàn hảo và sâu lắng cho mùi hương.",
          "Hương đầu: Cam bergamot, chanh, nho đen.",
          "Hương giữa: Hoa nhài, hoa cam, húng quế.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 5,
        productName: "Sugar Addict",
        price: [990000, 5300000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [sugar_addict_1, sugar_addict_2],
        description: [
          "'Sugar Addict' từ Borntostandout là một mùi hương ngọt ngào và quyến rũ, mang đến một trải nghiệm olfactory đầy hấp dẫn và ấm áp. Với sự kết hợp tinh tế giữa các nốt hương ngọt ngào và gỗ, 'Sugar Addict' phù hợp cho cả nam và nữ, lý tưởng để sử dụng trong những dịp đặc biệt hoặc khi bạn muốn tạo dấu ấn ngọt ngào và lôi cuốn.",
          "'Sugar Addict' mở đầu với sự tươi mới và ngọt ngào từ cam bergamot, quả mọng và nho đen, tạo nên một cảm giác dễ chịu và kích thích. Hương giữa của vanilla, caramel và hoa nhài tạo nên một lớp hương ngọt ngào và quyến rũ, mang lại sự ấm áp và hấp dẫn. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên một kết thúc sâu lắng và lâu dài, mang lại sự hoàn hảo cho mùi hương.",
          "Hương đầu: Cam bergamot, quả mọng, nho đen.",
          "Hương giữa: Vanilla, caramel, hoa nhài.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 6,
        productName: "Sex & Cognac",
        price: [990000, 5300000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [sex_cognac_1, sex_cognac_2],
        description: [
          "'Sex & Cognac' từ Borntostandout là một mùi hương mạnh mẽ và quyến rũ, mang đến một trải nghiệm olfactory đầy đẳng cấp và lôi cuốn. Với sự kết hợp tinh tế giữa các nốt hương đậm chất lãng mạn và sự sang trọng của cognac, 'Sex & Cognac' phù hợp cho cả nam và nữ, lý tưởng để tạo dấu ấn nổi bật và thu hút sự chú ý.",
          "'Sex & Cognac' mở đầu với sự tươi mới và cay nồng của cam bergamot, tiêu đen và gừng, tạo nên một cảm giác khởi đầu đầy năng lượng và kích thích. Hương giữa mang đến sự lôi cuốn của cognac kết hợp với hoa nhài và hổ phách, tạo nên một lớp hương quyến rũ và sâu lắng. Cuối cùng, hương da thuộc, gỗ đàn hương và xạ hương tạo nên một kết thúc ấm áp, mạnh mẽ và lâu dài, thể hiện sự sang trọng và đẳng cấp.",
          "Hương đầu: Cam bergamot, tiêu đen, gừng.",
          "Hương giữa: Cognac, hoa nhài, hổ phách.",
          "Hương cuối: Da thuộc, gỗ đàn hương, xạ hương.",
        ],
      },
    ],
  },
  {
    id: 5,
    brand: "Diptyque",
    logo: diptyque,
    products: [
      {
        id: 1,
        productName: "ILIO",
        price: [null, null],
        // dung tích
        volume: [10, 75],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [ilio_edp_1, ilio_edp_2],
        description: [
          "'ILIO' từ Diptyque là một mùi hương đầy sức sống và tươi mới, được lấy cảm hứng từ ánh sáng mặt trời và mùa hè. Với sự kết hợp của các nốt hương hoa cỏ và trái cây, 'ILIO' mang đến một trải nghiệm olfactory nhẹ nhàng, quyến rũ và đầy năng lượng, phù hợp cho cả nam và nữ.",
          "'ILIO' mở đầu với sự tươi mới và sảng khoái từ cam bergamot, chanh và quả mọng, mang lại cảm giác dễ chịu và năng lượng tích cực. Hương giữa kết hợp hoa nhài, hoa cam và cỏ vetiver, tạo nên một lớp hương quyến rũ và tinh tế, gợi nhớ đến những ngày hè nắng ấm. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên một kết thúc ấm áp, lâu dài và đầy phong cách.",
          "Hương đầu: Cam bergamot, chanh, quả mọng.",
          "Hương giữa: Hoa nhài, hoa cam, cỏ vetiver.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "ILIO LIMITTED 2024",
        price: [null, null],
        // dung tích
        volume: [10, 75],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [ilio_limited_1, ilio_limited_2],
        description: [
          "'ILIO Limited 2024' là phiên bản giới hạn của mùi hương 'ILIO' từ Diptyque, được ra mắt để kỷ niệm năm 2024. Được thiết kế để mang đến một trải nghiệm olfactory đặc biệt và độc đáo, phiên bản này tiếp tục truyền tải sự tươi mới và quyến rũ của phiên bản gốc, với những cải tiến tinh tế để làm nổi bật sự đặc biệt của năm nay.",
          "'ILIO Limited 2024' mở đầu với sự tươi mới và ngọt ngào từ cam bergamot, chanh và quả đào, mang lại cảm giác dễ chịu và hấp dẫn. Hương giữa là sự kết hợp của hoa nhài, hoa cam và cỏ vetiver, tạo nên một lớp hương quyến rũ và thanh thoát, gợi nhớ đến những ngày hè nắng ấm. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách mang đến một kết thúc ấm áp và lâu dài, thể hiện sự sang trọng và phong cách đặc biệt của phiên bản giới hạn này.",
          "Hương đầu: Cam bergamot, chanh, quả đào.",
          "Hương giữa: Hoa nhài, hoa cam, cỏ vetiver.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Diptyque Eau Rose",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [eau_rose_1, eau_rose_2],
        description: [
          "'Eau Rose' từ Diptyque là một mùi hương thanh khiết và quyến rũ, tôn vinh vẻ đẹp của hoa hồng với sự tinh tế và sự nhẹ nhàng. Được thiết kế để mang đến một trải nghiệm olfactory đậm chất hoa cỏ, 'Eau Rose' phù hợp cho cả nam và nữ, và là sự lựa chọn lý tưởng cho những ai yêu thích sự mềm mại và quyến rũ của hoa hồng.",
          "'Eau Rose' mở đầu với sự tươi mới và dễ chịu của quả lý chua và cam bergamot, mang lại một cảm giác sảng khoái và năng lượng. Hương giữa của hoa hồng, hoa nhài và hoa cam tạo nên một lớp hương quyến rũ và tinh tế, thể hiện sự thanh thoát và mềm mại của hoa hồng. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách mang đến một kết thúc ấm áp và lâu dài, bổ sung cho sự quyến rũ và hoàn hảo của mùi hương.",
          "Hương đầu: Quả lý chua, cam bergamot.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 4,
        productName: "Do Son",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [do_son_1, do_son_2],
        description: [
          "'Do Son' từ Diptyque là một mùi hương thanh khiết và quyến rũ, mang đến một trải nghiệm olfactory đầy sức sống và tươi mới. Được lấy cảm hứng từ những bông hoa nhài nở rộ bên bờ biển, 'Do Son' kết hợp sự nhẹ nhàng của hoa cỏ với sự tươi mát của biển cả, phù hợp cho cả nam và nữ.",
          "'Do Son' mở đầu với sự tươi mới của quả lý chua, cam bergamot và hoa hồng, mang lại cảm giác dễ chịu và sảng khoái. Hương giữa là sự hòa quyện của hoa nhài, hoa cam và cỏ vetiver, tạo nên một lớp hương quyến rũ và tinh tế, gợi nhớ đến những ngày hè bên bờ biển. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp và lâu dài, bổ sung cho sự hoàn hảo của mùi hương.",
          "Hương đầu: Quả lý chua, cam bergamot, hoa hồng.",
          "Hương giữa: Hoa nhài, hoa cam, cỏ vetiver.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 5,
        productName: "L’eau Parpier",
        price: [500000, 4350000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [leau_papier_1, leau_papier_2],
        description: [
          "'L'Eau Papier' từ Diptyque là một mùi hương độc đáo và thanh thoát, mang đến một trải nghiệm olfactory tinh tế và nghệ thuật. Được lấy cảm hứng từ sự nhẹ nhàng của giấy và cảm giác mới mẻ của những trang sách, 'L'Eau Papier' kết hợp sự tươi mới và thanh thoát, phù hợp cho cả nam và nữ.",
          "'L'Eau Papier' mở đầu với sự tươi mới và dễ chịu của cam bergamot, hương thảo và quả xanh, mang lại cảm giác sảng khoái và nhẹ nhàng. Hương giữa kết hợp hoa nhài, hoa cam và iris, tạo nên một lớp hương thanh thoát và nghệ thuật, gợi cảm giác của những trang giấy trắng tinh. Cuối cùng, hương xạ hương, hương gỗ và hổ phách tạo nên một kết thúc ấm áp và lâu dài, mang đến sự hoàn thiện và sự tinh tế cho mùi hương.",
          "Hương đầu: Cam bergamot, hương thảo, quả xanh.",
          "Hương giữa: Hoa nhài, hoa cam, iris.",
          "Hương cuối: Xạ hương, hương gỗ, hổ phách.",
        ],
      },
      {
        id: 6,
        productName: "TamDao",
        price: [600000, 4350000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [tamdao_1, tamdao_2],
        description: [
          "'Tam Dao' từ Diptyque là một mùi hương gỗ ấm áp và thư giãn, mang đến một trải nghiệm olfactory đầy sự tinh tế và dễ chịu. Được lấy cảm hứng từ những chuyến du hành qua những khu rừng gỗ ở miền Đông Nam Á, 'Tam Dao' kết hợp sự thanh thoát của gỗ với cảm giác thư giãn, phù hợp cho cả nam và nữ.",
          "'Tam Dao' mở đầu với sự tươi mới và nhẹ nhàng của hương cam, quả chanh và tiêu đen, tạo cảm giác dễ chịu và kích thích. Hương giữa mang đến sự ấm áp và thanh thoát của gỗ đàn hương, gỗ tuyết tùng và cỏ vetiver, gợi nhớ đến vẻ đẹp tự nhiên của những khu rừng xanh mướt. Cuối cùng, hương xạ hương, hổ phách và gỗ sồi tạo nên một kết thúc ấm áp và lâu dài, mang lại sự hoàn hảo cho mùi hương.",
          "Hương đầu: Hương cam, quả chanh, tiêu đen.",
          "Hương giữa: Gỗ đàn hương, gỗ tuyết tùng, cỏ vetiver.",
          "Hương cuối: Xạ hương, hổ phách, gỗ sồi.",
        ],
      },
      {
        id: 7,
        productName: "Diptyque Fleur de Peau",
        price: [null, null],
        // dung tích
        volume: [10, 75],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [fleur_de_peau_1, fleur_de_peau_2],
        description: [
          "'Fleur de Peau' từ Diptyque là một mùi hương hoa cỏ quyến rũ và tinh tế, mang đến một trải nghiệm olfactory đầy sự nhẹ nhàng và sâu lắng. Được lấy cảm hứng từ vẻ đẹp của các bông hoa mềm mại và cảm giác mềm mượt của làn da, 'Fleur de Peau' kết hợp sự quyến rũ của hoa cỏ với sự nhẹ nhàng, phù hợp cho cả nam và nữ.",
          "'Fleur de Peau' mở đầu với sự tươi mới và dễ chịu của cam bergamot, hương thảo và quả mọng, tạo ra một cảm giác nhẹ nhàng và kích thích. Hương giữa kết hợp hoa nhài, hoa hồng và iris, mang đến một lớp hương quyến rũ và mềm mại, gợi cảm giác của những bông hoa nở rộ. Cuối cùng, hương xạ hương, da thuộc và hổ phách tạo nên một kết thúc ấm áp và lâu dài, làm nổi bật sự tinh tế và sự quyến rũ của mùi hương.",
          "Hương đầu: Cam bergamot, hương thảo, quả mọng.",
          "Hương giữa: Hoa nhài, hoa hồng, iris.",
          "Hương cuối: Xạ hương, da thuộc, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 6,
    brand: "Armaf",
    logo: armaf,
    products: [
      {
        id: 1,
        productName: "Club De nuit Women",
        price: [220000, 1200000],
        // dung tích
        volume: [10, 105],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [club_de_nuit_women_1, club_de_nuit_women_2],
        description: [
          "'Club De Nuit Women' từ Armaf là một mùi hương tinh tế và sang trọng, được thiết kế dành riêng cho phái đẹp. Mang trong mình sự quyến rũ và thanh lịch, mùi hương này là sự kết hợp hoàn hảo của các nốt hương trái cây và hoa cỏ, làm nổi bật phong cách quý phái và nữ tính.",
          "Mở đầu với sự tươi mát và ngọt ngào từ quả cam, bưởi và quả đào, 'Club De Nuit Women' nhanh chóng lôi cuốn người dùng với hương thơm trái cây thanh thoát. Hương giữa là sự kết hợp tinh tế của hoa hồng, hoa nhài và quả vải, mang lại cảm giác dịu dàng và nữ tính. Cuối cùng, sự ấm áp của xạ hương, vani, hoắc hương và cỏ vetiver tạo nên một lớp hương cuối lưu luyến và cuốn hút.",
          "Hương đầu: Quả cam, bưởi, quả đào, cam bergamot.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa phong lữ, quả vải.",
          "Hương cuối: Xạ hương, hương vanilla, hoắc hương, cỏ vetiver.",
        ],
      },
      {
        id: 2,
        productName: "Club de Nuit Intense Man",
        price: [230000, 1400000],
        // dung tích
        volume: [10, 105],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [club_de_nuit_intense_man_1, club_de_nuit_intense_man_2],
        description: [
          "'Club De Nuit Intense Man' của Armaf là một mùi hương nam tính mạnh mẽ, được thiết kế để khơi dậy sự tự tin và lôi cuốn. Với hương thơm cổ điển và phong cách, nước hoa này được ví như một tác phẩm đỉnh cao dành cho những người đàn ông thích sự sang trọng và quyền lực.",
          "'Club De Nuit Intense Man' bắt đầu với sự bùng nổ tươi mát của chanh và cam bergamot, kết hợp với vị ngọt nhẹ của dứa, táo và nho đen, tạo nên một mở đầu cuốn hút và đầy năng lượng. Hương giữa mang đến sự hòa quyện của hoa nhài và hoa hồng, thêm vào đó là hương gỗ bu-lô, tạo nên một lớp hương phức hợp và tinh tế. Cuối cùng, sự ấm áp của xạ hương, hoắc hương, hổ phách và vanilla để lại dấu ấn sâu sắc, mạnh mẽ, và lâu dài.",
          "Hương đầu: Quả chanh, cam bergamot, dứa, táo, nho đen.",
          "Hương giữa: Hoa nhài, gỗ bu-lô, hoa hồng.",
          "Hương cuối: Xạ hương, hoắc hương, hổ phách, vanilla.",
        ],
      },
      {
        id: 3,
        productName: "Club de Nuit Intense Man Limited Edition",
        price: [290000, 2300000],
        // dung tích
        volume: [10, 105],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [
          club_de_nuit_intense_man_limited_edition_1,
          club_de_nuit_intense_man_limited_edition_2,
        ],
        description: [
          "'Club De Nuit Intense Man Limited Edition' là phiên bản giới hạn của dòng nước hoa 'Club De Nuit Intense Man', mang đến một trải nghiệm olfactory mạnh mẽ và sang trọng hơn với sự tinh chỉnh trong các nốt hương. Được thiết kế dành riêng cho những người đàn ông muốn thể hiện sự quyền lực và quyến rũ, phiên bản đặc biệt này mang đến độ lưu hương và tỏa hương tốt hơn, với chất lượng nguyên liệu cao cấp hơn.",
          "Giống như phiên bản gốc, 'Club De Nuit Intense Man Limited Edition' mở đầu với sự tươi mát từ chanh và cam bergamot, pha lẫn với sự ngọt ngào từ dứa, táo và nho đen. Hương giữa tiếp tục mang đến sự tinh tế của hoa nhài, hoa hồng, kết hợp với gỗ bu-lô, tạo nên cảm giác mạnh mẽ và quyền lực. Lớp hương cuối cùng là sự kết hợp đầy ấm áp và sâu lắng của xạ hương, hoắc hương, hổ phách, và vanilla, giúp nước hoa bám lâu và tỏa xa hơn.",
          "Hương đầu: Chanh, cam bergamot, dứa, táo, nho đen.",
          "Hương giữa: Hoa nhài, hoa hồng, gỗ bu-lô.",
          "Hương cuối: Xạ hương, hoắc hương, hổ phách, vanilla.",
        ],
      },
      {
        id: 4,
        productName: "Club de nuit Urban Elixir",
        price: [250000, 1550000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [club_de_nuit_urban_elixir_1, club_de_nuit_urban_elixir_2],
        description: [
          "'Club De Nuit Urban Elixir' là một phiên bản nước hoa mới từ Armaf, mang phong cách hiện đại và mạnh mẽ dành cho phái mạnh. Đây là sự pha trộn hoàn hảo giữa các nốt hương tươi mát, ấm áp và gỗ, tạo nên một mùi hương lôi cuốn, đầy quyến rũ và nam tính.",
          "'Club De Nuit Urban Elixir' mở đầu với sự tươi mát và mạnh mẽ từ cam bergamot và tiêu đen, kết hợp cùng sự ấm áp của bạch đậu khấu và sự nhẹ nhàng của hoa oải hương. Hương giữa mang đến sự tinh tế và lịch lãm với các nốt hương hoa diên vĩ và lá violet, tạo nên một lớp hương phức hợp và đầy sự cuốn hút. Cuối cùng, sự ấm áp từ gỗ tuyết tùng, hoắc hương, và hổ phách kết hợp cùng xạ hương tạo ra một lớp hương cuối lưu luyến và mạnh mẽ.",
          "Hương đầu: Cam bergamot, bạch đậu khấu, tiêu đen, hoa oải hương.",
          "Hương giữa: Hoa diên vĩ, lá violet, hoa phong lữ.",
          "Hương cuối: Gỗ tuyết tùng, hoắc hương, hổ phách, xạ hương.",
        ],
      },
      {
        id: 5,
        productName: "Club de Nuit Untold",
        price: [250000, 1550000],
        // dung tích
        volume: [10, 105],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [club_de_nuit_untold_1, club_de_nuit_untold_2],
        description: [
          "'Club De Nuit Untold' là một phiên bản nước hoa cao cấp thuộc dòng Parfum của Armaf, được thiết kế dành cho những người đàn ông yêu thích sự tinh tế và bí ẩn. Được coi là một trong những chai nước hoa có độ bám và tỏa hương mạnh mẽ nhất của Armaf, 'Untold' mang trong mình sự sang trọng, kết hợp hài hòa giữa các nốt hương tươi mát, ấm áp và gỗ, tạo nên một hương thơm đầy quyền lực và nam tính.",
          "'Club De Nuit Untold' mở đầu với sự tươi mới từ cam bergamot, táo xanh, và sự ngọt ngào của nho đen và dứa. Lớp hương giữa tiếp tục gây ấn tượng mạnh với sự thanh lịch và quyến rũ của hoa nhài và gỗ bu-lô. Cuối cùng, xạ hương, hổ phách và rêu sồi kết hợp với vani tạo nên một lớp hương ấm áp và sâu lắng, giúp nước hoa giữ mùi lâu và tạo dấu ấn riêng biệt.",
          "Hương đầu: Cam bergamot, táo xanh, nho đen, dứa.",
          "Hương giữa: Hoa nhài, gỗ bu-lô, hoắc hương.",
          "Hương cuối: Xạ hương, hổ phách, rêu sồi, vanilla.",
        ],
      },
      {
        id: 6,
        productName: "Club De Nuit Imperiale",
        price: [250000, 1550000],
        // dung tích
        volume: [10, 105],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [club_de_nuit_imperiale_1, club_de_nuit_imperiale_2],
        description: [
          "'Club De Nuit Imperiale' là một trong những sáng tạo tinh tế của Armaf, mang đậm phong cách sang trọng và lịch lãm dành cho cả nam và nữ. Với sự pha trộn hoàn hảo của các nốt hương trái cây, hoa cỏ và hương gỗ, nước hoa này tỏa ra sự quyến rũ đầy thanh lịch và sự cuốn hút không thể cưỡng lại.",
          "'Club De Nuit Imperiale' mở đầu với sự tươi mát của cam bergamot, bưởi và chanh, mang lại cảm giác sảng khoái và đầy năng lượng. Hương giữa là sự pha trộn tinh tế giữa các loài hoa như hoa nhài, hoa hồng và oải hương, tạo nên một lớp hương đầy quyến rũ và nhẹ nhàng. Cuối cùng, sự kết hợp ấm áp từ gỗ tuyết tùng, xạ hương, vani và hổ phách tạo nên một dấu ấn lưu luyến và sang trọng.",
          "Hương đầu: Cam bergamot, bưởi, cam chanh.",
          "Hương giữa: Hoa nhài, hoa hồng, oải hương.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, vani, hổ phách.",
        ],
      },
      {
        id: 7,
        productName: "Club de Nuit Blue Iconic",
        price: [250000, 1550000],
        // dung tích
        volume: [10, 105],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [club_de_nuit_blue_iconic_1, club_de_nuit_blue_iconic_2],
        description: [
          "'Club De Nuit Blue Iconic' là một hương thơm mới đầy mạnh mẽ và quyến rũ từ dòng nước hoa nam của Armaf. Được tạo ra cho những người đàn ông yêu thích sự hiện đại và thanh lịch, nước hoa này thể hiện sự nam tính với sự pha trộn tinh tế của hương gỗ, gia vị và các thành phần tươi mát.",
          "'Club De Nuit Blue Iconic' bắt đầu với sự bùng nổ tươi mát từ cam bergamot và bưởi, hòa quyện cùng sự nồng ấm của tiêu đen và bạc hà, tạo ra một lớp hương đầu lôi cuốn. Hương giữa là sự kết hợp tinh tế giữa hoa oải hương, gừng, nhục đậu khấu và hoa tím, mang đến cảm giác nam tính nhưng vẫn nhẹ nhàng. Cuối cùng, hương gỗ ấm áp từ gỗ tuyết tùng, gỗ đàn hương, hoắc hương và xạ hương tạo nên một lớp hương sâu lắng và lâu dài, mang lại sự ấm áp và đầy sức hút.",
          "Hương đầu: Cam bergamot, tiêu đen, bưởi, bạc hà.",
          "Hương giữa: Hoa oải hương, gừng, nhục đậu khấu, hoa tím.",
          "Hương cuối: Gỗ tuyết tùng, gỗ đàn hương, hoắc hương, xạ hương.",
        ],
      },
      {
        id: 8,
        productName: "Ventana",
        price: [180000, 1150000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [ventana_1, ventana_2],
        description: [
          "'Ventana' là một trong những dòng nước hoa dành cho nam nổi bật của Armaf, với sự kết hợp giữa các nốt hương tươi mát, ấm áp và gỗ, tạo nên một hương thơm quyến rũ và nam tính. Nước hoa này được thiết kế dành cho những người đàn ông yêu thích sự thanh lịch và mạnh mẽ, lý tưởng cho cả ngày và đêm.",
          "'Ventana' mở đầu với hương cam bergamot và bưởi, mang lại sự tươi mát và năng lượng. Hương giữa là sự pha trộn nhẹ nhàng của hoa oải hương và cỏ vetiver, mang đến cảm giác thư giãn nhưng vẫn đầy nam tính. Cuối cùng, lớp hương cuối từ gỗ tuyết tùng, hoắc hương và gỗ đàn hương tạo nên một kết thúc ấm áp, đầy sang trọng.",
          "Hương đầu: Chanh, cam bergamot, bưởi.",
          "Hương giữa: Hoa oải hương, cỏ vetiver, cây phong lữ.",
          "Hương cuối: Gỗ tuyết tùng, hoắc hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 9,
        productName: "La Rosa",
        price: [200000, 1250000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [la_rosa_1, la_rosa_2],
        description: [
          "'La Rosa' của Armaf là một lựa chọn hoàn hảo cho những người phụ nữ yêu thích sự quyến rũ và thanh lịch. Được thiết kế để tôn vinh vẻ đẹp nữ tính, nước hoa này kết hợp hài hòa các nốt hương hoa cỏ và trái cây, tạo nên một mùi hương thơm ngát và đầy cuốn hút.",
          "'La Rosa' mở đầu với sự tươi mới từ cam bergamot, táo, dâu tây và chanh, mang lại cảm giác sảng khoái và năng lượng. Hương giữa nổi bật với các nốt hoa hồng, hoa nhài và hoa cam, tạo nên một lớp hương quyến rũ và thanh lịch. Cuối cùng, lớp hương từ xạ hương, hổ phách, gỗ đàn hương và vanilla mang đến sự ấm áp và lưu luyến, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, táo, dâu tây, chanh.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương, vanilla.",
        ],
      },
    ],
  },
  {
    id: 7,
    brand: "Attar Collection",
    logo: attar_collection,
    products: [
      {
        id: 1,
        productName: "Musk Kashmir",
        price: [300000, 2450000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [musk_kashmir_1, musk_kashmir_2],
        description: [
          "'Musk Kashmir' của Attar Collection là một hương thơm quyến rũ và tinh tế, mang đến một cảm giác ấm áp và thoải mái với sự kết hợp hoàn hảo của các nốt hương xạ hương và gỗ. Được thiết kế cho cả nam và nữ, nước hoa này mang đến một sự trải nghiệm olfactory sâu lắng và sang trọng.",
          "'Musk Kashmir' mở đầu với sự tươi mới từ cam bergamot và húng quế, hòa quyện với gia vị nhẹ để tạo ra một lớp hương đầu lôi cuốn. Hương giữa là sự kết hợp quyến rũ của xạ hương, hoa nhài và gỗ đàn hương, mang đến một cảm giác ấm áp và thanh lịch. Cuối cùng, lớp hương từ xạ hương, hổ phách, gỗ tuyết tùng và vani tạo ra một kết thúc đầy sâu lắng và bền bỉ, mang đến sự ấm áp và sang trọng.",
          "Hương đầu: Cam bergamot, húng quế, gia vị nhẹ.",
          "Hương giữa: Xạ hương, hoa nhài, gỗ đàn hương.",
          "Hương cuối: Xạ hương, hổ phách, gỗ tuyết tùng, vani.",
        ],
      },
      {
        id: 2,
        productName: "Queen Of Sheba",
        price: [300000, 2450000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [queen_of_sheba_1, queen_of_sheba_2],
        description: [
          "'Queen of Sheba' của Attar Collection là một hương thơm mang đậm dấu ấn của sự quyến rũ và sang trọng, được lấy cảm hứng từ vẻ đẹp lôi cuốn của nữ hoàng Sheba. Nước hoa này kết hợp hoàn hảo giữa các nốt hương hoa cỏ, trái cây và gia vị, tạo nên một trải nghiệm olfactory đầy lôi cuốn và thanh lịch.",
          "'Queen of Sheba' mở đầu với sự tươi mới và ngọt ngào từ cam bergamot, dâu tây và nhục đậu khấu, mang lại một lớp hương đầu lôi cuốn và đầy năng lượng. Hương giữa nổi bật với sự quyến rũ của hoa nhài, hoa hồng và hoa cam, tạo nên một lớp hương nhẹ nhàng và thanh lịch. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương, hổ phách và vani kết hợp để tạo nên một kết thúc sâu lắng, sang trọng và lưu luyến.",
          "Hương đầu: Cam bergamot, dâu tây, nhục đậu khấu.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách, vani.",
        ],
      },
    ],
  },
  {
    id: 8,
    brand: "Hermes",
    logo: hermes,
    products: [
      {
        id: 1,
        productName: "Eau des Merveilles Bleue",
        price: [330000, 2800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [eau_des_merveilles_bleue_1, eau_des_merveilles_bleue_2],
        description: [
          "'Eau des Merveilles Bleue' của Hermès là một phiên bản mới của dòng nước hoa nổi tiếng 'Eau des Merveilles,' mang đến một trải nghiệm hương thơm tươi mới và đầy quyến rũ. Được thiết kế để gợi cảm giác của những điều kỳ diệu và sự thanh thoát của đại dương, nước hoa này kết hợp các nốt hương tự nhiên và tinh tế, tạo nên một hương thơm nhẹ nhàng nhưng đầy sức hút.",
          "'Eau des Merveilles Bleue' mở đầu với sự tươi mát và năng động từ cam bergamot, bưởi và chanh, mang lại một lớp hương đầu tươi mới và dễ chịu. Hương giữa là sự kết hợp thanh lịch của hoa nhài, hoa cam và gỗ tuyết tùng, tạo nên một lớp hương quyến rũ và nhẹ nhàng. Cuối cùng, lớp hương từ xạ hương, hổ phách và gỗ đàn hương mang lại một kết thúc ấm áp và bền lâu, để lại ấn tượng sâu sắc và thanh thoát.",
          "Hương đầu: Cam bergamot, bưởi, chanh.",
          "Hương giữa: Hoa nhài, hoa cam, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 2,
        productName: "Eau Des Merveilles",
        price: [300000, 2800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [eau_des_merveilles_1, eau_des_merveilles_2],
        description: [
          "'Eau des Merveilles' của Hermès là một tác phẩm nghệ thuật trong thế giới nước hoa, mang đến một hương thơm đầy quyến rũ và lôi cuốn. Được tạo ra để gợi cảm giác của những điều kỳ diệu và bí ẩn, nước hoa này kết hợp sự tươi mới của các nốt hương tự nhiên với sự ấm áp của gỗ và hổ phách, tạo nên một hương thơm đầy sâu lắng và thanh thoát.",
          "'Eau des Merveilles' mở đầu với sự tươi mới và sáng tạo từ cam bergamot, bưởi và chanh, mang lại một lớp hương đầu đầy năng lượng và dễ chịu. Hương giữa là sự kết hợp lôi cuốn của hoa nhài, hoa cam và tiêu đen, tạo nên một lớp hương quyến rũ và tinh tế. Cuối cùng, lớp hương từ xạ hương, hổ phách, gỗ đàn hương và gỗ tuyết tùng tạo nên một kết thúc ấm áp, bền lâu và sâu lắng, mang lại sự quyến rũ không thể cưỡng lại.",
          "Hương đầu: Cam bergamot, bưởi, chanh.",
          "Hương giữa: Hoa nhài, hoa cam, tiêu đen.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương, gỗ tuyết tùng.",
        ],
      },
      {
        id: 3,
        productName: "Un Jarrdin Sur Le Toit",
        price: [310000, 2600000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [un_jarrdin_sur_le_toit_1, un_jarrdin_sur_le_toit_2],
        description: [
          "'Un Jardin Sur Le Toit' của Hermès là một hương thơm tươi mới và nhẹ nhàng, được lấy cảm hứng từ một khu vườn trên mái nhà ở Paris. Nước hoa này mang đến một trải nghiệm olfactory như một chuyến dạo chơi trong khu vườn xanh tươi, với sự kết hợp của các nốt hương thiên nhiên và tươi mát.",
          "'Un Jardin Sur Le Toit' mở đầu với sự tươi mới và năng động từ táo xanh, quả lê và bạc hà, tạo ra một lớp hương đầu sảng khoái và dễ chịu. Hương giữa là sự kết hợp tinh tế của hoa hồng, hoa nhài và cây hương thảo, mang đến cảm giác thanh thoát và tự nhiên. Cuối cùng, lớp hương từ gỗ tuyết tùng, xạ hương và hạt tiêu tạo ra một kết thúc nhẹ nhàng và ấm áp, để lại ấn tượng sâu lắng và bền bỉ.",
          "Hương đầu: Táo xanh, quả lê, bạc hà.",
          "Hương giữa: Hoa hồng, hoa nhài, cây hương thảo.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, hạt tiêu.",
        ],
      },
      {
        id: 4,
        productName: "Hermes Un Jardin Sur Le Nil",
        price: [310000, 2700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [un_jardin_sur_le_nil_1, un_jardin_sur_le_nil_2],
        description: [
          "'Un Jardin Sur Le Nil' của Hermès là một hương thơm đầy tươi mới và quyến rũ, được lấy cảm hứng từ một khu vườn xanh mát nằm bên bờ sông Nile. Nước hoa này mang đến một trải nghiệm olfactory như một cuộc dạo chơi qua một khu vườn nhiệt đới, với sự kết hợp hài hòa của các nốt hương trái cây, hoa cỏ và gia vị.",
          "'Un Jardin Sur Le Nil' mở đầu với sự tươi mới từ bưởi, chanh, húng quế và tiêu xanh, tạo ra một lớp hương đầu đầy năng lượng và sảng khoái. Hương giữa nổi bật với sự quyến rũ của hoa nhài, hoa sen và gỗ guaiac, mang đến một cảm giác thanh thoát và tinh tế. Cuối cùng, lớp hương từ gỗ tuyết tùng, xạ hương và gỗ đàn hương tạo nên một kết thúc ấm áp, bền bỉ và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Bưởi, chanh, húng quế, tiêu xanh.",
          "Hương giữa: Hoa nhài, hoa sen, gỗ guaiac.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, gỗ đàn hương.",
        ],
      },
      {
        id: 5,
        productName: "Hermès H24",
        price: [300000, 2300000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [h24_1, h24_2],
        description: [
          "'H24' của Hermès là một nước hoa dành cho nam giới, mang đến một hương thơm tươi mới và hiện đại, phản ánh phong cách năng động và đầy sức sống. Được thiết kế để phù hợp với nhịp sống nhanh và đô thị, nước hoa này kết hợp các nốt hương tự nhiên và hiện đại, tạo nên một trải nghiệm olfactory đầy ấn tượng.",
          "'H24' mở đầu với sự tươi mới từ húng quế, tiêu xanh và cây hương thảo, tạo ra một lớp hương đầu đầy năng lượng và sảng khoái. Hương giữa nổi bật với sự kết hợp của hoa nhài, cây xô thơm và nho xanh, mang đến một lớp hương quyến rũ và tinh tế. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương và gỗ tuyết tùng tạo nên một kết thúc ấm áp và bền bỉ, để lại ấn tượng lâu dài và nam tính.",
          "Hương đầu: Húng quế, tiêu xanh, cây hương thảo.",
          "Hương giữa: Hoa nhài, cây xô thơm, nho xanh.",
          "Hương cuối: Gỗ đàn hương, xạ hương, gỗ tuyết tùng.",
        ],
      },
      {
        id: 6,
        productName: "Twilly d'Hermes Eau Poivree",
        price: [450000, 2900000],
        // dung tích
        volume: [10, 85],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [twilly_dhermes_eau_poivree_1, twilly_dhermes_eau_poivree_2],
        description: [
          "'Twilly d'Hermès Eau Poivrée' của Hermès là một phiên bản đầy lôi cuốn và năng động của dòng nước hoa 'Twilly'. Được tạo ra cho những người phụ nữ yêu thích sự phá cách và quyến rũ, nước hoa này mang đến một hương thơm tươi mới, với sự kết hợp độc đáo giữa các nốt hương cay nồng và hoa cỏ.",
          "'Twilly d'Hermès Eau Poivrée' mở đầu với sự mạnh mẽ và lôi cuốn từ tiêu hồng và tiêu đen, tạo ra một lớp hương đầu cay nồng và đầy năng lượng. Hương giữa nổi bật với sự quyến rũ của hoa hồng và hoa nhài, mang đến một lớp hương nữ tính và thanh thoát. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp, bền bỉ và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Tiêu hồng, tiêu đen.",
          "Hương giữa: Hoa hồng, hoa nhài.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 7,
        productName: "Terre d’Hermes",
        price: [310000, 2600000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [terre_dhermes_1, terre_dhermes_2],
        description: [
          "'Terre d’Hermès' của Hermès là một nước hoa nam giới mang đến một hương thơm mạnh mẽ và quyến rũ, được tạo ra để phản ánh sự kết nối sâu sắc với đất và thiên nhiên. Được đánh giá cao về sự tinh tế và chiều sâu, nước hoa này là một biểu hiện của sự mạnh mẽ và lôi cuốn trong thế giới nước hoa.",
          "'Terre d’Hermès' mở đầu với sự tươi mới từ cam bergamot và bưởi, tạo ra một lớp hương đầu sảng khoái và năng động. Hương giữa nổi bật với sự mạnh mẽ của hạt tiêu, hạt nhục đậu khấu và hoa nhài, mang đến một lớp hương quyến rũ và ấm áp. Cuối cùng, lớp hương từ gỗ tuyết tùng, xạ hương, hổ phách và gỗ đàn hương tạo ra một kết thúc sâu lắng, ấm áp và bền bỉ, để lại ấn tượng lâu dài và nam tính.",
          "Hương đầu: Cam bergamot, bưởi.",
          "Hương giữa: Hạt tiêu, hạt nhục đậu khấu, hoa nhài.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 8,
        productName: "Twilly d'Hermes",
        price: [400000, 2800000],
        // dung tích
        volume: [10, 85],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [twilly_dhermes_1, twilly_dhermes_2],
        description: [
          "'Twilly d'Hermès' của Hermès là một nước hoa dành cho nữ giới mang đến một hương thơm tươi mới và quyến rũ, được thiết kế để phản ánh sự trẻ trung và cá tính của phụ nữ hiện đại. Với sự kết hợp của các nốt hương trái cây, hoa cỏ và gia vị, nước hoa này tạo ra một trải nghiệm olfactory đầy lôi cuốn và vui tươi.",
          "'Twilly d'Hermès' mở đầu với sự tươi mới và năng động từ gừng và cam bergamot, tạo ra một lớp hương đầu đầy sức sống và vui tươi. Hương giữa nổi bật với sự quyến rũ của hoa nhài và hoa cam, mang đến một lớp hương nữ tính và thanh thoát. Cuối cùng, lớp hương từ xạ hương và gỗ đàn hương tạo nên một kết thúc ấm áp, bền bỉ và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Gừng, cam bergamot.",
          "Hương giữa: Hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, gỗ đàn hương.",
        ],
      },
    ],
  },
  {
    id: 9,
    brand: "BDK",
    logo: bdk,
    products: [
      {
        id: 1,
        productName: "Gris Charnel Extrait",
        price: [700000, 3900000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "Extrait de Parfum",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [gris_charnel_extrait_1, gris_charnel_extrait_2],
        description: [
          "Gris Charnel Extrait' của BDK Parfums là một phiên bản tinh tế và mạnh mẽ của dòng nước hoa 'Gris Charnel'. Với nồng độ cao hơn, sản phẩm này mang đến một hương thơm quyến rũ và bí ẩn, hoàn hảo cho những người yêu thích sự sang trọng và đẳng cấp.",
          "'Gris Charnel Extrait' mở đầu với sự tươi mới và gia vị từ cây xô thơm, chanh và hạt tiêu đen, tạo ra một lớp hương đầu đầy năng lượng và lôi cuốn. Hương giữa nổi bật với sự quyến rũ của hoa nhài, hoa hồng và hoắc hương, mang đến một lớp hương sâu lắng và tinh tế. Cuối cùng, lớp hương từ gỗ tuyết tùng, xạ hương, hổ phách và gỗ đàn hương tạo nên một kết thúc ấm áp, bền bỉ và bí ẩn, để lại ấn tượng lâu dài và quyến rũ.",
          "Hương đầu: Cây xô thơm, chanh, hạt tiêu đen.",
          "Hương giữa: Hoa nhài, hoa hồng, hoắc hương.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 2,
        productName: "Rouge Smoking",
        price: [700000, 6300000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [rouge_smoking_1, rouge_smoking_2],
        description: [
          "'Rouge Smoking' của BDK Parfums là một nước hoa đầy quyến rũ và gợi cảm, với sự kết hợp tinh tế của các nốt hương trái cây, hoa cỏ và gia vị. Nước hoa này mang đến một trải nghiệm olfactory độc đáo, tạo ra một lớp hương nồng nàn và lôi cuốn, hoàn hảo cho những ai yêu thích sự sang trọng và nổi bật.",
          "'Rouge Smoking' mở đầu với sự tươi mới và quyến rũ từ quả anh đào, tiêu hồng và bưởi, tạo ra một lớp hương đầu đầy năng lượng và hấp dẫn. Hương giữa nổi bật với sự lôi cuốn của hoa hồng, hoa nhài và hạt nhục đậu khấu, mang đến một lớp hương phong phú và tinh tế. Cuối cùng, lớp hương từ xạ hương, vani và hổ phách tạo nên một kết thúc ấm áp, ngọt ngào và bền bỉ, để lại ấn tượng lâu dài và quyến rũ.",
          "Hương đầu: Quả anh đào, tiêu hồng, bưởi.",
          "Hương giữa: Hoa hồng, hoa nhài, hạt nhục đậu khấu.",
          "Hương cuối: Xạ hương, vani, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Gris Charnel EDP",
        price: [500000, 4000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [gris_charnel_extrait_1, gris_charnel_extrait_2],
        description: [
          "'Gris Charnel' của BDK Parfums là một nước hoa đầy bí ẩn và quyến rũ, mang đến một hương thơm phong phú và tinh tế. Được thiết kế để phản ánh sự sang trọng và chiều sâu, nước hoa này kết hợp các nốt hương trái cây, hoa cỏ và gỗ, tạo ra một trải nghiệm olfactory độc đáo và lôi cuốn.",
          "'Gris Charnel' mở đầu với sự tươi mới và quyến rũ từ quả vải, tiêu đen và cam bergamot, tạo ra một lớp hương đầu phong phú và lôi cuốn. Hương giữa nổi bật với sự quyến rũ của hoa nhài, hoa hồng và hoắc hương, mang đến một lớp hương sâu lắng và tinh tế. Cuối cùng, lớp hương từ gỗ tuyết tùng, xạ hương, hổ phách và gỗ đàn hương tạo nên một kết thúc ấm áp, bền bỉ và bí ẩn, để lại ấn tượng lâu dài và sang trọng.",
          "Hương đầu: Quả vải, tiêu đen, cam bergamot.",
          "Hương giữa: Hoa nhài, hoa hồng, hoắc hương.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
    ],
  },
  {
    id: 10,
    brand: "Marie Jeanne",
    logo: marie_jeanne,
    products: [
      {
        id: 1,
        productName: "Vétiver Santal",
        price: [550000, 4950000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [vetiver_santal_1, vetiver_santal_2],
        description: [
          "'Vétiver Santal' của Marie Jeanne là một nước hoa tinh tế và quyến rũ, mang đến sự kết hợp hoàn hảo giữa các nốt hương gỗ và gia vị. Với sự hòa quyện độc đáo giữa vétiver và gỗ đàn hương, nước hoa này tạo ra một hương thơm sâu lắng và đầy lôi cuốn, phù hợp cho cả nam và nữ.",
          "'Vétiver Santal' mở đầu với sự tươi mới và gia vị từ cam bergamot, gừng và tiêu đen, tạo ra một lớp hương đầu đầy năng lượng và hấp dẫn. Hương giữa nổi bật với sự sâu lắng của vétiver và gỗ đàn hương, kết hợp với hạt nhục đậu khấu, mang đến một lớp hương phong phú và tinh tế. Cuối cùng, lớp hương từ xạ hương, gỗ tuyết tùng và hổ phách tạo nên một kết thúc ấm áp, bền bỉ và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, gừng, tiêu đen.",
          "Hương giữa: Vétiver, gỗ đàn hương, hạt nhục đậu khấu.",
          "Hương cuối: Xạ hương, gỗ tuyết tùng, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 11,
    brand: "Chloe",
    logo: chloe,
    products: [
      {
        id: 1,
        productName: "Chloé",
        price: [350000, 2350000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [chloe_1, chloe_2],
        description: [
          "'Chloé' của thương hiệu Chloé là một nước hoa nữ tính và thanh thoát, mang đến một hương thơm tươi mới và quyến rũ. Được thiết kế để phản ánh sự tinh tế và vẻ đẹp tự nhiên, nước hoa này là sự kết hợp hoàn hảo giữa các nốt hương hoa cỏ và trái cây, tạo ra một trải nghiệm olfactory đầy lôi cuốn và thanh thoát.",
          "'Chloé' mở đầu với sự tươi mới và thanh thoát từ hoa hồng, cam bergamot và dưa hấu, tạo ra một lớp hương đầu nhẹ nhàng và quyến rũ. Hương giữa nổi bật với sự nữ tính của hoa nhài, hoa cam và hoa mẫu đơn, mang đến một lớp hương phong phú và thanh thoát. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp, bền bỉ và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Hoa hồng, cam bergamot, dưa hấu.",
          "Hương giữa: Hoa nhài, hoa cam, hoa mẫu đơn.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Love Story",
        price: [380000, 2500000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [love_story_1, love_story_2],
        description: [
          "'Love Story' của Chloé là một nước hoa đầy lãng mạn và quyến rũ, mang đến một hương thơm tươi mới và thanh thoát, gợi nhớ đến những câu chuyện tình yêu ngọt ngào. Được thiết kế để phản ánh sự nữ tính và vẻ đẹp lôi cuốn, nước hoa này là sự kết hợp hoàn hảo giữa các nốt hương hoa cỏ và trái cây, tạo ra một trải nghiệm olfactory đầy cảm xúc.",
          "'Love Story' mở đầu với sự tươi mới và quyến rũ từ hoa nhài, cam bergamot và quýt, tạo ra một lớp hương đầu nhẹ nhàng và thanh thoát. Hương giữa nổi bật với sự nữ tính của hoa cam, hoa mẫu đơn và hoa hồng, mang đến một lớp hương phong phú và lãng mạn. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp, bền bỉ và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Hoa nhài, cam bergamot, quýt.",
          "Hương giữa: Hoa cam, hoa mẫu đơn, hoa hồng.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Nomade",
        price: [380000, 2500000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [nomade_1, nomade_2],
        description: [
          "'Nomade' của Chloé là một nước hoa đầy năng lượng và quyến rũ, mang đến một hương thơm tự do và phong trần, gợi nhớ đến những chuyến phiêu lưu và khám phá. Được thiết kế để phản ánh sự tự do và sự mạnh mẽ, nước hoa này là sự kết hợp hoàn hảo giữa các nốt hương hoa cỏ và gia vị, tạo ra một trải nghiệm olfactory độc đáo và đầy cảm xúc.",
          "'Nomade' mở đầu với sự tươi mới và quyến rũ từ quả hồng và cam bergamot, tạo ra một lớp hương đầu thanh thoát và lôi cuốn. Hương giữa nổi bật với sự phong phú của hoa nhài, hoa cam và hoa mẫu đơn, mang đến một lớp hương nữ tính và quyến rũ. Cuối cùng, lớp hương từ cỏ vetiver, gỗ đàn hương và xạ hương tạo nên một kết thúc ấm áp, bền bỉ và mạnh mẽ, để lại ấn tượng lâu dài.",
          "Hương đầu: Quả hồng, cam bergamot.",
          "Hương giữa: Hoa nhài, hoa cam, hoa mẫu đơn.",
          "Hương cuối: Cỏ vetiver, gỗ đàn hương, xạ hương.",
        ],
      },
    ],
  },
  {
    id: 12,
    brand: "Clive Christian",
    logo: clive_christian,
    products: [
      {
        id: 1,
        productName: "1872 Feminine",
        price: [2000000, 8500000],
        // price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [feminine_1872_1, feminine_1872_2],
        description: [
          "'1872 Feminine' của Clive Christian là một nước hoa sang trọng và cổ điển, mang đến một hương thơm quyến rũ và đầy lôi cuốn. Được đặt tên theo năm thành lập của thương hiệu, nước hoa này phản ánh sự tinh tế và đẳng cấp, kết hợp các nốt hương hoa cỏ và trái cây để tạo ra một trải nghiệm olfactory đầy cảm xúc và phong cách.",
          "'1872 Feminine' mở đầu với sự tươi mới và quyến rũ từ cam bergamot, chanh và húng quế, tạo ra một lớp hương đầu thanh thoát và đầy năng lượng. Hương giữa nổi bật với sự nữ tính của hoa nhài, hoa hồng và lily, mang đến một lớp hương phong phú và lôi cuốn. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp, bền bỉ và sang trọng, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, chanh, húng quế.",
          "Hương giữa: Hoa nhài, hoa hồng, lily.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "1872 Masculine",
        price: [2500000, 10000000],
        // price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [masculine_1872_1, masculine_1872_2],
        description: [
          "'1872 Masculine' của Clive Christian là một nước hoa nam tính và đầy phong cách, mang đến một hương thơm sang trọng và mạnh mẽ. Được đặt tên theo năm thành lập của thương hiệu, nước hoa này kết hợp các nốt hương gỗ, gia vị và citrus để tạo ra một trải nghiệm olfactory đầy lôi cuốn và đẳng cấp.",
          "'1872 Masculine' mở đầu với sự tươi mới và năng lượng từ cam bergamot, chanh, húng quế và nhục đậu khấu, tạo ra một lớp hương đầu phong phú và lôi cuốn. Hương giữa nổi bật với sự tinh tế của hoa hồng, nhài và gỗ tuyết tùng, mang đến một lớp hương đầy nam tính và sâu lắng. Cuối cùng, lớp hương từ cỏ vetiver, xạ hương và gỗ đàn hương tạo nên một kết thúc ấm áp, bền bỉ và mạnh mẽ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, chanh, húng quế, nhục đậu khấu.",
          "Hương giữa: Hoa hồng, nhài, gỗ tuyết tùng.",
          "Hương cuối: Cỏ vetiver, xạ hương, gỗ đàn hương.",
        ],
      },
      {
        id: 3,
        productName: "Town & Country",
        price: [2700000, 13000000],
        // dung tích
        volume: [10, 50],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [town_country_1, town_country_2],
        description: [
          "'Town & Country' của Clive Christian là một nước hoa nam tính và lịch lãm, được thiết kế để phản ánh sự tinh tế và phong cách cổ điển. Với sự kết hợp hài hòa giữa các nốt hương gỗ, gia vị và citrus, nước hoa này mang đến một trải nghiệm olfactory đầy sự thanh thoát và quyến rũ.",
          "'Town & Country' mở đầu với sự tươi mới và năng lượng từ cam bergamot, chanh, húng quế và nhục đậu khấu, tạo ra một lớp hương đầu phong phú và lôi cuốn. Hương giữa nổi bật với sự hòa quyện của gỗ tuyết tùng, hoa nhài và hoa hồng, mang đến một lớp hương đầy nam tính và tinh tế. Cuối cùng, lớp hương từ cỏ vetiver, xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp, bền bỉ và thanh thoát, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, chanh, húng quế, nhục đậu khấu.",
          "Hương giữa: Gỗ tuyết tùng, hoa nhài, hoa hồng.",
          "Hương cuối: Cỏ vetiver, xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 4,
        productName: "E Cashmere Musk",
        price: [2600000, 12000000],
        // dung tích
        volume: [10, 50],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [e_cashmere_musk_1, e_cashmere_musk_2],
        description: [
          "'E Cashmere Musk' của Clive Christian là một nước hoa nam tính, mang đến một hương thơm ấm áp và quyến rũ. Được thiết kế để phản ánh sự tinh tế và phong cách cổ điển, nước hoa này kết hợp các nốt hương musky và gỗ, tạo ra một trải nghiệm olfactory đầy sự lôi cuốn và sang trọng.",
          "'E Cashmere Musk' mở đầu với sự tươi mới và thanh thoát từ cam bergamot, chanh và húng quế, tạo ra một lớp hương đầu dễ chịu và đầy năng lượng. Hương giữa nổi bật với sự hòa quyện của hoa nhài, hoa hồng và gỗ tuyết tùng, mang đến một lớp hương nam tính và phong phú. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương, cashmere và hổ phách tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, chanh, húng quế.",
          "Hương giữa: Hoa nhài, hoa hồng, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, gỗ đàn hương, cashmere, hổ phách.",
        ],
      },
      {
        id: 5,
        productName: "Hedonistic",
        price: [2700000, 13000000],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "gần",
        img: [hedonistic_1, hedonistic_2],
        description: [
          "'Hedonistic' của Clive Christian là một nước hoa nữ tính và quyến rũ, mang đến một hương thơm đầy cảm xúc và sang trọng. Được thiết kế để phản ánh sự xa hoa và sự đam mê, nước hoa này kết hợp các nốt hương hoa cỏ, trái cây và gỗ, tạo ra một trải nghiệm olfactory lôi cuốn và mê hoặc.",
          "'Hedonistic' mở đầu với sự tươi mới và quyến rũ từ quả đào, cam bergamot và quýt, tạo ra một lớp hương đầu ngọt ngào và hấp dẫn. Hương giữa nổi bật với sự phong phú của hoa nhài, hoa hồng và hoa mẫu đơn, mang đến một lớp hương nữ tính và đầy lôi cuốn. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương, hổ phách và vani tạo nên một kết thúc ấm áp, sang trọng và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Quả đào, cam bergamot, quýt.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa mẫu đơn.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách, vani.",
        ],
      },
      {
        id: 6,
        productName: "Crab Apple Blossom",
        price: [2500000, 11000000],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [crab_apple_blossom_1, crab_apple_blossom_2],
        description: [
          "'Crab Apple Blossom' của Clive Christian là một nước hoa nữ tính, mang đến một hương thơm tươi mới và thanh thoát. Với sự kết hợp hoàn hảo giữa các nốt hương trái cây và hoa cỏ, nước hoa này gợi cảm giác của mùa xuân và sự tươi mới, tạo ra một trải nghiệm olfactory đầy sức sống và quyến rũ.",
          "'Crab Apple Blossom' mở đầu với sự tươi mới và ngọt ngào từ táo xanh, cam bergamot và quýt, tạo ra một lớp hương đầu sống động và đầy sức sống. Hương giữa nổi bật với sự phong phú của hoa nhài, hoa hồng và hoa mẫu đơn, mang đến một lớp hương nữ tính và quyến rũ. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương và hổ phách tạo nên một kết thúc ấm áp và thanh thoát, để lại ấn tượng lâu dài.",
          "Hương đầu: Táo xanh, cam bergamot, quýt.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa mẫu đơn.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 7,
        productName: "Iconic Feminine",
        price: [2600000, 11500000],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [_iconic_feminine_1, _iconic_feminine_2],
        description: [
          "'Iconic Feminine' của Clive Christian là một nước hoa nữ tính, mang đến một hương thơm sang trọng và quyến rũ. Được thiết kế để tôn vinh vẻ đẹp và sự tự tin của người phụ nữ hiện đại, nước hoa này kết hợp các nốt hương hoa cỏ, trái cây và gỗ, tạo ra một trải nghiệm olfactory đầy sự lôi cuốn và đẳng cấp.",
          "'Iconic Feminine' mở đầu với sự tươi mới và ngọt ngào từ cam bergamot, quả lê và nhục đậu khấu, tạo ra một lớp hương đầu tươi tắn và lôi cuốn. Hương giữa nổi bật với sự tinh tế của hoa nhài, hoa hồng và hoa violet, mang đến một lớp hương nữ tính và sang trọng. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương, hổ phách và vani tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, quả lê, nhục đậu khấu.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa violet.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách, vani.",
        ],
      },
    ],
  },
  {
    id: 13,
    brand: "Dior",
    logo: dior,
    products: [
      {
        id: 1,
        productName: "Sauvage EDT",
        price: [330000, 3300000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [sauvage_edt_1, sauvage_edt_2],
        description: [
          "'Sauvage Eau de Toilette' của Dior là một nước hoa nam tính mang đến một hương thơm tươi mới và mạnh mẽ. Với sự kết hợp hoàn hảo giữa các nốt hương tươi mới và gia vị, nước hoa này gợi cảm giác của sự tự do và phóng khoáng, tạo ra một trải nghiệm olfactory đầy sức sống và quyến rũ.",
          "'Sauvage Eau de Toilette' mở đầu với sự tươi mới và châm biếm từ cam bergamot và tiêu đen, tạo ra một lớp hương đầu đầy năng lượng và sống động. Hương giữa nổi bật với sự hòa quyện của hoa nhài và cây hương thảo, mang đến một lớp hương nam tính và quyến rũ. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương và ambroxan tạo nên một kết thúc ấm áp, mạnh mẽ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, tiêu đen.",
          "Hương giữa: Hoa nhài, cây hương thảo.",
          "Hương cuối: Gỗ đàn hương, xạ hương, ambroxan.",
        ],
      },
      {
        id: 2,
        productName: "Sauvage EDP",
        price: [350000, 3500000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [sauvage_edp_1, sauvage_edp_2],
        description: [
          "'Sauvage Eau de Parfum' của Dior là một phiên bản phong phú và đậm đà hơn của dòng Sauvage, mang đến một hương thơm mạnh mẽ và quyến rũ. Với sự kết hợp giữa các nốt hương gia vị và gỗ, nước hoa này tạo ra một trải nghiệm olfactory sâu lắng và lôi cuốn, phản ánh sự bí ẩn và cá tính mạnh mẽ.",
          "'Sauvage Eau de Parfum' mở đầu với sự tươi mới và sắc nét từ cam bergamot và tiêu đen, tạo ra một lớp hương đầu đầy năng lượng và cuốn hút. Hương giữa nổi bật với sự kết hợp của hoa nhài, cây hương thảo và tảo biển, mang đến một lớp hương nam tính và phức tạp. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương, ambroxan và vani tạo nên một kết thúc ấm áp, mạnh mẽ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, tiêu đen.",
          "Hương giữa: Hoa nhài, cây hương thảo, tảo biển.",
          "Hương cuối: Gỗ đàn hương, xạ hương, ambroxan, vani.",
        ],
      },
      {
        id: 3,
        productName: "Sauvage Parfum",
        price: [400000, 3800000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [sauvage_parfum_1, sauvage_parfum_2],
        description: [
          "'Sauvage Parfum' của Dior là phiên bản mạnh mẽ và đậm đà nhất trong dòng Sauvage, mang đến một hương thơm sâu lắng và quyến rũ. Được thiết kế để tạo ra một ấn tượng lâu dài, nước hoa này kết hợp các nốt hương gia vị, gỗ và chanh, tạo ra một trải nghiệm olfactory phong phú và mạnh mẽ.",
          "'Sauvage Parfum' mở đầu với sự tươi mới từ cam bergamot và tiêu đen, tạo ra một lớp hương đầu sắc nét và lôi cuốn. Hương giữa nổi bật với sự hòa quyện của hoa nhài, cây hương thảo và gỗ tuyết tùng, mang đến một lớp hương nam tính và phong phú. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương, labdanum và vani tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, tiêu đen.",
          "Hương giữa: Hoa nhài, cây hương thảo, gỗ tuyết tùng.",
          "Hương cuối: Gỗ đàn hương, xạ hương, labdanum, vani.",
        ],
      },
      {
        id: 4,
        productName: "Sauvage Elixir",
        // price: [550000, 3750000, 5500000],
        price: [550000, 5500000],
        // dung tích
        // volume: [10, 60, 100],
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "Elixir",
        // độ lưu hương
        scent_retention: "12-14",
        // độ toả hương
        scent_release: "xa",
        img: [sauvage_elixir_1, sauvage_elixir_2],
        description: [
          "'Sauvage Elixir' của Dior là phiên bản cao cấp và tinh túy nhất trong dòng Sauvage, mang đến một hương thơm mạnh mẽ và quyến rũ. Với sự pha trộn hoàn hảo giữa các nốt hương gia vị, gỗ và chanh, nước hoa này tạo ra một trải nghiệm olfactory sâu lắng và cuốn hút, phản ánh sự sang trọng và phong cách đẳng cấp.",
          "'Sauvage Elixir' mở đầu với sự tươi mới và sắc nét từ cam bergamot và tiêu đen, tạo ra một lớp hương đầu đầy năng lượng và lôi cuốn. Hương giữa nổi bật với sự kết hợp của hoa nhài, cây hương thảo và quế, mang đến một lớp hương nam tính và phong phú. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương, labdanum và hổ phách tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, tiêu đen.",
          "Hương giữa: Hoa nhài, cây hương thảo, quế",
          "Hương cuối: Gỗ đàn hương, xạ hương, labdanum, hổ phách.",
        ],
      },
      {
        id: 5,
        productName: "Miss Dior",
        price: [380000, 3850000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [miss_dior_1, miss_dior_2],
        description: [
          "'Miss Dior' của Dior là một nước hoa nữ tính, mang đến một hương thơm tươi mới và lãng mạn. Với sự kết hợp hoàn hảo giữa các nốt hương hoa cỏ, trái cây và gỗ, nước hoa này gợi cảm giác của sự thanh lịch và quyến rũ, tạo ra một trải nghiệm olfactory đầy sức sống và tinh tế.",
          "'Miss Dior' mở đầu với sự tươi mới và ngọt ngào từ cam bergamot, quýt và dâu tây, tạo ra một lớp hương đầu đầy năng lượng và cuốn hút. Hương giữa nổi bật với sự hòa quyện của hoa nhài, hoa hồng và hoa mẫu đơn, mang đến một lớp hương nữ tính và quyến rũ. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương, hổ phách và vanilla tạo nên một kết thúc ấm áp, thanh lịch và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, quýt, dâu tây.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa mẫu đơn.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách, vanilla.",
        ],
      },
      {
        id: 6,
        productName: "Rose n Rose",
        price: [300000, 2850000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [rose_n_rose_1, rose_n_rose_2],
        description: [
          "'Rose n Rose' của Dior là một nước hoa nữ tính, tươi mới, mang đến một hương thơm lãng mạn và quyến rũ. Với sự kết hợp hoàn hảo giữa các nốt hương hoa hồng và trái cây, nước hoa này gợi cảm giác của sự thanh thoát và tinh tế, tạo ra một trải nghiệm olfactory ngọt ngào và sống động.",
          "'Rose n Rose' mở đầu với sự tươi mới và châm biếm từ cam bergamot và quả lựu, tạo ra một lớp hương đầu sống động và cuốn hút. Hương giữa nổi bật với sự hòa quyện của hoa hồng, hoa nhài và hoa mẫu đơn, mang đến một lớp hương nữ tính và lãng mạn. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương và hổ phách tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, quả lựu.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa mẫu đơn.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 7,
        productName: "Blooming Bouquet",
        price: [380000, 3500000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [blooming_bouquet_1, blooming_bouquet_2],
        description: [
          "'Blooming Bouquet' của Dior là một nước hoa nữ tính, tươi mới, gợi cảm giác của một bó hoa rực rỡ và quyến rũ. Với sự kết hợp tinh tế giữa các nốt hương hoa cỏ và trái cây, nước hoa này mang đến một trải nghiệm olfactory đầy sức sống và sự thanh thoát, tạo nên một hương thơm tươi mới và lãng mạn.",
          "'Blooming Bouquet' mở đầu với sự tươi mới từ cam bergamot và đào, tạo ra một lớp hương đầu ngọt ngào và hấp dẫn. Hương giữa nổi bật với sự kết hợp của hoa hồng, hoa nhài và hoa mẫu đơn, mang đến một lớp hương nữ tính, lãng mạn và phong phú. Cuối cùng, lớp hương từ xạ hương và gỗ trắng tạo nên một kết thúc nhẹ nhàng, ấm áp và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, đào.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa mẫu đơn.",
          "Hương cuối: Xạ hương, gỗ trắng.",
        ],
      },
      {
        id: 8,
        productName: "Dior Joy",
        price: [380000, 3000000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [dior_joy_1, dior_joy_2],
        description: [
          "'Dior Joy' của Dior là một nước hoa nữ tính, mang đến sự tươi mới và quyến rũ. Được tạo ra để gợi lên cảm giác vui vẻ và sự lạc quan, nước hoa này kết hợp các nốt hương hoa cỏ và trái cây, tạo nên một trải nghiệm olfactory sảng khoái và đầy sức sống.",
          "'Dior Joy' mở đầu với sự tươi mới và năng lượng từ cam bergamot, chanh và tiêu hồng, mang lại một lớp hương đầu đầy sức sống và sáng tạo. Hương giữa nổi bật với sự hòa quyện của hoa nhài, hoa hồng và hoa cam, tạo nên một lớp hương nữ tính và quyến rũ. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương và vani tạo ra một kết thúc ấm áp, mềm mại và quyến rũ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, chanh, tiêu hồng.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Gỗ đàn hương, xạ hương, vani.",
        ],
      },
      {
        id: 9,
        productName: "Dior Homme Intense",
        price: [380000, 3500000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [dior_homme_intense_1, dior_homme_intense_2],
        description: [
          "'Dior Homme Intense' của Dior là một phiên bản mạnh mẽ và quyến rũ của dòng Dior Homme, mang đến một hương thơm sâu lắng và đầy ấn tượng. Được thiết kế cho những người đàn ông yêu thích sự sang trọng và mạnh mẽ, nước hoa này kết hợp các nốt hương gỗ, da thuộc và gia vị, tạo ra một trải nghiệm olfactory sâu sắc và đầy cuốn hút.",
          "'Dior Homme Intense' mở đầu với sự tươi mới và sắc nét từ cam bergamot, tiêu đen và cây bách, tạo ra một lớp hương đầu đầy năng lượng và lôi cuốn. Hương giữa nổi bật với sự kết hợp của da thuộc, hoa hồng và nhựa thơm, mang đến một lớp hương nam tính và phong phú. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương, hoắc hương và vanilla tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, tiêu đen, cây bách.",
          "Hương giữa: Da thuộc, hoa hồng, nhựa thơm.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hoắc hương, vanilla.",
        ],
      },
      {
        id: 10,
        productName: "J’adore",
        price: [390000, 3700000],
        // dung tích
        volume: [10, 150],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [jadore_1, jadore_2],
        description: [
          "'J’adore' của Dior là một nước hoa nữ tính và quyến rũ, mang đến sự thanh thoát và sang trọng. Với sự kết hợp hoàn hảo giữa các nốt hương hoa cỏ và trái cây, nước hoa này tạo ra một trải nghiệm olfactory tươi mới và lôi cuốn, phản ánh sự nữ tính và đẳng cấp.",
          "'J’adore' mở đầu với sự tươi mới từ cam bergamot, lê và đào, tạo ra một lớp hương đầu ngọt ngào và dễ chịu. Hương giữa nổi bật với sự kết hợp của hoa nhài, hoa hồng, hoa mẫu đơn và hoa lily, mang đến một lớp hương nữ tính, quyến rũ và phong phú. Cuối cùng, lớp hương từ gỗ đàn hương, xạ hương, hổ phách và vanilla tạo nên một kết thúc ấm áp, quyến rũ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, lê, đào.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa mẫu đơn, hoa lily.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách, vanilla.",
        ],
      },
      {
        id: 11,
        productName: "Holy Peony",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [holy_peony_1, holy_peony_2],
        description: [
          "'Holy Peony' của Dior là một nước hoa nữ tính, mang đến một hương thơm tươi mới và quyến rũ, tập trung vào vẻ đẹp của hoa mẫu đơn. Nước hoa này kết hợp các nốt hương hoa cỏ và trái cây, tạo ra một trải nghiệm olfactory thanh thoát và đầy sức sống.",
          "'Holy Peony' mở đầu với sự tươi mới từ cam bergamot và quả lê, mang lại một lớp hương đầu ngọt ngào và dễ chịu. Hương giữa nổi bật với sự hòa quyện của hoa mẫu đơn, hoa nhài và hoa hồng, tạo nên một lớp hương nữ tính, quyến rũ và phong phú. Cuối cùng, lớp hương từ xạ hương, gỗ đàn hương và hổ phách tạo ra một kết thúc ấm áp, mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cam bergamot, quả lê.",
          "Hương giữa: Hoa mẫu đơn, hoa nhài, hoa hồng.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 14,
    brand: "D&G",
    logo: dg,
    products: [
      {
        id: 1,
        productName: "Light Blue Eau Intense",
        price: [290000, 2400000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [light_blue_eau_intense_1, light_blue_eau_intense_2],
        description: [
          "'Light Blue Eau Intense' của Dolce & Gabbana là một phiên bản mạnh mẽ và tinh tế hơn của dòng Light Blue nổi tiếng, mang đến một hương thơm tươi mới và quyến rũ. Nước hoa này kết hợp các nốt hương trái cây và hoa cỏ, tạo ra một trải nghiệm olfactory mãnh liệt và đầy cảm xúc.",
          "'Light Blue Eau Intense' mở đầu với sự tươi mới từ chanh Sicilia và táo xanh, tạo ra một lớp hương đầu sắc nét và đầy sức sống. Hương giữa nổi bật với sự kết hợp của hoa nhài và hoa cam, mang đến một lớp hương nữ tính và quyến rũ. Cuối cùng, lớp hương từ xạ hương và hổ phách tạo ra một kết thúc ấm áp, mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Chanh Sicilia, táo xanh.",
          "Hương giữa: Hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Light Blue Eau Intense Pour Homme",
        price: [280000, 2300000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [
          light_blue_eau_intense_pour_homme_1,
          light_blue_eau_intense_pour_homme_2,
        ],
        description: [
          "'Light Blue Eau Intense Pour Homme' của Dolce & Gabbana là phiên bản mạnh mẽ và đầy cảm xúc của dòng Light Blue, được thiết kế đặc biệt cho nam giới. Với sự kết hợp hoàn hảo giữa các nốt hương trái cây và gỗ, nước hoa này mang đến một trải nghiệm olfactory tươi mới và quyến rũ, đồng thời toát lên sự mạnh mẽ và nam tính.",
          "'Light Blue Eau Intense Pour Homme' mở đầu với sự tươi mới từ chanh Sicilia và bưởi, tạo ra một lớp hương đầu sắc nét và đầy sức sống. Hương giữa nổi bật với sự kết hợp của hoa nhài và gỗ tuyết tùng, mang đến một lớp hương nam tính và phong phú. Cuối cùng, lớp hương từ xạ hương, hổ phách và gỗ đàn hương tạo ra một kết thúc ấm áp, mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Chanh Sicilia, bưởi.",
          "Hương giữa: Hoa nhài, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 3,
        productName: "The One for Men",
        price: [300000, 2300000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [the_one_for_men_1, the_one_for_men_2],
        description: [
          "'The One for Men Eau de Parfum' của Dolce & Gabbana là phiên bản Eau de Parfum của dòng nước hoa nổi tiếng 'The One for Men', mang đến một hương thơm ấm áp và quyến rũ hơn với độ lưu hương bền bỉ hơn. Được thiết kế cho những người đàn ông tự tin và phong cách, nước hoa này kết hợp các nốt hương gia vị, gỗ và thảo mộc, tạo ra một trải nghiệm olfactory đầy cảm xúc và sang trọng.",
          "'The One for Men Eau de Parfum' mở đầu với sự kết hợp mạnh mẽ và tươi mới từ bưởi, thìa là và tiêu đen, mang lại một lớp hương đầu ấn tượng và đầy sức sống. Hương giữa nổi bật với sự hòa quyện của hoa nhài, đinh hương và cây hương bài, tạo nên một lớp hương phong phú, quyến rũ và tinh tế. Cuối cùng, lớp hương từ hổ phách, gỗ đàn hương và xạ hương tạo ra một kết thúc ấm áp, mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Bưởi, thìa là, tiêu đen.",
          "Hương giữa: Hoa nhài, đinh hương, cây hương bài.",
          "Hương cuối: Hổ phách, gỗ đàn hương, xạ hương.",
        ],
      },
      {
        id: 4,
        productName: "L’Imperatrice 3",
        price: [200000, 1800000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [limperatrice_3_1, limperatrice_3_2],
        description: [
          "'L’Imperatrice 3' của Dolce & Gabbana là một phần của bộ sưu tập The D&G Anthology, nổi bật với hương thơm tươi mới và quyến rũ. Được thiết kế cho những người phụ nữ yêu thích sự nữ tính và cá tính, nước hoa này kết hợp các nốt hương trái cây và hoa cỏ, tạo ra một trải nghiệm olfactory độc đáo và đầy sức sống.",
          "'L’Imperatrice 3' mở đầu với sự tươi mới từ dưa hấu, chanh và kiêu kiêu, tạo ra một lớp hương đầu sảng khoái và đầy sức sống. Hương giữa nổi bật với sự hòa quyện của hoa nhài, hoa hồng và cây hương bài, mang đến một lớp hương nữ tính, quyến rũ và tinh tế. Cuối cùng, lớp hương từ xạ hương, hổ phách và gỗ đàn hương tạo ra một kết thúc ấm áp và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Dưa hấu, chanh, kiêu kiêu.",
          "Hương giữa: Hoa nhài, hoa hồng, cây hương bài.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 5,
        productName: "L’Imperatrice EDT Limited Edition",
        price: [250000, 2000000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [
          limperatrice_3__limited_edition_1,
          limperatrice_3__limited_edition_2,
        ],
        description: [
          "'L’Imperatrice EDT Limited Edition' của Dolce & Gabbana là phiên bản giới hạn của dòng nước hoa nổi tiếng 'L’Imperatrice', mang đến một hương thơm tươi mới và quyến rũ với thiết kế đặc biệt và khác biệt. Được thiết kế cho những người phụ nữ yêu thích sự nữ tính và cá tính, nước hoa này kết hợp các nốt hương trái cây và hoa cỏ, tạo ra một trải nghiệm olfactory độc đáo và phong phú.",
          "'L’Imperatrice EDT Limited Edition' mở đầu với sự tươi mới từ dưa hấu, chanh và kiwi, tạo ra một lớp hương đầu sảng khoái và đầy sức sống. Hương giữa nổi bật với sự hòa quyện của hoa nhài, hoa hồng và cây hương bài, mang đến một lớp hương nữ tính, quyến rũ và tinh tế. Cuối cùng, lớp hương từ xạ hương, hổ phách và gỗ đàn hương tạo ra một kết thúc ấm áp và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Dưa hấu, chanh, kiwi.",
          "Hương giữa: Hoa nhài, hoa hồng, cây hương bài.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 6,
        productName: "Pour Homme Intenso",
        price: [250000, 2000000],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [pour_homme_intenso_1, pour_homme_intenso_2],
        description: [
          "'Pour Homme Intenso' của Dolce & Gabbana là một phiên bản mạnh mẽ và đầy cảm xúc của dòng Pour Homme, mang đến một hương thơm sâu lắng và quyến rũ. Được thiết kế cho những người đàn ông tự tin và trưởng thành, nước hoa này kết hợp các nốt hương gỗ, gia vị và thảo mộc, tạo ra một trải nghiệm olfactory ấm áp và sang trọng.",
          "'Pour Homme Intenso' mở đầu với sự kết hợp đầy sức sống từ hương thảo, cây hương bài và tiêu đen, tạo ra một lớp hương đầu mạnh mẽ và quyến rũ. Hương giữa nổi bật với sự hòa quyện của da thuộc, hoa nhài và đinh hương, mang đến một lớp hương phong phú và tinh tế. Cuối cùng, lớp hương từ gỗ đàn hương, hổ phách và xạ hương tạo ra một kết thúc ấm áp, mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Hương thảo, cây hương bài, tiêu đen.",
          "Hương giữa: Da thuộc, hoa nhài, đinh hương.",
          "Hương cuối: Gỗ đàn hương, hổ phách, xạ hương.",
        ],
      },
    ],
  },
  {
    id: 15,
    brand: "Mad et Len",
    logo: mad_et_len,
    products: [
      {
        id: 1,
        productName: "Red Musc",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [red_musc_1, red_musc_2],
        description: [
          "'Red Musc' của Mad et Len là một nước hoa unisex mang đến một hương thơm nồng nàn và quyến rũ. Được thiết kế để phù hợp với cả nam và nữ, nước hoa này kết hợp các nốt hương xạ hương, gia vị và gỗ, tạo ra một trải nghiệm olfactory đầy cảm xúc và sự lôi cuốn.",
          "'Red Musc' mở đầu với sự hòa quyện ấm áp của nhựa thơm và gỗ đàn hương, tạo ra một lớp hương đầu sâu lắng và quyến rũ. Hương giữa nổi bật với xạ hương đỏ và nhục đậu khấu, mang đến một lớp hương nồng nàn và đầy cảm xúc. Cuối cùng, lớp hương từ gỗ đàn hương và xạ hương tạo ra một kết thúc ấm áp, mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Nhựa thơm, gỗ đàn hương.",
          "Hương giữa: Xạ hương đỏ, nhục đậu khấu.",
          "Hương cuối: Gỗ đàn hương, xạ hương.",
        ],
      },
      {
        id: 2,
        productName: "Black Musc",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [black_musc_1, black_musc_2],
        description: [
          "'Black Musc' của Mad et Len là một nước hoa unisex với hương thơm bí ẩn và quyến rũ. Được thiết kế để phù hợp với cả nam và nữ, nước hoa này mang đến một trải nghiệm olfactory độc đáo với sự kết hợp của xạ hương đen, gia vị và gỗ.",
          "'Black Musc' mở đầu với sự hòa quyện của xạ hương đen và nhựa thơm, tạo ra một lớp hương đầu bí ẩn và đầy cuốn hút. Hương giữa nổi bật với sự kết hợp của hổ phách và nhục đậu khấu, mang đến một lớp hương ấm áp và nồng nàn. Cuối cùng, lớp hương từ gỗ đàn hương và xạ hương tạo ra một kết thúc mềm mại và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Xạ hương đen, nhựa thơm.",
          "Hương giữa: Hổ phách, nhục đậu khấu.",
          "Hương cuối: Gỗ đàn hương, xạ hương.",
        ],
      },
      {
        id: 3,
        productName: "Terre Noire",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [terre_noire_1, terre_noire_2],
        description: [
          "'Terre Noire' của Mad et Len là một nước hoa unisex mang đến một hương thơm đậm chất và bí ẩn, kết hợp các nốt hương từ đất đai, gỗ và gia vị. Được thiết kế để phù hợp với cả nam và nữ, nước hoa này tạo ra một trải nghiệm olfactory độc đáo và sâu lắng.",
          "'Terre Noire' mở đầu với sự hòa quyện của cây thuốc lá và xô thơm, tạo ra một lớp hương đầu ấm áp và quyến rũ. Hương giữa nổi bật với sự kết hợp của gỗ đàn hương và nhựa thơm, mang đến một lớp hương phong phú và sâu lắng. Cuối cùng, lớp hương từ xạ hương và da thuộc tạo ra một kết thúc mạnh mẽ và bền bỉ, để lại ấn tượng lâu dài.",
          "Hương đầu: Cây thuốc lá, xô thơm.",
          "Hương giữa: Gỗ đàn hương, nhựa thơm.",
          "Hương cuối: Xạ hương, da thuộc.",
        ],
      },
    ],
  },
  {
    id: 16,
    brand: "Elizabeth Arden",
    logo: elizabeth_arden,
    products: [
      {
        id: 1,
        productName: "White Tea",
        price: [170000, 1200000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [white_tea_1, white_tea_2],
        description: [
          "'White Tea' của Elizabeth Arden là một nước hoa nhẹ nhàng và thanh thoát, mang đến cảm giác tươi mới và thư giãn. Được thiết kế cho những người phụ nữ yêu thích sự đơn giản và tinh tế, nước hoa này kết hợp các nốt hương trà trắng, hoa cỏ và gỗ, tạo ra một trải nghiệm olfactory nhẹ nhàng và dễ chịu.",
          "'White Tea' mở đầu với sự tươi mới từ trà trắng, hoa nhài và cây xô thơm, tạo ra một lớp hương đầu nhẹ nhàng và thanh thoát. Hương giữa nổi bật với sự hòa quyện của hoa hồng, nhựa thơm và hương gỗ, mang đến một lớp hương tinh tế và dễ chịu. Cuối cùng, lớp hương từ xạ hương, hổ phách và gỗ đàn hương tạo ra một kết thúc ấm áp và bền bỉ, để lại ấn tượng nhẹ nhàng và thanh thoát.",
          "Hương đầu: Trà trắng, hoa nhài, cây xô thơm.",
          "Hương giữa: Hoa hồng, nhựa thơm, hương gỗ.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
    ],
  },
  {
    id: 17,
    brand: "Ex Nihilo",
    logo: Exnihilo,
    products: [
      {
        id: 1,
        productName: "Fleur Narcotique",
        price: [900000, 8000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [fleur_narcotique_1, fleur_narcotique_2],
        description: [
          "'Fleur Narcotique' của Ex Nihilo là một nước hoa gợi cảm và tươi mới, được thiết kế cho cả nam và nữ. Hương thơm này là một sự tôn vinh vẻ đẹp và sự cuốn hút của các loài hoa, với các nốt hương hoa quả và gỗ, tạo nên một sự hòa quyện đầy sức sống và quyến rũ.",
          "'Fleur Narcotique' mở đầu với sự tươi mát từ quả vải, cam bergamot và đào, tạo ra một lớp hương đầu rực rỡ và tràn đầy sức sống. Hương giữa hòa quyện giữa các loài hoa như hoa mẫu đơn, hoa nhài và hoa cam, mang đến cảm giác lãng mạn và quyến rũ. Cuối cùng, lớp hương xạ hương và gỗ tạo ra một nền tảng ấm áp và bền bỉ, mang lại sự tự tin và cuốn hút.",
          "Hương đầu: Quả vải, cam bergamot, đào.",
          "Hương giữa: Hoa mẫu đơn, hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, gỗ và rêu sồi.",
        ],
      },
      {
        id: 2,
        productName: "Fleur Narcotique 10 Years Limited Edition",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [
          fleur_narcotique_10years_limited_1,
          fleur_narcotique_10years_limited_2,
        ],
        description: [
          "Để kỷ niệm 10 năm thành lập, Ex Nihilo ra mắt phiên bản giới hạn đặc biệt 'Fleur Narcotique 10 Years Limited Edition'. Đây là phiên bản đặc biệt của hương thơm biểu tượng 'Fleur Narcotique', mang đến sự sang trọng và cuốn hút với thiết kế tinh xảo và độc đáo, dành riêng cho những ai yêu thích và sưu tập nước hoa.",
          "Mùi hương giữ nguyên bản chất tinh tế từ phiên bản gốc, bắt đầu với sự tươi mát và ngọt ngào của quả vải, cam bergamot và đào. Lớp hương giữa là sự nở rộ của hoa mẫu đơn và hoa nhài, mang đến nét đẹp lãng mạn và gợi cảm. Kết thúc với hương xạ hương và gỗ, 'Fleur Narcotique 10 Years Limited Edition' đem đến một trải nghiệm bền bỉ, quyến rũ, khó phai.",
          "Hương đầu: Quả vải, cam bergamot, đào.",
          "Hương giữa: Hoa mẫu đơn, hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, gỗ, rêu sồi.",
        ],
      },
      {
        id: 3,
        productName: "Santal Calling",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [santal_calling_1, santal_calling_2],
        description: [
          "'Santal Calling' của Ex Nihilo là một hương thơm mang đậm chất gỗ đàn hương (sandalwood), gợi cảm giác ấm áp, sang trọng và thanh thoát. Nước hoa này tập trung vào việc tôn vinh sự êm dịu và tinh tế của gỗ đàn hương, kết hợp cùng các nốt hương gợi cảm để tạo ra một trải nghiệm olfactory đầy cuốn hút.",
          "'Santal Calling' mở đầu với sự tươi sáng và sảng khoái từ aldehydes và hương cay nhẹ từ bạch đậu khấu. Lớp hương giữa mang đến sự mềm mại và tinh tế từ hoa diên vĩ và violet. Cuối cùng, lớp hương ấm áp từ gỗ đàn hương, xạ hương trắng và đậu tonka tạo nên một cái kết mượt mà và đầy gợi cảm.",
          "Hương đầu: Aldehydes, bạch đậu khấu.",
          "Hương giữa: Hoa diên vĩ, violet.",
          "Hương cuối: Gỗ đàn hương, xạ hương trắng, đậu tonka.",
        ],
      },
    ],
  },
  {
    id: 18,
    brand: "Giardini",
    logo: giardini,
    products: [
      {
        id: 1,
        productName: "Di Toscana Bianco Latte",
        price: [450000, 4000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [di_toscana_bianco_latte_1, di_toscana_bianco_latte_2],
        description: [
          "'Di Toscana Bianco Latte' là một sáng tạo đầy thư giãn và ấm áp từ thương hiệu Giardini di Toscana, mang hương thơm ngọt ngào và dễ chịu của sữa và vani, kết hợp với các nốt hương nhẹ nhàng để tạo ra một cảm giác thoải mái và dịu dàng như đang được ôm ấp trong chiếc áo len mềm mại.",
          "'Bianco Latte' mở ra với nốt hương sữa béo ngậy kết hợp cùng caramel ngọt ngào, mang đến sự ấm áp và dễ chịu ngay từ lần xịt đầu tiên. Lớp hương giữa nhẹ nhàng từ hoa trắng và hạnh nhân bổ sung thêm chút nữ tính và tinh tế. Kết thúc với lớp hương vani và xạ hương, tạo nên một bức tranh thơm thoảng mùi gỗ và ngọt ngào, để lại cảm giác thoải mái và bình yên.",
          "Hương đầu: Sữa, caramel.",
          "Hương giữa: Hoa trắng, hạnh nhân.",
          "Hương cuối: Vani, xạ hương, hương gỗ.",
        ],
      },
    ],
  },
  {
    id: 19,
    brand: "Giorgio Armani",
    logo: giorgio_armani,
    products: [
      {
        id: 1,
        productName: "Acqua Di Gio Absolu",
        price: [320000, 2800000, 3300000],
        // dung tích
        volume: [10, 75, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [acqua_di_gio_absolu_1, acqua_di_gio_absolu_2],
        description: [
          "'Acqua Di Gio Absolu' là một phiên bản mới của dòng nước hoa nổi tiếng Acqua Di Gio, kết hợp sự tươi mát đặc trưng của biển cả với hương gỗ ấm áp, tạo ra một hương thơm đầy nam tính, mạnh mẽ nhưng cũng không kém phần tinh tế. Đây là lựa chọn lý tưởng cho người đàn ông hiện đại, tự tin và phóng khoáng.",
          "Nước hoa mở đầu với sự tươi mát của cam bergamot, quả lê và cam quýt, đem lại cảm giác sảng khoái ngay từ lần xịt đầu tiên. Lớp hương giữa với sự kết hợp giữa hương biển và các loài hoa như phong lữ và oải hương, mang đến sự thanh lịch, tươi mới. Cuối cùng, hương gỗ và hoắc hương làm nền tảng cho mùi hương ấm áp, mạnh mẽ, cùng xạ hương và đậu tonka tăng thêm chiều sâu và độ lưu hương bền bỉ.",
          "Hương đầu: Cam bergamot, quả lê, cam quýt.",
          "Hương giữa: Hoa oải hương, hương biển, phong lữ, hương thảo.",
          "Hương cuối: Gỗ, hoắc hương, xạ hương, đậu tonka.",
        ],
      },
      {
        id: 2,
        productName: "Acqua Di Giò",
        price: [290000, 2250000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [di_gio_edt_1, di_gio_edt_2],
        description: [
          "'Acqua Di Giò' là một trong những dòng nước hoa mang tính biểu tượng nhất của Giorgio Armani, ra mắt vào năm 1996. Được lấy cảm hứng từ vẻ đẹp tự nhiên của vùng biển Địa Trung Hải, mùi hương này là sự kết hợp hoàn hảo giữa sự tươi mát của biển cả và các thành phần thiên nhiên khác, tạo nên một phong cách thanh lịch, tự do và đầy năng lượng.",
          "Mở đầu với sự bùng nổ của hương cam chanh và hương biển sảng khoái, 'Acqua Di Giò' mang đến cảm giác tươi mới, trẻ trung và nam tính. Lớp hương giữa của hoa nhài và hương thảo tạo nên sự cân bằng tinh tế giữa sự mềm mại và mạnh mẽ. Kết thúc với nốt hương gỗ tuyết tùng và hoắc hương, mang lại chiều sâu và sự bền bỉ cho mùi hương.",
          "Hương đầu: Cam bergamot, cam quýt, chanh, hương biển.",
          "Hương giữa: Hoa nhài, hương thảo, cay xô thơm, hoa hồng.",
          "Hương cuối: Gỗ tuyết tùng, hoắc hương, xạ hương.",
        ],
      },
      {
        id: 3,
        productName: "Acqua Di Gio Profumo",
        // price: [350000, 3200000],
        price: [null, null],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [acqua_di_gio_profumo_1, acqua_di_gio_profumo_2],
        description: [
          "'Acqua Di Gio Profumo' là phiên bản cao cấp và tinh tế hơn của dòng nước hoa Acqua Di Gio huyền thoại, ra mắt vào năm 2015. Được sáng tạo bởi Alberto Morillas, mùi hương này là sự hòa quyện giữa sự tươi mát của biển cả và độ sâu bí ẩn của gỗ và nhựa hương, mang đến cho người dùng cảm giác mạnh mẽ, lịch lãm và đầy gợi cảm.",
          "Nước hoa mở đầu với sự bùng nổ tươi mát của cam bergamot và hương biển, mang đến cảm giác tự do và thanh khiết như làn gió biển. Lớp hương giữa là sự hòa quyện tinh tế của nhũ hương và các loài thảo mộc như hương thảo và xô thơm, tạo ra một mùi hương nam tính, thanh lịch. Kết thúc với hoắc hương và nhựa hương, mang đến độ sâu và cảm giác bí ẩn đầy thu hút.",
          "Hương đầu: Cam bergamot, hương biển.",
          "Hương giữa: Nhũ hương, hương thảo, xô thơm, phong lữ.",
          "Hương cuối: Hoắc hương, gỗ, nhựa hương.",
        ],
      },
      {
        id: 4,
        productName: "Acqua Di Giò Parfum",
        price: [null, null],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [acqua_di_gio_parfum_1, acqua_di_gio_parfum_2],
        description: [
          "'Acqua Di Giò Parfum' là phiên bản đậm đặc và sang trọng hơn của dòng nước hoa kinh điển Acqua Di Giò, mang đến trải nghiệm hương thơm mạnh mẽ và sâu lắng hơn. Với sự kết hợp tinh tế giữa các nốt hương tươi mát, gỗ và nhựa hương, 'Acqua Di Giò Parfum' đại diện cho sự thanh lịch, tinh tế và đầy cuốn hút.",
          "Mùi hương mở đầu với sự tươi mát sảng khoái của cam bergamot và hương biển, mang lại cảm giác phóng khoáng, tự do. Tiếp theo là lớp hương giữa đặc trưng của nhũ hương và các thảo mộc như hương thảo, xô thơm, tạo nên sự mạnh mẽ, nam tính. Lớp hương cuối cùng là sự kết hợp của hoắc hương, gỗ và nhựa hương, mang lại sự nồng nàn, bí ẩn và quyến rũ.",
          "Hương đầu: Cam bergamot, hương biển.",
          "Hương giữa: Nhũ hương, hương thảo, xô thơm.",
          "Hương cuối: Hoắc hương, gỗ, nhựa hương.",
        ],
      },
      {
        id: 5,
        productName: "Acqua Di Gio Profondo",
        price: [330000, 2850000],
        // dung tích
        volume: [10, 75],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [acqua_di_gio_profondo_1, acqua_di_gio_profondo_2],
        description: [
          "'Acqua Di Gio Profondo' ra mắt vào năm 2020, là một phiên bản đậm hơn và hiện đại hơn của dòng nước hoa Acqua Di Gio huyền thoại. Được sáng tạo bởi nhà pha chế Alberto Morillas, mùi hương này khám phá độ sâu của biển cả, mang lại cảm giác tự do, tinh tế và mạnh mẽ. 'Acqua Di Gio Profondo' đại diện cho sự kết hợp hoàn hảo giữa các nốt hương biển, thảo mộc và gỗ.",
          "Mở đầu với hương cam bergamot tươi mát và hương biển sảng khoái, 'Acqua Di Gio Profondo' mang đến cảm giác thanh khiết và phóng khoáng như những cơn sóng biển. Lớp hương giữa kết hợp tinh tế giữa nhựa hương và các thảo mộc như hương thảo, oải hương, tạo ra chiều sâu mạnh mẽ, nam tính. Cuối cùng, hoắc hương, gỗ tuyết tùng và xạ hương mang đến lớp hương cuối đầy ấm áp và bền lâu.",
          "Hương đầu: Cam bergamot, quả quýt xanh, hương biển.",
          "Hương giữa: Nhựa hương, hương thảo, oải hương, xô thơm.",
          "Hương cuối: Gỗ tuyết tùng, hoắc hương, xạ hương.",
        ],
      },
      {
        id: 6,
        productName: "Acqua Di Giò EDP",
        price: [320000, 2650000, 3000000],
        // price: [320000, 3000000],
        // dung tích
        volume: [10, 75, 125],
        // volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [acqua_di_gio_edp_1, acqua_di_gio_edp_2],
        description: [
          "'Acqua Di Giò Eau de Parfum' là phiên bản hiện đại và bền lâu hơn của dòng nước hoa kinh điển Acqua Di Giò. Được ra mắt vào năm 2022, mùi hương này mang đến cảm giác tươi mát đặc trưng nhưng với độ sâu và độ bền cao hơn, phù hợp cho những người đàn ông yêu thích sự tươi mát, thanh lịch và mạnh mẽ. Đặc biệt, phiên bản EDP này là lựa chọn bền vững với việc sử dụng nguyên liệu tái tạo và thiết kế chai có thể tái nạp.",
          "Mùi hương mở đầu với sự bùng nổ tươi mát của quả quýt xanh kết hợp cùng hương biển, mang đến cảm giác mát lành, sảng khoái như gió biển. Lớp hương giữa đầy lôi cuốn với sự kết hợp của nhựa hương và các loại thảo mộc như cây xô thơm và phong lữ, tạo ra sự mạnh mẽ, nam tính. Lớp hương cuối kéo dài với sự ấm áp của hoắc hương, cỏ hương bài và gỗ tuyết tùng, làm cho mùi hương trở nên nồng nàn và gợi cảm.",
          "Hương đầu: Quả quýt xanh, hương biển.",
          "Hương giữa: Nhựa hương, cây xô thơm, phong lữ.",
          "Hương cuối: Hoắc hương, cỏ hương bài, gỗ tuyết tùng.",
        ],
      },
      {
        id: 7,
        productName: "Acqua di Gioia",
        price: [300000, 2600000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [acqua_di_gioia_1, acqua_di_gioia_2],
        description: [
          "'Acqua di Gioia' là biểu tượng của thiên nhiên, đại diện cho sự hòa quyện giữa con người và thiên nhiên với vẻ đẹp của nước, gió và đất. Được ra mắt vào năm 2010, dòng nước hoa này mang đến hương thơm tươi mát, đầy sức sống và quyến rũ, thích hợp cho những người phụ nữ yêu tự do và sự thanh khiết.",
          "Mùi hương mở đầu với sự tươi mát và sống động của chanh Amalfi kết hợp cùng hương bạc hà, mang lại cảm giác sảng khoái và trong lành. Lớp hương giữa là sự kết hợp thanh tao và nữ tính của hoa nhài và mẫu đơn, tạo nên sự mềm mại và quyến rũ. Cuối cùng, lớp hương cuối ấm áp với gỗ tuyết tùng và đường nâu, mang lại độ sâu và gợi cảm.",
          "Hương đầu: Chanh Amalfi, lá bạc hà.",
          "Hương giữa: Hoa nhài, mẫu đơn, hồng tiêu.",
          "Hương cuối: Đường nâu, gỗ tuyết tùng, labdanum.",
        ],
      },
      {
        id: 8,
        productName: "My Way",
        price: [350000, 2900000],
        // dung tích
        volume: [10, 150],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [my_way_edp_1, my_way_edp_2],
        description: [
          "'My Way' ra mắt vào năm 2020, là dòng nước hoa tôn vinh sự khám phá và trải nghiệm cuộc sống của người phụ nữ hiện đại. Mùi hương của My Way mang đậm tính toàn cầu với nguyên liệu từ nhiều nơi trên thế giới, tạo nên sự cân bằng giữa vẻ đẹp tự nhiên và sức mạnh nội tâm.",
          "Mở đầu với hương cam bergamot tươi mát hòa quyện cùng hương hoa cam ngọt ngào, 'My Way' tạo nên cảm giác trong trẻo và nữ tính. Lớp hương giữa tỏa sáng với sự thanh lịch của hoa nhài và hoa huệ, mang lại sự sang trọng và quyến rũ. Cuối cùng, mùi hương ấm áp của gỗ tuyết tùng, xạ hương trắng và vani tạo nên sự gợi cảm và lâu dài.",
          "Hương đầu: Cam bergamot từ Calabria, hoa cam từ Ai Cập.",
          "Hương giữa: Hoa nhài Ấn Độ, hoa huệ.",
          "Hương cuối: Gỗ tuyết tùng từ Virginia, vani Madagascar, xạ hương trắng.",
        ],
      },
      {
        id: 9,
        productName: "Sì",
        price: [340000, 2800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [si_edp_1, si_edp_2],
        description: [
          "'Sì Eau de Parfum' được ra mắt vào năm 2013, là biểu tượng của người phụ nữ hiện đại với phong cách thanh lịch, mạnh mẽ nhưng không kém phần quyến rũ và tinh tế. Mùi hương của Sì đại diện cho sự hòa quyện hoàn hảo giữa sự tự do và sự sang trọng, dành cho những phụ nữ dám nói 'Sì' (có) với cuộc sống và những thử thách mới.",
          "Mở đầu với hương trái cây ngọt ngào của quả lý chua đen, mang đến cảm giác sảng khoái và tươi mới. Lớp hương giữa là sự kết hợp tinh tế của hoa hồng và hoa lan Nam Phi, tạo nên sự mềm mại và nữ tính. Cuối cùng, mùi hương quyến rũ và ấm áp của vanilla, hoắc hương và gỗ tuyết tùng làm cho hương thơm kéo dài và gây ấn tượng sâu sắc.",
          "Hương đầu: Quả lý chua đen.",
          "Hương giữa: Hoa hồng, hoa lan Nam Phi.",
          "Hương cuối: Vanilla, hoắc hương, gỗ tuyết tùng, hổ phách.",
        ],
      },
      {
        id: 10,
        productName: "Sì Passione",
        price: [320000, 3100000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [si_passione_edp_1, si_passione_edp_2],
        description: [
          "Ra mắt vào năm 2017, 'Sì Passione Eau de Parfum' là sự kết hợp hoàn hảo của sự mạnh mẽ và nữ tính, mang đến một mùi hương bùng nổ cảm xúc và đam mê. Dành cho người phụ nữ tự tin, táo bạo, và không ngại thể hiện bản thân, Sì Passione là lời khẳng định đầy lôi cuốn về sự độc lập và sức mạnh nội tâm.",
          "Mùi hương mở đầu với sự tươi mát và ngọt ngào của quả lý chua đen và lê, tạo nên cảm giác sảng khoái đầy năng lượng. Lớp hương giữa là sự kết hợp quyến rũ của hoa hồng và hoa nhài, mang đến sự nữ tính và thanh lịch. Hương cuối ấm áp và ngọt ngào với vanilla và hổ phách, tạo nên sức hút kéo dài đầy mê hoặc.",
          "Hương đầu: Lý chua đen, lê, hồng tiêu.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa vòi voi (heliotrope).",
          "Hương cuối: Vanilla, gỗ tuyết tùng, hổ phách.",
        ],
      },
      {
        id: 11,
        productName: "Privé Thé Yulong",
        price: [500000, 4000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [prive_the_yulong_edt_1, prive_the_yulong_edt_2],
        description: [
          "Ra mắt trong bộ sưu tập cao cấp Armani Privé, 'Thé Yulong' là một mùi hương độc đáo được tạo ra từ sự hòa quyện giữa trà xanh Trung Quốc và trà đen Yunnan, mang đến trải nghiệm mùi hương tinh tế và thanh lịch, gợi nhớ đến những ngọn núi Yulong đầy sương mù. 'Thé Yulong' là sự kết hợp giữa tươi mát và sâu lắng, thể hiện một phong cách thanh lịch, phù hợp cho cả nam và nữ.",
          "Mở đầu với hương cam chanh tươi mát, 'Thé Yulong' nhanh chóng dẫn dắt bạn vào lớp hương giữa nhẹ nhàng và thanh thoát của trà xanh và hoa nhài, tạo cảm giác thư giãn và tinh khiết. Hương cuối là sự ấm áp của gỗ tuyết tùng và xạ hương, mang đến sự cân bằng hoàn hảo giữa sự tươi mát và sâu lắng.",
          "Hương đầu: Cam bergamot, quýt, chanh.",
          "Hương giữa: Trà xanh, trà đen, hoa nhài.",
          "Hương cuối: Gỗ tuyết tùng, xạ hương.",
        ],
      },
      {
        id: 12,
        productName: "Privé Thé Yulong Soie De Nacre",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [
          prive_the_yulong_soie_de_nacre_edt_1,
          prive_the_yulong_soie_de_nacre_edt_2,
        ],
        description: [
          "'Privé Thé Yulong Soie De Nacre' là phiên bản đặc biệt của dòng nước hoa 'Thé Yulong' thuộc bộ sưu tập cao cấp Armani Privé, được bổ sung với hiệu ứng ánh ngọc trai (Soie De Nacre). Sự kết hợp độc đáo giữa trà xanh Trung Quốc, trà đen Yunnan và ánh ngọc trai lung linh mang đến một trải nghiệm mới lạ, vừa tươi mát vừa lấp lánh trên làn da.",
          "Lớp hương đầu tươi mát từ các loại cam chanh đánh thức các giác quan ngay tức khắc. Lớp hương giữa thanh thoát của trà xanh và hoa nhài tạo nên sự thư thái, mát dịu. Hương cuối với xạ hương và gỗ tuyết tùng ấm áp mang đến chiều sâu, kết hợp hoàn hảo với hiệu ứng ánh ngọc trai, giúp làn da trở nên lấp lánh một cách tinh tế.",
          "Hương đầu: Cam bergamot, chanh, quýt.",
          "Hương giữa: Trà xanh, trà đen, hoa nhài.",
          "Hương cuối: Xạ hương, gỗ tuyết tùng.",
        ],
      },
      {
        id: 13,
        productName: "Rose Milano",
        price: [500000, 4300000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [rose_milano_edt_1, rose_milano_edt_2],
        description: [
          "'Rose Milano' thuộc bộ sưu tập cao cấp Armani Privé, là mùi hương tươi mới và tinh tế, lấy cảm hứng từ vẻ đẹp thanh lịch và cổ điển của thành phố Milan. Nước hoa này kết hợp giữa hương hoa hồng tươi sáng và những nốt hương đất đặc trưng, tạo nên một hương thơm nhẹ nhàng nhưng đầy quyến rũ.",
          "Mở đầu với hương cam bergamot tươi mát, 'Rose Milano' nhanh chóng đưa bạn đến với hương hoa hồng thanh lịch, trung tâm của mùi hương này. Lớp hương cuối với hoắc hương, rêu và xạ hương tạo nên sự cân bằng hoàn hảo giữa nét ngọt ngào và sự trầm ấm, giúp nước hoa lưu lại lâu trên da.",
          "Hương đầu: Cam bergamot.",
          "Hương giữa: Hoa hồng.",
          "Hương cuối: Cây hoắc hương, rêu, xạ hương.",
        ],
      },
      {
        id: 14,
        productName: "Rose Milano Soie De Nacre",
        price: [580000, 5000000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [rose_milano_soie_de_nacre_1, rose_milano_soie_de_nacre_2],
        description: [
          "'Rose Milano Soie De Nacre' là phiên bản đặc biệt của 'Rose Milano', nổi bật với hiệu ứng ánh ngọc trai lấp lánh (Soie De Nacre). Nước hoa này giữ nguyên hương thơm thanh lịch, quyến rũ của 'Rose Milano', nhưng được bổ sung thêm ánh sáng tinh tế cho làn da, tạo nên sự lấp lánh nổi bật và sang trọng.",
          "'Rose Milano Soie De Nacre' mở đầu với hương cam bergamot tươi sáng, tiếp nối bằng hương giữa của hoa hồng đầy thanh khiết và quyến rũ. Lớp hương cuối với hoắc hương, rêu và xạ hương tạo nên sự ấm áp và trầm lắng, mang lại cảm giác sang trọng và tinh tế.",
          "Hương đầu: Cam bergamot.",
          "Hương giữa: Hoa hồng.",
          "Hương cuối: Hoắc hương, rêu, xạ hương.",
        ],
      },
    ],
  },
  {
    id: 20,
    brand: "Gucci",
    logo: gucci,
    products: [
      {
        id: 1,
        productName: "Mémoire d’une Odeur",
        price: [300000, 2500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [memoire_dune_odeur_1, memoire_dune_odeur_2],
        description: [
          "'Mémoire d’une Odeur' là một mùi hương độc đáo và đầy sáng tạo từ thương hiệu Gucci, được xây dựng dựa trên ý tưởng về ký ức và mùi hương. Nước hoa này được thiết kế để không thuộc về thời gian, giới tính hay quy tắc cố định, đem lại cảm giác tự do và hoài niệm. Hương thơm kết hợp các nốt hương thảo mộc, hoa cỏ và xạ hương, tạo ra sự cân bằng tinh tế giữa thiên nhiên và ký ức.",
          "Mùi hương bắt đầu với sự nhẹ nhàng và thư giãn từ cúc La Mã và hạnh nhân, dẫn dắt đến tầng hương giữa tinh tế và ấm áp với hoa nhài và xạ hương. Cuối cùng, lớp hương nền với gỗ đàn hương, tuyết tùng và vani tạo nên chiều sâu và sự bí ẩn đầy cuốn hút.",
          "Hương đầu: Cúc La Mã, hạnh nhân đắng.",
          "Hương giữa: Hoa nhài, xạ hương.",
          "Hương cuối: Gỗ đàn hương, tuyết tùng, vani.",
        ],
      },
      {
        id: 2,
        productName: "Gucci Bloom",
        price: [350000, 2500000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [bloom_edp_1, bloom_edp_2],
        description: [
          "'Gucci Bloom' là dòng nước hoa mang đậm chất nữ tính, tôn vinh sự tươi trẻ và sức sống của phụ nữ hiện đại. Được sáng tạo bởi giám đốc sáng tạo Alessandro Michele, nước hoa này là một vườn hoa rực rỡ, sống động, đầy sắc hương và tinh tế. 'Bloom' tập trung vào sự tinh khiết của các loài hoa trắng, mang lại cảm giác nhẹ nhàng nhưng vẫn quyến rũ.",
          "Mùi hương mở ra với sự thanh khiết của hoa nhài, tiếp nối là hương hoa huệ nữ tính và ngọt ngào, mang lại cảm giác tươi mát, thanh tao. Cuối cùng, hương rễ diên vĩ đem lại sự cân bằng trầm ấm, tạo nên chiều sâu cho nước hoa.",
          "Hương đầu: Hoa nhài Sambac.",
          "Hương giữa: Hoa huệ.",
          "Hương cuối: Rễ cây diên vĩ.",
        ],
      },
      {
        id: 3,
        productName: "Gucci Flora Gorgeous Gardenia",
        price: [380000, 3000000],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [flora_gorgeous_gardenia_1, flora_gorgeous_gardenia_2],
        description: [
          "'Gucci Flora Gorgeous Gardenia' là một dòng nước hoa tươi sáng và ngọt ngào, lấy cảm hứng từ vẻ đẹp tinh khiết và cuốn hút của loài hoa dành dành. Hương thơm mang đậm chất nữ tính với sự hòa quyện của các nốt hương hoa cỏ và trái cây, tạo nên một mùi hương nhẹ nhàng, sảng khoái và tràn đầy sức sống.",
          "Nước hoa mở đầu với hương thơm tươi mát và ngọt ngào của quả lê và hoa lan Nam Phi, sau đó tiếp tục bằng hương giữa thanh thoát của hoa dành dành và hoa nhài. Kết thúc bằng hương cuối ấm áp của đường nâu và hoắc hương, tạo nên một sự cân bằng hoàn hảo giữa sự ngọt ngào và quyến rũ.",
          "Hương đầu: Quả lê, hoa lan Nam Phi.",
          "Hương giữa: Hoa dành dành, hoa nhài.",
          "Hương cuối: Đường nâu, hoắc hương.",
        ],
      },
      {
        id: 4,
        productName: "Gucci Flora Gorgeous Jasmine",
        price: [390000, 3050000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [flora_gorgeous_jasmine_1, flora_gorgeous_jasmine_2],
        description: [
          "'Gucci Flora Gorgeous Jasmine' là một hương thơm tươi mới, đầy tinh tế và nữ tính, tập trung vào vẻ đẹp đầy quyến rũ của hoa nhài. Đây là dòng nước hoa mang phong cách lãng mạn và ngọt ngào, hòa quyện các nốt hương hoa cỏ với trái cây tươi mát, tạo nên sự mềm mại và cuốn hút.",
          "Sự khởi đầu tươi mát của quả quýt và cam Bergamot kết hợp với chút cay nồng nhẹ của hạt tiêu đen tạo nên một ấn tượng đầu dễ chịu. Hương giữa là sự hòa quyện nồng nàn và tinh tế của hoa nhài, hoa mộc lan và hoa hồng, đem lại cảm giác nữ tính và lôi cuốn. Cuối cùng, hương gỗ đàn hương và benzoin tạo nên lớp nền ấm áp và lâu bền.",
          "Hương đầu: Quả quýt, cam Bergamot, hạt tiêu đen.",
          "Hương giữa: Hoa nhài Grandiflorum, hoa mộc lan, hoa hồng.",
          "Hương cuối: Gỗ đàn hương, benzoin, nhựa thơm.",
        ],
      },
      {
        id: 5,
        productName: "Gucci Flora Gorgeous Magnolia",
        price: [390000, 3050000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [flora_gorgeous_magnolia_1, flora_gorgeous_magnolia_2],
        description: [
          "'Gucci Flora Gorgeous Magnolia' là một hương thơm nữ tính và tươi mới, tập trung vào vẻ đẹp tinh tế và cuốn hút của hoa magnolia. Nước hoa này mang đến sự hòa quyện hoàn hảo giữa các nốt hương hoa cỏ và trái cây, tạo nên một trải nghiệm olfactory đầy sức sống và thanh lịch.",
          "Nước hoa mở đầu với sự tươi mới và chua ngọt của quả bưởi và cam Bergamot, tiếp nối bằng hương giữa rực rỡ của hoa magnolia, hoa nhài và hoa hồng, mang lại cảm giác tươi mới và quyến rũ. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu.",
          "Hương đầu: Quả bưởi, cam Bergamot.",
          "Hương giữa: Hoa magnolia, hoa nhài, hoa hồng.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 6,
        productName: "Gucci Bamboo",
        price: [360000, 2700000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [bamboo_edp_1, bamboo_edp_2],
        description: [
          "'Gucci Bamboo' là một hương thơm nữ tính, mang đến cảm giác thanh lịch và quyến rũ với sự kết hợp hoàn hảo giữa các nốt hương hoa cỏ và gỗ. Được lấy cảm hứng từ sự mạnh mẽ và mềm mại của cây tre, nước hoa này thể hiện sự cân bằng giữa sự tinh tế và sức mạnh nội tại của người phụ nữ hiện đại.",
          "Mở đầu với sự tươi mát và ngọt ngào của chanh và quýt, tiếp nối bằng hương giữa quyến rũ của hoa nhài, hoa lily và hoa cam, tạo nên sự pha trộn tinh tế và thanh thoát. Cuối cùng, hương gỗ đàn hương, xạ hương và vani tạo nên lớp nền ấm áp và lâu dài.",
          "Hương đầu: Quả chanh, quýt.",
          "Hương giữa: Hoa nhài, hoa lily, hoa cam.",
          "Hương cuối: Gỗ đàn hương, xạ hương, vani.",
        ],
      },
      {
        id: 7,
        productName: "Gucci Guilty Elixir Pour Femme",
        price: [500000, 3200000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [guilty_elixir_pour_femme_1, guilty_elixir_pour_femme_2],
        description: [
          "'Gucci Guilty Elixir Pour Femme' là một dòng nước hoa đầy quyến rũ và mạnh mẽ, mang đến một trải nghiệm olfactory bí ẩn và lôi cuốn. Đây là phiên bản cao cấp hơn của bộ sưu tập Guilty, với hương thơm phong phú và đầy cảm xúc, phản ánh sự tự tin và sức quyến rũ của người phụ nữ hiện đại.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả lê và quả mâm xôi, mang lại cảm giác sảng khoái và hấp dẫn. Hương giữa là sự hòa quyện đầy lôi cuốn của hoa nhài, hoa hồng và hương gỗ, tạo nên một sự kết hợp tinh tế và quyến rũ. Cuối cùng, hương xạ hương, hổ phách và vani tạo nên lớp nền ấm áp, lâu bền và bí ẩn.",
          "Hương đầu: Quả lê, quả mâm xôi.",
          "Hương giữa: Hoa nhài, hoa hồng, hương gỗ.",
          "Hương cuối: Xạ hương, hổ phách, vani.",
        ],
      },
    ],
  },
  {
    id: 21,
    brand: "Jean Paul Gaultier",
    logo: jean_paul_gaultier,
    products: [
      {
        id: 1,
        productName: "Le Male Pride Edition",
        price: [300000, 2750000],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [le_male_pride_edition_1, le_male_pride_edition_2],
        description: [
          "'Jean Paul Gaultier Le Male Pride Edition' là phiên bản đặc biệt của dòng nước hoa nổi tiếng Le Male, được thiết kế để tôn vinh sự tự tin và sự đa dạng. Với hương thơm nam tính, mạnh mẽ và quyến rũ, sản phẩm này mang đến một trải nghiệm olfactory đầy ấn tượng và cá tính.",
          "Nước hoa mở đầu với sự tươi mát của hương bưởi và bạc hà, mang lại cảm giác sảng khoái và năng động. Hương giữa là sự kết hợp đầy nam tính của hoa oải hương, hoa nhài và gia vị, tạo nên sự pha trộn quyến rũ và mạnh mẽ. Cuối cùng, hương xạ hương, gỗ đàn hương và vani tạo nên lớp nền ấm áp, bền lâu và đầy quyến rũ.",
          "Hương đầu: Hương bưởi, hương bạc hà.",
          "Hương giữa: Hoa oải hương, hoa nhài, gia vị.",
          "Hương cuối: Xạ hương, gỗ đàn hương, vani.",
        ],
      },
      {
        id: 2,
        productName: "Scandal By Night EDP Intense",
        price: [null, null],
        // dung tích
        volume: [10, 80],
        gender: ["female"],
        // nồng độ
        concentration: "EDP Intense",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [scandal_by_night_edp_intense_1, scandal_by_night_edp_intense_2],
        description: [
          "'Scandal By Night EDP Intense' là phiên bản mạnh mẽ và quyến rũ hơn của dòng Scandal nổi tiếng, được thiết kế để làm nổi bật sự tự tin và gợi cảm của phụ nữ hiện đại. Với hương thơm phong phú và đầy cảm xúc, nước hoa này mang đến một trải nghiệm olfactory đậm chất quyến rũ và bí ẩn.",
          "Nước hoa mở đầu với sự tươi mát và ngọt ngào của cam và bưởi, tạo nên một cảm giác sảng khoái và hấp dẫn. Hương giữa mang đến sự quyến rũ của hoa nhài, hoa cam và mật ong, tạo nên sự hòa quyện gợi cảm và lôi cuốn. Cuối cùng, lớp nền ấm áp của gỗ đàn hương, hổ phách và xạ hương tạo ra một sự kết thúc bền lâu và bí ẩn.",
          "Hương đầu: Hương cam, hương bưởi.",
          "Hương giữa: Hoa nhài, hoa cam, mật ong.",
          "Hương cuối: Gỗ đàn hương, hổ phách, xạ hương.",
        ],
      },
      {
        id: 3,
        productName: "Scandal",
        price: [350000, 2700000],
        // dung tích
        volume: [10, 80],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [scandal_edp_1, scandal_edp_2],
        description: [
          "'Jean Paul Gaultier Scandal' là một trong những nước hoa nổi tiếng của thương hiệu Jean Paul Gaultier, mang đến một hương thơm quyến rũ và đầy sự táo bạo. Sản phẩm này thể hiện sự gợi cảm và cá tính của người phụ nữ hiện đại, tạo nên một dấu ấn mạnh mẽ và khó quên.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả cam bergamot kết hợp với mật ong, mang lại cảm giác quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự pha trộn gợi cảm của hoa nhài, hoa cam và hương đào, tạo nên một cảm giác nữ tính và lôi cuốn. Cuối cùng, hương gỗ đàn hương, hổ phách và xạ hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và cuốn hút.",
          "Hương đầu: Quả cam bergamot, mật ong.",
          "Hương giữa: Hoa nhài, hoa cam, hương đào.",
          "Hương cuối: Gỗ đàn hương, hổ phách, xạ hương.",
        ],
      },
      {
        id: 4,
        productName: "Jean Paul Gaultier So Scandal!",
        price: [380000, 2800000],
        // dung tích
        volume: [10, 80],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [jean_paul_gaultier_so_scandal_1, jean_paul_gaultier_so_scandal_2],
        description: [
          "'Jean Paul Gaultier So Scandal!' là phiên bản mới của dòng Scandal nổi tiếng, mang đến một hương thơm đầy sự táo bạo và quyến rũ. Phiên bản này thể hiện sự lôi cuốn mạnh mẽ và cá tính của người phụ nữ hiện đại, mang lại một trải nghiệm olfactory đầy năng lượng và thú vị.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả lê và quả dâu tây, tạo ra một cảm giác vui tươi và quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp đầy lôi cuốn của hoa nhài và hoa cam, mang lại một lớp hương nữ tính và đầy sự lôi cuốn. Cuối cùng, hương xạ hương, hổ phách và vani tạo nên lớp nền ấm áp, bền lâu và quyến rũ, thể hiện sự tự tin và cá tính của người sử dụng.",
          "Hương đầu: Quả lê, quả dâu tây.",
          "Hương giữa: Hoa nhài, hoa cam.",
          "Hương cuối: Xạ hương, hổ phách, vani.",
        ],
      },
      {
        id: 5,
        productName: "Jean Paul Gaultier Le Beau",
        price: [300000, 2600000],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [
          jean_paul_gaultier_le_beau_edt_1,
          jean_paul_gaultier_le_beau_edt_2,
        ],
        description: [
          "'Jean Paul Gaultier Le Beau' là phiên bản mới và quyến rũ của dòng Le Male nổi tiếng, mang đến một hương thơm đầy sức hút và tươi mới. Được thiết kế để tôn vinh vẻ đẹp nam tính, nước hoa này là sự kết hợp hoàn hảo giữa sự quyến rũ và sự tươi mới, tạo nên một dấu ấn đặc biệt và đầy lôi cuốn.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của dừa kết hợp với bưởi, mang lại một cảm giác tươi mới và quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp của hoa cam, tạo nên một lớp hương nam tính và lôi cuốn. Cuối cùng, hương gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và nam tính.",
          "Hương đầu: Dừa, bưởi.",
          "Hương giữa: Hoa cam.",
          "Hương cuối: Gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 6,
        productName: "Scandal Pour Homme",
        price: [320000, 2600000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [scandal_pour_homme_edt_1, scandal_pour_homme_edt_2],
        description: [
          "'Jean Paul Gaultier Scandal Pour Homme' là phiên bản nam của dòng Scandal nổi tiếng, mang đến một hương thơm mạnh mẽ và đầy sự quyến rũ. Nước hoa này được thiết kế để tôn vinh sự tự tin và cá tính của nam giới hiện đại, tạo ra một ấn tượng đầy sức hút và lôi cuốn.",
          "Nước hoa mở đầu với sự tươi mới và cay nhẹ của cam bergamot kết hợp với tiêu đen, tạo ra một cảm giác mạnh mẽ và lôi cuốn ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp quyến rũ của hoa nhài và hoa cam, mang đến một lớp hương nam tính và đầy sức hút. Cuối cùng, hương gỗ đàn hương, hổ phách và xạ hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và mạnh mẽ.",
          "Hương đầu: Quả cam bergamot, tiêu đen.",
          "Hương giữa: Hoa nhài, hoa cam.",
          "Hương cuối: Gỗ đàn hương, hổ phách, xạ hương.",
        ],
      },
      {
        id: 7,
        productName: "Jean Paul Gaultier La Belle Le Parfum",
        price: [350000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [
          jean_paul_gaultier_la_belle_le_parfum_1,
          jean_paul_gaultier_la_belle_le_parfum_2,
        ],
        description: [
          "'Jean Paul Gaultier La Belle Le Parfum' là phiên bản mới của dòng La Belle, mang đến một hương thơm đầy sự quyến rũ và sang trọng. Phiên bản này tăng cường sự gợi cảm và cá tính, tạo nên một dấu ấn mạnh mẽ và đầy lôi cuốn cho người phụ nữ hiện đại.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả lê, tạo ra một cảm giác quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp của hoa nhài, mang lại một lớp hương nữ tính và đầy lôi cuốn. Cuối cùng, hương vanilla và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và sang trọng.",
          "Hương đầu: Quả lê.",
          "Hương giữa: Hoa nhài.",
          "Hương cuối: Vanilla, hổ phách.",
        ],
      },
      {
        id: 8,
        productName: "Jean Paul Gaultier Le Male Le Parfum",
        price: [320000, 2800000],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [
          jean_paul_gaultier_le_male_le_parfum_1,
          jean_paul_gaultier_le_male_le_parfum_2,
        ],
        description: [
          "'Jean Paul Gaultier Le Male Le Parfum' là phiên bản nâng cấp của dòng Le Male nổi tiếng, mang đến một hương thơm mạnh mẽ và đầy quyến rũ. Phiên bản này tăng cường sự lôi cuốn và nam tính, tạo nên một dấu ấn đậm nét và đầy sức hút cho người đàn ông hiện đại.",
          "Nước hoa mở đầu với sự tươi mới và mát lạnh của bạc hà kết hợp với gừng, tạo ra một cảm giác đầy sức sống và năng lượng ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp quyến rũ của hoa cam và hoa nhài, mang đến một lớp hương nam tính và lôi cuốn. Cuối cùng, hương hổ phách, vani và gỗ đàn hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và mạnh mẽ.",
          "Hương đầu: Bạc hà, gừng.",
          "Hương giữa: Hoa cam, hoa nhài.",
          "Hương cuối: Hổ phách, vani, gỗ đàn hương.",
        ],
      },
      {
        id: 9,
        productName: "Jean Paul Gaultier Ultra Male",
        price: [300000, 2900000],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [jean_paul_gaultier_ultra_male_1, jean_paul_gaultier_ultra_male_2],
        description: [
          "'Jean Paul Gaultier Ultra Male' là phiên bản mạnh mẽ và đầy lôi cuốn của dòng Le Male nổi tiếng. Được thiết kế để tăng cường sự quyến rũ và cá tính, nước hoa này tạo ra một ấn tượng sâu sắc và bền lâu, hoàn hảo cho những người đàn ông yêu thích sự nổi bật và đầy sức hút.",
          "Nước hoa mở đầu với sự ngọt ngào và tươi mới của quả lê kết hợp với cam bergamot, tạo nên một sự khởi đầu đầy sức sống và lôi cuốn. Hương giữa mang đến một lớp hương ấm áp và quyến rũ với sự kết hợp của hoa nhài và quế. Cuối cùng, hương vanilla, hổ phách và gỗ đàn hương tạo nên một lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ mạnh mẽ và nam tính.",
          "Hương đầu: Quả lê, cam bergamot.",
          "Hương giữa: Hoa nhài, quế.",
          "Hương cuối: Vanilla, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 10,
        productName: "Jean Paul Gaultier Le Male Lover",
        price: [null, null],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [le_male_lover_1, le_male_lover_2],
        description: [
          "'Jean Paul Gaultier Le Male Lover' là phiên bản mới trong dòng Le Male, mang đến một hương thơm đầy quyến rũ và mạnh mẽ. Phiên bản này tiếp tục truyền thống của dòng Le Male với sự kết hợp hoàn hảo giữa sự nam tính và lôi cuốn, tạo nên một dấu ấn mạnh mẽ và đầy sức hút.",
          "Nước hoa mở đầu với sự tươi mới và mát lạnh của cam bergamot kết hợp với bạc hà, mang đến một cảm giác năng lượng và sự quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp của hoa nhài và hoa cam, tạo nên một lớp hương nam tính và quyến rũ. Cuối cùng, hương vanilla, gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và mạnh mẽ.",
          "Hương đầu: Cam bergamot, bạc hà.",
          "Hương giữa: Hoa nhài, hoa cam.",
          "Hương cuối: Vanilla, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 11,
        productName: "Jean Paul Gaultier Le Male Elixir",
        price: [350000, 3300000],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [le_male_elixir_edp_1, le_male_elixir_edp_2],
        description: [
          "'Jean Paul Gaultier Le Male Elixir' là phiên bản nâng cấp đầy lôi cuốn của dòng Le Male, được thiết kế để tăng cường sự quyến rũ và nam tính. Phiên bản này mang đến một hương thơm mạnh mẽ và ấn tượng, hoàn hảo cho những người đàn ông yêu thích sự nổi bật và cuốn hút.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của cam bergamot và quả lê, tạo nên một cảm giác tươi mới và quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp ấm áp của hoa nhài và quế, mang đến một lớp hương nam tính và lôi cuốn. Cuối cùng, hương vanilla, hổ phách và gỗ đàn hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và mạnh mẽ.",
          "Hương đầu: Cam bergamot, quả lê.",
          "Hương giữa: Hoa nhài, quế.",
          "Hương cuối: Vanilla, hổ phách, gỗ đàn hương.",
        ],
      },
    ],
  },
  {
    id: 22,
    brand: "Once perfume",
    logo: once,
    products: [
      {
        id: 1,
        productName: "Lipsens",
        price: [20000, 1500000],
        // dung tích
        volume: [10, 100],
        gender: ["male", "female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [lipsens_edp_1, lipsens_edp_2],
        description: [
          "'Lipsens' từ Once Perfume là một sáng tạo nước hoa đầy lôi cuốn và tinh tế, mang đến một hương thơm quyến rũ và ấn tượng cho cả nam và nữ. Với sự kết hợp hoàn hảo giữa các nốt hương phong phú, 'Lipsens' tạo nên một dấu ấn mạnh mẽ và đầy cảm xúc.",
          "Nước hoa mở đầu với sự tươi mới của chanh và quả vải, tạo ra một cảm giác sảng khoái và năng lượng ngay từ những giây phút đầu tiên. Hương giữa mang đến sự quyến rũ và nữ tính với sự kết hợp của hoa nhài và hồng, tạo nên một lớp hương lôi cuốn và quyến rũ. Cuối cùng, hương xạ hương và gỗ đàn hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và sâu lắng.",
          "Hương đầu: Chanh, quả vải.",
          "Hương giữa: Hoa nhài, hồng.",
          "Hương cuối: Xạ hương, gỗ đàn hương.",
        ],
      },
    ],
  },
  {
    id: 23,
    brand: "Juliette Has A Gun",
    logo: juliette_has_a_gun,
    products: [
      {
        id: 1,
        productName: "Juliette Has A Gun Not A Perfume",
        price: [280000, 2400000],
        // dung tích
        volume: [10, 100],
        gender: ["male", "female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [
          juliette_has_a_gun_not_a_perfume_edp_1,
          juliette_has_a_gun_not_a_perfume_edp_2,
        ],
        description: [
          "'Juliette Has A Gun Not A Perfume' là một sự sáng tạo độc đáo trong thế giới nước hoa, nổi bật với sự tinh giản và hiện đại. Đây là một nước hoa được chế tạo hoàn toàn từ một thành phần chính, Cetalox, một hợp chất hương gỗ độc quyền. Sản phẩm này thể hiện một phong cách tối giản nhưng đầy cuốn hút, hoàn hảo cho những ai yêu thích sự đơn giản và tinh tế.",
          "Điểm đặc biệt của 'Not A Perfume' là nó không có sự phân lớp hương như các nước hoa truyền thống. Thay vào đó, nó sử dụng Cetalox để tạo ra một hương thơm gỗ nhẹ nhàng và thanh thoát, mang lại sự sạch sẽ và tinh tế.",
          "Hương đầu: Cetalox (hương gỗ)",
          "Hương giữa: Cetalox (hương gỗ)",
          "Hương cuối: Cetalox (hương gỗ)",
        ],
      },
    ],
  },
  {
    id: 24,
    brand: "Kilian",
    logo: kilian,
    products: [
      {
        id: 1,
        productName: "Kilian Voulez-Vous Coucher Avec Moi",
        price: [800000, 560000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "gần",
        img: [
          kilian_voulez_vous_coucher_avec_moi_1,
          kilian_voulez_vous_coucher_avec_moi_2,
        ],
        description: [
          "'Kilian Voulez-Vous Coucher Avec Moi' là một tác phẩm đầy lôi cuốn và quyến rũ từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm đầy cảm xúc và táo bạo. Nước hoa này được thiết kế để tạo nên một ấn tượng mạnh mẽ và thu hút, với sự kết hợp của các nốt hương phong phú và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot và quả đào, mang đến một cảm giác năng lượng và sự quyến rũ ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp lôi cuốn của hoa nhài, hoa hồng và hoa huệ, tạo nên một lớp hương nữ tính và quyến rũ. Cuối cùng, hương vani, hổ phách và xạ hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và lôi cuốn.",
          "Hương đầu: Cam bergamot, quả đào.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa huệ.",
          "Hương cuối: Hương vani, hổ phách, xạ hương.",
        ],
      },
      {
        id: 2,
        productName: "Kilian Good Girl Gone Bad",
        price: [800000, 5600000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "gần",
        img: [kilian_good_girl_gone_bad_1, kilian_good_girl_gone_bad_2],
        description: [
          "'Kilian Good Girl Gone Bad' là một sáng tạo đầy lôi cuốn từ thương hiệu Kilian, mang đến một hương thơm quyến rũ và táo bạo. Được thiết kế cho những phụ nữ yêu thích sự nổi bật và cá tính, nước hoa này kết hợp các nốt hương phong phú để tạo ra một trải nghiệm đầy cảm xúc và phong cách.",
          "Nước hoa mở đầu với sự tươi mới và quyến rũ của hoa nhài và hoa cam, tạo nên một cảm giác lôi cuốn ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp của hoa hồng, hoa huệ và quả mâm xôi, tạo ra một lớp hương nữ tính, phong cách và quyến rũ. Cuối cùng, hương vani, hổ phách và gỗ đàn hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ sâu lắng và táo bạo.",
          "Hương đầu: Hoa nhài, hoa cam.",
          "Hương giữa: Hoa hồng, hoa huệ, quả mâm xôi.",
          "Hương cuối: Hương vani, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 3,
        productName: "Kilian Moonlight In Heaven",
        price: [800000, 5600000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [kilian_moonlight_in_heaven_1, kilian_moonlight_in_heaven_2],
        description: [
          "'Kilian Moonlight In Heaven' là một tác phẩm đầy lôi cuốn và thanh thoát từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm tươi mới và quyến rũ. Nước hoa này được thiết kế để gợi nhớ về những đêm huyền bí dưới ánh trăng, với sự kết hợp hoàn hảo của các nốt hương trái cây và hoa.",
          "Nước hoa mở đầu với sự tươi mới và sảng khoái của chanh, dứa và bưởi, tạo ra một cảm giác năng lượng và sự sảng khoái ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp lôi cuốn của hoa nhài, hoa cam và dừa, tạo nên một lớp hương quyến rũ và thanh thoát. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, mang đến sự quyến rũ sâu lắng và cảm xúc.",
          "Hương đầu: Chanh, dứa, bưởi.",
          "Hương giữa: Hoa nhài, hoa cam, dừa.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 4,
        productName: "Kilian Rolling in Love",
        price: [800000, 5000000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [kilian_rolling_in_love_1, kilian_rolling_in_love_2],
        description: [
          "'Kilian Rolling in Love' là một sáng tạo lãng mạn và quyến rũ từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm đầy cảm xúc và ấm áp. Nước hoa này được thiết kế để gợi nhớ đến những cảm xúc say mê và lãng mạn, với sự kết hợp hoàn hảo của các nốt hương hoa và gia vị.",
          "Nước hoa mở đầu với sự tươi mới và lôi cuốn của hạnh nhân, cam bergamot và quả táo, tạo ra một cảm giác tươi mát và lãng mạn ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp quyến rũ của hoa nhài, hoa hồng và iris, mang đến một lớp hương nữ tính và lôi cuốn. Cuối cùng, hương xạ hương, vani và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ và cảm xúc sâu lắng.",
          "Hương đầu: Hạnh nhân, cam bergamot, quả táo.",
          "Hương giữa: Hoa nhài, hoa hồng, iris.",
          "Hương cuối: Xạ hương, vani, hổ phách.",
        ],
      },
      {
        id: 5,
        productName: "Kilian Black Phantom - Memento Mori",
        price: [85000, 5900000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [
          kilian_black_phantom_memento_mori_1,
          kilian_black_phantom_memento_mori_2,
        ],
        description: [
          "'Kilian Black Phantom - Memento Mori' là một tác phẩm đầy bí ẩn và quyến rũ từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm sâu lắng và lôi cuốn. Nước hoa này được thiết kế để gợi nhớ đến những đêm huyền bí và sự quyến rũ đen tối, với sự kết hợp hoàn hảo của các nốt hương gỗ và gia vị.",
          "Nước hoa mở đầu với sự lôi cuốn của rượu rum, cà phê và hạnh nhân, tạo ra một cảm giác quyến rũ và sâu lắng ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp đầy mê hoặc của hoa nhài, chocolate đen và đường caramel, mang đến một lớp hương phong phú và đầy cảm xúc. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự bí ẩn và lôi cuốn.",
          "Hương đầu: Rượu rum, cà phê, hạnh nhân.",
          "Hương giữa: Hoa nhài, chocolate đen, đường caramel.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 6,
        productName: "Kilian Roses On Ice",
        price: [800000, 4500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [kilian_roses_on_ice_1, kilian_roses_on_ice_2],
        description: [
          "'Kilian Roses On Ice' là một tác phẩm tươi mát và thanh thoát từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm quyến rũ và sảng khoái. Nước hoa này được thiết kế để gợi nhớ về sự tươi mới và quyến rũ của những cánh hoa hồng được làm lạnh, với sự kết hợp hoàn hảo của các nốt hương hoa và gia vị.",
          "Nước hoa mở đầu với sự tươi mới của dưa hấu và chanh, kết hợp với hương hoa hồng tươi mát, tạo ra một cảm giác sảng khoái ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp quyến rũ của hoa hồng và hoa nhài, mang đến một lớp hương thanh thoát và lôi cuốn. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự quyến rũ và cảm xúc sâu lắng.",
          "Hương đầu: Dưa hấu, chanh, hương hoa hồng tươi mát.",
          "Hương giữa: Hoa hồng, hoa nhài, dưa hấu.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 7,
        productName: "Kilian Angels' Share",
        price: [850000, 4700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [kilian_angels_share_1, kilian_angels_share_2],
        description: [
          "'Kilian Angels' Share' là một sáng tạo đậm đà và quyến rũ từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm ấm áp và phong phú. Nước hoa này được thiết kế để gợi nhớ đến sự tinh tế và sang trọng của những ly rượu mạnh quý giá, với sự kết hợp hoàn hảo của các nốt hương gia vị, gỗ và vani.",
          "Nước hoa mở đầu với sự ấm áp của quế và hạt nhục đậu khấu, kết hợp với sự sang trọng của rượu cognac, tạo ra một cảm giác quyến rũ và lôi cuốn ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp phong phú của caramel và vani, mang đến một lớp hương ngọt ngào và ấm áp. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền bền lâu và đầy cảm xúc, thể hiện sự sang trọng và quyến rũ.",
          "Hương đầu: Quế, hạt nhục đậu khấu, rượu cognac.",
          "Hương giữa: Hương gỗ, caramel, vani.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 8,
        productName: "Kilian Apple Brandy On The Rocks",
        price: [850000, 4700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [
          kilian_apple_brandy_on_the_rock_1,
          kilian_apple_brandy_on_the_rock_2,
        ],
        description: [
          "'Kilian Apple Brandy On The Rocks' là một tác phẩm độc đáo và lôi cuốn từ thương hiệu Kilian, mang đến một trải nghiệm hương thơm tươi mát và quyến rũ, như một ly rượu táo được làm lạnh hoàn hảo. Nước hoa này kết hợp các nốt hương trái cây, gia vị và gỗ, tạo ra một cảm giác mới mẻ và sang trọng.",
          "Nước hoa mở đầu với sự tươi mát của táo xanh và chanh, kết hợp với một chút gừng để tạo ra một cảm giác sảng khoái ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp quyến rũ của rượu brandy, hoa nhài và hương táo, mang đến một lớp hương phong phú và sâu lắng. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và quyến rũ.",
          "Hương đầu: Táo xanh, chanh, gừng.",
          "Hương giữa: Rượu brandy, hoa nhài, hương táo.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 25,
    brand: "Le Labo",
    logo: le_labo,
    products: [
      { 
        id: 1,
        productName: "Le Labo Another 13",
        price: [750000, 5500000, 7700000],
        // dung tích
        volume: [10, 50, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [le_labo_another_13_1, le_labo_another_13_2],
        description: [
          "'Le Labo Another 13' là một sự sáng tạo độc đáo và quyến rũ từ thương hiệu Le Labo, mang đến một trải nghiệm hương thơm hiện đại và thanh thoát. Nước hoa này được thiết kế để thể hiện sự tinh tế và phong cách cá nhân với một sự kết hợp tinh tế của các nốt hương gỗ, xạ hương và hương hoa.",
          "Nước hoa mở đầu với sự tươi mát của cam bergamot và táo, kết hợp với hương hoa thanh thoát, tạo ra một cảm giác nhẹ nhàng và quyến rũ. Hương giữa là sự kết hợp tinh tế của xạ hương, gỗ đàn hương và hoa nhài, mang đến một lớp hương sâu lắng và phong phú. Cuối cùng, hương hổ phách, xạ hương và gỗ tuyết tùng tạo nên lớp nền bền lâu, thể hiện sự thanh thoát và quyến rũ.",
          "Hương đầu: Cam bergamot, táo, hương hoa.",
          "Hương giữa: Xạ hương, gỗ đàn hương, hương hoa nhài.",
          "Hương cuối: Hổ phách, xạ hương, gỗ tuyết tùng.",
        ],
      },
      {
        id: 2,
        productName: "Bergamote 22",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [bergamote_22_1, bergamote_22_2],
        description: [
          "'Le Labo Bergamote 22' là một sự sáng tạo tinh tế từ thương hiệu Le Labo, mang đến một trải nghiệm hương thơm tươi mát và quyến rũ. Nước hoa này được thiết kế để thể hiện sự tinh khiết và thanh thoát với sự kết hợp hoàn hảo của các nốt hương cam bergamot và gỗ.",
          "Nước hoa mở đầu với sự tươi mát của cam bergamot, chanh và bưởi, tạo ra một cảm giác sảng khoái và thanh thoát ngay từ những giây phút đầu tiên. Hương giữa là sự kết hợp quyến rũ của hoa nhài, hoa hồng và hương gia vị, mang đến một lớp hương phong phú và tinh tế. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền bền lâu, thể hiện sự quyến rũ và sang trọng.",
          "Hương đầu: Cam bergamot, chanh, bưởi.",
          "Hương giữa: Hoa nhài, hoa hồng, hương gia vị.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Le Labo Santal 33",
        price: [700000, 5300000, 6700000],
        // dung tích
        volume: [10, 50, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [santal_33_1, santal_33_2],
        description: [
          "'Le Labo Santal 33' là một trong những nước hoa biểu tượng của thương hiệu Le Labo, mang đến một trải nghiệm hương thơm gỗ đặc trưng và quyến rũ. Nước hoa này nổi bật với sự kết hợp hoàn hảo giữa hương gỗ và gia vị, tạo nên một phong cách độc đáo và hiện đại.",
          "Nước hoa mở đầu với sự kết hợp của đinh hương và hương cam, mang đến một cảm giác tươi mới và dễ chịu. Hương giữa là sự hòa quyện phong phú của gỗ đàn hương, gỗ tuyết tùng và hoa nhài, tạo ra một lớp hương gỗ ấm áp và quyến rũ. Cuối cùng, hương xạ hương, hổ phách và va ni tạo nên lớp nền bền lâu, thể hiện sự sang trọng và hiện đại.",
          "Hương đầu: Đinh hương, hương cam.",
          "Hương giữa: Gỗ đàn hương, gỗ tuyết tùng, hoa nhài.",
          "Hương cuối: Xạ hương, hổ phách, va ni.",
        ],
      },
      {
        id: 4,
        productName: "Le Labo Rose 31",
        price: [660000, 5800000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [rose_31_1, rose_31_2],
        description: [
          "'Le Labo Rose 31' là một sáng tạo đặc biệt của Le Labo, mang đến một trải nghiệm hương thơm quyến rũ và mạnh mẽ với sự kết hợp độc đáo giữa hoa hồng và các nốt hương gỗ. Nước hoa này phá vỡ các quy tắc truyền thống về hương hoa hồng, tạo ra một sản phẩm hiện đại và đầy cá tính.",
          "Nước hoa mở đầu với sự cay nồng của hạt tiêu và caraway, mang đến một cảm giác kích thích và tươi mới. Hương giữa là sự hòa quyện tinh tế của hoa hồng, gỗ đàn hương và hoa nhài, tạo nên một lớp hương hoa ấm áp và quyến rũ. Cuối cùng, hương xạ hương, gỗ tuyết tùng và hổ phách tạo nên lớp nền bền lâu, thể hiện sự sang trọng và hiện đại.",
          "Hương đầu: Hạt tiêu, caraway.",
          "Hương giữa: Hoa hồng, gỗ đàn hương, hoa nhài.",
          "Hương cuối: Xạ hương, gỗ tuyết tùng, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 26,
    brand: "Les Soeurs de Noe",
    logo: les_soeurs_de_noe,
    products: [
      {
        id: 1,
        productName: "Les Soeurs de Noe Call Me Iris",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [call_me_iris_edp_1, call_me_iris_edp_2],
        description: [
          "'Call Me Iris' là một tác phẩm tinh tế từ Les Soeurs de Noe, mang đến một trải nghiệm hương thơm thanh thoát và sang trọng với sự tập trung vào hương diên vĩ. Nước hoa này kết hợp hoàn hảo giữa các nốt hương hoa diên vĩ và hương gỗ, tạo nên một sản phẩm độc đáo và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot, hạt tiêu và chanh, tạo ra một cảm giác sảng khoái và đầy năng lượng. Hương giữa là sự hòa quyện tinh tế của hoa diên vĩ, hoa nhài và hoa hồng, mang đến một lớp hương hoa thanh thoát và quyến rũ. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền bền lâu, thể hiện sự sang trọng và tinh tế.",
          "Hương đầu: Cam bergamot, hạt tiêu, chanh.",
          "Hương giữa: Hoa diên vĩ, hoa nhài, hoa hồng.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Les Soeurs de Noe Clandestine Rendez-Vous",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [clandestine_rendez_vous_edp_1, clandestine_rendez_vous_edp_2],
        description: [
          "'Clandestine Rendez-Vous' từ Les Soeurs de Noe là một tác phẩm đầy bí ẩn và quyến rũ, mang đến một trải nghiệm hương thơm độc đáo với sự kết hợp hài hòa giữa các nốt hương gỗ và gia vị. Nước hoa này tạo nên một bầu không khí bí ẩn và lôi cuốn, lý tưởng cho những dịp đặc biệt.",
          "Nước hoa mở đầu với sự cay nồng của hạt tiêu và nhục đậu khấu, kết hợp với sự tươi mới của cam bergamot, tạo ra một cảm giác kích thích và đầy năng lượng. Hương giữa là sự hòa quyện quyến rũ của gỗ đàn hương, hoa hồng và gỗ tuyết tùng, mang đến một lớp hương gỗ ấm áp và lôi cuốn. Cuối cùng, hương xạ hương, hổ phách và vani tạo nên lớp nền bền lâu, thể hiện sự sang trọng và bí ẩn.",
          "Hương đầu: Hạt tiêu, cam bergamot, nhục đậu khấu.",
          "Hương giữa: Gỗ đàn hương, hoa hồng, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, hổ phách, vani.",
        ],
      },
    ],
  },
  {
    id: 27,
    brand: "Atelier des Ors",
    logo: atelier_des_ors,
    products: [
      {
        id: 1,
        productName: "Atelier des Ors Nomad Soul",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [nomad_soul_edp_1, nomad_soul_edp_2],
        description: [
          "'Nomad Soul' từ Atelier des Ors là một nước hoa thể hiện sự khám phá và tự do, với sự kết hợp tinh tế giữa các nốt hương gỗ và gia vị. Được lấy cảm hứng từ tinh thần du mục và sự kỳ bí của những cuộc hành trình xa xôi, nước hoa này mang đến một trải nghiệm hương thơm đầy lôi cuốn và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới và cay nồng của hạt tiêu kết hợp với cam bergamot và chanh, tạo ra một cảm giác kích thích và tươi mới. Hương giữa chứa sự hòa quyện ấm áp của gỗ đàn hương, hoa nhài và gỗ tuyết tùng, tạo nên một lớp hương gỗ quyến rũ và phong phú. Cuối cùng, hương xạ hương, hổ phách và vani mang đến một lớp nền bền lâu, thể hiện sự sang trọng và tinh tế.",
          "Hương đầu: Hạt tiêu, cam bergamot, chanh.",
          "Hương giữa: Gỗ đàn hương, hoa nhài, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, hổ phách, vani.",
        ],
      },
      {
        id: 2,
        productName: "Atelier des Ors Nuda Veritas",
        price: [550000, 4500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nuda_veritas_edp_1, nuda_veritas_edp_2],
        description: [
          "'Nuda Veritas' từ Atelier des Ors là một nước hoa mang đến sự tinh tế và quyến rũ thông qua sự kết hợp hài hòa giữa các nốt hương hoa cỏ và gia vị. Với sự thiết kế đầy sáng tạo và tinh tế, nước hoa này mở ra một không gian hương thơm đầy cảm xúc và tựa như một tác phẩm nghệ thuật.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot và sự ấm áp của nhục đậu khấu kết hợp với hạt tiêu, tạo ra một lớp hương đầu kích thích và đầy năng lượng. Hương giữa là sự hòa quyện quyến rũ của hoa nhài, hoa hồng và hoa iris, mang đến một lớp hương hoa cỏ thanh thoát và sang trọng. Cuối cùng, hương xạ hương, hổ phách và gỗ đàn hương tạo nên lớp nền bền lâu, thể hiện sự sang trọng và quyến rũ.",
          "Hương đầu: Cam bergamot, nhục đậu khấu, hạt tiêu.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa iris.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 3,
        productName: "Les Soeurs de Noe Pink Me Up",
        price: [600000, 5500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [pink_me_up_edp_1, pink_me_up_edp_2],
        description: [
          "'Pink Me Up' từ Les Soeurs de Noe là một nước hoa đầy sự tươi mới và quyến rũ, với sự kết hợp hài hòa giữa các nốt hương hoa quả ngọt ngào và tinh tế. Nước hoa này mang đến một trải nghiệm hương thơm vui tươi và lôi cuốn, lý tưởng cho những ai yêu thích sự nhẹ nhàng và dễ chịu.",
          "Nước hoa mở đầu với sự tươi mới của quả dứa và cam quýt, kết hợp với sự ngọt ngào của quả lựu, tạo ra một cảm giác vui tươi và sảng khoái. Hương giữa chứa sự hòa quyện của hoa nhài, hoa hồng và quả mâm xôi, mang đến một lớp hương hoa quả quyến rũ và đầy nữ tính. Cuối cùng, hương xạ hương, hổ phách và gỗ tuyết tùng tạo nên lớp nền bền lâu, thể hiện sự sang trọng và tinh tế.",
          "Hương đầu: Quả dứa, cam quýt, quả lựu.",
          "Hương giữa: Hoa nhài, hoa hồng, quả mâm xôi.",
          "Hương cuối: Xạ hương, hổ phách, gỗ tuyết tùng.",
        ],
      },
      {
        id: 4,
        productName: "Atelier des Ors Noir by Night",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "10-12",
        // độ toả hương
        scent_release: "xa",
        img: [noir_by_night_edp_1, noir_by_night_edp_2],
        description: [
          "'Noir by Night' từ Atelier des Ors là một nước hoa thể hiện sự bí ẩn và quyến rũ, được lấy cảm hứng từ những đêm đen huyền bí và lôi cuốn. Nước hoa này kết hợp các nốt hương gỗ, gia vị và hương cay, mang đến một trải nghiệm hương thơm đầy ấn tượng và gợi cảm.",
          "Nước hoa mở đầu với sự tươi mới và cay nồng của tiêu đen kết hợp với cam bergamot và nhục đậu khấu, tạo ra một lớp hương đầu kích thích và ấm áp. Hương giữa chứa sự hòa quyện quyến rũ của gỗ đàn hương, da thuộc và hoa nhài, mang đến một lớp hương gỗ sâu lắng và sang trọng. Cuối cùng, hương xạ hương, hổ phách và gỗ tuyết tùng tạo nên lớp nền bền lâu, thể hiện sự quyến rũ và lôi cuốn.",
          "Hương đầu: Tiêu đen, cam bergamot, nhục đậu khấu.",
          "Hương giữa: Gỗ đàn hương, da thuộc, hoa nhài.",
          "Hương cuối: Xạ hương, hổ phách, gỗ tuyết tùng.",
        ],
      },
    ],
  },
  {
    id: 28,
    brand: "Burberry",
    logo: burberry,
    products: [
      {
        id: 1,
        productName: "Burberry Her",
        price: [320000, 2750000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [burberry_her_edp_1, burberry_her_edp_2],
        description: [
          "'Burberry Her' là một nước hoa nữ tính từ Burberry, mang đến sự kết hợp hài hòa giữa các nốt hương hoa cỏ và trái cây, tạo ra một trải nghiệm hương thơm tươi mới và quyến rũ. Đây là lựa chọn lý tưởng cho những ai yêu thích sự nhẹ nhàng, thanh thoát và đầy sức sống.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả dâu tây, quả mâm xôi và quả việt quất, tạo nên một lớp hương đầu đầy sức sống và quyến rũ. Hương giữa chứa sự hòa quyện của hoa nhài, hoa hồng và hoa cam, mang đến một lớp hương hoa cỏ thanh thoát và nữ tính. Cuối cùng, hương xạ hương, hổ phách và gỗ đàn hương tạo nên lớp nền bền lâu, thể hiện sự sang trọng và tinh tế.",
          "Hương đầu: Quả dâu tây, quả mâm xôi, quả việt quất.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
      {
        id: 2,
        productName: "Burberry London For Women",
        price: [250000, 1800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [burberry_london_for_women_1, burberry_london_for_women_2],
        description: [
          "'Burberry London For Women' là một nước hoa nữ tính từ Burberry, mang đến một sự kết hợp thanh thoát và tinh tế giữa các nốt hương hoa cỏ và gỗ, gợi lên một cảm giác ấm áp và quyến rũ. Đây là lựa chọn lý tưởng cho những ai yêu thích sự thanh lịch và sang trọng.",
          "Nước hoa mở đầu với sự tươi mới và quyến rũ của hoa nhài, hoa hồng và cam bergamot, tạo ra một lớp hương đầu đầy sức sống và nữ tính. Hương giữa chứa sự hòa quyện của hoa nhài, hoa diên vĩ và hương dưa hấu, mang đến một lớp hương hoa cỏ và trái cây nhẹ nhàng. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và sang trọng.",
          "Hương đầu: Hoa nhài, hoa hồng, cam bergamot.",
          "Hương giữa: Hoa nhài, hoa diên vĩ, hương dưa hấu.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Burberry London For Men",
        price: [250000, 1700000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [burberry_london_for_men_1, burberry_london_for_men_2],
        description: [
          "'Burberry London For Men' là một nước hoa nam tính từ Burberry, mang đến sự kết hợp hoàn hảo giữa các nốt hương gỗ và gia vị, tạo ra một trải nghiệm hương thơm mạnh mẽ và đầy ấn tượng. Đây là lựa chọn lý tưởng cho những ai yêu thích sự sang trọng và quyến rũ trong hương thơm.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của quả bưởi và cam bergamot, hòa quyện với sự ấm áp của quế. Hương giữa chứa sự hòa quyện của hoa hồng, tiêu đen và nhục đậu khấu, mang đến một lớp hương gia vị và hoa cỏ quyến rũ. Cuối cùng, hương xạ hương, gỗ đàn hương và hoắc hương tạo nên lớp nền bền lâu, thể hiện sự sang trọng và nam tính.",
          "Hương đầu: Quả bưởi, cam bergamot, quế.",
          "Hương giữa: Hoa hồng, tiêu đen, nhục đậu khấu.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hoắc hương.",
        ],
      },
    ],
  },
  {
    id: 29,
    brand: "Afnan",
    logo: afnan,
    products: [
      {
        id: 1,
        productName: "Afnan 9AM",
        price: [180000, 1200000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [afnan_9am_1, afnan_9am_2],
        description: [
          "'Afnan 9AM' là một nước hoa nam tính từ Afnan, nổi bật với sự kết hợp mạnh mẽ giữa các nốt hương gỗ và gia vị, tạo ra một trải nghiệm hương thơm ấn tượng và đầy năng lượng. Đây là lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và phong cách mạnh mẽ trong hương thơm.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của cam bergamot và bưởi, hòa quyện với sự ấm áp của gia vị. Hương giữa chứa sự hòa quyện của hoa nhài, gỗ tuyết tùng và hương gia vị, mang đến một lớp hương quyến rũ và mạnh mẽ. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên lớp nền bền lâu, thể hiện sự sang trọng và nam tính.",
          "Hương đầu: Cam bergamot, bưởi, gia vị.",
          "Hương giữa: Hoa nhài, gỗ tuyết tùng, hương gia vị.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Afnan 9PM",
        price: [180000, 1200000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [afnan_9pm_1, afnan_9pm_2],
        description: [
          "'Afnan 9PM' là một nước hoa nam tính từ Afnan, được thiết kế để tôn vinh vẻ quyến rũ và sang trọng của phái mạnh trong những dịp đặc biệt. Với sự kết hợp hoàn hảo giữa các nốt hương gỗ và gia vị, nước hoa này mang đến một trải nghiệm hương thơm mạnh mẽ và đầy ấn tượng, lý tưởng cho những buổi tối sang trọng.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của cam bergamot và bưởi, kết hợp với gia vị nồng ấm để tạo ra một lớp hương đầu đầy sức sống. Hương giữa chứa sự hòa quyện của hoa nhài, gỗ tuyết tùng và hương gia vị, mang đến một lớp hương quyến rũ và nam tính. Cuối cùng, hương xạ hương, gỗ đàn hương, hổ phách và da thuộc tạo nên lớp nền bền lâu, thể hiện sự sang trọng và quyến rũ của sản phẩm.",
          "Hương đầu: Cam bergamot, bưởi, gia vị nồng ấm.",
          "Hương giữa: Hoa nhài, gỗ tuyết tùng, hương gia vị.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách, da thuộc.",
        ],
      },
      {
        id: 3,
        productName: "Afnan Supremacy Silver",
        price: [200000, 1350000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [afnan_supremacy_silver_1, afnan_supremacy_silver_2],
        description: [
          "'Afnan Supremacy Silver' là một nước hoa nam tính từ Afnan, nổi bật với sự kết hợp tinh tế giữa các nốt hương gỗ, gia vị và tươi mát, mang đến một trải nghiệm hương thơm sang trọng và ấn tượng. Sản phẩm này lý tưởng cho những ai yêu thích sự thanh thoát và đầy phong cách.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của cam bergamot và chanh, kết hợp với sự thanh mát của húng quế. Hương giữa mang đến sự hòa quyện của hoa nhài, tiêu đen và gỗ tuyết tùng, tạo nên một lớp hương quyến rũ và mạnh mẽ. Cuối cùng, hương xạ hương, gỗ đàn hương và hoắc hương tạo nên lớp nền bền lâu, thể hiện sự thanh lịch và sang trọng của sản phẩm.",
          "Hương đầu: Cam bergamot, chanh, húng quế.",
          "Hương giữa: Hoa nhài, tiêu đen, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hoắc hương.",
        ],
      },
    ],
  },
  {
    id: 30,
    brand: "Creed",
    logo: creed,
    products: [
      {
        id: 1,
        productName: "Creed Spring Flower",
        price: [850000, 5900000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [spring_flower_edp_1, spring_flower_edp_2],
        description: [
          "'Spring Flower' từ Creed là một nước hoa nữ mang đến sự tươi mới và nữ tính, như một làn sóng hương hoa rực rỡ trong mùa xuân. Với sự kết hợp hài hòa giữa các nốt hương hoa cỏ và trái cây, sản phẩm này mang đến một trải nghiệm hương thơm tươi mát và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả đào, quả lê và chanh, tạo nên một lớp hương đầu đầy sức sống. Hương giữa mang đến sự hòa quyện của hoa nhài, hoa hồng và hoa cam, tạo ra một lớp hương hoa cỏ quyến rũ và nữ tính. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và tinh tế của sản phẩm.",
          "Hương đầu: Quả đào, quả lê, chanh.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Creed Wind Flowers",
        price: [900000, 6500000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [wind_flowers_edp_1, wind_flowers_edp_2],
        description: [
          "'Wind Flowers' từ Creed là một nước hoa nữ mang đến một cảm giác tươi mới và nhẹ nhàng, như một làn gió xuân mang theo hương hoa cỏ thanh thoát. Sản phẩm này thể hiện sự tinh tế và quyến rũ qua sự kết hợp hoàn hảo giữa các nốt hương hoa và trái cây.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của cam bergamot, nho đen và chanh, tạo nên một lớp hương đầu đầy sức sống. Hương giữa mang đến sự hòa quyện của hoa nhài, hoa hồng và hoa cam, tạo ra một lớp hương hoa cỏ quyến rũ và nữ tính. Cuối cùng, hương xạ hương, gỗ đàn hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và tinh tế của sản phẩm.",
          "Hương đầu: Cam bergamot, nho đen, chanh.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách.",
        ],
      },
      {
        id: 3,
        productName: "Creed Silver Mountain Water",
        price: [650000, 6000000],
        // dung tích
        volume: [10, 75],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [silver_moutain_water_edp_1, silver_moutain_water_edp_2],
        description: [
          "'Silver Mountain Water' của Creed là một nước hoa mang đến sự tươi mát và trong trẻo, lấy cảm hứng từ vẻ đẹp của những ngọn núi phủ tuyết. Sản phẩm này kết hợp sự thanh thoát của nước và sự sang trọng của các nốt hương tự nhiên, tạo ra một trải nghiệm hương thơm độc đáo và đầy sức sống.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của cam bergamot, chanh và húng quế, tạo nên một lớp hương đầu đầy sức sống và thanh thoát. Hương giữa mang đến sự hòa quyện của trà xanh, hoa nhài và gia vị, tạo ra một lớp hương quyến rũ và thư giãn. Cuối cùng, hương xạ hương, gỗ đàn hương và bạch đậu khấu tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và thanh lịch của sản phẩm.",
          "Hương đầu: Cam bergamot, chanh, húng quế.",
          "Hương giữa: Trà xanh, hoa nhài, gia vị.",
          "Hương cuối: Xạ hương, gỗ đàn hương, bạch đậu khấu.",
        ],
      },
      {
        id: 4,
        productName: "Creed Aventus for Her",
        price: [750000, 6700000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [creed_aventus_for_her_1, creed_aventus_for_her_2],
        description: [
          "'Aventus for Her' của Creed là một nước hoa nữ mang đến một sự kết hợp độc đáo giữa sự quyến rũ và tinh tế, được thiết kế để tôn vinh sự mạnh mẽ và nữ tính. Sản phẩm này mở ra một trải nghiệm hương thơm đầy sức sống và quyến rũ, kết hợp sự tươi mới của trái cây với sự sang trọng của các nốt hương hoa.",
          "Nước hoa mở đầu với sự tươi mới của quả táo xanh, chanh và dưa hấu, tạo nên một lớp hương đầu sảng khoái và đầy sức sống. Hương giữa mang đến sự hòa quyện quyến rũ của hoa hồng, hoa nhài và hoa nhài trắng, tạo ra một lớp hương hoa cỏ mềm mại và nữ tính. Cuối cùng, hương xạ hương, gỗ đàn hương và vani tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và thanh lịch của sản phẩm.",
          "Hương đầu: Quả táo xanh, chanh, dưa hấu.",
          "Hương giữa: Hoa hồng, hoa nhài, hoa nhài trắng.",
          "Hương cuối: Xạ hương, gỗ đàn hương, vani.",
        ],
      },
      {
        id: 5,
        productName: "Creed Aventus for Men",
        price: [80000, 7500000],
        // dung tích
        volume: [10, 75],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [aventus_for_men_edp_1, aventus_for_men_edp_2],
        description: [
          "'Aventus for Men' của Creed là một nước hoa nam mạnh mẽ và đầy quyến rũ, được tạo ra để tôn vinh sự thành công và quyền lực. Sản phẩm này mang đến một trải nghiệm hương thơm đầy sức sống và phong cách, kết hợp sự tươi mới của trái cây với sự sang trọng của các nốt hương gỗ và khói.",
          "Nước hoa mở đầu với sự tươi mới và chua nhẹ của táo xanh, chanh, dứa và cam bergamot, tạo nên một lớp hương đầu đầy sức sống và tươi mới. Hương giữa mang đến sự hòa quyện của hoa hồng, hoa nhài, gia vị và gỗ tuyết tùng, tạo ra một lớp hương phong phú và quyến rũ. Cuối cùng, hương xạ hương, gỗ đàn hương, hổ phách, vani và khói tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và mạnh mẽ của sản phẩm.",
          "Hương đầu: Táo xanh, chanh, dứa, cam bergamot.",
          "Hương đầu: Táo xanh, chanh, dứa, cam bergamot.",
          "Hương cuối: Xạ hương, gỗ đàn hương, hổ phách, vani, khói.",
        ],
      },
    ],
  },
  {
    id: 31,
    brand: "Liquides Imaginaires",
    logo: liquides_imaginaires,
    products: [
      {
        id: 1,
        productName: "Liquides Imaginaires Tapis Volant",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [tapis_volant_edp_1, tapis_volant_edp_2],
        description: [
          "'Tapis Volant' của Liquides Imaginaires mang đến một hành trình hương thơm kỳ diệu, lấy cảm hứng từ sự kỳ bí và ma thuật của những chuyến bay trên thảm bay. Sản phẩm này kết hợp các nốt hương gỗ ấm áp với sự tươi mới của gia vị, tạo ra một trải nghiệm hương thơm đầy cảm xúc và sự bí ẩn.",
          "Nước hoa mở đầu với sự tươi mới của quả lê, chanh và gừng, tạo nên một lớp hương đầu đầy sức sống và quyến rũ. Hương giữa mang đến sự hòa quyện của gỗ tuyết tùng, nhựa hương và gia vị, tạo ra một lớp hương ấm áp và phong phú. Cuối cùng, hương xạ hương, gỗ đàn hương và vani tạo nên lớp nền bền lâu và ấm áp, thể hiện sự sang trọng và bí ẩn của sản phẩm.",
          "Hương đầu: Quả lê, chanh, gừng.",
          "Hương giữa: Gỗ tuyết tùng, nhựa hương, gia vị.",
          "Hương cuối: Xạ hương, gỗ đàn hương, vani.",
        ],
      },
      {
        id: 2,
        productName: "Liquides Imaginaires Phan Tasma",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [phan_tasma_edp_1, phan_tasma_edp_2],
        description: [
          "'Phan Tasma' của Liquides Imaginaires là một nước hoa unisex đầy bí ẩn và cuốn hút, mang đến một trải nghiệm hương thơm phong phú và đa dạng. Được lấy cảm hứng từ những vùng đất kỳ lạ và phong cảnh huyền bí, sản phẩm này kết hợp sự ấm áp của hương gỗ với các nốt gia vị và hoa, tạo ra một hương thơm độc đáo và lôi cuốn.",
          "Nước hoa mở đầu với sự cay nồng của bạch đậu khấu, nhục đậu khấu và hạt tiêu, tạo nên một lớp hương đầu đầy kích thích và năng lượng. Hương giữa mang đến sự hòa quyện tinh tế của hoa nhài, hoa hồng và gỗ đàn hương, tạo ra một lớp hương phong phú và lôi cuốn. Cuối cùng, hương xạ hương, hổ phách và gỗ tuyết tùng tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và bí ẩn của sản phẩm.",
          "Hương đầu: Bạch đậu khấu, nhục đậu khấu, hạt tiêu.",
          "Hương giữa: Hoa nhài, hoa hồng, gỗ đàn hương.",
          "Hương cuối: Xạ hương, hổ phách, gỗ tuyết tùng.",
        ],
      },
      {
        id: 3,
        productName: "Liquides Imaginaires Blanche Bête",
        price: [650000, 6000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [blanche_bete_1, blanche_bete_2],
        description: [
          "'Blanche Bête' của Liquides Imaginaires là một nước hoa unisex đầy cuốn hút và quyến rũ, mang đến một trải nghiệm hương thơm độc đáo và bí ẩn. Được lấy cảm hứng từ vẻ đẹp thuần khiết và sức mạnh của sự bí ẩn, sản phẩm này kết hợp sự tươi mới của hương hoa với các nốt hương gỗ và chua, tạo ra một hương thơm phong phú và lôi cuốn.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot, chanh và quýt, tạo nên một lớp hương đầu đầy sức sống và năng lượng. Hương giữa mang đến sự hòa quyện tinh tế của hoa nhài, hoa huệ và gỗ tuyết tùng, tạo ra một lớp hương phong phú và quyến rũ. Cuối cùng, hương xạ hương, hổ phách và gỗ đàn hương tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và bí ẩn của sản phẩm.",
          "Hương đầu: Cam bergamot, chanh, quýt.",
          "Hương giữa: Hoa nhài, hoa huệ, gỗ tuyết tùng.",
          "Hương cuối: Xạ hương, hổ phách, gỗ đàn hương.",
        ],
      },
    ],
  },
  {
    id: 32,
    brand: "Initio Parfums Prives",
    logo: initio_parfums_prives,
    products: [
      {
        id: 1,
        productName: "Initio Parfums Prives Musk Therapy",
        price: [850000, 6500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "gần",
        img: [musk_therapy_edp_1, musk_therapy_edp_2],
        description: [
          "'Musk Therapy' của Initio Parfums Prives là một nước hoa unisex mang đến một trải nghiệm hương thơm sang trọng và thư giãn. Được tạo ra để giúp cân bằng và xoa dịu các giác quan, sản phẩm này kết hợp sự ấm áp của xạ hương với các nốt hương hoa và gỗ, tạo ra một hương thơm tinh tế và thanh thoát.",
          "Nước hoa mở đầu với sự tươi mới của quả cam và quả lê, tạo nên một lớp hương đầu đầy sức sống và sáng sủa. Hương giữa mang đến sự hòa quyện của hoa nhài và hoa hồng, tạo ra một lớp hương thanh thoát và nữ tính. Cuối cùng, hương xạ hương, gỗ đàn hương và vani tạo nên lớp nền ấm áp và lôi cuốn, mang lại cảm giác thư giãn và dễ chịu.",
          "Hương đầu: Quả cam, quả lê.",
          "Hương giữa: Hoa nhài, hoa hồng.",
          "Hương cuối: Xạ hương, gỗ đàn hương, vani.",
        ],
      },
    ],
  },
  {
    id: 33,
    brand: "LV",
    logo: lv,
    products: [
      {
        id: 1,
        productName: "LV Rose des Vents Eau de Parfum",
        price: [950000, 8700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [rose_des_vents_eau_de_parfum_1, rose_des_vents_eau_de_parfum_2],
        description: [
          "'Rose des Vents' của Louis Vuitton là một nước hoa unisex tinh tế, mang đến một trải nghiệm hương thơm thanh nhã và sang trọng. Được lấy cảm hứng từ vẻ đẹp của hoa hồng và các yếu tố thiên nhiên, sản phẩm này kết hợp sự tinh khiết của hương hoa với các nốt hương gỗ, tạo ra một hương thơm quyến rũ và thanh thoát.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot và hoa nhài, tạo nên một lớp hương đầu đầy năng lượng và thanh thoát. Hương giữa mang đến sự hòa quyện tinh tế của hoa hồng, hoa nhài và iris, tạo ra một lớp hương hoa phong phú và quyến rũ. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự sang trọng và tinh tế của sản phẩm.",
          "Hương đầu: Cam bergamot, hoa nhài.",
          "Hương giữa: Hoa hồng, hoa nhài, iris.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "LV Pacific Chill Eau de Parfum",
        price: [95000, 8700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [parcific_chill_1, parcific_chill_2],
        description: [
          "'Pacific Chill' của Louis Vuitton là một nước hoa unisex, mang đến một trải nghiệm hương thơm tươi mới và thư giãn. Được lấy cảm hứng từ vẻ đẹp và sự tĩnh lặng của đại dương, sản phẩm này kết hợp các nốt hương biển mát mẻ với sự tươi mới của cây cỏ, tạo ra một hương thơm đầy sức sống và dễ chịu.",
          "Hương đầu: Cam chanh, dưa hấu.",
          "Hương giữa: Hoa nhài, lá bạc hà.",
          "Hương cuối: Gỗ đàn hương, xạ hương, rêu sồi.",
          "Nước hoa mở đầu với sự tươi mới của cam chanh và dưa hấu, mang đến một lớp hương đầu đầy năng lượng và mát mẻ. Hương giữa kết hợp hoa nhài và lá bạc hà, tạo ra một lớp hương nhẹ nhàng và thư giãn. Cuối cùng, hương gỗ đàn hương, xạ hương và rêu sồi tạo nên lớp nền ấm áp và bền lâu, mang lại cảm giác như đang hòa mình vào sự yên bình của đại dương.",
        ],
      },
      {
        id: 3,
        productName: "LV California Dream Eau de Parfum",
        price: [950000, 8700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [
          louis_vuitton_california_dream_1,
          louis_vuitton_california_dream_2,
        ],
        description: [
          "'California Dream' của Louis Vuitton là một nước hoa unisex mang đến một trải nghiệm hương thơm tươi mới và quyến rũ. Được lấy cảm hứng từ vẻ đẹp của bờ biển California và sự tự do của cuộc sống ngoài trời, sản phẩm này kết hợp các nốt hương trái cây tươi mát với sự sang trọng của hương hoa và gỗ.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot, chanh vàng và dưa hấu, tạo nên một lớp hương đầu đầy sức sống và sảng khoái. Hương giữa mang đến sự hòa quyện của hoa nhài, hoa hồng và hoa cam, tạo ra một lớp hương hoa phong phú và quyến rũ. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, thể hiện sự tinh tế và sang trọng của sản phẩm.",
          "Hương đầu: Cam bergamot, chanh vàng, dưa hấu.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 4,
        productName: "LV Attrape-Rêves Eau de Parfum",
        price: [1250000, 11600000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [louis_vuitton_attrape_reves_1, louis_vuitton_attrape_reves_2],
        description: [
          "'Attrape-Rêves' của Louis Vuitton là một nước hoa unisex, mang đến một trải nghiệm hương thơm lôi cuốn và bí ẩn. Tên gọi 'Attrape-Rêves' (Người bắt giấc mơ) gợi lên hình ảnh của một thế giới huyền bí và lãng mạn, nơi những giấc mơ trở thành hiện thực. Sản phẩm này kết hợp các nốt hương hoa, gỗ và vani, tạo nên một hương thơm đầy quyến rũ và tinh tế.",
          "Nước hoa mở đầu với sự cay nồng của hạt tiêu đỏ và nhựa cây benzoe, tạo ra một lớp hương đầu đầy lôi cuốn và mạnh mẽ. Hương giữa mang đến sự hòa quyện của hoa nhài, hoa hồng và hoa cam, tạo ra một lớp hương hoa phong phú và quyến rũ. Cuối cùng, hương gỗ đàn hương, vani và xạ hương tạo nên lớp nền ấm áp và bền lâu, mang lại cảm giác huyền bí và sang trọng.",
          "Hương đầu: Hạt tiêu đỏ, nhựa cây benzoe.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Gỗ đàn hương, vani, xạ hương.",
        ],
      },
      {
        id: 5,
        productName: "LV Imagination Eau de Parfum",
        price: [950000, 8700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [imagination_1, imagination_2],
        description: [
          "'Imagination' của Louis Vuitton là một nước hoa unisex, mang đến một trải nghiệm hương thơm sáng tạo và đầy cảm hứng. Được thiết kế để kích thích trí tưởng tượng và cảm xúc, sản phẩm này kết hợp các nốt hương hoa, trái cây và gỗ, tạo ra một hương thơm độc đáo và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới và sảng khoái của cam bergamot, chanh vàng và táo xanh, mang đến một lớp hương đầu đầy sức sống và năng lượng. Hương giữa kết hợp hoa nhài, hoa hồng và hoa cam, tạo ra một lớp hương hoa phong phú và quyến rũ. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, mang lại cảm giác thanh lịch và sang trọng.",
          "Hương đầu: Cam bergamot, chanh vàng, táo xanh.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
      {
        id: 6,
        productName: "LV Spell on You Eau de Parfum",
        price: [950000, 8700000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [spell_on_you_1, spell_on_you_2],
        description: [
          "'Spell on You' của Louis Vuitton là một nước hoa unisex mang đến một trải nghiệm hương thơm lôi cuốn và bí ẩn. Tên gọi 'Spell on You' gợi lên cảm giác ma mị và quyến rũ, kết hợp những nốt hương hoa, trái cây và gỗ, tạo ra một hương thơm đặc biệt và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới và ngọt ngào của quả lý chua, cam bergamot và quả lê, tạo ra một lớp hương đầu đầy sức sống và hấp dẫn. Hương giữa mang đến sự hòa quyện của hoa nhài, hoa hồng và hoa cam, tạo ra một lớp hương hoa phong phú và quyến rũ. Cuối cùng, hương gỗ đàn hương, xạ hương và vani tạo nên lớp nền ấm áp và bền lâu, mang lại cảm giác thanh lịch và sang trọng.",
          "Hương đầu: Quả lý chua, cam bergamot, quả lê.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Gỗ đàn hương, xạ hương, vani.",
        ],
      },
      {
        id: 7,
        productName: "LV Dancing Blossom Eau de Parfum",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [_dancing_blossom_1, _dancing_blossom_2],
        description: [
          "'Dancing Blossom' của Louis Vuitton là một nước hoa unisex, mang đến một trải nghiệm hương thơm tươi mới và lôi cuốn. Tên gọi 'Dancing Blossom' gợi lên hình ảnh của những đóa hoa đang nở rộ trong không khí trong lành, kết hợp các nốt hương hoa, trái cây và gỗ để tạo ra một hương thơm độc đáo và quyến rũ.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot, quả đào và quả lê, tạo ra một lớp hương đầu đầy sức sống và quyến rũ. Hương giữa kết hợp hoa nhài, hoa hồng và hoa cam, mang đến một lớp hương hoa phong phú và nữ tính. Cuối cùng, hương gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, mang lại cảm giác thanh lịch và sang trọng.",
          "Hương đầu: Cam bergamot, quả đào, quả lê.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa cam.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 34,
    brand: "MFK",
    logo: mfk,
    products: [
      {
        id: 1,
        productName: "MFK Baccarat Rouge 540 Extrait de Parfum",
        price: [1100000, 6000000, 9500000, 17600000],
        // dung tích
        volume: [10, 35, 70, 200],
        gender: ["unisex"],
        // nồng độ
        concentration: "Extrait de Parfum",
        // độ lưu hương
        scent_retention: "12-24",
        // độ toả hương
        scent_release: "xa",
        img: [
          baccarat_rouge_540_extrait_de_parfum_1,
          baccarat_rouge_540_extrait_de_parfum_2,
        ],
        description: [
          "'Baccarat Rouge 540 Extrait de Parfum' là phiên bản tinh chế và đậm đặc hơn của nước hoa nổi tiếng Baccarat Rouge 540, mang đến một trải nghiệm hương thơm đầy ấn tượng và quyến rũ. Phiên bản Extrait de Parfum cung cấp một chiều sâu hương sắc hơn, với sự kết hợp hoàn hảo của các nốt hương gỗ, hoa và nhựa.",
          "Mở đầu với sự tươi mới và nồng nàn của safran và nhựa amber, hương đầu của nước hoa mang lại sự ấm áp và sang trọng. Hương giữa được làm phong phú bởi hoa nhài và hoa hồng, kết hợp với sự êm dịu của tinh dầu gỗ. Cuối cùng, lớp nền của gỗ tuyết tùng, hổ phách và xạ hương mang đến một kết thúc ấm áp, bền lâu và đầy quyến rũ.",
          "Hương đầu: Hương nhựa amber, nhựa cây, safran.",
          "Hương giữa: Hoa nhài, hoa hồng, tinh dầu gỗ.",
          "Hương cuối: Gỗ tuyết tùng, hổ phách, xạ hương.",
        ],
      },
      {
        id: 2,
        productName: "MFK Baccarat Rouge 540 Eau de Parfum",
        price: [75000, 4500000, 6500000, 11900000],
        // dung tích
        volume: [10, 35, 70, 200],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [mfk_baccarat_rouge_540_edp_1, mfk_baccarat_rouge_540_edp_2],
        description: [
          "'Baccarat Rouge 540 Eau de Parfum' là một trong những sáng tạo nổi bật của Maison Francis Kurkdjian, nổi tiếng với hương thơm sang trọng và đầy lôi cuốn. Được thiết kế để mang lại sự cân bằng hoàn hảo giữa các nốt hương gỗ, hoa và nhựa, phiên bản Eau de Parfum này cung cấp một trải nghiệm hương thơm đặc biệt và quyến rũ.",
          "Nước hoa mở đầu với sự kết hợp mạnh mẽ của saffron và nhựa cây, mang đến một cảm giác ấm áp và đầy sự kỳ bí. Hương giữa là sự hòa quyện hoàn hảo của hoa nhài và hoa hồng, tạo ra một lớp hương hoa thanh lịch và quyến rũ. Cuối cùng, hương của gỗ tuyết tùng, hổ phách và xạ hương tạo nên một lớp nền ấm áp, bền lâu và cuốn hút.",
          "Hương đầu: Saffron (nhụy hoa nghệ tây), nhựa cây.",
          "Hương giữa: Hoa nhài, hoa hồng.",
          "Hương cuối: Gỗ tuyết tùng, hổ phách, xạ hương.",
        ],
      },
      {
        id: 3,
        productName: "MFK 724 Eau de Parfum",
        price: [750000, 5600000],
        // dung tích
        volume: [10, 200],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [
          maison_francis_kurkdjian_724_edp_1,
          maison_francis_kurkdjian_724_edp_2,
        ],
        description: [
          "'Maison Francis Kurkdjian 724 Eau de Parfum' là một trong những sáng tạo độc đáo của Maison Francis Kurkdjian, mang đến một trải nghiệm hương thơm tươi mới và thanh lịch. Với sự kết hợp hoàn hảo giữa các nốt hương floral, citrus và gỗ, 724 là sự chọn lựa lý tưởng cho những ai yêu thích sự nhẹ nhàng và đầy sang trọng.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot, chanh và hoa cam, mang đến một lớp hương đầu đầy sức sống và sáng sủa. Hương giữa kết hợp hoa nhài, hoa hồng và hoa phong lữ, tạo ra một lớp hương floral nhẹ nhàng và quyến rũ. Cuối cùng, hương của gỗ đàn hương, xạ hương và hổ phách tạo nên lớp nền ấm áp và bền lâu, mang lại cảm giác thanh lịch và tinh tế.",
          "Hương đầu: Cam bergamot, chanh, hoa cam.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa phong lữ.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 35,
    brand: "MCM",
    logo: mcm,
    products: [
      {
        id: 1,
        productName: "MCM Eau de Parfum",
        price: [380000, 2350000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [mcm_edp_1, mcm_edp_2],
        description: [
          "'MCM Eau de Parfum' là một sản phẩm nước hoa đầy sáng tạo của MCM, mang đến một trải nghiệm hương thơm độc đáo và sang trọng. Với sự kết hợp hài hòa giữa các nốt hương floral, citrus và gỗ, nước hoa này thể hiện sự thanh lịch và hiện đại.",
          "Nước hoa mở đầu với sự tươi mới của cam bergamot và chanh, được làm phong phú bởi hạt tiêu, mang lại một lớp hương đầu đầy sức sống và năng lượng. Hương giữa là sự hòa quyện của hoa nhài, hoa hồng và hoa phong lữ, tạo ra một lớp hương floral nhẹ nhàng và quyến rũ. Cuối cùng, lớp nền của gỗ đàn hương, xạ hương và hổ phách mang lại sự ấm áp và bền lâu, tạo cảm giác thanh lịch và tinh tế.",
          "Hương đầu: Cam bergamot, chanh, hạt tiêu.",
          "Hương giữa: Hoa nhài, hoa hồng, hoa phong lữ.",
          "Hương cuối: Gỗ đàn hương, xạ hương, hổ phách.",
        ],
      },
    ],
  },
  {
    id: 36,
    brand: "Missoni",
    logo: missoni,
    products: [
      {
        id: 1,
        productName: "Missoni Wave Eau de Toilette",
        price: [200000, 1500000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [missoni_wave_edt_1, missoni_wave_edt_2],
        description: [
          "'Missoni Wave' là một hương nước hoa tươi mát, nam tính, được lấy cảm hứng từ vẻ đẹp và sức sống của biển cả. Đây là sự kết hợp tinh tế giữa các nốt hương cam quýt, thảo mộc và gỗ, tạo nên một mùi hương hoàn hảo cho người đàn ông hiện đại, năng động và phóng khoáng.",
          "Nước hoa mở đầu với sự bùng nổ tươi mới của cam chanh từ Sicilia và cam bergamot từ Calabria, hòa quyện cùng hương biển mát lành, mang lại cảm giác sảng khoái và tràn đầy năng lượng. Hương giữa là sự kết hợp hài hòa giữa hoa oải hương và các loài thảo mộc như rosemary và phong lữ, tạo nên sự thanh lịch và nam tính. Cuối cùng, hương gỗ sồi, xạ hương và vanilla mang đến sự ấm áp, mạnh mẽ và đầy cuốn hút, để lại dấu ấn lâu dài trên làn da.",
          "Hương đầu: Cam chanh Sicilia, cam bergamot Calabria, hương biển.",
          "Hương giữa: Hoa oải hương, lá rosemary, phong lữ, cây đơn sâm.",
          "Hương cuối: Gỗ sồi, xạ hương, vanilla, hoắc hương.",
        ],
      },
    ],
  },
  {
    id: 37,
    brand: "Montale",
    logo: montale,
    products: [
      {
        id: 1,
        productName: "Montale Chocolate Greedy Eau de Parfum",
        price: [280000, 2100000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [chocolate_greedy_1, chocolate_greedy_2],
        description: [
          "'Chocolate Greedy' của Montale là một hương thơm đậm chất gourmand, mang đến sự ngọt ngào đầy mê hoặc và quyến rũ với tâm điểm là chocolate đen. Nước hoa này tạo nên một không gian ấm áp, hấp dẫn, khiến người dùng như được đắm mình trong hương vị của những chiếc bánh chocolate tươi mới và thơm lừng.",
          "'Chocolate Greedy' mở đầu bằng hương cam tươi mát kết hợp cùng chút vị cà phê, mang lại một sự đối lập thú vị giữa sự sảng khoái và ấm áp. Tiếp theo là hương giữa của chocolate đen và đậu Tonka, tạo nên một lớp hương đậm đà, ngọt ngào, nhưng không hề gây cảm giác ngấy. Cuối cùng, vanilla và hạnh nhân mang đến sự mềm mại và quyến rũ cho hương thơm, khiến bạn khó lòng cưỡng lại.",
          "Hương đầu: Cam, cà phê.",
          "Hương giữa: Chocolate đen, đậu Tonka.",
          "Hương cuối: Vanilla, hạt hạnh nhân.",
        ],
      },
      {
        id: 2,
        productName: "Montale Intense Cafe Eau de Parfum",
        price: [290000, 2300000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [intense_cafe_1, intense_cafe_2],
        description: [
          "'Intense Cafe' của Montale là một bản giao hưởng độc đáo của hương cà phê đậm đà hòa quyện cùng hoa hồng ngọt ngào và vanilla mịn màng. Đây là sự kết hợp giữa những nốt hương cà phê mạnh mẽ nhưng đầy tinh tế, mang đến cho người dùng một trải nghiệm hương thơm quyến rũ và mê hoặc.",
          "'Intense Cafe' bắt đầu với hương cà phê đậm đặc, mang đến một sự khởi đầu mạnh mẽ, ấn tượng. Sau đó, lớp hương giữa là sự pha trộn tinh tế của hoa hồng mềm mại, thanh lịch cùng hổ phách ấm áp, tạo nên một sự cân bằng hoàn hảo giữa sự mạnh mẽ và lãng mạn. Cuối cùng, hương vanilla ngọt ngào và xạ hương trắng giúp lưu giữ mùi hương thật lâu, mang lại cảm giác ấm áp và quyến rũ suốt cả ngày.",
          "Hương đầu: Hương cà phê.",
          "Hương giữa: Hoa hồng, hổ phách.",
          "Hương cuối: Vanilla, xạ hương trắng.",
        ],
      },
    ],
  },
  {
    id: 38,
    brand: "Montblanc",
    logo: montblanc,
    products: [
      {
        id: 1,
        productName: "Montblanc Signature Eau de Parfum",
        price: [280000, 1850000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [montblanc_signature_1, montblanc_signature_2],
        description: [
          "'Montblanc Signature' là một sự kết hợp hài hòa giữa sự nữ tính thanh thoát và phong cách tinh tế. Hương thơm của nó phản ánh hình ảnh người phụ nữ tự tin, duyên dáng nhưng đầy cá tính. Nước hoa mang đậm dấu ấn đặc trưng của Montblanc, với sự mềm mại của hương vani cùng các thành phần tự nhiên cao cấp.",
          "Hương thơm mở đầu bằng sự tươi mát, dịu dàng của quýt vàng, tạo cảm giác nhẹ nhàng và thanh khiết. Lớp hương giữa với sự quyến rũ từ hoa mộc lan, hoa ngọc lan tây và mẫu đơn mang lại một vẻ đẹp lôi cuốn, tinh tế và thanh lịch. Cuối cùng, hương vani ấm áp, xạ hương trắng mềm mại và hổ phách giúp mùi hương lưu luyến, tạo nên sự ngọt ngào và quyến rũ dài lâu.",
          "Hương đầu: Quýt vàng.",
          "Hương giữa: Hoa mộc lan, hoa ngọc lan tây, mẫu đơn.",
          "Hương cuối: Vani, xạ hương trắng, hổ phách.",
        ],
      },
      {
        id: 2,
        productName: "Montblanc Explorer Eau de Parfum",
        price: [260000, 2000000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [montblanc_explorer_1, montblanc_explorer_2],
        description: [
          "'Montblanc Explorer' là một dòng nước hoa dành cho những người đàn ông có tinh thần phiêu lưu, thích khám phá những điều mới mẻ và không ngừng tìm kiếm trải nghiệm. Hương thơm của 'Explorer' gợi lên một hành trình độc đáo, đi qua những vùng đất xa xôi với hương thơm đầy bí ẩn và mạnh mẽ.",
          "Mùi hương mở đầu bằng sự tươi mát, sảng khoái từ cam bergamot, tạo ra cảm giác thanh thoát và tràn đầy năng lượng. Lớp hương giữa mang đến sự mạnh mẽ, tự nhiên từ gỗ vetiver, tạo nên nét nam tính và cuốn hút. Cuối cùng, hoắc hương từ Indonesia đem lại sự ấm áp, bí ẩn và lưu hương bền bỉ trên da.",
          "Hương đầu: Cam bergamot từ Ý.",
          "Hương giữa: Gỗ vetiver từ Haiti.",
          "Hương cuối: Hoắc hương từ Indonesia.",
        ],
      },
    ],
  },
  {
    id: 39,
    brand: "L’Orchestre Parfum",
    logo: lorchestre_parfum,
    products: [
      {
        id: 1,
        productName: "Vetiver Overdrive Eau de Parfum",
        price: [570000, 4900000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [vetiver_overdrive_1, vetiver_overdrive_2],
        description: [
          "'Vetiver Overdrive' là một mùi hương tươi mát và mạnh mẽ dành cho cả nam và nữ, mang đến cảm giác tự do và phóng khoáng với sự kết hợp độc đáo từ các nguyên liệu thiên nhiên. Lấy cảm hứng từ âm thanh sống động của thiên nhiên, dòng nước hoa này là một sự kết hợp hoàn hảo của các nốt hương tạo nên sự cân bằng tinh tế giữa sự mạnh mẽ và nhẹ nhàng.",
          "Sự kết hợp giữa cam chanh tươi mát và vetiver nồng nàn tạo nên một tầng hương phức tạp và cuốn hút. 'Vetiver Overdrive' mang lại cảm giác tự nhiên nhưng không kém phần sang trọng, gợi nhớ đến những chuyến phiêu lưu giữa thiên nhiên hoang dã, với làn gió mát mẻ và mùi hương của gỗ.",
          "Hương đầu: Cam chanh và bưởi tươi mát.",
          "Hương giữa: Gỗ tuyết tùng và tiêu đen, mang đến cảm giác cay nồng và ấm áp.",
          "Hương cuối: Vetiver từ Java, mang lại sự khô thoáng, mộc mạc và bền bỉ.",
        ],
      },
      {
        id: 2,
        productName: "Piano Santal Eau de Parfum",
        price: [5700, 4900000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [piano_santal_1, piano_santal_2],
        description: [
          "'Piano Santal' là một tác phẩm nghệ thuật trong thế giới nước hoa, mang đến một trải nghiệm hương thơm đầy cảm xúc và sự quyến rũ. Lấy cảm hứng từ âm nhạc và sự hòa quyện tinh tế của các nốt hương, nước hoa này tạo ra một bản giao hưởng của các thành phần tự nhiên với sự cân bằng hoàn hảo giữa sự ấm áp và tươi mát.",
          "'Piano Santal' kết hợp hương cam bergamot tươi mát với gỗ santal nồng nàn và sự ấm áp từ xạ hương, mang đến một trải nghiệm hương thơm phong phú và đầy đặn. Đây là một sự kết hợp hoàn hảo cho những người yêu thích sự sang trọng, tinh tế nhưng cũng không kém phần mạnh mẽ.",
          "Hương đầu: Cam bergamot và tiêu hồng, mở đầu bằng sự tươi mới và cay nồng nhẹ nhàng.",
          "Hương giữa: Gỗ santal và da thuộc, tạo nên sự ấm áp, mềm mại và sang trọng.",
          "Hương cuối: Xạ hương và hổ phách, kết thúc với sự ấm áp và lưu luyến, tạo cảm giác bí ẩn và quyến rũ.",
        ],
      },
      {
        id: 3,
        productName: "Flamenco Neroli Eau de Parfum",
        price: [570000, 4900000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [flamenco_neroli_1, flamenco_neroli_2],
        description: [
          "'Flamenco Neroli' là một bản giao hưởng hương thơm tươi mới và quyến rũ, được truyền cảm hứng từ điệu nhảy Flamenco đầy năng lượng và cảm xúc. Nước hoa này kết hợp sự tươi mới của hương hoa cam neroli với các yếu tố hương liệu khác, tạo nên một trải nghiệm olfactory đầy lôi cuốn và sôi động.",
          "'Flamenco Neroli' mang đến một sự kết hợp hoàn hảo giữa tươi mới và nữ tính, với các nốt hương tạo nên một bản hòa ca đầy cảm xúc. Sự kết hợp của cam bergamot, hoa cam neroli và xạ hương làm cho nước hoa này trở thành lựa chọn lý tưởng cho những ai yêu thích sự tươi mới nhưng vẫn muốn để lại ấn tượng mạnh mẽ.",
          "Hương đầu: Cam bergamot và chanh, mở ra với sự tươi mát và sắc sảo, tạo cảm giác sống động và tràn đầy sức sống.",
          "Hương giữa: Hoa cam neroli và hoa nhài, mang đến sự nữ tính, thanh thoát và quyến rũ. Hương hoa cam neroli nổi bật với sự nhẹ nhàng, tươi mới, kết hợp hài hòa với sự ngọt ngào của hoa nhài.",
          "Hương cuối: Xạ hương và hổ phách, tạo nên lớp nền ấm áp và lôi cuốn, giúp lưu hương lâu và để lại ấn tượng mạnh mẽ.",
        ],
      },
    ],
  },
  {
    id: 40,
    brand: "Narciso Rodriguez",
    logo: narciso_rodriguez,
    products: [
      {
        id: 1,
        productName: "Nar Poudree Eau de Parfum",
        price: [330000, 2500000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "vừa",
        img: [nar_poudree_edp_1, nar_poudree_edp_2],
        description: [
          "'Nar Poudree' của Narciso Rodriguez là một mùi hương quyến rũ và thanh thoát, mang đến sự kết hợp hoàn hảo giữa sự sang trọng và nhẹ nhàng. Đây là một phần của dòng nước hoa nổi tiếng Narciso Rodriguez For Her, và được thiết kế để tạo ra một ấn tượng lâu dài và đầy cảm xúc.",
          "'Nar Poudree' mang đến một sự kết hợp hoàn hảo giữa sự thanh thoát và quyến rũ, với các nốt hương tạo nên một bản giao hưởng cảm xúc đầy tinh tế. Đây là một lựa chọn lý tưởng cho những ai yêu thích sự sang trọng và lôi cuốn trong một mùi hương.",
          "Hương đầu: Bắt đầu với sự tươi mới và thanh thoát từ hương cam bergamot và hoa hồng, tạo nên một khởi đầu nhẹ nhàng và đầy sức sống.",
          "Hương giữa: Hoa nhài và xạ hương, mang đến sự nữ tính và quyến rũ với sự kết hợp của hương hoa nhài thanh thoát và xạ hương ấm áp. Sự hòa quyện này tạo ra một lớp hương trung tâm đầy mê hoặc.",
          "Hương cuối: Hương gỗ đàn hương và vani, tạo nên lớp nền ấm áp và lôi cuốn, giúp lưu giữ hương thơm lâu dài và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 2,
        productName: "Nar Ambree Eau de Parfum",
        price: [330000, 2500000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "vừa",
        img: [nar_ambree_1, nar_ambree_2],
        description: [
          "'Nar Ambree' của Narciso Rodriguez là một mùi hương đầy quyến rũ và ấm áp, thuộc bộ sưu tập Narciso Rodriguez For Her. Sản phẩm này mang đến một trải nghiệm hương thơm phong phú và tinh tế, với sự kết hợp hoàn hảo giữa sự lôi cuốn và sự ấm áp.",
          "'Nar Ambree' kết hợp sự quyến rũ của hương hoa với độ ấm của hương gỗ và vani, tạo ra một mùi hương đặc biệt và đầy lôi cuốn. Đây là một lựa chọn lý tưởng cho những ai yêu thích sự tinh tế và sang trọng trong một mùi hương.",
          "Hương đầu: Mở đầu với sự tươi mới và ngọt ngào của hương cam bergamot và hoa nhài, tạo nên một sự khởi đầu thanh thoát và quyến rũ.",
          "Hương giữa: Sự kết hợp của xạ hương và hương gỗ, mang đến một cảm giác ấm áp và lôi cuốn. Hương xạ hương nổi bật với sự quyến rũ đầy nữ tính, trong khi các nốt hương gỗ tạo ra một lớp nền vững chắc và đầy cảm xúc.",
          "Hương cuối: Hương vani và hổ phách, tạo nên lớp nền ấm áp và ngọt ngào, giúp giữ cho hương thơm lưu lại lâu và để lại một ấn tượng sâu sắc.",
        ],
      },
      {
        id: 3,
        productName: "Nar Cristal Eau de Parfum",
        price: [350000, 2700000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "vừa",
        img: [nar_cristal_edp_1, nar_cristal_edp_2],
        description: [
          "'Nar Cristal' của Narciso Rodriguez là một mùi hương tinh tế và quyến rũ, mang đến sự sáng tạo và mới mẻ trong bộ sưu tập Narciso Rodriguez For Her. Đây là sự kết hợp hoàn hảo giữa sự thanh thoát và sự quyến rũ, tạo nên một trải nghiệm hương thơm độc đáo và đầy cảm xúc.",
          "'Nar Cristal' mang đến một sự kết hợp tuyệt vời giữa sự tinh tế của hương hoa và độ ấm của hương gỗ, tạo ra một mùi hương đầy quyến rũ và phong cách. Đây là sự lựa chọn lý tưởng cho những ai yêu thích sự sáng tạo và sang trọng trong một mùi hương.",
          "Hương đầu: Mở đầu với sự tươi mới của hương cam bergamot và hoa hồng, mang lại sự thanh thoát và sự quyến rũ từ những nốt hương đầu tiên.",
          "Hương giữa: Hương xạ hương và hoa nhài, tạo nên sự lôi cuốn và sự nữ tính. Xạ hương mang đến sự ấm áp và quyến rũ, trong khi hoa nhài tạo ra một lớp hương hoa nhẹ nhàng và thanh thoát.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ và hổ phách, tạo nên một lớp nền ấm áp và lôi cuốn. Sự hòa quyện này giúp hương thơm lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 4,
        productName: "Nar All Of Me Eau de Parfum",
        price: [320000, 2800000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "vừa",
        img: [nar_all_of_me_edp_1, nar_all_of_me_edp_2],
        description: [
          "'Nar All Of Me' của Narciso Rodriguez là một mùi hương mới mẻ và quyến rũ, mang đến sự kết hợp hoàn hảo giữa sự nữ tính và sang trọng. Đây là một phần của bộ sưu tập Narciso Rodriguez For Her, nổi bật với sự tươi mới và sự ấm áp quyến rũ.",
          "'Nar All Of Me' là sự kết hợp hoàn hảo giữa sự tươi mới của trái cây, sự quyến rũ của hương hoa và sự ấm áp của hương gỗ. Đây là sự lựa chọn lý tưởng cho những ai yêu thích một mùi hương tinh tế, tự nhiên và sang trọng.",
          "Hương đầu: Mở đầu với sự tươi mới của hương cam bergamot và hương trái cây như lê và đào, mang lại một cảm giác nhẹ nhàng và quyến rũ ngay từ những nốt hương đầu tiên.",
          "Hương giữa: Được làm nổi bật bởi sự kết hợp của hương xạ hương và hoa nhài, tạo nên một lớp hương tinh tế và lôi cuốn. Xạ hương mang đến sự ấm áp và quyến rũ, trong khi hoa nhài cung cấp một lớp hương hoa thanh thoát và nữ tính.",
          "Hương cuối: Hương gỗ và hổ phách tạo nên một lớp nền ấm áp và lôi cuốn, giúp hương thơm lưu lại lâu và để lại ấn tượng sâu sắc. Sự kết hợp này mang lại cảm giác sang trọng và đầy cảm xúc.",
        ],
      },
      {
        id: 5,
        productName: "Narciso Musc Nude Eau de Parfum",
        price: [350000, 2950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [narciso_musc_nude_1, narciso_musc_nude_2],
        description: [
          "'Narciso Musc Nude' của Narciso Rodriguez là một mùi hương tinh tế và quyến rũ, thuộc bộ sưu tập Narciso Rodriguez For Her. Sản phẩm này mang đến một sự kết hợp hoàn hảo giữa sự nhẹ nhàng của xạ hương và sự ấm áp của các thành phần hương gỗ, tạo nên một mùi hương tự nhiên và đầy quyến rũ.",
          "'Narciso Musc Nude' là sự kết hợp hoàn hảo giữa sự nhẹ nhàng của hương trái cây, sự quyến rũ của xạ hương và sự ấm áp của hương gỗ. Đây là sự lựa chọn lý tưởng cho những ai yêu thích một mùi hương tinh tế, quyến rũ và tự nhiên.",
          "Hương đầu: Mở đầu với sự nhẹ nhàng và tươi mới của hương cam bergamot và hương trái cây mềm mại như lê, mang lại cảm giác dễ chịu và tươi mới ngay từ những nốt hương đầu tiên.",
          "Hương giữa: Hương xạ hương là trung tâm của mùi hương, kết hợp hoàn hảo với các nốt hương hoa nhài và hoa cam, tạo nên một lớp hương nữ tính và lôi cuốn. Xạ hương mang đến sự ấm áp và quyến rũ, trong khi các nốt hoa nhài và hoa cam làm nổi bật sự thanh thoát và sự tinh tế.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ nhẹ và hổ phách, tạo ra một lớp nền ấm áp và lôi cuốn. Sự kết hợp này giúp hương thơm lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 6,
        productName: "Narciso Fleur Musc Eau de Parfum",
        price: [320000  , 2500000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nar_fleur_musc_1, nar_fleur_musc_2],
        description: [
          "'Narciso Fleur Musc' của Narciso Rodriguez là một phiên bản mới của bộ sưu tập Narciso Rodriguez For Her, mang đến một mùi hương quyến rũ và tươi mới với sự kết hợp độc đáo giữa các thành phần hoa và xạ hương. Đây là một lựa chọn hoàn hảo cho những người yêu thích sự nữ tính và sang trọng.",
          "'Narciso Fleur Musc' là sự kết hợp hoàn hảo giữa sự tươi mới của trái cây, sự quyến rũ của xạ hương và sự nữ tính của hương hoa. Đây là sự lựa chọn lý tưởng cho những ai yêu thích một mùi hương quyến rũ, tinh tế và tươi mới.",
          "Hương đầu: Mở đầu với sự tươi mới và năng động của hương cam bergamot và hương gia vị như tiêu hồng, mang lại một sự khởi đầu tươi sáng và đầy sức sống.",
          "Hương giữa: Tinh thần của mùi hương là sự kết hợp giữa xạ hương và các nốt hoa hồng cùng hoa nhài. Xạ hương mang đến sự ấm áp và quyến rũ, trong khi hoa hồng và hoa nhài tạo ra một lớp hương hoa thanh thoát và nữ tính.",
          "Hương cuối: Kết thúc với sự ấm áp và chiều sâu từ hương gỗ và hổ phách, giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc. Sự kết hợp này mang lại cảm giác sang trọng và quyến rũ.",
        ],
      },
      {
        id: 7,
        productName: "Narciso Rodriguez For Her Eau de Toilette",
        price: [320000, 2500000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [nar_for_her_edt_1, nar_for_her_edt_2],
        description: [
          "'Narciso Rodriguez For Her Eau de Toilette' là một trong những sản phẩm nổi bật trong bộ sưu tập Narciso Rodriguez For Her, nổi bật với sự kết hợp hoàn hảo giữa xạ hương và các nốt hương hoa quyến rũ. Mùi hương của sản phẩm này mang lại sự thanh thoát và sang trọng, phù hợp cho những người yêu thích sự nữ tính và tinh tế.",
          "'Narciso Rodriguez For Her Eau de Toilette' mang đến một mùi hương quyến rũ và thanh thoát, là sự lựa chọn lý tưởng cho những ai yêu thích sự nữ tính và tinh tế. Đây là sự lựa chọn hoàn hảo cho cả những dịp đặc biệt và hàng ngày.",
          "Hương đầu: Mở đầu với sự tươi mới của hương cam bergamot và hương trái cây như lê, mang lại cảm giác dễ chịu và tươi mới ngay từ những nốt hương đầu tiên.",
          "Hương giữa: Tinh thần của mùi hương là sự kết hợp giữa xạ hương và các nốt hoa như hoa nhài và hoa hồng. Xạ hương mang đến sự quyến rũ và ấm áp, trong khi hoa nhài và hoa hồng tạo ra một lớp hương hoa thanh thoát và nữ tính.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ và hổ phách, tạo ra một lớp nền ấm áp và lôi cuốn. Sự kết hợp này giúp hương thơm lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 8,
        productName: "Narciso Rodriguez Nar Forever Eau de Parfum",
        price: [330000, 2800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nar_forever_edp_1, nar_forever_edp_2],
        description: [
          "'Narciso Rodriguez Nar Forever Eau de Parfum' là một trong những sản phẩm nổi bật của Narciso Rodriguez, mang đến một mùi hương quyến rũ và đầy cảm xúc với sự kết hợp tinh tế giữa các thành phần hoa và xạ hương. Sản phẩm này thể hiện sự sang trọng và nữ tính, phù hợp cho những dịp đặc biệt và hàng ngày.",
          "'Narciso Rodriguez Nar Forever Eau de Parfum' mang đến một mùi hương quyến rũ và tinh tế, là sự lựa chọn lý tưởng cho những ai yêu thích sự nữ tính và sang trọng. Đây là sự lựa chọn hoàn hảo cho cả những dịp đặc biệt và hàng ngày.",
          "Hương đầu: Mở đầu với sự tươi mới của hương cam bergamot và hương hoa nhài, mang lại cảm giác dễ chịu và đầy sức sống ngay từ những nốt hương đầu tiên.",
          "Hương giữa: Tinh thần của mùi hương được thể hiện qua sự kết hợp hoàn hảo giữa xạ hương và hương hoa hồng cùng hoa cam. Xạ hương mang đến sự quyến rũ và ấm áp, trong khi hoa hồng và hoa cam tạo ra một lớp hương hoa thanh thoát và nữ tính.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ, hổ phách và vanilla, tạo ra một lớp nền ấm áp và lôi cuốn. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      // {
      //   id: 9,
      //   productName: "Iconic feminine",
      //   price: [null, null],
      //   // dung tích
      //   volume: [10, 50],
      //   gender: ["male"],
      //   // nồng độ
      //   concentration: "EDT",
      // độ lưu hương
      // scent_retention: "",
      // độ toả hương
      // scent_release: "",
      //   img: [_iconic_feminine_1, _iconic_feminine_2],
      //   description: ["", "", "", "", ""],
      // },
      {
        id: 10,
        productName: "Narciso Rodriguez Musc Noir Eau de Parfum",
        price: [320000, 2500000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nar_musc_noir_edp_1, nar_musc_noir_edp_2],
        description: [
          "'Narciso Rodriguez Musc Noir Eau de Parfum' là một mùi hương đầy quyến rũ và bí ẩn, mang đến sự kết hợp độc đáo giữa các thành phần xạ hương và hương hoa. Sản phẩm này thể hiện một sự lôi cuốn đầy bí ẩn, hoàn hảo cho những người yêu thích sự nữ tính và sự sang trọng.",
          "'Narciso Rodriguez Musc Noir Eau de Parfum' là một mùi hương đầy bí ẩn và quyến rũ, mang đến sự kết hợp hoàn hảo giữa sự nữ tính và sự sang trọng. Đây là sự lựa chọn lý tưởng cho những dịp đặc biệt và những buổi tối lãng mạn.",
          "Hương đầu: Mở đầu với sự tươi mới của hương cam bergamot và hương hoa hồng, tạo nên một cảm giác tinh khiết và nhẹ nhàng ngay từ những nốt hương đầu tiên.",
          "Hương giữa: Tinh thần của mùi hương được thể hiện qua sự kết hợp nổi bật giữa xạ hương và hương hoa nhài. Xạ hương mang đến một lớp hương ấm áp và quyến rũ, trong khi hoa nhài tạo ra một sự kết hợp hài hòa và nữ tính.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ và hổ phách, tạo ra một lớp nền sâu lắng và lôi cuốn. Hương này giúp mùi hương lưu lại lâu và để lại ấn tượng mạnh mẽ.",
        ],
      },
      {
        id: 11,
        productName: "Narciso Rodriguez Musc Noir Rose Eau de Parfum",
        price: [350000, 2850000],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nar_musc_noir_rose_edp_1, nar_musc_noir_rose_edp_2],
        description: [
          "'Narciso Rodriguez Musc Noir Rose Eau de Parfum' là một phiên bản mới của dòng Musc Noir, kết hợp sự quyến rũ bí ẩn của xạ hương với sự tươi mới của hoa hồng. Đây là một mùi hương hoàn hảo cho những người yêu thích sự nữ tính và lôi cuốn.",
          "'Narciso Rodriguez Musc Noir Rose Eau de Parfum' là sự kết hợp hoàn hảo giữa sự quyến rũ bí ẩn và sự tươi mới của hoa hồng, mang đến một mùi hương đầy cuốn hút và tinh tế. Đây là sự lựa chọn lý tưởng cho những dịp đặc biệt và những buổi tối lãng mạn.",
          "Hương đầu: Mở đầu với sự tươi mới của hương cam bergamot và hương hoa hồng. Hương hoa hồng trong phiên bản này nổi bật hơn, mang đến một sự tươi mới và thanh thoát.",
          "Hương giữa: Nổi bật với sự kết hợp giữa xạ hương và hương hoa nhài, tạo nên một lớp hương quyến rũ và sâu lắng. Xạ hương mang đến sự ấm áp và lôi cuốn, trong khi hoa nhài làm tăng thêm phần nữ tính và tinh tế.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ và hổ phách, tạo ra một lớp nền ấm áp và lôi cuốn. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 12,
        productName: "Narciso Rodriguez Pure Musc Eau de Parfum",
        price: [330000, 2900000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nar_pure_musc_1, nar_pure_musc_2],
        description: [
          "'Narciso Rodriguez Pure Musc Eau de Parfum' là một phiên bản tôn vinh sự quyến rũ của xạ hương. Đây là một mùi hương thanh thoát và sang trọng, phù hợp cho những người yêu thích sự tinh tế và thu hút.",
          "'Narciso Rodriguez Pure Musc Eau de Parfum' là sự lựa chọn hoàn hảo cho những ai tìm kiếm một mùi hương tinh tế và quyến rũ, thích hợp cho cả những dịp đặc biệt và hàng ngày.",
          "Hương đầu: Mở đầu với sự nhẹ nhàng của hương cam bergamot và hoa hồng, mang lại sự tươi mới và thanh thoát.",
          "Hương giữa: Nổi bật với sự kết hợp của xạ hương và hoa nhài. Xạ hương là thành phần chủ đạo, mang đến một lớp hương ấm áp và quyến rũ, trong khi hoa nhài thêm phần nữ tính và thanh thoát.",
          "Hương cuối: Kết thúc với sự kết hợp nhẹ nhàng của hương gỗ và hổ phách, tạo nên một lớp nền sâu lắng và sang trọng. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 13,
        productName: "Narciso Rodriguez For Her Eau de Parfum",
        price: [320000, 2600000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [narciso_rodriguez_for_her_edp_1, narciso_rodriguez_for_her_edp_2],
        description: [
          "'Narciso Rodriguez For Her Eau de Parfum' là một trong những mùi hương đặc trưng của thương hiệu Narciso Rodriguez, nổi bật với sự quyến rũ và thanh lịch. Được ra mắt lần đầu vào năm 2003, sản phẩm này đã nhanh chóng trở thành một biểu tượng của sự tinh tế và nữ tính.",
          "'Narciso Rodriguez For Her Eau de Parfum' là sự lựa chọn lý tưởng cho những ai tìm kiếm một mùi hương quyến rũ, thanh lịch và đầy nữ tính, phù hợp cho cả những dịp đặc biệt và hàng ngày.",
          "Hương đầu: Mở đầu với sự nhẹ nhàng của hương cam bergamot, hương đào và hoa hồng. Sự kết hợp này mang đến một cảm giác tươi mới và dễ chịu ngay từ lần xịt đầu tiên.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của xạ hương, hoa nhài và hương gỗ. Xạ hương là thành phần chủ đạo, mang đến một lớp hương ấm áp và quyến rũ, trong khi hoa nhài thêm phần nữ tính và tinh tế.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ, hổ phách và xạ hương, tạo nên một lớp nền sâu lắng và sang trọng. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
    ],
  },
  {
    id: 41,
    brand: "Nasomatto",
    logo: nasomatto,
    products: [
      {
        id: 1,
        productName: "Nasomatto Black Afgano Eau de Parfum",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [black_afgano_1, black_afgano_2],
        description: [
          "'Nasomatto Black Afgano Eau de Parfum' là một trong những tác phẩm nổi bật của Nasomatto, nổi bật với sự bí ẩn và chiều sâu của mùi hương. Được ra mắt vào năm 2009, sản phẩm này mang đến một trải nghiệm olfactive đầy lôi cuốn và đặc biệt.",
          "'Nasomatto Black Afgano Eau de Parfum' là sự lựa chọn hoàn hảo cho những ai yêu thích sự bí ẩn và phong cách độc đáo. Mùi hương này thích hợp cho cả nam và nữ, và đặc biệt phù hợp với những dịp tối và lạnh.",
          "Hương đầu: Mở đầu với một sự kết hợp mạnh mẽ của hương resin và hương thảo dược. Mùi hương ban đầu mang đến một cảm giác sâu lắng và hơi khói.",
          "Hương giữa: Trung tâm của nước hoa là sự kết hợp giữa hương gỗ và hương khói, tạo nên một lớp hương đậm đà và quyến rũ. Hương gỗ xạ hương và hương nhựa thông làm nổi bật sự bí ẩn và chiều sâu của mùi hương.",
          "Hương cuối: Kết thúc với sự kết hợp của hương nhựa thơm, hương vani và một chút hương thuốc lá. Sự kết hợp này tạo nên một lớp nền ấm áp và lôi cuốn, lưu lại lâu trên da.",
        ],
      },
      {
        id: 2,
        productName: "Nasomatto Silver Musk Eau de Parfum",
        price: [null, null],
        // dung tích
        volume: [10, 30],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [silver_musk_1, silver_musk_2],
        description: [
          "'Nasomatto Silver Musk Eau de Parfum' là một tác phẩm nổi bật của Nasomatto, nổi bật với sự tinh tế và quyến rũ của xạ hương. Ra mắt vào năm 2010, sản phẩm này mang đến một mùi hương độc đáo, đầy sự sáng tạo và mới mẻ.",
          "'Nasomatto Silver Musk Eau de Parfum' là sự lựa chọn lý tưởng cho những ai yêu thích sự tinh tế và sáng tạo trong mùi hương. Với phong cách unisex, sản phẩm này phù hợp cho cả nam và nữ, và đặc biệt thích hợp cho những dịp hàng ngày và các sự kiện trang trọng.",
          "Hương đầu: Mở đầu với sự tươi mới của xạ hương và hương gỗ nhẹ nhàng, tạo cảm giác tươi mát và sạch sẽ ngay từ lần xịt đầu tiên.",
          "Hương giữa: Trung tâm của nước hoa là sự kết hợp hài hòa giữa xạ hương và các hương gỗ, tạo nên một lớp hương thanh thoát và đầy sức sống.",
          "Hương cuối: Kết thúc với sự hòa quyện của hương gỗ mềm mại và một chút hương nhựa thơm, tạo nên một lớp nền ấm áp và quyến rũ. Sự kết hợp này làm cho mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
    ],
  },
  {
    id: 42,
    brand: "Viktor & Rolf",
    logo: viktor_rolf,
    products: [
      {
        id: 1,
        productName: "Spicebomb Extreme Pour Homme Eau de Parfum",
        price: [300000, 2400000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [spicebomb_extreme_pour_homme_1, spicebomb_extreme_pour_homme_2],
        description: [
          "'Spicebomb Extreme Pour Homme Eau de Parfum' là phiên bản mạnh mẽ và ấn tượng hơn của dòng Spicebomb nổi tiếng của Viktor & Rolf. Ra mắt vào năm 2015, sản phẩm này mang đến một mùi hương đầy quyến rũ và lôi cuốn, phù hợp cho những người đàn ông yêu thích sự mạnh mẽ và nam tính.",
          "'Spicebomb Extreme Pour Homme Eau de Parfum' là sự lựa chọn lý tưởng cho những ai yêu thích sự mạnh mẽ và quyến rũ trong mùi hương. Sản phẩm này đặc biệt phù hợp cho các dịp đặc biệt, buổi tối và những lúc cần thể hiện sự tự tin và lôi cuốn.",
          "Hương đầu: Mở đầu với sự kết hợp đậm đà của hạt tiêu đen, quế và carmen, tạo ra một sự khởi đầu mạnh mẽ và ấm áp, mang lại cảm giác sảng khoái và kích thích.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện giữa hương hoa nhài và hương nghệ tây, kết hợp với hương đinh hương, tạo nên một lớp hương ấm áp và quyến rũ. Hương giữa mang đến một cảm giác đầy sâu lắng và cuốn hút, với sự kết hợp hoàn hảo của các gia vị và hoa.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ, hương vani và hương hổ phách, tạo ra một lớp nền ấm áp và nam tính. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc, tạo nên một dấu ấn khó quên.",
        ],
      },
    ],
  },
  {
    id: 43,
    brand: "Valentino",
    logo: valentino,
    products: [
      {
        id: 1,
        productName: "Valentino Donna Eau de Parfum",
        price: [350000, 2800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [donna_1, donna_2],
        description: [
          "'Valentino Donna Eau de Parfum' là một tác phẩm quyến rũ từ Valentino, ra mắt vào năm 2015. Đây là một sự kết hợp hoàn hảo giữa sự thanh lịch và phong cách hiện đại, mang đến một mùi hương đầy quyến rũ và tinh tế.",
          "'Valentino Donna Eau de Parfum' là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và thanh lịch trong mùi hương. Với phong cách nữ tính và sang trọng, sản phẩm này phù hợp cho các dịp đặc biệt và những buổi tối lãng mạn.",
          "Hương đầu: Mở đầu với sự kết hợp của hương cam Bergamot tươi mát và hương hoa nhài nhẹ nhàng, tạo nên một sự khởi đầu tươi mới và hấp dẫn.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện giữa hương hoa hồng và hương da thuộc, mang đến một lớp hương ấm áp và quyến rũ. Hương hoa hồng tạo sự thanh thoát, trong khi hương da thuộc thêm phần sang trọng.",
          "Hương cuối: Kết thúc với sự kết hợp của hương vani, hương gỗ và hương nhựa thơm, tạo nên một lớp nền ấm áp và lôi cuốn. Sự kết hợp này tạo cho mùi hương một độ lưu hương dài lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 2,
        productName: "Valentino Uomo Intense Eau de Parfum",
        price: [380000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [uomo_intense_1, uomo_intense_2],
        description: [
          "'Valentino Uomo Intense Eau de Parfum' là phiên bản mạnh mẽ và quyến rũ của dòng nước hoa Valentino Uomo. Ra mắt vào năm 2016, sản phẩm này mang đến một mùi hương nam tính và sâu lắng, phù hợp cho những người đàn ông yêu thích sự sang trọng và lôi cuốn.",
          "'Valentino Uomo Intense Eau de Parfum' là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và nam tính trong mùi hương. Sản phẩm này đặc biệt phù hợp cho các buổi tối đặc biệt, sự kiện quan trọng và những dịp cần sự xuất hiện nổi bật.",
          "Hương đầu: Mở đầu với sự kết hợp của hương cam Bergamot tươi mới và hương bạch đậu khấu, tạo ra một sự khởi đầu năng động và lôi cuốn.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện giữa hương da thuộc, hương hoa diên vĩ, và hương cà phê, mang đến một lớp hương ấm áp, quyến rũ và đầy sự lôi cuốn. Hương da thuộc và hoa diên vĩ kết hợp tạo nên một cảm giác sang trọng, trong khi hương cà phê thêm phần hấp dẫn và nam tính.",
          "Hương cuối: Kết thúc với sự kết hợp của hương vani, hương hổ phách và hương gỗ, tạo ra một lớp nền ấm áp, sâu lắng và đầy quyến rũ. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng mạnh mẽ.",
        ],
      },
      {
        id: 3,
        productName: "Valentino Uomo Born in Roma Eau de Parfum",
        price: [350000, 2800000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [uomo_born_in_roma_intense_1, uomo_born_in_roma_intense_2],
        description: [
          "'Valentino Uomo Born in Roma Eau de Parfum' là một biểu tượng của sự hiện đại và phong cách nam tính. Ra mắt vào năm 2019, sản phẩm này mang đến một mùi hương mạnh mẽ và quyến rũ, phản ánh tinh thần của thành phố Roma hiện đại và cổ điển.",
          "'Valentino Uomo Born in Roma Eau de Parfum' là sự lựa chọn lý tưởng cho những ai yêu thích sự hiện đại và nam tính trong mùi hương. Sản phẩm này đặc biệt phù hợp cho các dịp đặc biệt, sự kiện quan trọng và những ngày cần sự tự tin nổi bật.",
          "Hương đầu: Mở đầu với sự kết hợp của hương bạc hà và hương chanh tươi mát, tạo ra một sự khởi đầu đầy năng lượng và sảng khoái.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện giữa hương da thuộc, hương hoa nhài và hương hạt tiêu, mang đến một lớp hương ấm áp, quyến rũ và lôi cuốn. Hương da thuộc và hoa nhài tạo nên một cảm giác sang trọng, trong khi hương hạt tiêu thêm phần nam tính và mạnh mẽ.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ, hương vani và hương hổ phách, tạo ra một lớp nền ấm áp và đầy quyến rũ. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 4,
        productName: "Valentino Uomo Born in Roma Intense Eau de Parfum",
        price: [380000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [uomo_born_in_roma_intense_1, uomo_born_in_roma_intense_2],
        description: [
          "'Valentino Uomo Born in Roma Intense Eau de Parfum' là phiên bản đậm chất hơn của dòng nước hoa Uomo Born in Roma, mang đến một mùi hương mạnh mẽ và quyến rũ hơn. Ra mắt vào năm 2020, sản phẩm này phản ánh sự sang trọng và lôi cuốn của thành phố Roma, với một mùi hương đầy quyến rũ và tinh tế.",
          "'Valentino Uomo Born in Roma Intense Eau de Parfum' là sự lựa chọn lý tưởng cho những ai yêu thích sự nam tính và quyến rũ trong mùi hương. Sản phẩm này đặc biệt phù hợp cho các dịp đặc biệt, buổi tối và những lúc cần thể hiện sự tự tin và lôi cuốn.",
          "Hương đầu: Mở đầu với sự kết hợp mạnh mẽ của hương chanh tươi mát và hương cam Bergamot, tạo ra một sự khởi đầu đầy sức sống và năng lượng.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện giữa hương da thuộc, hương hoa nhài và hương hạt tiêu, mang đến một lớp hương ấm áp, quyến rũ và đầy lôi cuốn. Hương da thuộc và hoa nhài kết hợp tạo nên một cảm giác sang trọng và thanh lịch, trong khi hương hạt tiêu thêm phần nam tính và mạnh mẽ.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ, hương vani và hương hổ phách, tạo ra một lớp nền ấm áp và quyến rũ. Sự kết hợp này giúp mùi hương lưu lại lâu và để lại ấn tượng sâu sắc.",
        ],
      },
      {
        id: 5,
        productName: "Valentino Uomo Born in Roma EDT",
        price: [300000, 2400000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [uomo_born_in_roma_edt_1, uomo_born_in_roma_edt_2],
        description: [
          "'Uomo Born in Roma' của Valentino mang đến một mùi hương hiện đại và tinh tế dành cho những người đàn ông mạnh mẽ, tự tin.",
          "Thiết kế chai mang đậm phong cách cổ điển nhưng vẫn toát lên vẻ hiện đại và mạnh mẽ, phù hợp với mọi hoàn cảnh.",
          "Hương đầu: Hương cam chanh tươi mát, kết hợp cùng gia vị cay nhẹ, tạo cảm giác sảng khoái ngay từ lần đầu tiếp xúc.",
          "Hương giữa: Pha trộn giữa hoa oải hương và hương thảo, mang lại sự nam tính và cuốn hút.",
          "Hương cuối: Gỗ quý và xạ hương ấm áp, tạo nên dấu ấn lâu dài và lôi cuốn.",
        ],
      },
    ],
  },
  {
    id: 44,
    brand: "Imaginary Authors",
    logo: imaginary_authors,
    products: [
      {
        id: 1,
        productName: "Falling Into The Sea",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [ia_falling_into_the_sea_1, ia_falling_into_the_sea_2],
        description: [
          "'Falling Into The Sea' từ Imaginary Authors là một mùi hương tươi mát và lôi cuốn, gợi lên cảm giác của một chuyến đi lãng mạn bên bờ biển. Ra mắt vào năm 2013, sản phẩm này mang đến một trải nghiệm hương thơm độc đáo và quyến rũ, với sự kết hợp hoàn hảo của các yếu tố tự nhiên và sáng tạo.",
          "'Falling Into The Sea' là một sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp giữa tự nhiên và sự quyến rũ trong mùi hương. Sản phẩm này phù hợp cho cả ngày thường và các dịp đặc biệt, mang đến cảm giác nhẹ nhàng và thư giãn.",
          "Hương đầu: Mở đầu với sự kết hợp tươi mát của chanh xanh và hương vị của nước biển, tạo nên một cảm giác sảng khoái và làm mới. Đây là lớp hương tạo nên một sự khởi đầu nhẹ nhàng và dễ chịu, gợi lên hình ảnh của những làn sóng biển trong làn nắng.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của hương dứa và hương vỏ cây, mang lại một cảm giác ngọt ngào và thư giãn. Hương giữa này phản ánh sự hòa quyện tự nhiên giữa biển và cỏ cây, tạo ra một lớp hương ấm áp và dễ chịu.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ và hương xạ hương, tạo ra một lớp nền sâu lắng và nam tính. Hương cuối này mang đến sự bền bỉ và lâu dài, với một chút hương gỗ giúp tạo ra một dấu ấn ấn tượng.",
        ],
      },
    ],
  },
  {
    id: 45,
    brand: "Loewe",
    logo: loewe,
    products: [
      {
        id: 1,
        productName: "Loewe 001 Woman EDT",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [loewe_001_woman_edt_1, loewe_001_woman_edt_2],
        description: [
          "'001 Woman EDT' từ Loewe là một mùi hương tươi mới và quyến rũ, mang đến cảm giác thanh thoát và tinh tế. Ra mắt vào năm 2016, sản phẩm này là sự kết hợp hoàn hảo giữa sự hiện đại và cổ điển, phản ánh sự thanh lịch và tự tin của người phụ nữ hiện đại.",
          "'001 Woman EDT' là một sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp giữa tươi mới và quyến rũ trong mùi hương. Sản phẩm này phù hợp cho cả ngày thường và các dịp đặc biệt, mang đến cảm giác nhẹ nhàng và tự tin.",
          "Hương đầu: Mở đầu với sự tươi mát của cam bergamot và hương chanh, tạo nên một cảm giác mới mẻ và dễ chịu. Sự kết hợp này mang lại một lớp hương đầu năng động và đầy sức sống, phản ánh sự tươi mới của một ngày mới.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của hương hoa nhài và hương hạnh nhân, mang lại một cảm giác quyến rũ và lôi cuốn. Hương giữa này tạo ra một lớp hương ấm áp và dễ chịu, phản ánh sự tinh tế và nữ tính.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ đàn hương và hương xạ hương, tạo ra một lớp nền bền bỉ và quyến rũ. Hương cuối này mang đến sự ấm áp và chiều sâu, với một chút hương gỗ giúp tạo ra một dấu ấn ấn tượng.",
        ],
      },
    ],
  },
  {
    id: 46,
    brand: "Franck Boclet",
    logo: franck_boclet,
    products: [
      {
        id: 1,
        productName: "Cocaïne",
        price: [350000, 2700000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [cocaine_1, cocaine_2],
        description: [
          "'Cocaïne' của Franck Boclet là một nước hoa nam đầy cá tính và quyến rũ, mang đến một trải nghiệm hương thơm mạnh mẽ và lôi cuốn. Ra mắt vào năm 2013, sản phẩm này là sự kết hợp hoàn hảo giữa sự hiện đại và quyến rũ, phản ánh phong cách sống đầy năng lượng và tự tin.",
          "'Cocaïne' là sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp giữa năng lượng và quyến rũ trong mùi hương. Sản phẩm này phù hợp cho cả ngày thường và các dịp đặc biệt, mang đến một trải nghiệm hương thơm độc đáo và lôi cuốn.",
          "Hương đầu: Mở đầu với sự kết hợp của cam bergamot và hương tiêu đen, tạo nên một lớp hương đầu đầy sức sống và tươi mới. Sự kết hợp này mang đến một cảm giác tươi tắn và năng động, khiến bạn cảm thấy tràn đầy năng lượng và sự tự tin.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của hương hoa nhài, hương da thuộc và hương quế, mang đến một lớp hương quyến rũ và lôi cuốn. Sự kết hợp này tạo ra một cảm giác ấm áp và quyến rũ, phản ánh sự cá tính và phong cách của người dùng.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ đàn hương, hương xạ hương và hương hoắc hương, tạo ra một lớp nền bền bỉ và sâu lắng. Hương cuối này mang đến sự ấm áp và chiều sâu, với một chút hương gỗ giúp tạo ra một dấu ấn mạnh mẽ và ấn tượng.",
        ],
      },
    ],
  },
  {
    id: 47,
    brand: "Alaïa",
    logo: alaia,
    products: [
      {
        id: 1,
        productName: "Alaïa Eau de Parfum",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [alaia_edp_1, alaia_edp_2],
        description: [
          "'Alaïa' Eau de Parfum của Alaïa là một tác phẩm nghệ thuật trong thế giới nước hoa, mang đến một hương thơm quyến rũ và sang trọng. Ra mắt vào năm 2015, nước hoa này là sự kết hợp hoàn hảo giữa sự thanh lịch và sự lôi cuốn, phản ánh phong cách tinh tế và sang trọng của thương hiệu Alaïa.",
          "'Alaïa' Eau de Parfum là sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp giữa quyến rũ và sang trọng trong mùi hương. Sản phẩm này phù hợp cho cả ngày thường và các dịp đặc biệt, mang đến một trải nghiệm hương thơm độc đáo và lôi cuốn.",
          "Hương đầu: Mở đầu với sự kết hợp của hương tiêu đen và hương cam bergamot, tạo nên một lớp hương đầu tươi mới và sống động. Sự kết hợp này mang đến một cảm giác sảng khoái và năng động, đồng thời kích thích các giác quan của bạn.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của hương hoa nhài, hương hoa hồng và hương iris, tạo ra một lớp hương quyến rũ và lôi cuốn. Hương giữa này mang đến sự thanh lịch và sự mềm mại, phản ánh sự nữ tính và quyến rũ của người dùng.",
          "Hương cuối: Kết thúc với sự kết hợp của hương xạ hương, hương gỗ đàn hương và hương hoắc hương, tạo ra một lớp nền bền bỉ và sâu lắng. Hương cuối này mang đến sự ấm áp và chiều sâu, tạo ra một dấu ấn mạnh mẽ và ấn tượng.",
        ],
      },
    ],
  },
  {
    id: 48,
    brand: "Tamburins",
    logo: tamburins,
    products: [
      {
        id: 1,
        productName: "CHAMO",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [chamo_1, chamo_2],
        description: [
          "'CHAMO' của Tamburins là một hương nước hoa mang phong cách hiện đại và độc đáo, phù hợp cho cả nam và nữ. Ra mắt trong bộ sưu tập nước hoa của Tamburins, 'CHAMO' kết hợp giữa các thành phần tự nhiên và thiết kế sáng tạo, tạo ra một trải nghiệm hương thơm tươi mới và quyến rũ.",
          "'CHAMO' của Tamburins là lựa chọn lý tưởng cho những ai yêu thích sự tươi mới và quyến rũ trong mùi hương. Sản phẩm này phù hợp cho mọi dịp, từ những buổi sáng làm việc đến các buổi tối đặc biệt, mang đến một hương thơm độc đáo và ấn tượng.",
          "Hương đầu: Mở đầu với sự kết hợp của các nốt hương chanh tươi mới và hương cam bergamot, tạo nên một lớp hương đầu sống động và tràn đầy sức sống. Sự tươi mới của các nốt hương này mang đến một cảm giác sảng khoái và năng lượng.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của hương hoa nhài, hương hoa hồng và hương hoa oải hương, tạo ra một lớp hương giữa quyến rũ và thanh thoát. Hương giữa này phản ánh sự mềm mại và sự nữ tính, làm nổi bật sự thanh lịch và lôi cuốn.",
          "Hương cuối: Kết thúc với sự kết hợp của hương xạ hương, hương gỗ đàn hương và hương hoắc hương, tạo ra một lớp nền bền bỉ và sâu lắng. Hương cuối này mang đến một sự ấm áp và chiều sâu, để lại một ấn tượng mạnh mẽ và lâu dài.",
        ],
      },
    ],
  },
  {
    id: 49,
    brand: "Orto Parisi",
    logo: orto_parisi,
    products: [
      {
        id: 1,
        productName: "Megamare",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [megamare_1, megamare_2],
        description: [
          "'Megamare' của Orto Parisi là một tác phẩm nghệ thuật trong thế giới nước hoa, mang đến một trải nghiệm hương thơm tươi mới và đầy sức sống. Sản phẩm này được thiết kế dành cho cả nam và nữ, kết hợp giữa sự tươi mới của biển cả và sự quyến rũ của các yếu tố tự nhiên.",
          "'Megamare' của Orto Parisi là sự lựa chọn hoàn hảo cho những ai yêu thích sự kết hợp giữa sự tươi mới của biển cả và sự quyến rũ của hương thơm tự nhiên. Đây là một nước hoa phù hợp cho các dịp hàng ngày và những sự kiện đặc biệt, mang đến một cảm giác tươi mới và đầy sự sống.",
          "Hương đầu: Mở đầu với sự kết hợp của các nốt hương tươi mới từ cam chanh, bạc hà và dưa hấu, tạo ra một lớp hương đầu sảng khoái và đầy năng lượng. Những nốt hương này mang đến một cảm giác mát lạnh và sảng khoái như làn sóng vỗ về.",
          "Hương giữa: Trung tâm của nước hoa là sự hòa quyện của hương biển, hương muối biển và các nốt hương thực vật như cỏ xanh và lá bạc hà, tạo nên một lớp hương giữa mát mẻ và dễ chịu. Đây là một sự phản ánh của sự tươi mới và sự phong phú của thiên nhiên biển cả.",
          "Hương cuối: Kết thúc với sự kết hợp của hương gỗ nhẹ nhàng, xạ hương và một chút hương hổ phách, tạo ra một lớp nền bền bỉ và sâu lắng. Hương cuối này mang đến sự ấm áp và chiều sâu, để lại một dấu ấn quyến rũ và lâu dài.",
        ],
      },
      {
        id: 2,
        productName: "Bergamask",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [bergamask_1, bergamask_2],
        description: [
          "'Bergamask' của Orto Parisi là một tác phẩm nước hoa độc đáo, kết hợp giữa sự tươi mới của cam bergamot và sự ấm áp của hương mask. Đây là một sản phẩm dành cho cả nam và nữ, mang đến một trải nghiệm hương thơm quyến rũ và đầy sự sang trọng.",
          "'Bergamask' của Orto Parisi là sự lựa chọn hoàn hảo cho những ai yêu thích sự kết hợp giữa sự tươi mới của cam bergamot và sự quyến rũ của hương mask. Đây là một nước hoa phù hợp cho các dịp hàng ngày và những sự kiện đặc biệt, mang đến một cảm giác sang trọng và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và các nốt hương citrus khác, tạo ra một lớp hương đầu tươi mới và sáng sủa. Những nốt hương này mang đến một cảm giác sảng khoái và đầy năng lượng, như làn gió mới trong một ngày hè nóng bức.",
          "Hương giữa: Trung tâm của nước hoa là sự kết hợp của các nốt hương hoa nhẹ nhàng, như hoa nhài và hoa hồng, hòa quyện với một chút hương gỗ và gia vị. Đây là một lớp hương giữa phong phú và ấm áp, tạo nên một sự cân bằng hoàn hảo giữa sự tươi mới và sự quyến rũ.",
          "Hương cuối: Kết thúc với hương mask, xạ hương và hương gỗ, tạo ra một lớp nền bền bỉ và sâu lắng. Hương cuối này mang đến sự ấm áp và chiều sâu, để lại một dấu ấn quyến rũ và lâu dài.",
        ],
      },
      {
        id: 3,
        productName: "Seminalis",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [seminalis_1, seminalis_2],
        description: [
          "'Seminalis' của Orto Parisi là một sáng tạo nước hoa độc đáo, mang đến một trải nghiệm hương thơm đầy bí ẩn và gợi cảm. Sản phẩm này được thiết kế để phù hợp với cả nam và nữ, với một hương thơm có khả năng tạo nên ấn tượng mạnh mẽ và lâu dài.",
          "'Seminalis' của Orto Parisi là sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp giữa sự tươi mới, bí ẩn và gợi cảm. Đây là một nước hoa phù hợp cho cả những dịp hàng ngày lẫn các sự kiện đặc biệt, mang đến một cảm giác đầy sức hút và cá tính.",
          "Hương đầu: Mở ra với sự kết hợp của các nốt hương tươi mới và nồng nàn, tạo nên một lớp hương đầu đầy kích thích và lôi cuốn. Các thành phần như gia vị và trái cây nhiệt đới mang đến sự tươi mới và năng lượng cho khứu giác.",
          "Hương giữa: Hương giữa của 'Seminalis' là sự pha trộn tinh tế của các nốt hương hoa và gia vị, cùng với một chút hương gỗ ấm áp. Đây là lớp hương thể hiện sự tinh tế và chiều sâu, làm nổi bật sự gợi cảm và bí ẩn của nước hoa.",
          "Hương cuối: Kết thúc với một lớp hương ấm áp và quyến rũ của xạ hương, hương gỗ và một chút hương nhựa. Hương cuối này mang đến một cảm giác bền bỉ và ấn tượng, lưu lại dấu ấn mạnh mẽ trên cơ thể.",
        ],
      },
    ],
  },
  {
    id: 50,
    brand: "Chanel",
    logo: chanel,
    products: [
      {
        id: 1,
        productName: "Chanel COCO Mademoiselle EDP",
        price: [450000, 4300000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [chanel_coco_mademoiselle_edp_1, chanel_coco_mademoiselle_edp_2],
        description: [
          "'COCO Mademoiselle' của Chanel là một biểu tượng trong thế giới nước hoa, mang đến một hương thơm quyến rũ và thanh lịch. Sản phẩm này được thiết kế để phản ánh sự tự tin và cá tính của người phụ nữ hiện đại, kết hợp giữa sự tươi mới và gợi cảm.",
          "'COCO Mademoiselle' của Chanel là sự lựa chọn hoàn hảo cho những dịp đặc biệt cũng như hàng ngày. Đây là một nước hoa đa năng, phù hợp cho cả những buổi tối lãng mạn và các cuộc họp quan trọng.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và quýt, tạo nên một lớp hương đầu nhẹ nhàng và sống động. Sự kết hợp này mang đến cảm giác thanh thoát và sảng khoái ngay từ lần xịt đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'COCO Mademoiselle' là sự hòa quyện hoàn hảo của hoa hồng, nhài và hoa nhung tuyết. Những nốt hương hoa này tạo nên một sự mềm mại và nữ tính, đồng thời mang đến một sự quyến rũ và tinh tế.",
          "Hương cuối: Kết thúc với một lớp hương ấm áp và sang trọng của xạ hương, hoắc hương và vani. Hương cuối này lưu lại dấu ấn lâu dài và mang đến cảm giác quyến rũ và cuốn hút.",
        ],
      },
      {
        id: 2,
        productName: "Chanel No5 L'eau EDT",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [chanel_no5_leau_edt_1, chanel_no5_leau_edt_2],
        description: [
          "'No5 L'eau' của Chanel là một phiên bản hiện đại và tươi mới của biểu tượng nước hoa 'No5' nổi tiếng. Được ra mắt để làm mới hình ảnh của một trong những nước hoa huyền thoại nhất thế giới, 'No5 L'eau' mang đến một sự kết hợp hoàn hảo giữa sự tinh tế và hiện đại.",
          "'No5 L'eau' của Chanel là sự lựa chọn lý tưởng cho những ai yêu thích sự thanh lịch và tinh tế, với hương thơm nhẹ nhàng và tươi mới phù hợp cho mọi dịp.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh và cam bergamot, tạo nên một cảm giác sảng khoái và năng động. Những nốt hương này giúp làm mới và tinh thần ngay từ lần xịt đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'No5 L'eau' là sự hòa quyện nhẹ nhàng của hoa nhài, hoa hồng và hoa cam. Các nốt hương hoa này tạo nên một sự tinh tế và nữ tính, làm nổi bật vẻ đẹp thanh lịch của nước hoa.",
          "Hương cuối: Kết thúc với một lớp hương nền nhẹ nhàng của xạ hương và hương gỗ. Những nốt hương này giúp tạo ra một sự kết thúc thanh thoát và đầy lôi cuốn.",
        ],
      },
      {
        id: 3,
        productName: "Chanel Coco Noir",
        price: [430000, 4200000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [chanel_coco_noir_1, chanel_coco_noir_2],
        description: [
          "'Coco Noir' của Chanel là một trong những sự lựa chọn quyến rũ và bí ẩn nhất từ thương hiệu danh tiếng này. Được thiết kế cho những người phụ nữ tự tin và đầy sức hút, 'Coco Noir' mang đến một hương thơm đậm chất cổ điển nhưng vẫn vô cùng hiện đại.",
          "'Coco Noir' của Chanel là một sự lựa chọn hoàn hảo cho những dịp đặc biệt và buổi tối, với hương thơm quyến rũ và đầy bí ẩn, mang lại sự tự tin và phong cách cho người sử dụng.",
          "Hương đầu: Mở đầu với sự nồng nàn của hương bưởi và cam bergamot, kết hợp với sự quyến rũ của tiêu đen. Những nốt hương này tạo nên một sự khởi đầu mạnh mẽ và đầy lôi cuốn.",
          "Hương giữa: Lớp hương giữa của 'Coco Noir' bao gồm hoa hồng, hoa nhài và hoa cam. Đây là một sự kết hợp tinh tế giữa những nốt hương hoa cổ điển, tạo ra một cảm giác nữ tính và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, hương gỗ, vani và hổ phách. Những nốt hương này tạo ra một sự kết thúc ấm áp và đầy bí ẩn, lưu lại ấn tượng mạnh mẽ và lâu dài.",
        ],
      },
      {
        id: 4,
        productName: "Chanel Gabrielle",
        price: [420000, 3850000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [chanel_gabrielle_edp_1, chanel_gabrielle_edp_2],
        description: [
          "'Gabrielle' của Chanel là một trong những mùi hương mới nhất từ thương hiệu danh tiếng, được đặt tên theo tên thật của nhà sáng lập Gabrielle Chanel. Đây là một sự lựa chọn hoàn hảo cho những người phụ nữ yêu thích sự tươi mới, tinh tế và quyến rũ.",
          "'Gabrielle' của Chanel mang đến một mùi hương thanh thoát và tươi mới, phù hợp với mọi dịp trong ngày, từ công việc đến những buổi tối đặc biệt. Đây là sự lựa chọn lý tưởng cho những ai yêu thích sự nhẹ nhàng, tinh tế và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới và sảng khoái của cam bergamot và quả lý chua đen, mang lại cảm giác tựa như làn gió mát trong mùa hè.",
          "Hương giữa: Lớp hương giữa của 'Gabrielle' bao gồm hoa nhài, hoa cam và hoa mộc lan. Đây là sự kết hợp tinh tế của các nốt hương hoa, mang lại sự nhẹ nhàng, quyến rũ và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ đàn hương và vani. Những nốt hương này tạo ra một sự kết thúc ấm áp và đầy quyến rũ, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 5,
        productName: "Chanel Chance Eau Tendre",
        price: [450000, 3950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [chanel_chance_eau_tendre_edt_1, chanel_chance_eau_tendre_edt_2],
        description: [
          "'Chanel Chance Eau Tendre' là một trong những phiên bản nổi bật của dòng Chance từ Chanel, mang đến một hương thơm nhẹ nhàng, tươi mới và quyến rũ. Đây là lựa chọn lý tưởng cho những người phụ nữ yêu thích sự nhẹ nhàng và thanh thoát trong nước hoa.",
          "'Chanel Chance Eau Tendre' mang đến một hương thơm nhẹ nhàng và thanh thoát, phù hợp cho cả những ngày thường và các dịp đặc biệt. Đây là lựa chọn lý tưởng cho những ai yêu thích sự tươi mới, quyến rũ và dịu dàng trong nước hoa.",
          "Hương đầu: Mở đầu với sự tươi mới của bưởi và quả hồng. Sự kết hợp này mang lại một cảm giác sảng khoái và năng động ngay từ những giây phút đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'Chance Eau Tendre' bao gồm hoa nhài và hoa hồng, tạo nên một sự hòa quyện tinh tế và thanh thoát. Những nốt hương hoa này mang lại sự quyến rũ nhẹ nhàng và dịu dàng.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ tuyết tùng và xạ hương, tạo ra một sự kết thúc ấm áp và dễ chịu. Những nốt hương này giúp nước hoa lưu lại lâu và tạo cảm giác dễ chịu.",
        ],
      },
      {
        id: 6,
        productName: "Chanel Allure Homme Sport EDT",
        price: [390000, 3650000],
        // dung tích
        volume: [10, 50],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "vừa",
        img: [chanel_allure_homme_sport_edt_1, chanel_allure_homme_sport_edt_2],
        description: [
          "'Chanel Allure Homme Sport' là một lựa chọn lý tưởng cho những người đàn ông yêu thích sự năng động, thể thao và tươi mới. Nước hoa này mang đến một hương thơm nam tính, đầy sức sống, phù hợp cho những hoạt động thể thao cũng như những buổi gặp gỡ hàng ngày.",
          "'Chanel Allure Homme Sport' là sự lựa chọn hoàn hảo cho những người đàn ông yêu thích sự tươi mới và năng động, phù hợp cho cả những hoạt động thể thao và các dịp thường ngày. Nước hoa này mang đến một sự kết hợp hoàn hảo giữa sự tươi mới, ấm áp và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh và cam, kết hợp với hương bạc hà và gừng, tạo nên một cảm giác sảng khoái và năng động ngay từ những giây phút đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'Allure Homme Sport' bao gồm sự hòa quyện của hoa nhài và hương hồ tiêu, mang lại sự ấm áp và quyến rũ, tạo nên một cảm giác nam tính và đầy cuốn hút.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ tuyết tùng, hổ phách và xạ hương, tạo ra một sự kết thúc ấm áp, bền lâu và đầy sức sống. Những nốt hương này giúp nước hoa lưu lại lâu và mang lại cảm giác dễ chịu.",
        ],
      },
      {
        id: 7,
        productName: "Chanel Allure Homme Sport Extreme EDT",
        price: [450000, 3950000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "vừa",
        img: [
          chanel_allure_homme_sport_extreme_1,
          chanel_allure_homme_sport_extreme_2,
        ],
        description: [
          "Chanel Allure Homme Sport Extreme' là phiên bản mạnh mẽ và cuốn hút của dòng 'Allure Homme Sport'. Được thiết kế dành riêng cho những người đàn ông yêu thích sự mạnh mẽ và khao khát vượt qua mọi giới hạn, nước hoa này mang đến một hương thơm tươi mới và đầy năng lượng.",
          "Chanel Allure Homme Sport Extreme' là sự lựa chọn hoàn hảo cho những người đàn ông khao khát sự mạnh mẽ và tự tin. Nước hoa này không chỉ phù hợp cho các hoạt động thể thao mà còn cho những dịp đặc biệt khi bạn muốn nổi bật và thu hút.",
          "Hương đầu: Mở đầu với sự tươi mới và năng động của chanh, cam bergamot và bạch đậu khấu, tạo nên một cảm giác sảng khoái và hứng khởi ngay từ những giây phút đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'Allure Homme Sport Extreme' bao gồm sự kết hợp hoàn hảo của tiêu đen, gừng và hoa nhài. Những nốt hương này mang đến sự ấm áp và nam tính, làm nổi bật vẻ quyến rũ và cường độ mạnh mẽ của sản phẩm.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ tuyết tùng, hổ phách và xạ hương, tạo ra một sự kết thúc ấm áp và bền lâu. Những nốt hương này không chỉ làm tăng cường sự nam tính mà còn giúp nước hoa lưu lại lâu và mang lại cảm giác dễ chịu.",
        ],
      },
      {
        id: 8,
        productName: "Chanel Bleu de Chanel EDP",
        price: [450000, 3950000],
        // dung tích
        volume: [10, 150],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-10",
        // độ toả hương
        scent_release: "xa",
        img: [chanel_bleu_de_chanel_edp_1, chanel_bleu_de_chanel_edp_2],
        description: [
          "'Chanel Bleu de Chanel EDP' là một trong những dòng nước hoa nam đình đám của Chanel, mang đến sự kết hợp hoàn hảo giữa sự nam tính mạnh mẽ và sự tinh tế thanh lịch. Phiên bản Eau de Parfum này mang đến một hương thơm phong phú và bền lâu, phù hợp cho cả những dịp hàng ngày và các sự kiện đặc biệt.",
          "'Chanel Bleu de Chanel EDP' là lựa chọn hoàn hảo cho những người đàn ông tự tin, hiện đại và yêu thích sự tinh tế trong phong cách. Với khả năng lưu hương lâu và tạo dấu ấn mạnh mẽ, sản phẩm này giúp bạn khẳng định bản thân và nổi bật trong mọi tình huống.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh và cam bergamot, kết hợp với sự cay nồng của tiêu đen. Lớp hương đầu tạo nên một cảm giác tươi mới và quyến rũ ngay từ lần xịt đầu tiên.",
          "Hương giữa: Lớp hương giữa bao gồm sự kết hợp của gỗ tuyết tùng, nhục đậu khấu và hoa nhài. Những nốt hương này mang đến sự ấm áp và nam tính, làm nổi bật vẻ quyến rũ và sự trưởng thành của người dùng.",
          "Hương cuối: Lớp hương nền của 'Bleu de Chanel EDP' gồm sự hòa quyện của gỗ đàn hương, hổ phách và xạ hương. Những nốt hương này tạo nên một dấu ấn lâu dài và đầy lôi cuốn, làm tăng cường sự bền bỉ và nam tính của sản phẩm.",
        ],
      },
      // {
      //   id: 9,
      //   productName: "Chanel Chance Eau Tendre Eau de Parfum",
      //   price: [500000, 4500000],
      //   // dung tích
      //   volume: [10, 100],
      //   gender: ["female"],
      //   // nồng độ
      //   concentration: "EDP",
      //   // độ lưu hương
      //   scent_retention: "6-8",
      //   // độ toả hương
      //   scent_release: "gần",
      //   img: [chanel_bleu_de_chanel_edp_1, chanel_bleu_de_chanel_edp_2],
      //   description: [
      //     "'Chance Eau Tendre' của Chanel là một hương thơm tươi trẻ và đầy nữ tính, dành cho những cô gái yêu thích sự dịu dàng và lãng mạn.",
      //     "'Chance Eau Tendre' là lựa chọn hoàn hảo cho những dịp hàng ngày, mang đến sự nhẹ nhàng và quyến rũ, giúp bạn tự tin và rạng rỡ trong mọi khoảnh khắc. Thiết kế chai hình tròn mềm mại thể hiện sự tinh tế, hoàn hảo cho những cô gái trẻ trung và yêu đời.",
      //     "Hương đầu: Bưởi và quả mộc qua mang lại cảm giác tươi mát và nhẹ nhàng ngay từ lần xịt đầu tiên.",
      //     "Hương giữa: Hoa nhài và hoa hồng tinh tế, làm nổi bật nét nữ tính và ngọt ngào.",
      //     "Hương cuối: Xạ hương và hổ phách ấm áp, để lại dấu ấn lâu dài và quyến rũ.",
      //   ],
      // },
      {
        id: 10,
        productName: "Chanel Bleu de Chanel Parfum",
        price: [480000, 4350000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "12-14",
        // độ toả hương
        scent_release: "xa",
        img: [chanel_bleu_de_chanel_parfum_1, chanel_bleu_de_chanel_parfum_2],
        description: [
          "'Bleu de Chanel Parfum' là hương thơm mạnh mẽ và cuốn hút, đại diện cho sự nam tính và tự do.",
          "'Bleu de Chanel Parfum' là biểu tượng của sự sang trọng và phong cách hiện đại, phù hợp cho những dịp đặc biệt, tạo nên sức hút khó cưỡng cho phái mạnh.",
          "Hương đầu: Cam Bergamot và chanh vàng mang đến cảm giác tươi mát và sảng khoái.",
          "Hương giữa: Gỗ đàn hương, gỗ tuyết tùng và nhục đậu khấu tạo nên sự ấm áp và sâu lắng.",
          "Hương cuối: Gỗ đàn hương nồng nàn kết hợp với hổ phách và gỗ tuyết tùng, để lại một ấn tượng mạnh mẽ và lâu dài.",
        ],
      },
    ],
  },
  {
    id: 51,
    brand: "Prada",
    logo: prada,
    products: [
      {
        id: 1,
        productName: "Prada Paradoxe EDP",
        price: [380000, 3200000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [prada_paradoxe_edp_1, prada_paradoxe_edp_2],
        description: [
          "'Prada Paradoxe EDP' là dòng nước hoa nữ hiện đại và sang trọng từ Prada, dành cho những phụ nữ yêu thích sự phá cách và muốn thể hiện cá tính riêng biệt của mình. Mùi hương này là sự cân bằng hoàn hảo giữa sự tươi mát, ngọt ngào và ấm áp, tạo nên một trải nghiệm đầy cảm xúc.",
          "'Prada Paradoxe EDP' là sự kết hợp độc đáo giữa truyền thống và hiện đại, tạo nên một hương thơm đầy cảm xúc và sâu lắng, phù hợp với những phụ nữ tự tin, độc lập và luôn muốn khẳng định bản thân.",
          "Hương đầu: Mở ra với sự tươi mới đầy sảng khoái của cam bergamot và quả lê. Lớp hương đầu này tạo nên sự nhẹ nhàng và ngọt ngào, làm người dùng cảm thấy sảng khoái ngay từ cái nhìn đầu tiên.",
          "Hương giữa: Trung tâm của Prada Paradoxe là sự hòa quyện giữa hoa cam, hoa nhài, và xạ hương. Những nốt hương này mang đến sự nữ tính, quyến rũ và tinh tế, làm nổi bật vẻ đẹp dịu dàng nhưng không kém phần mạnh mẽ của người phụ nữ.",
          "Hương cuối: Lớp nền là sự pha trộn giữa vani, nhựa amber và gỗ đàn hương. Các nốt hương ấm áp và sâu lắng này giúp nước hoa lưu giữ lâu hơn, đồng thời mang lại cảm giác thanh lịch và sang trọng.",
        ],
      },
    ],
  },
  {
    id: 52,
    brand: "Maison Margiela Replica",
    logo: maison_margiela_replica,
    products: [
      {
        id: 1,
        productName: "Matcha Meditation",
        price: [380000, 3100000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [matcha_edition_1, matcha_edition_2],
        description: [
          "'Matcha Meditation' thuộc bộ sưu tập Replica của Maison Margiela, nổi tiếng với những hương thơm gợi nhớ đến những kỷ niệm đẹp và cảm xúc thư thái. Nước hoa này lấy cảm hứng từ thiền trà đạo Nhật Bản, nơi matcha đóng vai trò quan trọng trong việc tạo ra không gian thư giãn và tĩnh lặng.",
          "'Matcha Meditation' là sự kết hợp hoàn hảo giữa hương trà xanh matcha tươi mát và sự ấm áp của sô-cô-la trắng, tạo nên một trải nghiệm hương thơm độc đáo và dễ chịu, phù hợp cho cả nam và nữ.",
          "Hương đầu: Lớp hương mở đầu là sự tươi mát, sảng khoái của cam bergamot, hương cam và quýt, tạo nên cảm giác nhẹ nhàng và trong trẻo như ánh bình minh buổi sáng.",
          "Hương giữa: Trọng tâm của 'Matcha Meditation' chính là nốt trà xanh matcha kết hợp với hoa cam và hoa nhài, mang lại sự tinh khiết, thanh thoát và tĩnh lặng. Hương trà xanh matcha là yếu tố chủ đạo, tạo nên cảm giác thư giãn, yên bình, như đang thưởng thức một tách trà matcha ấm áp.",
          "Hương cuối: Kết thúc bằng hương ấm áp của sô-cô-la trắng, nhựa amber và gỗ tuyết tùng, mang đến sự ngọt ngào, sâu lắng và tạo nên độ bền mùi lâu dài.",
        ],
      },
      {
        id: 2,
        productName: "Jazz Club",
        price: [360000, 2950000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "xa",
        img: [jazz_club_1, jazz_club_2],
        description: [
          "'Jazz Club' thuộc bộ sưu tập Replica của Maison Margiela, một dòng nước hoa gợi lại những ký ức và khoảnh khắc đặc biệt trong cuộc sống. Hương thơm này lấy cảm hứng từ không gian ấm áp và hoài cổ của những câu lạc bộ nhạc jazz trong những năm 1950, nơi âm nhạc và không khí sôi động hòa quyện cùng khói xì gà và hương rượu whiskey.",
          "",
          "Hương đầu: Mở đầu với sự tươi mát của cam bergamot, chanh vàng và tiêu hồng, tạo nên cảm giác thanh lịch và tinh tế, thu hút từ những nốt hương đầu tiên.",
          "Hương giữa: Hương giữa lan tỏa sự nam tính và cổ điển với sự kết hợp hoàn hảo của hương rượu rum, dầu cây đơn sâm và cỏ vetiver, gợi nhớ đến những ly cocktail sang trọng và khói thuốc nhẹ nhàng trong những buổi trình diễn jazz đầy cảm xúc.",
          "Hương cuối: Kết thúc bằng hương ấm áp, gợi cảm của hạt vani, thuốc lá và nhựa benzoic, mang lại sự sâu lắng và kéo dài của hương thơm, giống như những nốt nhạc cuối cùng vang lên trong không gian ngọt ngào của câu lạc bộ.",
        ],
      },
      {
        id: 3,
        productName: "Flower Market",
        price: [360000, 2950000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [flower_market_1, flower_market_2],
        description: [
          "'Flower Market' từ bộ sưu tập Replica của Maison Margiela tái hiện lại hình ảnh của một khu chợ hoa tươi, tràn ngập những bó hoa rực rỡ và mùi hương ngọt ngào của thiên nhiên. Đây là loại nước hoa tinh tế, nhẹ nhàng và tươi mới, mang lại cảm giác như bạn đang bước chân vào một khu chợ hoa đầy màu sắc và hương thơm.",
          "'Flower Market' là lựa chọn hoàn hảo cho những ai yêu thích sự tinh khiết, tươi mới của hoa cỏ, và muốn mang lại cảm giác lãng mạn, dễ chịu trong mọi hoàn cảnh. Hương thơm này thích hợp sử dụng hàng ngày, đặc biệt trong những ngày nắng ấm hoặc những dịp mang tính chất nhẹ nhàng và thư giãn.",
          "Hương đầu: Tươi mới với sự kết hợp của hương hoa nhài, lan Nam Phi, và hoa hồng, tạo nên một cảm giác trong lành và mượt mà.",
          "Hương giữa: Hương thơm phát triển với sự pha trộn của hương huệ, hoa huệ tây và hoa nhài, mang lại sự thanh khiết và quyến rũ, như thể bạn đang chìm đắm trong một khu vườn hoa nở rộ.",
          "Hương cuối: Hương cuối của gỗ tuyết tùng và lá cây xanh mang lại chiều sâu và cảm giác êm ái, giữ cho hương thơm lâu phai và tạo cảm giác thư thái.",
        ],
      },
      {
        id: 4,
        productName: "Lazy Sunday Morning",
        price: [360000, 2950000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [lazy_sunday_morning_1, lazy_sunday_morning_2],
        description: [
          "'Lazy Sunday Morning' từ dòng Replica của Maison Margiela là một hương thơm gợi cảm giác thư thái, nhẹ nhàng và thoải mái như một buổi sáng Chủ Nhật lười biếng. Mùi hương của nước hoa này gợi nhớ đến những buổi sáng nắng sớm, khi bạn đang nằm trên bộ chăn ga trắng tinh, thoáng mát và mượt mà, thư giãn trong không gian êm dịu.",
          "'Lazy Sunday Morning' là sự lựa chọn hoàn hảo cho những ai yêu thích sự nhẹ nhàng và thư giãn. Hương thơm lý tưởng để sử dụng hàng ngày, đặc biệt vào những ngày nghỉ ngơi, khi bạn muốn tận hưởng những khoảnh khắc tĩnh lặng và dịu êm.",
          "Hương đầu: Sự tươi mát đến từ hương lê và aldehyde, tạo cảm giác thoáng đãng, tươi sáng như ánh nắng đầu ngày.",
          "Hương giữa: Hương thơm dịu dàng của hoa hồng và hoa diên vĩ, mang lại sự thanh lịch và nhẹ nhàng, như cảm giác được bao bọc trong chiếc ga giường mềm mại.",
          "Hương cuối: Hương xạ hương trắng và gỗ đàn hương tạo nên sự ấm áp, thoải mái, và lưu luyến lâu dài trên da.",
        ],
      },
      {
        id: 5,
        productName: "When The Rain Stops",
        price: [360000, 2950000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [when_the_rain_stops_1, when_the_rain_stops_2],
        description: [
          "'When The Rain Stops' của Maison Margiela là một hương thơm đầy sảng khoái, gợi nhớ đến không khí trong lành và tươi mát sau cơn mưa. Hương thơm này giống như cảm giác hít thở bầu không khí sau khi những giọt mưa cuối cùng rơi xuống, khi mặt trời bắt đầu ló dạng và sự tươi mới của thiên nhiên được hồi sinh.",
          "'When The Rain Stops' mang đến sự tươi mới và thanh thoát, hoàn hảo cho những ai yêu thích cảm giác sảng khoái của thiên nhiên sau cơn mưa. Nước hoa này thích hợp cho cả ngày dài và những dịp khi bạn cần tìm kiếm sự thư giãn và tái tạo năng lượng.",
          "Hương đầu: Mở ra với hương aldehyde và hương cam bergamot, tái hiện cảm giác mát lành và trong trẻo sau cơn mưa, mang đến năng lượng tươi mới.",
          "Hương giữa: Hương thơm của hoa hồng và hoa nhài hòa quyện, tạo nên cảm giác của những bông hoa vừa được tưới tắm bởi mưa, nở rộ và căng tràn sức sống.",
          "Hương cuối: Kết thúc bằng mùi hương gỗ tuyết tùng và xạ hương, lưu lại cảm giác ấm áp và sảng khoái, tựa như tia nắng đầu tiên sau cơn mưa, tạo nên sự thư giãn dài lâu.",
        ],
      },
    ],
  },
  {
    id: 53,
    brand: "Roja Parfums",
    logo: roja_parfums,
    products: [
      {
        id: 1,
        productName: "Elysium Pour Homme Parfum Cologne",
        price: [700000, 6400000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [
          elysium_pour_homme_parfum_cologne_1,
          elysium_pour_homme_parfum_cologne_2,
        ],
        description: [
          "'Elysium Pour Homme Parfum Cologne' là một trong những sáng tạo tinh tế từ Roja Parfums, dành cho những người đàn ông khao khát sự tự do và quyền lực. Tên gọi Elysium xuất phát từ vùng đất huyền thoại nơi các vị thần trú ngụ, và mùi hương này cũng mang đậm tinh thần thần thoại ấy với sự thanh lịch và sang trọng.",
          "'Elysium Pour Homme Parfum Cologne' mang đến sự kết hợp giữa tươi mát và quyền lực, tạo nên một hương thơm đặc biệt phù hợp cho những dịp quan trọng hoặc để sử dụng hàng ngày, giúp tôn vinh phong cách nam tính và lịch lãm.",
          "Hương đầu: Sự pha trộn tươi mát của cam bergamot, chanh, bưởi, và cam quýt hòa cùng hương thơm nồng nàn của quả lý chua đen. Tầng hương đầu mang lại cảm giác phấn chấn, tươi mới.",
          "Hương giữa: Các nốt hương thảo mộc từ cây ngải, hương thảo, và hương hoa lily, nhài, hòa với sự ấm áp của quả táo và hương hoa hồng. Sự kết hợp này tạo nên một lớp hương quyến rũ và thanh tao.",
          "Hương cuối: Lớp hương ấm áp và nam tính từ hổ phách, gỗ đàn hương, da thuộc, và xạ hương, kết hợp với sự nồng nàn của hương vani và rêu sồi. Đây là lớp hương bền lâu, mang lại cảm giác quyền lực và quyến rũ.",
        ],
      },
      {
        id: 2,
        productName: "Elysium Eau Intense",
        price: [900000, 8400000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [elysium_eau_intense_1, elysium_eau_intense_2],
        description: [
          "'Elysium Eau Intense' là một phiên bản mạnh mẽ hơn của Elysium, mang đậm dấu ấn của sự tươi mới và quyền lực. Mùi hương này tôn vinh sự tự do, lòng can đảm và sự thanh lịch của người đàn ông hiện đại.",
          "'Elysium Eau Intense' của Roja Parfums là sự kết hợp hoàn hảo giữa sự tươi mới, mạnh mẽ và nam tính, lý tưởng cho những dịp đặc biệt và để sử dụng hàng ngày, tôn lên phong cách lịch lãm và quyền lực của người đàn ông.",
          "Hương đầu: Sự tươi mát nồng nàn từ các loại trái cây họ cam chanh như cam bergamot, chanh, bưởi hòa quyện cùng hương thảo mộc của ngải cứu và quả lý chua đen, tạo nên một cảm giác tươi mới và phấn chấn.",
          "Hương giữa: Nổi bật với các nốt hương của hoa nhài, hồng, và lily, mang lại một sự hòa quyện tinh tế, đầy sức sống. Hương trái cây từ táo và hương thảo mộc giúp lớp hương giữa trở nên phong phú và quyến rũ.",
          "Hương cuối: Lớp hương nền đầy sang trọng từ hổ phách, xạ hương, gỗ đàn hương và da thuộc, tạo nên sự ấm áp, mạnh mẽ và lưu hương lâu dài. Hương rêu sồi và vani làm tăng thêm sự tinh tế và quyền lực.",
        ],
      },
      {
        id: 3,
        productName: "Elixir",
        price: [700000, 6500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [elixir_1, elixir_2],
        description: [
          "'Elixir' từ Roja Parfums là một mùi hương huyền bí và quyến rũ, dành cho những ai yêu thích sự lôi cuốn và sang trọng. Được tạo ra để mang lại sự bí ẩn và lôi cuốn với sự kết hợp tinh tế giữa các nốt hương hoa quyến rũ và các thành phần quý giá.",
          "'Elixir' của Roja Parfums là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ, bí ẩn và sang trọng trong một mùi hương.",
          "Hương đầu: Khởi đầu với sự tươi mới của cam bergamot, chanh và bưởi, tạo cảm giác sảng khoái và đầy năng lượng. Sự kết hợp này còn được làm phong phú thêm bởi các nốt hương gia vị nhẹ nhàng.",
          "Hương giữa: Lớp hương giữa của 'Elixir' là sự hòa quyện tinh tế giữa hoa nhài, hoa hồng, và hoa phong lữ, mang lại một cảm giác quyến rũ và nữ tính. Những nốt hương này kết hợp với sự mềm mại của hạnh nhân và vanilla, tạo nên một lớp hương sâu lắng và phong phú.",
          "Hương cuối: Kết thúc với sự ấm áp và quyến rũ của hổ phách, gỗ đàn hương, xạ hương và da thuộc. Các nốt hương này tạo ra một sự kết thúc sâu lắng và sang trọng, lưu lại ấn tượng lâu dài và đầy bí ẩn.",
        ],
      },
      {
        id: 4,
        productName: "Burlington 1819",
        price: [800000, 7600000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [burlington_1819_1, burlington_1819_2],
        description: [
          "'Burlington 1819' từ Roja Parfums là một mùi hương cổ điển và sang trọng, mang đậm phong cách quý tộc và lịch sử. Được đặt theo tên của tòa nhà Burlington House, nơi tọa lạc của Royal Academy of Arts, nước hoa này tôn vinh sự thanh lịch và phong cách truyền thống.",
          "'Burlington 1819' của Roja Parfums là sự lựa chọn lý tưởng cho những ai yêu thích sự cổ điển, sang trọng và quyến rũ trong một mùi hương quý phái.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và bưởi, tạo ra một cảm giác sảng khoái và tươi mới. Các nốt hương gia vị như nhục đậu khấu và tiêu đen cũng góp phần làm tăng thêm độ sâu và sự tinh tế cho lớp hương đầu.",
          "Hương giữa: Lớp hương giữa của 'Burlington 1819' bao gồm sự kết hợp quyến rũ của hoa hồng, hoa nhài và hoa cam, cùng với các nốt hương của gỗ đàn hương và hổ phách. Đây là sự hòa quyện tinh tế giữa sự tươi mới của hoa cỏ và sự ấm áp của các thành phần gỗ.",
          "Hương cuối: Kết thúc với sự ấm áp và sang trọng của xạ hương, da thuộc và gỗ tuyết tùng. Những nốt hương này tạo ra một lớp nền sâu lắng và đầy quyền lực, lưu lại ấn tượng lâu dài và quyến rũ.",
        ],
      },
      {
        id: 5,
        productName: "Oceania",
        price: [1050000, 9000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [oceania_1, oceania_2],
        description: [
          "'Oceania' từ Roja Parfums mang đến một trải nghiệm nước hoa tươi mới và sảng khoái, gợi lên cảm giác về đại dương bao la và những chuyến phiêu lưu mạo hiểm. Được truyền cảm hứng từ vẻ đẹp nguyên sơ của biển cả, nước hoa này là sự kết hợp hoàn hảo giữa sự tươi mới của thiên nhiên và sự tinh tế của nghệ thuật chế tác nước hoa.",
          "'Oceania' của Roja Parfums là sự lựa chọn lý tưởng cho những ai yêu thích sự tươi mới, sảng khoái và tự nhiên trong một mùi hương gợi cảm giác về biển cả và thiên nhiên.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và húng quế, mang lại cảm giác sảng khoái như làn gió biển mát lạnh. Các nốt hương của bạc hà và nhục đậu khấu làm tăng thêm sự sống động và tươi mới cho lớp hương đầu.",
          "Hương giữa: Lớp hương giữa của 'Oceania' bao gồm sự hòa quyện quyến rũ của hoa nhài, hoa cam và hoa hồng, kết hợp với các nốt hương của gỗ đàn hương và xạ hương. Sự kết hợp này tạo ra một lớp hương thanh thoát và quyến rũ, gợi lên hình ảnh của những bãi biển nhiệt đới và không khí trong lành.",
          "Hương cuối: Kết thúc với sự ấm áp của xạ hương, gỗ tuyết tùng và hổ phách, tạo ra một lớp nền sâu lắng và lâu dài. Những nốt hương này mang lại cảm giác thư giãn và dễ chịu, như một chuyến du ngoạn trên đại dương.",
        ],
      },
      {
        id: 6,
        productName: "Isola Blu",
        price: [2500000, 10000000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [roja_isola_blu_parfum_1, roja_isola_blu_parfum_2],
        description: [
          "'Isola Blu' từ Roja Parfums mang đến một trải nghiệm nước hoa tinh tế và sang trọng, gợi lên hình ảnh về những hòn đảo xanh mướt và biển cả bao la. Được chế tác để phản ánh sự quyến rũ và thanh thoát của thiên nhiên, nước hoa này là sự kết hợp hoàn hảo giữa sự tươi mới của trái cây và sự sang trọng của các nốt hương phương Đông.",
          "'Isola Blu' của Roja Parfums là sự lựa chọn lý tưởng cho những ai yêu thích sự tươi mới, quyến rũ và sang trọng trong một mùi hương gợi cảm giác về thiên nhiên và biển cả.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và bưởi, mang lại cảm giác sảng khoái và năng động. Sự hòa quyện của các nốt hương này tạo nên một lớp hương đầu tươi mới và tràn đầy sức sống.",
          "Hương giữa: Lớp hương giữa của 'Isola Blu' bao gồm sự kết hợp quyến rũ của hoa nhài, hoa hồng và hoa cam, kết hợp với các nốt hương của gỗ đàn hương và hổ phách. Sự kết hợp này tạo ra một lớp hương quyến rũ và sang trọng, như một buổi chiều thư giãn trên hòn đảo xanh.",
          "Hương cuối: Kết thúc với sự ấm áp của xạ hương, gỗ tuyết tùng và vani, tạo ra một lớp nền sâu lắng và lâu dài. Những nốt hương này mang lại cảm giác thư giãn và dễ chịu, như làn sóng vỗ về trong một ngày hè.",
        ],
      },
      {
        id: 7,
        productName: "Manhattan",
        price: [1200000, 10000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [roja_manhattan_edp_1, roja_manhattan_edp_2],
        description: [
          "'Manhattan' từ Roja Parfums là một mùi hương đẳng cấp và sang trọng, mang đến sự tinh tế và quyến rũ của cuộc sống đô thị. Được chế tác để phản ánh sự lôi cuốn và phong cách của thành phố New York, nước hoa này là sự kết hợp hoàn hảo giữa sự ấm áp của các nốt hương gỗ và sự tươi mới của các loại trái cây.",
          "'Manhattan' của Roja Parfums là sự lựa chọn lý tưởng cho những ai yêu thích sự sang trọng, quyến rũ và đẳng cấp trong một mùi hương phản ánh sự sống động và phong cách của thành phố New York.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và táo xanh, mang lại cảm giác sảng khoái và năng động. Sự kết hợp này tạo nên một lớp hương đầu tươi mới và đầy sức sống, gợi lên hình ảnh của những buổi sáng rạng rỡ tại Manhattan.",
          "Hương giữa: Lớp hương giữa của 'Manhattan' bao gồm sự kết hợp quyến rũ của hoa nhài, hoa hồng và gia vị như tiêu và nhục đậu khấu. Sự pha trộn này tạo ra một lớp hương sang trọng và quyến rũ, phản ánh sự sôi động và lôi cuốn của thành phố.",
          "Hương cuối: Kết thúc với sự ấm áp của gỗ đàn hương, hổ phách và xạ hương. Những nốt hương này mang lại một lớp nền sâu lắng và quyến rũ, tạo cảm giác thư giãn và dễ chịu như một buổi tối lãng mạn ở New York.",
        ],
      },
      {
        id: 8,
        productName: "Elixir Parfum Coffret",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [
          roja_parfums_elixir_parfum_coffret_1,
          roja_parfums_elixir_parfum_coffret_2,
        ],
        description: [
          "'Elixir Parfum Coffret' từ Roja Parfums là bộ quà tặng sang trọng, bao gồm một hoặc nhiều chai nước hoa 'Elixir' trong một thiết kế tinh tế. Sản phẩm này được chế tác để mang đến sự quyến rũ và đẳng cấp, phù hợp với những người yêu thích sự sang trọng trong từng chi tiết.",
          "'Elixir Parfum Coffret' của Roja Parfums là món quà lý tưởng cho những ai yêu thích sự sang trọng, quyến rũ và đẳng cấp trong một bộ quà tặng cao cấp, hoàn hảo cho những dịp đặc biệt.",
          "Hương đầu: Mở đầu với sự tươi mới của các nốt trái cây như cam bergamot và chanh, kết hợp với các gia vị nhẹ nhàng như nhục đậu khấu. Lớp hương đầu này tạo cảm giác sảng khoái và năng động, làm nền tảng cho sự quyến rũ của nước hoa.",
          "Hương giữa: Lớp hương giữa của 'Elixir' bao gồm sự kết hợp tinh tế của hoa nhài, hoa hồng và hoa cam. Sự kết hợp này tạo ra một lớp hương quyến rũ và thanh thoát, mang đến cảm giác nhẹ nhàng và sang trọng.",
          "Hương cuối: Kết thúc với sự ấm áp của xạ hương, gỗ đàn hương và hổ phách. Những nốt hương này tạo ra một lớp nền sâu lắng và lâu dài, mang lại cảm giác thư giãn và quyến rũ.",
        ],
      },
      {
        id: 9,
        productName: "Elysium Parfum Pour Homme",
        price: [null, null],
        // dung tích
        volume: [10, 50],
        gender: ["male"],
        // nồng độ
        concentration: "Parfum",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [elysium_parfum_pour_homme_1, elysium_parfum_pour_homme_2],
        description: [
          "'Elysium Parfum Pour Homme' từ Roja Parfums là một tác phẩm hoàn hảo dành cho phái mạnh, kết hợp sự tươi mới với sự quyến rũ đẳng cấp. Đây là lựa chọn lý tưởng cho những người đàn ông yêu thích sự thanh thoát và sang trọng trong mùi hương của mình.",
          "'Elysium Parfum Pour Homme' của Roja Parfums là lựa chọn lý tưởng cho những người đàn ông yêu thích sự tươi mới, quyến rũ và đẳng cấp trong một mùi hương mạnh mẽ và đầy phong cách.",
          "Hương đầu: Khởi đầu với sự tươi mới của cam bergamot, chanh, và bưởi, hòa quyện với hương gia vị nhẹ nhàng như nhục đậu khấu và tiêu. Sự kết hợp này tạo ra một cảm giác sảng khoái và năng động ngay từ lần xịt đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'Elysium' bao gồm sự kết hợp của hoa nhài, hoa hồng và hoa cam, mang đến một cảm giác quyến rũ và thanh thoát. Các nốt hương hoa này tạo ra sự tinh tế và sang trọng.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và hổ phách. Những nốt hương này tạo ra một sự ấm áp và sâu lắng, để lại ấn tượng lâu dài và quyến rũ.",
        ],
      },
      {
        id: 10,
        productName: "Roja Parfums Elixir EDP (New 2023)",
        price: [750000, 6500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [roja_parfums_elixir_edp_2023_1, roja_parfums_elixir_edp_2023_2],
        description: [
          "'Roja Parfums Elixir EDP (New 2023)' là phiên bản mới nhất của dòng nước hoa danh tiếng Elixir từ Roja Parfums, mang đến một trải nghiệm hương thơm quyến rũ và sang trọng. Đây là lựa chọn lý tưởng cho những ai yêu thích sự bí ẩn và đẳng cấp trong mùi hương của mình.",
          "'Roja Parfums Elixir EDP (New 2023)' là sự lựa chọn hoàn hảo cho những ai yêu thích sự quyến rũ, sang trọng và bí ẩn trong một mùi hương đầy phong cách và đẳng cấp.",
          "Hương đầu: Mở đầu với sự tươi mới và mạnh mẽ của cam bergamot và chanh, kết hợp với hương gia vị của tiêu và nhục đậu khấu. Sự pha trộn này tạo ra một cảm giác sảng khoái và cuốn hút ngay từ những giây đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'Elixir' bao gồm sự kết hợp tinh tế của hoa nhài, hoa hồng, và hoa cam. Những nốt hương này tạo ra sự quyến rũ và lôi cuốn, mang lại cảm giác sang trọng và bí ẩn.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, hổ phách và xạ hương. Những nốt hương này tạo ra một sự ấm áp và lâu dài, để lại ấn tượng mạnh mẽ và đầy quyến rũ.",
        ],
      },
      {
        id: 11,
        productName: "Roja Elysium Pour Femme EDP",
        price: [1100000, 7800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [roja_elysium_pour_femme_edp_1, roja_elysium_pour_femme_edp_2],
        description: [
          "'Roja Elysium Pour Femme EDP' là một trong những mùi hương đẳng cấp của Roja Parfums, mang đến một trải nghiệm tươi mới và quyến rũ dành riêng cho phái đẹp. Đây là sự lựa chọn hoàn hảo cho những người phụ nữ yêu thích sự thanh thoát, sang trọng và cuốn hút.",
          "'Roja Elysium Pour Femme EDP' là sự lựa chọn lý tưởng cho những người phụ nữ yêu thích sự quyến rũ, tươi mới và sang trọng trong một mùi hương đầy phong cách.",
          "Hương đầu: Mở đầu với sự tươi mới và sáng rực của cam bergamot, chanh, và quýt, kết hợp với hương hoa nhài và hoa hồng. Sự kết hợp này tạo ra một cảm giác tươi mới, sảng khoái và đầy sức sống.",
          "Hương giữa: Lớp hương giữa của 'Elysium Pour Femme' bao gồm sự kết hợp của hoa cam, hoa nhài và hoa lan, tạo ra một mùi hương nhẹ nhàng và quyến rũ, mang lại cảm giác nữ tính và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và hổ phách. Những nốt hương này tạo ra một sự ấm áp và lâu dài, để lại ấn tượng sâu sắc và đầy quyến rũ.",
        ],
      },
    ],
  },
  {
    id: 54,
    brand: "Mugler",
    logo: mugler,
    products: [
      {
        id: 1,
        productName: "Mugler Alien EDP",
        price: [360000, 2750000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [mugler_alien_edp_1, mugler_alien_edp_2],
        description: [
          "'Mugler Alien EDP' là một trong những mùi hương đặc trưng và nổi bật từ Mugler, mang đến một trải nghiệm quyến rũ và bí ẩn. Đây là lựa chọn lý tưởng cho những người phụ nữ yêu thích sự khác biệt, độc đáo và mạnh mẽ.",
          "'Mugler Alien EDP' là sự lựa chọn lý tưởng cho những người phụ nữ yêu thích sự bí ẩn và quyến rũ trong một mùi hương mạnh mẽ và khác biệt.",
          "Hương đầu: Mở đầu với sự ấm áp và quyến rũ của hương nhựa trầm và cây hoắc hương. Sự kết hợp này tạo ra một cảm giác bí ẩn và cuốn hút ngay từ lần xịt đầu tiên.",
          "Hương giữa: Lớp hương giữa của 'Alien' bao gồm sự kết hợp của hoa nhài, tạo ra một mùi hương nở rộ và đầy nữ tính. Đây là sự kết hợp hoàn hảo giữa sự quyến rũ và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương và hương vani. Những nốt hương này tạo ra một cảm giác ấm áp, mịn màng và lưu lại ấn tượng lâu dài.",
        ],
      },
    ],
  },
  {
    id: 55,
    brand: "Alexandria Fragrances",
    logo: alexandria_fragrances,
    products: [
      {
        id: 1,
        productName: "Other 13",
        price: [350000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [other_13_1, other_13_2],
        description: [
          "'Other 13' của Alexandria Fragrances mang đến một trải nghiệm hương thơm độc đáo và hiện đại, phù hợp với cả nam và nữ. Đây là lựa chọn lý tưởng cho những ai yêu thích sự mới mẻ và sáng tạo trong một mùi hương.",
          "'Other 13' của Alexandria Fragrances là sự lựa chọn lý tưởng cho những ai yêu thích sự tươi mới và quyến rũ trong một mùi hương hiện đại và đầy sáng tạo.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và hương dưa hấu. Sự kết hợp này mang lại cảm giác mát mẻ và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa của 'Other 13' bao gồm sự kết hợp của hoa nhài và hương gỗ. Các nốt hương này tạo ra một sự hòa quyện tinh tế giữa sự quyến rũ và sự sang trọng.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương và hương gỗ đàn hương. Những nốt hương này tạo ra một cảm giác ấm áp, quyến rũ và lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 2,
        productName: "Visionary",
        price: [350000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [visionary_1, visionary_2],
        description: [
          "'Visionary' của Alexandria Fragrances là một mùi hương độc đáo, mang đến sự kết hợp hoàn hảo giữa sự tươi mới và sự sang trọng, phù hợp cho cả nam và nữ. Đây là lựa chọn lý tưởng cho những ai yêu thích sự sáng tạo và khác biệt trong một mùi hương.",
          "'Visionary' của Alexandria Fragrances là sự lựa chọn lý tưởng cho những ai yêu thích sự tươi mới, quyến rũ và sang trọng trong một mùi hương đầy sáng tạo.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh xanh và hương bạc hà, tạo cảm giác mát mẻ và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa của 'Visionary' bao gồm sự kết hợp của hoa nhài và hương gỗ, mang lại một sự hòa quyện tinh tế và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương và hương gỗ đàn hương, tạo ra một cảm giác ấm áp và sang trọng, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 3,
        productName: "Cali Love Eau de Parfum",
        price: [350000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [cali_love_1, cali_love_2],
        description: [
          "'Cali Love' của Alexandria Fragrances là một hương thơm đầy sức sống và ngọt ngào, gợi nhớ đến những ngày nắng ấm của California với không khí trong lành và biển xanh bao la.",
          "'Cali Love' là một lựa chọn hoàn hảo cho những ai yêu thích sự tươi mới, ngọt ngào, và đầy lôi cuốn, phù hợp với những ngày dạo chơi dưới nắng hoặc những buổi hẹn hò lãng mạn.",
          "Hương đầu: Mở đầu với sự tươi mát của quả cam và chanh vàng, mang lại cảm giác phấn chấn và sảng khoái.",
          "Hương giữa: Hương trái cây ngọt ngào và nồng nàn của dứa và dừa tạo nên sự độc đáo và cuốn hút.",
          "Hương cuối: Hương cuối với vani, xạ hương, và gỗ đàn hương, mang lại cảm giác ấm áp và lâu dài trên da.",
        ],
      },
    ],
  },
  {
    id: 56,
    brand: "Parfums de Marly",
    logo: parfums_de_marly,
    products: [
      {
        id: 1,
        productName: "Galloway",
        price: [null, null],
        // dung tích
        volume: [10, 125],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [galloway_edp_1, galloway_edp_2],
        description: [
          "'Galloway' của Parfums de Marly là một nước hoa mang đến sự tươi mới và quyến rũ với sự kết hợp hoàn hảo giữa các nốt hương tự nhiên và phong cách cổ điển. Đây là một sự lựa chọn lý tưởng cho những người đàn ông yêu thích sự tinh tế và sang trọng.",
          "'Galloway' của Parfums de Marly là một sự lựa chọn hoàn hảo cho những dịp đặc biệt hoặc khi bạn muốn để lại ấn tượng mạnh mẽ với sự tinh tế và sang trọng.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh và cam, tạo nên cảm giác sảng khoái và năng động ngay từ những giây phút đầu tiên.",
          "Hương giữa: Lớp hương giữa bao gồm hoa nhài và hoa cam, mang đến sự mềm mại và quyến rũ, kết hợp với nốt hương của gia vị nhẹ nhàng.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương và gỗ đàn hương, tạo nên một sự kết thúc ấm áp và đầy phong cách.",
        ],
      },
      {
        id: 2,
        productName: "Valaya",
        price: [750000, 5500000],
        // dung tích
        volume: [10, 125],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [valaya_edp_1, valaya_edp_2],
        description: [
          "'Valaya' của Parfums de Marly là một nước hoa nữ tính và quyến rũ, mang đến sự tươi mới và sang trọng với sự kết hợp hoàn hảo giữa các nốt hương hoa và trái cây. Đây là một lựa chọn lý tưởng cho những người phụ nữ yêu thích sự nhẹ nhàng nhưng đầy cuốn hút.",
          "'Valaya' của Parfums de Marly là sự lựa chọn hoàn hảo cho những dịp đặc biệt hoặc khi bạn muốn thể hiện sự nữ tính và quyến rũ của mình.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và quả lý chua đen, tạo ra một cảm giác sảng khoái và năng động ngay từ những giây phút đầu tiên.",
          "Hương giữa: Lớp hương giữa bao gồm hoa nhài, hoa mộc lan và hoa hồng, mang đến sự mềm mại, quyến rũ và thanh thoát, kết hợp hoàn hảo với nốt hương trái cây ngọt ngào.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương và xạ hương, tạo nên một sự kết thúc ấm áp và đầy quyến rũ.",
        ],
      },
      {
        id: 3,
        productName: "Delina",
        price: [850000, 5750000],
        // dung tích
        volume: [10, 125],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [parfums_de_marly_delina_edp_1, parfums_de_marly_delina_edp_2],
        description: [
          "'Delina' của Parfums de Marly là một mùi hương nữ tính và quyến rũ, nổi bật với sự kết hợp hoàn hảo giữa các nốt hương hoa và trái cây. Đây là một sự lựa chọn lý tưởng cho những người phụ nữ yêu thích sự quyến rũ, tươi mới và thanh thoát.",
          "'Delina' của Parfums de Marly là sự lựa chọn hoàn hảo cho những dịp đặc biệt, khi bạn muốn thể hiện sự nữ tính và quyến rũ của mình.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh, bưởi và quả lý chua đen, mang đến một cảm giác sảng khoái và năng động ngay từ những giây phút đầu tiên.",
          "Hương giữa: Lớp hương giữa bao gồm hoa nhài, hoa hồng và hoa mộc lan, tạo ra một sự kết hợp nhẹ nhàng và quyến rũ, với sự tinh tế và thanh thoát của các nốt hương hoa.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và vani, tạo nên một sự kết thúc ấm áp và quyến rũ, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 4,
        productName: "Delina La Rosée",
        price: [800000, 5650000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [
          parfums_de_marly_delina_la_rosee_1,
          parfums_de_marly_delina_la_rosee_2,
        ],
        description: [
          "'Delina La Rosée' của Parfums de Marly mang đến một phiên bản mới đầy tươi mới và quyến rũ của dòng nước hoa Delina nổi tiếng. Đây là một lựa chọn lý tưởng cho những người phụ nữ yêu thích sự nữ tính, tinh tế và đầy sức sống.",
          "'Delina La Rosée' là sự lựa chọn lý tưởng cho những dịp đặc biệt hoặc hàng ngày, khi bạn muốn thể hiện sự nữ tính, tươi mới và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh và quả lý chua đen, kết hợp với một chút tươi mát từ quả dưa hấu, mang lại cảm giác sảng khoái và dễ chịu.",
          "Hương giữa: Lớp hương giữa của 'Delina La Rosée' bao gồm hoa hồng, hoa nhài và hoa mộc lan, tạo nên một sự kết hợp nhẹ nhàng, quyến rũ và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương và gỗ đàn hương, mang đến một sự kết thúc ấm áp và tinh tế, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 5,
        productName: "Delina Exclusif",
        price: [900000, 5900000],
        // dung tích
        volume: [10, 75],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [
          parfums_de_marly_delina_exclusif_1,
          parfums_de_marly_delina_exclusif_2,
        ],
        description: [
          "'Delina La Rosée' của Parfums de Marly mang đến một phiên bản mới đầy tươi mới và quyến rũ của dòng nước hoa Delina nổi tiếng. Đây là một lựa chọn lý tưởng cho những người phụ nữ yêu thích sự nữ tính, tinh tế và đầy sức sống.",
          "'Delina La Rosée' là sự lựa chọn lý tưởng cho những dịp đặc biệt hoặc hàng ngày, khi bạn muốn thể hiện sự nữ tính, tươi mới và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của chanh và quả lý chua đen, kết hợp với một chút tươi mát từ quả dưa hấu, mang lại cảm giác sảng khoái và dễ chịu.",
          "Hương giữa: Lớp hương giữa của 'Delina La Rosée' bao gồm hoa hồng, hoa nhài và hoa mộc lan, tạo nên một sự kết hợp nhẹ nhàng, quyến rũ và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương và gỗ đàn hương, mang đến một sự kết thúc ấm áp và tinh tế, lưu lại ấn tượng lâu dài.",
        ],
      },
    ],
  },
  {
    id: 57,
    brand: "Thomas Kosmala",
    logo: thomas_kosmala,
    products: [
      {
        id: 1,
        productName: "No4",
        price: [360000, 2850000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [no4_1, no4_2],
        description: [
          "'No4' của Thomas Kosmala là một mùi hương độc đáo và lôi cuốn, phù hợp cho cả nam và nữ. Đây là sự lựa chọn lý tưởng cho những ai yêu thích sự mới mẻ, khác biệt và đầy quyến rũ.",
          "'No4' là sự lựa chọn hoàn hảo cho những ai yêu thích sự kết hợp giữa sự tươi mới và quyến rũ trong một mùi hương độc đáo và đặc biệt.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và chanh, kết hợp với một chút gia vị của tiêu hồng, tạo nên một sự khởi đầu sảng khoái và kích thích.",
          "Hương giữa: Lớp hương giữa của 'No4' bao gồm hoa oải hương, hoa nhài và một chút hương gỗ nhẹ nhàng, mang đến một sự kết hợp quyến rũ và dễ chịu, tạo nên một dấu ấn mạnh mẽ.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ đàn hương và một chút vani, tạo ra một sự kết thúc ấm áp và lâu dài, lưu lại ấn tượng dễ chịu và tinh tế.",
        ],
      },
    ],
  },
  {
    id: 58,
    brand: "Tom Ford",
    logo: tom_ford,
    products: [
      {
        id: 1,
        productName: "Ombre Leather",
        price: [450000, 3650000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [tom_ford_ombre_leather_1, tom_ford_ombre_leather_2],
        description: [
          "'Ombre Leather' của Tom Ford là một mùi hương mạnh mẽ và quyến rũ, phù hợp với nam giới yêu thích sự cá tính và độc đáo. Đây là một sự lựa chọn lý tưởng cho những ai muốn tạo dấu ấn riêng biệt và đầy lôi cuốn.",
          "'Ombre Leather' là sự lựa chọn hoàn hảo cho những ai yêu thích sự mạnh mẽ và quyến rũ trong một mùi hương đầy cá tính và đặc biệt.",
          "Hương đầu: Mở đầu với sự tươi mới của quả lý chua đen và hạt tiêu, tạo nên một sự khởi đầu sắc sảo và cuốn hút.",
          "Hương giữa: Lớp hương giữa của 'Ombre Leather' bao gồm da thuộc và hoa nhài, kết hợp với một chút hương gỗ nhẹ nhàng, mang đến một sự kết hợp quyến rũ và ấm áp, tạo nên một dấu ấn mạnh mẽ và nam tính.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ đàn hương và một chút vani, tạo ra một sự kết thúc sâu lắng và lâu dài, lưu lại ấn tượng dễ chịu và đầy quyến rũ.",
        ],
      },
      {
        id: 2,
        productName: "Black Orchid",
        price: [420000, 3600000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "12-18",
        // độ toả hương
        scent_release: "rất xa",
        img: [black_orchid_1, black_orchid_2],
        description: [
          "'Black Orchid' của Tom Ford là một mùi hương huyền bí và quyến rũ, nổi bật với sự kết hợp độc đáo của các thành phần phong phú và lôi cuốn. Đây là sự lựa chọn hoàn hảo cho những ai yêu thích sự bí ẩn và tinh tế.",
          "'Black Orchid' là sự lựa chọn lý tưởng cho những ai yêu thích sự bí ẩn và quyến rũ trong một mùi hương sang trọng và cao cấp.",
          "Hương đầu: Mở đầu với sự tươi mới và mạnh mẽ của cam bergamot, nho đen và nấm truffle, tạo ra một sự khởi đầu đầy lôi cuốn và bí ẩn.",
          "Hương giữa: Lớp hương giữa của 'Black Orchid' bao gồm hoa phong lan đen, hoa nhài và hương quế, mang đến một sự kết hợp sang trọng và quyến rũ, tạo ra một dấu ấn mạnh mẽ và độc đáo.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương, và vani, tạo ra một sự kết thúc ấm áp và đầy bí ẩn, lưu lại ấn tượng lâu dài và quyến rũ.",
        ],
      },
      {
        id: 3,
        productName: "Grey Vetiver",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [grey_vetiver_1, grey_vetiver_2],
        description: [
          "'Grey Vetiver' của Tom Ford là một mùi hương tươi mới và thanh lịch, nổi bật với sự kết hợp hoàn hảo giữa các thành phần gỗ và hương thảo. Đây là sự lựa chọn lý tưởng cho những người đàn ông yêu thích sự tinh tế và sang trọng.",
          "'Grey Vetiver' là sự lựa chọn hoàn hảo cho những dịp trang trọng, công việc hoặc những buổi tối đặc biệt, phù hợp với những ai yêu thích sự thanh lịch và tinh tế.",
          "Hương đầu: Mở đầu với sự tươi mới và sáng sủa của bưởi và chanh, mang đến một cảm giác sảng khoái và năng động.",
          "Hương giữa: Lớp hương giữa của 'Grey Vetiver' bao gồm hoa nhài, hương thảo và tiêu, tạo ra một sự kết hợp tinh tế và thanh lịch, mang lại cảm giác tựa như làn gió mới.",
          "Hương cuối: Kết thúc với lớp hương nền của vetiver, gỗ đàn hương và xạ hương, tạo ra một sự kết thúc ấm áp và sang trọng, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 4,
        productName: "Neroli Portofino",
        price: [850000, 7500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [neroli_portofino_1, neroli_portofino_2],
        description: [
          "'Neroli Portofino' của Tom Ford là một mùi hương tươi mới và thanh thoát, mang đến cảm giác như làn gió mát của vùng Địa Trung Hải. Đây là sự lựa chọn lý tưởng cho những ai yêu thích sự sang trọng và tự nhiên.",
          "'Neroli Portofino' là sự lựa chọn hoàn hảo cho những dịp đặc biệt, công việc hoặc những ngày hè, phù hợp với những ai yêu thích sự tươi mới và sang trọng trong một mùi hương tự nhiên.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và hoa cam, tạo ra một cảm giác sảng khoái và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa của 'Neroli Portofino' bao gồm hoa nhài, hoa cam và hương thảo, mang lại sự nhẹ nhàng, thanh thoát và tinh tế.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ đàn hương và hoắc hương, tạo ra một sự kết thúc ấm áp và thanh lịch, lưu lại ấn tượng dễ chịu và lâu dài.",
        ],
      },
      {
        id: 5,
        productName: "Oud Wood",
        price: [700000, 6500000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [oud_wood_1, oud_wood_2],
        description: [
          "'Oud Wood' của Tom Ford là một mùi hương sang trọng và quyến rũ, nổi bật với sự kết hợp của các nốt hương gỗ quý giá và gia vị độc đáo. Đây là lựa chọn hoàn hảo cho những ai yêu thích sự sang trọng và đẳng cấp.",
          "'Oud Wood' là sự lựa chọn hoàn hảo cho những dịp đặc biệt, sự kiện quan trọng hoặc những buổi tối sang trọng, phù hợp với những ai yêu thích sự quyến rũ và đẳng cấp trong một mùi hương gỗ ấm áp.",
          "Hương đầu: Mở đầu với sự kết hợp của tiêu đen và gừng, mang lại một sự khởi đầu tươi mới và có phần ấm áp.",
          "Hương giữa: Lớp hương giữa của 'Oud Wood' bao gồm gỗ oud, gỗ đàn hương và gỗ tuyết tùng, tạo nên một sự kết hợp tinh tế và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của vani và xạ hương, mang lại một sự kết thúc ấm áp và dễ chịu, tạo ấn tượng lâu dài và sang trọng.",
        ],
      },
    ],
  },
  // {
  //   id: 59,
  //   brand: "Van Cleef & Arpels",
  //   logo: van_cleef_arpels,
  //   products: [
  //     {
  //       id: 1,
  //       productName: "1872 Feminine",
  //       price: [null, null],
  //       // dung tích
  //       volume: [10, 100],
  //       gender: ["female"],
  //       // nồng độ
  //       concentration: "EDP",
  // độ lưu hương
  // scent_retention: "",
  // độ toả hương
  // scent_release: "",
  //       img: [null, null],
  //       description: [
  //         "",
  //         "",
  //         "",
  //         "",
  //         ""
  //       ],
  //     },
  //   ],
  // },
  {
    id: 60,
    brand: "Versace",
    logo: versace,
    products: [
      {
        id: 1,
        productName: "Bright Crystal",
        price: [260000, 1850000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [bright_crystal_1, bright_crystal_2],
        description: [
          "'Bright Crystal' của Versace là một mùi hương tươi mới và quyến rũ, mang đến sự thanh thoát và sang trọng. Đây là sự lựa chọn hoàn hảo cho những người phụ nữ yêu thích sự nhẹ nhàng, tinh tế và đầy quyến rũ.",
          "'Bright Crystal' là sự lựa chọn hoàn hảo cho những dịp đặc biệt hoặc hàng ngày, phù hợp với những ai yêu thích sự tươi mới và sang trọng trong một mùi hương quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của lựu và chanh, mang lại cảm giác sảng khoái và tràn đầy sức sống.",
          "Hương giữa: Lớp hương giữa của 'Bright Crystal' bao gồm hoa mẫu đơn, hoa nhài và hoa sen, tạo ra một sự kết hợp nhẹ nhàng, quyến rũ và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ và hổ phách, mang đến sự ấm áp và dễ chịu, lưu lại ấn tượng lâu dài và quyến rũ.",
        ],
      },
      {
        id: 2,
        productName: "Bright Crystal Absolu",
        price: [270000, 1950000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [bright_crystal_absolu_1, bright_crystal_absolu_2],
        description: [
          "'Bright Crystal Absolu' của Versace là phiên bản đậm đà hơn của dòng Bright Crystal, mang đến một mùi hương quyến rũ và sang trọng hơn. Đây là lựa chọn lý tưởng cho những người phụ nữ yêu thích sự mạnh mẽ, cuốn hút và đầy cảm xúc.",
          "'Bright Crystal Absolu' là sự lựa chọn hoàn hảo cho các sự kiện đặc biệt hoặc những buổi tối lãng mạn, mang đến sự quyến rũ và sang trọng cho người sử dụng.",
          "Hương đầu: Mở đầu với sự tươi mới và ngọt ngào của lựu và chanh, tạo cảm giác sảng khoái và tràn đầy năng lượng.",
          "Hương giữa: Lớp hương giữa của 'Bright Crystal Absolu' là sự kết hợp đầy quyến rũ của hoa mẫu đơn, hoa nhài và hoa sen, mang đến sự mềm mại, gợi cảm và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ và hổ phách, tạo ra một sự ấm áp và sang trọng, lưu lại ấn tượng mạnh mẽ và lâu dài.",
        ],
      },
      {
        id: 3,
        productName: "Pour Homme",
        price: [240000, 1800000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [pour_homme_1, pour_homme_2],
        description: [
          "'Pour Homme' của Versace là một mùi hương nam tính và tinh tế, mang đến sự kết hợp hoàn hảo giữa sự tươi mới và sự quyến rũ. Đây là sự lựa chọn lý tưởng cho những người đàn ông hiện đại, yêu thích sự sang trọng và đầy cá tính.",
          "'Pour Homme' là sự lựa chọn hoàn hảo cho các dịp đặc biệt hay những buổi tối sang trọng, mang đến cho người sử dụng sự tự tin và phong cách.",
          "Hương đầu: Mở đầu với sự tươi mới và năng động của chanh, cam bergamot và hương thảo, tạo ra một cảm giác sảng khoái và dễ chịu.",
          "Hương giữa: Lớp hương giữa của 'Pour Homme' bao gồm sự kết hợp của hoa oải hương, húng quế và nhục đậu khấu, mang đến một sự pha trộn tinh tế và nam tính.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và hổ phách, tạo ra một sự ấm áp và quyến rũ, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 4,
        productName: "Eros",
        price: [260000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [eros_edt_1, eros_edt_2],
        description: [
          "'Eros' của Versace là một mùi hương mạnh mẽ và quyến rũ, được thiết kế dành cho những người đàn ông tự tin và đầy phong cách. Mùi hương này mang đến sự kết hợp hoàn hảo giữa sự tươi mới và sự quyến rũ, làm nổi bật sự nam tính của người sử dụng.",
          "'Eros' là sự lựa chọn lý tưởng cho những dịp đặc biệt, từ những buổi tối lãng mạn đến các sự kiện quan trọng, mang đến cho người sử dụng sự tự tin và sự quyến rũ không thể cưỡng lại.",
          "Hương đầu: Mở đầu với sự tươi mới và sảng khoái của bạc hà, chanh và táo xanh, tạo ra một cảm giác đầy năng lượng và sự sống động.",
          "Hương giữa: Lớp hương giữa của 'Eros' bao gồm sự kết hợp của hoa nhài, đinh hương và hồ tiêu, mang đến một sự pha trộn mạnh mẽ và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và vani, tạo ra một sự ấm áp và đầy lôi cuốn, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 5,
        productName: "Dylan Blue",
        price: [260000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [dylan_blue_1, dylan_blue_2],
        description: [
          "'Dylan Blue' của Versace là một mùi hương mạnh mẽ và quyến rũ, thể hiện sự tự tin và phong cách của người đàn ông hiện đại. Với sự kết hợp độc đáo giữa các nốt hương tươi mới và các thành phần mạnh mẽ, đây là một lựa chọn lý tưởng cho những dịp đặc biệt.",
          "'Dylan Blue' là sự lựa chọn hoàn hảo cho những người đàn ông yêu thích sự quyến rũ và hiện đại, phù hợp với cả những buổi tối lãng mạn và các sự kiện quan trọng.",
          "Hương đầu: Mở đầu với sự tươi mới và năng lượng từ chanh, bưởi và hương biển, mang lại một cảm giác sảng khoái và sống động.",
          "Hương giữa: Lớp hương giữa của 'Dylan Blue' là sự kết hợp của hoa nhài, húng quế và tiêu đen, tạo nên một sự pha trộn mạnh mẽ và nam tính.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và hổ phách, tạo ra một sự ấm áp và quyến rũ, lưu lại ấn tượng lâu dài và đầy cuốn hút.",
        ],
      },
    ],
  },
  {
    id: 61,
    brand: "Xerjoff",
    logo: xerjoff,
    products: [
      {
        id: 1,
        productName: "1861 Renaissance",
        price: [650000, 5650000],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [renaissance_edp_1861_1, renaissance_edp_1861_2],
        description: [
          "'1861 Renaissance' của Xerjoff là một mùi hương tinh tế và sang trọng, mang đến một cảm giác tươi mới và quyến rũ. Được thiết kế để kỷ niệm lịch sử và sự tái sinh, nước hoa này thể hiện sự hòa quyện hoàn hảo của các nốt hương tự nhiên và quyến rũ.",
          "'1861 Renaissance' của Xerjoff là sự lựa chọn lý tưởng cho những ai yêu thích sự tinh tế và sang trọng trong một mùi hương tươi mới và quyến rũ. Đây là lựa chọn hoàn hảo cho cả ban ngày và ban đêm, từ các sự kiện đặc biệt đến những dịp thường ngày.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và hương hoa nhài, tạo ra một cảm giác sảng khoái và năng lượng.",
          "Hương giữa: Lớp hương giữa bao gồm sự kết hợp của hoa hồng, hoa nhài và hương cỏ vetiver, mang lại một sự pha trộn tinh tế và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của hổ phách, gỗ đàn hương và xạ hương, tạo ra một sự ấm áp và sang trọng, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 2,
        productName: "Xerjoff Naxos",
        price: [650000, 5650000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [xerjoff_naxos_edp_1, xerjoff_naxos_edp_2],
        description: [
          "'Naxos' của Xerjoff là một mùi hương nam tính quyến rũ, lấy cảm hứng từ sự xa hoa và quyến rũ của đảo Naxos ở Hy Lạp. Được thiết kế để mang lại sự tự tin và quyến rũ, nước hoa này thể hiện một sự pha trộn hoàn hảo của các nốt hương ấm áp và đậm đà.",
          "'Naxos' của Xerjoff là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và đậm đà trong một mùi hương nam tính. Đây là lựa chọn hoàn hảo cho các dịp đặc biệt và buổi tối, mang đến sự tự tin và sức quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới và ngọt ngào của chanh, cam bergamot và hương gia vị, tạo ra một cảm giác năng động và tràn đầy sức sống.",
          "Hương giữa: Lớp hương giữa bao gồm sự kết hợp của hoa nhài, hoa hồng và hương quế, mang lại một sự pha trộn tinh tế và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của hổ phách, gỗ đàn hương và xạ hương, tạo ra một sự ấm áp và đậm đà, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 3,
        productName: "Xerjoff Casamorati Mefisto",
        price: [650000, 5650000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [xerjoff_casamorati_mefisto_1, xerjoff_casamorati_mefisto_2],
        description: [
          "'Casamorati Mefisto' của Xerjoff thuộc dòng Casamorati, nổi bật với phong cách cổ điển và sự quyến rũ sang trọng. Mùi hương này mang đến một sự kết hợp tinh tế của các nốt hương tươi mới và thanh thoát, lý tưởng cho những người đàn ông yêu thích sự thanh lịch và đầy lôi cuốn.",
          "'Casamorati Mefisto' của Xerjoff là sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp hoàn hảo giữa sự tươi mới và quyến rũ. Đây là lựa chọn tuyệt vời cho các dịp đặc biệt hoặc hàng ngày, mang đến sự tự tin và phong cách.",
          "Hương đầu: Mở đầu với sự tươi mới và năng động của cam bergamot, chanh và hương gia vị nhẹ nhàng, tạo ra một sự khởi đầu tràn đầy sức sống.",
          "Hương giữa: Lớp hương giữa bao gồm sự kết hợp của hoa nhài, hoa hồng và hương gỗ, mang đến một cảm giác thanh thoát và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, gỗ đàn hương và hổ phách, tạo ra một sự ấm áp và đậm đà, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 4,
        productName: "Xerjoff Torino 21",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [xerjoff_torino_21_1, xerjoff_torino_21_2],
        description: [
          "'Xerjoff Torino 21' là một phần trong bộ sưu tập Torino của Xerjoff, mang đến một mùi hương đầy sang trọng và quyến rũ. Được thiết kế để tôn vinh sự tinh tế và sự khác biệt, nước hoa này là sự lựa chọn hoàn hảo cho những ai yêu thích sự độc đáo và ấn tượng.",
          "'Xerjoff Torino 21' là sự lựa chọn lý tưởng cho những ai yêu thích sự kết hợp hoàn hảo giữa sự sang trọng và độc đáo. Đây là lựa chọn tuyệt vời cho các dịp đặc biệt hoặc hàng ngày, mang đến sự tự tin và phong cách.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot, chanh và hương gia vị nhẹ nhàng, tạo ra một sự khởi đầu đầy sức sống và năng lượng.",
          "Hương giữa: Lớp hương giữa kết hợp sự thanh thoát của hoa nhài, hoa hồng và hương gỗ, mang đến một cảm giác quyến rũ và thanh lịch.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, hổ phách và gỗ đàn hương, tạo ra một sự ấm áp và đậm đà, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
    ],
  },
  {
    id: 62,
    brand: "YSL",
    logo: ysl,
    products: [
      {
        id: 1,
        productName: "Black Opium EDP",
        price: [390000, 3100000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [ysl_black_opium_edp_1, ysl_black_opium_edp_2],
        description: [
          "'Black Opium EDP' của Yves Saint Laurent là một trong những nước hoa nổi bật nhất trong bộ sưu tập của thương hiệu, mang đến một mùi hương quyến rũ và đầy bí ẩn. Được thiết kế để tôn vinh sự tự tin và cá tính, nước hoa này là sự lựa chọn hoàn hảo cho những người phụ nữ yêu thích sự cuốn hút và mạnh mẽ.",
          "'Black Opium EDP' là sự lựa chọn lý tưởng cho các buổi tối đặc biệt hoặc các dịp lễ hội, mang đến sự tự tin và phong cách cho người sử dụng. Đây là một mùi hương đầy cảm xúc và mạnh mẽ, phù hợp với những người phụ nữ yêu thích sự bí ẩn và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của cà phê đen và hương gia vị, tạo ra một sự khởi đầu mạnh mẽ và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa kết hợp sự quyến rũ của hoa nhài, hoa cam và hương vani, mang đến một cảm giác ấm áp và mê hoặc.",
          "Hương cuối: Kết thúc với lớp hương nền của hổ phách, gỗ đàn hương và xạ hương, tạo ra một sự ấm áp và đầy lôi cuốn, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 2,
        productName: "Over Red",
        price: [450000, 3500000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [over_red_1, over_red_2],
        description: [
          "'Over Red' của Yves Saint Laurent là một lựa chọn tinh tế cho những ai yêu thích sự quyến rũ và đầy màu sắc. Nước hoa này mang đến một mùi hương tươi mới và quyến rũ, hoàn hảo cho những ngày mùa hè hoặc các dịp đặc biệt khi bạn muốn nổi bật và gây ấn tượng.",
          "'Over Red' của YSL là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và nổi bật trong mỗi khoảnh khắc. Đây là một mùi hương đầy màu sắc và tươi mới, phù hợp với những buổi tối đặc biệt hoặc các dịp lễ hội.",
          "Hương đầu: Mở đầu với sự tươi mới và ngọt ngào của trái cây đỏ như dâu tây và việt quất, kết hợp với một chút hương cam bergamot để tạo ra một sự khởi đầu tươi mới và đầy sức sống.",
          "Hương giữa: Lớp hương giữa mang đến sự quyến rũ với sự kết hợp của hoa hồng, hoa nhài và một chút hương gia vị, tạo ra một cảm giác lôi cuốn và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, hổ phách và xạ hương, tạo ra một sự ấm áp và dễ chịu, lưu lại ấn tượng lâu dài và quyến rũ.",
        ],
      },
      {
        id: 3,
        productName: "La Nuit de L’Homme EDP",
        price: [320000, 2600000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [ysl_la_nuit_de_lhomme_edp_1, ysl_la_nuit_de_lhomme_edp_2],
        description: [
          "'La Nuit de L’Homme EDP' của Yves Saint Laurent là một lựa chọn hoàn hảo cho những người đàn ông yêu thích sự quyến rũ và bí ẩn. Nước hoa này mang đến một mùi hương sâu lắng và nam tính, phù hợp với những buổi tối đặc biệt và các dịp lãng mạn.",
          "'La Nuit de L’Homme EDP' của YSL là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và nam tính trong mỗi khoảnh khắc. Đây là một mùi hương hoàn hảo cho các dịp tối và những buổi hẹn hò đặc biệt.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và hạt nhục đậu khấu, tạo ra một ấn tượng mạnh mẽ và đầy sức hút.",
          "Hương giữa: Lớp hương giữa của 'La Nuit de L’Homme EDP' bao gồm hoa oải hương, gỗ tuyết tùng và một chút hương tiêu đen, mang lại sự ấm áp và bí ẩn.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, vani và hổ phách, tạo ra một cảm giác quyến rũ và ấm áp, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 4,
        productName: "YSL Y EDP",
        price: [360000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [ysl_y_edp_1, ysl_y_edp_2],
        description: [
          "'YSL Y EDP' của Yves Saint Laurent là một lựa chọn lý tưởng cho những người đàn ông hiện đại, yêu thích sự tươi mới và năng động. Đây là phiên bản Eau de Parfum của 'YSL Y', mang đến một mùi hương mạnh mẽ và quyến rũ, phù hợp cho cả ngày lẫn đêm.",
          "'YSL Y EDP' của YSL là sự lựa chọn hoàn hảo cho những ai yêu thích sự tươi mới và năng động trong mỗi ngày. Đây là một mùi hương lý tưởng cho cả công việc và các buổi tối đặc biệt.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và gừng, mang lại cảm giác sảng khoái và đầy sức sống.",
          "Hương giữa: Lớp hương giữa của 'YSL Y EDP' bao gồm hoa oải hương và táo xanh, tạo ra một sự kết hợp tươi mới và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ tuyết tùng, hổ phách và xạ hương, tạo ra một cảm giác ấm áp và nam tính, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 5,
        productName: "YSL MYSLF EDP",
        price: [380000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [ysl_myslf_edp_1, ysl_myslf_edp_2],
        description: [
          "'YSL MYSLF EDP' của Yves Saint Laurent là một lựa chọn hoàn hảo cho những người đàn ông tự tin và cá tính. Đây là một mùi hương mang đậm phong cách hiện đại, được thiết kế để phản ánh sự tự tin và cái tôi mạnh mẽ của người sử dụng.",
          "'YSL MYSLF EDP' là sự lựa chọn lý tưởng cho những ai yêu thích sự quyến rũ và cá tính trong mỗi ngày. Mùi hương này phù hợp cho cả công việc và những buổi tối đặc biệt.",
          "Hương đầu: Mở đầu với sự kết hợp mạnh mẽ của cam bergamot và tiêu đen, mang đến cảm giác tươi mới và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa bao gồm hoa oải hương và hương gỗ, tạo nên một sự kết hợp quyến rũ và đầy chiều sâu.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và hổ phách, tạo ra một cảm giác ấm áp và nam tính, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 6,
        productName: "YSL Mon Paris EDP",
        price: [380000, 2850000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [ysl_mon_paris_edp_1, ysl_mon_paris_edp_2],
        description: [
          "'YSL Mon Paris EDP' của Yves Saint Laurent là một sự lựa chọn hoàn hảo cho những người phụ nữ yêu thích sự lãng mạn và quyến rũ. Nước hoa này mang đến một mùi hương tươi mới và đầy cảm xúc, lấy cảm hứng từ tình yêu và thành phố Paris huyền bí.",
          "'YSL Mon Paris EDP' là lựa chọn lý tưởng cho những ai yêu thích sự lãng mạn và quyến rũ trong mỗi ngày. Mùi hương này rất phù hợp cho các dịp đặc biệt hoặc những buổi tối lãng mạn.",
          "Hương đầu: Mở đầu với sự kết hợp tươi mới của dâu tây, cam bergamot và quả lý chua đen, tạo ra một cảm giác ngọt ngào và đầy sức sống.",
          "Hương giữa: Lớp hương giữa bao gồm hoa nhài, hoa hồng và hoa cam, tạo nên một sự kết hợp lãng mạn và quyến rũ, làm nổi bật nét đẹp và sự thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, hổ phách và vani, tạo ra một cảm giác ấm áp và lôi cuốn, lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 7,
        productName: "YSL Libre Eau de ParfumP",
        price: [380000, 3000000],
        // dung tích
        volume: [10, 90],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8x`x",
        // độ toả hương
        scent_release: "gần",
        img: [ysl_libre_edp_1, ysl_libre_edp_2],
        description: [
          "'Libre' của YSL là hiện thân của sự tự do và mạnh mẽ, mang đến một hương thơm gợi cảm và hiện đại.",
          "'Libre' của YSL là lựa chọn hoàn hảo cho những người phụ nữ tự tin, thể hiện sự tự do trong từng khoảnh khắc.",
          "Hương đầu: Cam Bergamot và tinh dầu hoa oải hương tạo ra cảm giác tươi mới và năng động.",
          "Hương giữa: Hoa nhài kết hợp với hoa cam mang lại sự nữ tính, thanh lịch.",
          "Hương cuối: Vani Madagascar và xạ hương trắng mang đến sự ấm áp và gợi cảm.",
        ],
      },
    ],
  },
  {
    id: 63,
    brand: "Al Haramain Perfumes",
    logo: al_haramain_perfumes,
    products: [
      {
        id: 1,
        productName: "Amber Oud Ruby Edition",
        price: [280000, 2800000],
        // dung tích
        volume: [10, 120],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [amber_oud_ruby_edition_1, amber_oud_ruby_edition_2],
        description: [
          "'Amber Oud Ruby Edition' của Al Haramain Perfumes là một mùi hương đầy ấn tượng và quyến rũ, mang đến sự sang trọng và sự nổi bật. Đây là sự lựa chọn lý tưởng cho những ai yêu thích sự lôi cuốn và cảm giác xa hoa.",
          "'Amber Oud Ruby Edition' là lựa chọn lý tưởng cho những dịp đặc biệt hoặc các buổi tối quan trọng. Mùi hương này sẽ làm nổi bật cá tính và sự quyến rũ của bạn.",
          "Hương đầu: Mở đầu với sự kết hợp mạnh mẽ của hương cam bergamot và gia vị, tạo ra một cảm giác tươi mới và đầy sức sống.",
          "Hương giữa: Lớp hương giữa bao gồm hương gỗ đàn hương, nhựa thơm và hoa nhài, tạo nên một sự kết hợp ấm áp và quyến rũ, làm nổi bật sự thanh thoát và sang trọng.",
          "Hương cuối: Kết thúc với lớp hương nền của hổ phách, xạ hương và gỗ tuyết tùng, mang lại một cảm giác ấm áp và đầy lôi cuốn, lưu lại ấn tượng lâu dài.",
        ],
      },
    ],
  },
  {
    id: 64,
    brand: "D.S. & Durga",
    logo: ds_durga,
    products: [
      {
        id: 1,
        productName: "I Don’t Know What",
        price: [null, null],
        // dung tích
        volume: [10, 100],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [i_dont_know_what_edp_1, i_dont_know_what_edp_2],
        description: [
          "'I Don’t Know What' của D.S. & Durga là một mùi hương đầy bí ẩn và độc đáo, mang đến một trải nghiệm thú vị và khác biệt. Được thiết kế để thể hiện sự tự nhiên và sự phong phú của các yếu tố hương liệu, đây là sự lựa chọn hoàn hảo cho những ai yêu thích sự mới mẻ và khám phá.",
          "'I Don’t Know What' là sự lựa chọn lý tưởng cho những dịp đặc biệt hoặc khi bạn muốn thể hiện cá tính độc đáo và bí ẩn của mình.",
          "Hương đầu: Mở đầu với các nốt hương nhẹ nhàng và tươi mới của cam quýt và gia vị, tạo ra một cảm giác sảng khoái và sống động.",
          "Hương giữa: Lớp hương giữa là sự kết hợp của hương gỗ, hoa và thảo mộc, mang đến một chiều sâu và sự phong phú cho mùi hương, làm nổi bật sự bí ẩn và quyến rũ.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, nhựa thơm và hương gỗ ấm áp, tạo ra một cảm giác ấm cúng và đầy lôi cuốn, lưu lại ấn tượng lâu dài.",
        ],
      },
    ],
  },
  {
    id: 65,
    brand: "Marc Jacobs",
    logo: marc_jacobs,
    products: [
      {
        id: 1,
        productName: "Eau So Sweet",
        price: [260000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [eau_so_sweet_1, eau_so_sweet_2],
        description: [
          "'Eau So Sweet' của Marc Jacobs là một phiên bản nhẹ nhàng và quyến rũ của dòng nước hoa nổi tiếng Marc Jacobs. Được thiết kế để mang lại sự tươi mới và ngọt ngào, đây là sự lựa chọn hoàn hảo cho những ngày mùa xuân và mùa hè.",
          "'Eau So Sweet' là sự lựa chọn lý tưởng cho những ai yêu thích sự ngọt ngào và quyến rũ trong một mùi hương tươi mới và thanh thoát.",
          "Hương đầu: Mở đầu với các nốt hương ngọt ngào của quả mọng và cam, tạo ra một cảm giác sảng khoái và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa của 'Eau So Sweet' bao gồm hoa hồng, hoa nhài và quả đào, mang đến một sự kết hợp tinh tế và quyến rũ, làm nổi bật sự tươi mới và nữ tính.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương và gỗ đàn hương, tạo ra một cảm giác ấm cúng và đầy lôi cuốn, lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 2,
        productName: "Eau So Fresh",
        price: [260000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [eau_so_fresh_1, eau_so_fresh_2],
        description: [
          "'Eau So Fresh' của Marc Jacobs là một phiên bản tươi mới và đầy sức sống của dòng nước hoa nổi tiếng. Được thiết kế để mang đến sự sảng khoái và vui tươi, đây là sự lựa chọn lý tưởng cho những ngày hè oi ả hoặc những buổi tối nhẹ nhàng.",
          "'Eau So Fresh' là sự lựa chọn hoàn hảo cho những ai yêu thích sự tươi mới và quyến rũ trong một mùi hương ngọt ngào và thanh thoát.",
          "Hương đầu: Mở đầu với sự tươi mới và ngọt ngào của quả lý chua đen, quả mọng và cam, mang đến cảm giác sảng khoái và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa của 'Eau So Fresh' bao gồm hoa nhài, hoa hồng và hoa cam, tạo nên một sự kết hợp quyến rũ và thanh thoát, làm nổi bật sự nhẹ nhàng và nữ tính.",
          "Hương cuối: Kết thúc với lớp hương nền của gỗ đàn hương, xạ hương và hương gỗ, tạo ra một cảm giác ấm áp và lưu lại ấn tượng lâu dài và dễ chịu.",
        ],
      },
      {
        id: 3,
        productName: "Daisy Dream",
        price: [260000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [daisy_dream_edt_1, daisy_dream_edt_2],
        description: [
          "'Daisy Dream' của Marc Jacobs là một sự lựa chọn hoàn hảo cho những ai yêu thích mùi hương tươi mới và lãng mạn. Với thiết kế thanh thoát và nhẹ nhàng, nước hoa này mang đến một cảm giác tựa như làn gió mùa xuân đầy tươi mới và quyến rũ.",
          "'Daisy Dream' là sự lựa chọn lý tưởng cho những ai yêu thích sự tươi mới và lãng mạn trong một mùi hương nhẹ nhàng và quyến rũ.",
          "Hương đầu: Mở đầu với sự tươi mới của quả lý chua đen, quả lê và cam, tạo ra một sự kết hợp sảng khoái và đầy sức sống.",
          "Hương giữa: Lớp hương giữa của 'Daisy Dream' bao gồm hoa nhài, hoa hồng và hoa cam, tạo nên một sự kết hợp nhẹ nhàng và lãng mạn, làm nổi bật sự nữ tính và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của xạ hương, hương gỗ và hương trái cây, tạo ra một cảm giác ấm áp và lưu lại ấn tượng lâu dài.",
        ],
      },
      {
        id: 4,
        productName: "Perfect",
        price: [300000, 2550000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [perfect_1, perfect_2],
        description: [
          "'Perfect' của Marc Jacobs là một lựa chọn hoàn hảo cho những ai yêu thích sự tươi mới và vui tươi trong mùi hương của mình. Với thiết kế trẻ trung và năng động, nước hoa này mang đến một cảm giác tươi mới và quyến rũ, hoàn hảo cho những ngày hè năng động hoặc những dịp đặc biệt.",
          "'Perfect' là sự lựa chọn lý tưởng cho những ai yêu thích sự nhẹ nhàng và quyến rũ trong một mùi hương tươi mới và vui tươi.",
          "Hương đầu: Mở đầu với sự tươi mới của quả dứa và quả táo, tạo ra một sự kết hợp sảng khoái và đầy sức sống.",
          "Hương giữa: Lớp hương giữa của 'Perfect' bao gồm hoa nhài và hoa hồng, mang lại sự nhẹ nhàng và quyến rũ, làm nổi bật sự nữ tính và thanh thoát.",
          "Hương cuối: Kết thúc với lớp hương nền của hương gỗ và xạ hương, tạo ra một cảm giác ấm áp và lưu lại ấn tượng lâu dài.",
        ],
      },
    ],
  },
  {
    id: 66,
    brand: "Moschino",
    logo: moschino,
    products: [
      {
        id: 1,
        productName: "Toy 2 Bubble",
        price: [280000, 1900000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [toy_2_bubble_1, toy_2_bubble_2],
        description: [
          "'Toy 2 Bubble' của Moschino là một mùi hương tươi sáng và đầy màu sắc, được thiết kế dành cho những ai yêu thích sự vui nhộn và phá cách. Được đựng trong một chiếc chai hình chú gấu dễ thương, nước hoa này mang đến một cảm giác sảng khoái, đầy sức sống và cực kỳ dễ thương.",
          "'Toy 2 Bubble' là sự lựa chọn hoàn hảo cho những cô gái yêu thích sự tươi trẻ và vui nhộn, phù hợp cho mọi hoạt động trong ngày.",
          "Hương đầu: Mở đầu với sự bùng nổ của quả chanh vàng và cam quýt, tạo nên một cảm giác tươi mát và đầy năng lượng.",
          "Hương giữa: Lớp hương giữa là sự kết hợp của hoa nhài và hoa mẫu đơn, mang lại sự dịu dàng, nữ tính và mềm mại.",
          "Hương cuối: Kết thúc với xạ hương trắng và hương gỗ tuyết tùng, tạo nên một dấu ấn ấm áp và lưu luyến.",
        ],
      },
      {
        id: 2,
        productName: "Toy 2",
        price: [250000, 1800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [toy_2_1, toy_2_2],
        description: [
          "'Toy 2' của Moschino là sự trở lại đầy ngoạn mục của thiết kế chai hình chú gấu dễ thương, biểu tượng đặc trưng của thương hiệu. Được giới thiệu như một mùi hương mang lại cảm giác tươi mới, sống động và ngọt ngào, 'Toy 2' là lựa chọn hoàn hảo cho những cô gái hiện đại, yêu thích sự năng động và cá tính.",
          "'Toy 2' của Moschino mang đến một mùi hương tươi trẻ và độc đáo, rất phù hợp với những buổi hẹn hò hoặc hoạt động ngoài trời trong những ngày nắng đẹp.",
          "Hương đầu: Sự kết hợp tươi mát từ quả quýt Ý, táo xanh và hoa mộc lan mang lại cảm giác sảng khoái và đầy sức sống.",
          "Hương giữa: Hương giữa hòa quyện giữa hoa nhài, hoa mẫu đơn và hoa nho đen, tạo nên một sự nữ tính dịu dàng, dễ thương và ngọt ngào.",
          "Hương cuối: Kết thúc với xạ hương, hổ phách và gỗ đàn hương, tạo ra một dấu ấn ấm áp, thanh lịch nhưng vẫn không kém phần nổi bật.",
        ],
      },
      {
        id: 3,
        productName: "Toy Boy",
        price: [250000, 1800000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [_toy_boy_1, _toy_boy_2],
        description: [
          "'Toy Boy' của Moschino là một sáng tạo mới, độc đáo và đầy cá tính, được thiết kế dành riêng cho những người đàn ông yêu thích sự phá cách và không ngại thử nghiệm những điều mới mẻ. Đây là một mùi hương quyến rũ, gợi cảm nhưng vẫn toát lên sự nam tính mạnh mẽ, thích hợp cho mọi dịp, từ công việc đến những buổi tối hẹn hò.",
          "'Toy Boy' của Moschino không chỉ là một mùi hương mà còn là biểu tượng của sự tự tin, cá tính và sáng tạo trong thời trang và cuộc sống.",
          "Hương đầu: Mở đầu với sự tươi mát và sảng khoái của cam bergamot, quả lê và hồng tiêu, mang lại cảm giác tự do và tươi mới.",
          "Hương giữa: Hương giữa hòa quyện giữa hoa hồng, đinh hương và hạt nhục đậu khấu, tạo nên một sự nam tính quyến rũ và bí ẩn.",
          "Hương cuối: Kết thúc với xạ hương, gỗ hổ phách và vetiver, để lại một ấn tượng mạnh mẽ, bền bỉ và đầy phong trần.",
        ],
      },
    ],
  },
  {
    id: 67,
    brand: "Signorina",
    logo: signorina,
    products: [
      {
        id: 1,
        productName: "Signorina Eau de Parfum",
        price: [230000, 1750000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [eau_de_parfum_1, eau_de_parfum_2],
        description: [
          "'Signorina' Eau de Parfum là sự tôn vinh nét đẹp tinh tế và thanh lịch của người phụ nữ hiện đại. Với sự hòa quyện của các nốt hương tươi mát, hoa cỏ và ngọt ngào, 'Signorina' mang đến một dấu ấn khó quên, thể hiện sự tự tin và quyến rũ theo cách riêng biệt.",
          "'Signorina' Eau de Parfum là sự lựa chọn hoàn hảo cho những ai yêu thích mùi hương ngọt ngào nhưng không kém phần tinh tế, phù hợp cho cả ngày và đêm, từ công sở đến những dịp đặc biệt.",
          "Hương đầu: Bắt đầu bằng sự tươi mát, sống động của quả lý chua và hạt tiêu hồng, tạo nên một sự mở đầu rực rỡ và năng động.",
          "Hương giữa: Lớp hương giữa dịu dàng với sự kết hợp của hoa nhài, hoa mẫu đơn và hoa hồng, mang đến sự nữ tính và nhẹ nhàng.",
          "Hương cuối: Kết thúc với hương ngọt ngào của bánh panna cotta, xạ hương mềm mại và hoắc hương, để lại một dấu ấn ấm áp và đầy quyến rũ.",
        ],
      },
      {
        id: 2,
        productName: "Signorina Misteriosa Eau de Parfum",
        price: [250000, 1800000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [null, null],
        description: [
          "'Signorina Misteriosa' Eau de Parfum là mùi hương dành cho những phụ nữ yêu thích sự bí ẩn, mạnh mẽ và không ngại khám phá những điều mới mẻ. Đây là biểu tượng của sự quyến rũ đầy sức hút và những khoảnh khắc bất ngờ, hoàn hảo cho những dịp đặc biệt vào buổi tối.",
          "'Signorina Misteriosa' là sự kết hợp hoàn hảo giữa hương hoa và hương ngọt ngào, khiến bất kỳ ai cũng không thể cưỡng lại sức hút của nó.",
          "Hương đầu: Mở đầu với sự tươi mới của quả dâu rừng và hương cam bergamot, tạo nên một làn gió mát lạnh, bí ẩn và đầy cuốn hút.",
          "Hương giữa: Lớp hương giữa ngọt ngào của hoa cam hòa quyện với nốt hương gợi cảm của hoa huệ, mang đến một cảm giác nữ tính và lôi cuốn.",
          "Hương cuối: Kết thúc bằng sự ấm áp, ngọt ngào của kem mousse đen, hoắc hương và vani, mang lại một dấu ấn quyến rũ, bí ẩn và đầy táo bạo.",
        ],
      },
    ],
  },
  {
    id: 68,
    brand: "Carolina Herrera",
    logo: carolina_herrera,
    products: [
      {
        id: 1,
        productName: "Good Girl Eau de Parfum",
        price: [400000, 2800000],
        // dung tích
        volume: [10, 80],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [good_girl_1, good_girl_2],
        description: [
          "'Good Girl' Eau de Parfum của Carolina Herrera là mùi hương đặc trưng cho những phụ nữ hiện đại – vừa mạnh mẽ, vừa nữ tính. Hương thơm này là biểu tượng của sự tự tin, quyến rũ và táo bạo, dành cho những ai không ngại thể hiện cả hai mặt của mình: dịu dàng nhưng đầy nội lực.",
          "'Good Girl' là sự cân bằng hoàn hảo giữa nét nữ tính, gợi cảm và một chút phá cách, khiến nó trở thành lựa chọn lý tưởng cho những buổi tối đặc biệt.",
          "Hương đầu: Mở đầu với sự tươi mới và quyến rũ của hạnh nhân và hương cà phê, tạo nên một cảm giác mạnh mẽ và đầy lôi cuốn.",
          "Hương giữa: Hòa quyện giữa sự nữ tính của hoa nhài sambac và hoa huệ, mang lại một nét quyến rũ và sang trọng không thể chối từ.",
          "Hương cuối: Được bao bọc bởi sự ấm áp của cacao và đậu tonka, lớp hương cuối mang lại sự ngọt ngào nhưng cũng đầy bí ẩn, khiến người khác phải dõi theo từng bước chân của bạn.",
        ],
      },
      {
        id: 2,
        productName: "212 Men NYC Eau de Toilette",
        price: [290000, 2250000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [men_nyc_212_1, men_nyc_212_2],
        description: [
          "'212 Men NYC' Eau de Toilette của Carolina Herrera là biểu tượng của sự sôi nổi, tự tin và nam tính của một người đàn ông hiện đại sống giữa nhịp sống không ngừng nghỉ của thành phố New York. Mùi hương này mang đến một cảm giác phóng khoáng, mạnh mẽ và đầy tinh tế.",
          "'212 Men NYC' là hương thơm lý tưởng cho những người đàn ông hiện đại, yêu thích sự sôi động của cuộc sống thành thị nhưng vẫn giữ nét tinh tế và lịch lãm.",
          "Hương đầu: Mở ra với sự tươi mát của cam bergamot, hương chanh và lá xanh, tạo cảm giác sảng khoái ngay từ lần xịt đầu tiên.",
          "Hương giữa: Tầng hương giữa bùng nổ với sự kết hợp giữa gia vị cay nồng của tiêu và hoa, làm nổi bật nét tinh tế và lịch lãm của phái mạnh.",
          "Hương cuối: Kết thúc với lớp hương ấm áp của gỗ đàn hương, cỏ vetiver và xạ hương, tạo ra sự lôi cuốn nam tính, lưu giữ ấn tượng mạnh mẽ suốt cả ngày.",
        ],
      },
    ],
  },
  {
    id: 69,
    brand: "Bvlgari",
    logo: bvlgari,
    products: [
      {
        id: 1,
        productName: "Aqva Pour Homme",
        price: [320000, 2650000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDT",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [aqva_pour_homme_1, aqva_pour_homme_2],
        description: [
          "'Aqva Pour Homme' của Bvlgari là sự kết hợp hoàn hảo giữa sự mạnh mẽ và tươi mát, gợi lên hình ảnh đại dương bao la và sức mạnh tự nhiên. Hương thơm này là lựa chọn lý tưởng cho người đàn ông năng động, yêu thích sự tự do và thanh thoát.",
          "'Aqva Pour Homme' là hương thơm của sự tự do, của biển khơi, dành cho những người đàn ông yêu thích sự tươi mới và phóng khoáng, nhưng không kém phần nam tính và mạnh mẽ.",
          "Hương đầu: Mở đầu bằng sự tươi mát mạnh mẽ của quả cam quýt và tinh chất petitgrain, mang lại cảm giác sảng khoái và phóng khoáng.",
          "Hương giữa: Lớp hương giữa với tảo biển posidonia và hương nước biển, tái hiện sự mát lành của đại dương và sự thanh khiết tự nhiên.",
          "Hương cuối: Kết thúc bằng nốt hương ấm áp của gỗ tuyết tùng và long diên hương, tạo ra một dấu ấn nam tính, đầy lôi cuốn và bền bỉ.",
        ],
      },
      {
        id: 2,
        productName: "Man in Black Eau de Parfum",
        price: [330000, 2700000],
        // dung tích
        volume: [10, 100],
        gender: ["male"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [man_in_black_eau_de_parfum_1, man_in_black_eau_de_parfum_2],
        description: [
          "'Man in Black' của Bvlgari là một hương thơm đầy nam tính, được thiết kế dành riêng cho người đàn ông hiện đại, mạnh mẽ và đầy bí ẩn. Được lấy cảm hứng từ thần thoại của Vulcan – vị thần rèn của La Mã, nước hoa này là sự hòa quyện đầy mạnh mẽ của các nốt hương cay nồng và gỗ ấm.",
          "'Man in Black' của Bvlgari là biểu tượng của sức mạnh, sự bí ẩn và lôi cuốn – hoàn hảo cho những người đàn ông muốn tạo ấn tượng mạnh mẽ, tự tin và phong cách.",
          "Hương đầu: Mở đầu bằng sự kết hợp táo bạo của gia vị cay nồng như quế và tiêu đen, mang lại cảm giác mạnh mẽ, cuốn hút ngay từ lần xịt đầu tiên.",
          "Hương giữa: Lớp hương giữa được xây dựng quanh tinh chất rượu rum và hoa huệ, tạo ra một sự pha trộn quyến rũ và đầy sức hút.",
          "Hương cuối: Kết thúc bằng sự ấm áp của gỗ đàn hương, hổ phách và đậu tonka, tạo nên một dấu ấn nam tính mạnh mẽ, kéo dài và quyến rũ.",
        ],
      },
    ],
  },
  {
    id: 70,
    brand: "Lancome",
    logo: lancome,
    products: [
      {
        id: 1,
        productName: "Lancome La Vie Est Belle Eau de Parfum",
        price: [400000, 3000000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "8-12",
        // độ toả hương
        scent_release: "xa",
        img: [lavie_est_belle_1, lavie_est_belle_2],
        description: [
          "'La Vie Est Belle' của Lancôme là một biểu tượng của sự tự do và hạnh phúc, được thiết kế dành riêng cho người phụ nữ yêu đời và biết tận hưởng những điều nhỏ bé trong cuộc sống. Đây là mùi hương mang tính biểu tượng, với sự kết hợp hoàn hảo của các nốt hương ngọt ngào và quyến rũ.",
          "'La Vie Est Belle' của Lancôme không chỉ là một mùi hương, mà còn là một thông điệp về niềm hạnh phúc và sự tự do, hoàn hảo cho những người phụ nữ hiện đại, mạnh mẽ và yêu đời.",
          "Hương đầu: Mở đầu với sự tươi mát và ngọt ngào của quả lê và quả lý chua đen, mang lại cảm giác đầy sức sống và dễ chịu.",
          "Hương giữa: Lớp hương giữa là sự kết hợp tinh tế của hoa diên vĩ, hoa nhài và hoa cam, tạo nên sự thanh lịch, nữ tính và quyến rũ.",
          "Hương cuối: Kết thúc với sự ấm áp của vani, kẹo hạnh nhân và đậu tonka, tạo nên một dấu ấn ngọt ngào và lâu dài.",
        ],
      },
      {
        id: 2,
        productName: "Idôle Eau de Parfum",
        price: [380000, 2750000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [lancome_idole_eau_de_parfum_1, lancome_idole_eau_de_parfum_2],
        description: [
          "'Idôle' của Lancôme là một sự tôn vinh cho sức mạnh và sự tự tin của người phụ nữ hiện đại. Với sự kết hợp hoàn hảo của các nốt hương tươi mới và quyến rũ, đây là một lựa chọn lý tưởng cho những ai yêu thích sự mới mẻ và độc đáo trong mỗi bước đi.",
          "'Idôle' của Lancôme là một mùi hương thể hiện sự hiện đại và độc lập, hoàn hảo cho những phụ nữ tự tin, luôn tìm kiếm sự đổi mới và phong cách trong cuộc sống hàng ngày.",
          "Hương đầu: Mở đầu với sự tươi mới của cam bergamot và quả lê, tạo nên một cảm giác sảng khoái và đầy sức sống.",
          "Hương giữa: Lớp hương giữa là sự kết hợp của hoa hồng, hoa nhài và hoa cam, mang đến sự thanh thoát và quyến rũ, như một sự tôn vinh vẻ đẹp tự nhiên của phụ nữ.",
          "Hương cuối: Kết thúc với nốt hương gỗ đàn hương, xạ hương và hương vani, tạo ra một dấu ấn ấm áp và quyến rũ, lưu lại ấn tượng lâu dài.",
        ],
      },
    ],
  },
  {
    id: 71,
    brand: "Maison Matine",
    logo: maison_matine,
    products: [
      {
        id: 1,
        productName: "Missoni Warni Warni Eau de Parfum",
        price: [400000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [warni_warni_edp_1, warni_warni_edp_2],
        description: [
          "'Warni Warni' là dòng nước hoa độc đáo, thể hiện sự vui tươi và năng động với các nốt hương sống động, nhiều màu sắc. Hương thơm này gợi lên một thế giới đầy sáng tạo, sắc màu và tràn đầy năng lượng, phù hợp với những người phụ nữ yêu thích sự tươi mới và luôn muốn khám phá những điều mới mẻ.",
          "Nước hoa bắt đầu với sự ngọt ngào, tươi mới của quả mâm xôi và cam bergamot, hòa quyện cùng một chút cay nồng của hạt tiêu hồng. Hương giữa tỏa ra vẻ nữ tính với sự mềm mại từ hoa mẫu đơn và hoa nhài, mang đến cảm giác nhẹ nhàng nhưng đầy cuốn hút. Cuối cùng, hương gỗ tuyết tùng, vani và xạ hương đọng lại trên da, mang đến cảm giác ấm áp và quyến rũ.",
          "Hương đầu: Quả mâm xôi, cam bergamot, hạt tiêu hồng.",
          "Hương giữa: Hoa mẫu đơn, hoa nhài, hoa cam.",
          "Hương cuối: Gỗ tuyết tùng, vani, xạ hương.",
        ],
      },
      {
        id: 2,
        productName: "Missoni Nature Insolente Eau de Parfum",
        price: [400000, 1950000],
        // dung tích
        volume: [10, 100],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [nature_insolente_edp_1, nature_insolente_edp_2],
        description: [
          "'Nature Insolente' là dòng nước hoa mang đậm nét tinh tế của thiên nhiên, kết hợp giữa sự hoang dã và tự nhiên với chút quyến rũ. Hương thơm mang lại cảm giác tươi mát, nhẹ nhàng nhưng vẫn đầy quyền lực, phù hợp với những phụ nữ yêu thích sự tự do và đam mê cuộc sống.",
          "Hương đầu tươi sáng từ cam chanh và lý chua đen mang lại cảm giác sảng khoái, tự nhiên. Hương giữa ngọt ngào của hoa nhài và hoa hồng tạo ra một sự quyến rũ tinh tế, nữ tính. Cuối cùng, hương gỗ đàn hương và xạ hương trắng lưu lại trên da, tạo nên cảm giác mềm mại và thanh lịch.",
          "Hương đầu: Cam chanh, quả lý chua đen.",
          "Hương giữa: Hoa nhài, hoa hồng.",
          "Hương cuối: Gỗ đàn hương, xạ hương trắng.",
        ],
      },
      {
        id: 3,
        productName: "Poom Poom Eau de Parfum",
        price: [400000, 1950000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "4-6",
        // độ toả hương
        scent_release: "gần",
        img: [maison_matine_poom_poom_1, maison_matine_poom_poom_2],
        description: [
          "'Poom Poom' của Maison Matine là một mùi hương táo bạo và nổi loạn, mang đến một sự kết hợp độc đáo giữa sự tươi mát và nét cá tính riêng biệt. Đây là một mùi hương unisex, phù hợp cho những ai muốn tạo dấu ấn cá nhân mà không bị giới hạn bởi giới tính.",
          "'Poom Poom' là sự lựa chọn hoàn hảo cho những ai muốn thể hiện cá tính mạnh mẽ và không ngại sự khác biệt. Mùi hương này mang đến cảm giác như một phát pháo táo bạo, để lại ấn tượng sâu sắc trong mọi hoàn cảnh.",
          "Hương đầu: Khởi đầu với nốt hương cam bergamot tươi mát và lá cà chua đầy sự bất ngờ, mang đến cảm giác sảng khoái và sinh động.",
          "Hương giữa: Lớp hương giữa là sự hòa quyện của nhục đậu khấu và hạt tiêu hồng, tạo nên sự phá cách, đầy táo bạo và mạnh mẽ.",
          "Hương cuối: Kết thúc với nốt hương gỗ và xạ hương, tạo nên sự ấm áp, sâu lắng và cuốn hút, mang lại dấu ấn khó quên.",
        ],
      },
      {
        id: 4,
        productName: "Avant l’Orage Eau de Parfum",
        price: [400000, 1950000],
        // dung tích
        volume: [10, 50],
        gender: ["unisex"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "6-8",
        // độ toả hương
        scent_release: "gần",
        img: [maison_matine_avant_lorage_1, maison_matine_avant_lorage_2],
        description: [
          "'Avant l’Orage' của Maison Matine là một mùi hương mang tính nghệ thuật, lấy cảm hứng từ khoảnh khắc bình yên trước cơn bão. Mùi hương này được tạo ra cho những người tìm kiếm sự tĩnh lặng và sâu lắng, nhưng không kém phần bí ẩn và cuốn hút.",
          "'Avant l’Orage' là một hương thơm unisex, mang đến sự cân bằng giữa sự tươi mát và ấm áp, phù hợp với những ai muốn tìm kiếm sự mơ mộng, bí ẩn và tinh tế trong cuộc sống.",
          "Hương đầu: Mở đầu bằng nốt hương chanh và bưởi, mang lại cảm giác tươi mát và thanh khiết như làn gió nhẹ trước khi bão tố ập đến.",
          "Hương giữa: Lớp hương giữa mang sự sâu lắng với sự kết hợp của hoa nhài và hương thảo, tạo nên sự tinh tế và dịu dàng, như những suy tư lướt qua trước giông bão.",
          "Hương cuối: Kết thúc với hương gỗ đàn hương và xạ hương trắng, mang đến cảm giác ấm áp và dễ chịu, gợi lên sự bình yên sau cơn bão.",
        ],
      },
    ],
  },
  {
    id: 72,
    brand: "Mancera",
    logo: maison_matine,
    products: [
      {
        id: 1,
        productName: "Mancera Cedrat Boise",
        price: [320000, 2450000],
        // dung tích
        volume: [10, 120],
        gender: ["female"],
        // nồng độ
        concentration: "EDP",
        // độ lưu hương
        scent_retention: "12-14",
        // độ toả hương
        scent_release: "xa",
        img: [mancera_cedrat_boise_edp_1, mancera_cedrat_boise_edp_2],
        description: [
          "'Cedrat Boise' của Mancera là một sự pha trộn tinh tế giữa sự tươi mát và sang trọng, mang đến một hương thơm độc đáo và hiện đại.",
          "'Cedrat Boise' của Mancera phù hợp với những ai yêu thích sự tươi mát và sang trọng, lý tưởng cho cả ngày lẫn đêm, và thích hợp cho mọi dịp.",
          "Hương đầu: Cam bergamot, chanh, và quả lý chua đen tạo nên sự tươi mát, sảng khoái ngay từ ban đầu.",
          "Hương giữa: Lớp hương giữa là sự kết hợp hài hòa của lá hoắc hương và gỗ đàn hương, mang lại sự ấm áp và cuốn hút.",
          "Hương cuối: Hương cuối với hương vani và xạ hương trắng tạo nên một cảm giác sang trọng, sâu lắng và kéo dài.",
        ],
      },
    ],
  },
];

export const Brading = {
  A: [
    { brand: "Afnan", logo: afnan },
    { brand: "Al Haramain Perfumes", logo: al_haramain_perfumes },
    // { brand: "Alaïa", logo: alaia },
    { brand: "Alaïa", logo: alaia },
    { brand: "Alexandria Fragrances", logo: alexandria_fragrances },
    { brand: "Armaf", logo: armaf },
    { brand: "Atelier des Ors", logo: atelier_des_ors },
    { brand: "Attar Collection", logo: attar_collection },
  ],
  B: [
    { brand: "BDK", logo: bdk },
    { brand: "Borntostandout", logo: borntostandout },
    { brand: "Burberry", logo: burberry },
    { brand: "Bvlgari", logo: bvlgari },
  ],
  C: [
    { brand: "Carolina Herrera", logo: carolina_herrera },
    { brand: "Chanel", logo: chanel },
    { brand: "Chloe", logo: chloe },
    { brand: "Clive Christian", logo: clive_christian },
    { brand: "Creed", logo: creed },
  ],
  D: [
    { brand: "D.S. & Durga", logo: ds_durga },
    { brand: "D&G", logo: dg },
    { brand: "Dior", logo: dior },
    { brand: "Diptyque", logo: diptyque },
  ],
  E: [
    { brand: "Elizabeth Arden", logo: elizabeth_arden },
    { brand: "Exnihilo", logo: Exnihilo },
  ],
  F: [{ brand: "Franck Boclet", logo: franck_boclet }],
  G: [
    { brand: "Giardini", logo: giardini },
    { brand: "Giorgio Armani", logo: giorgio_armani },
    { brand: "Gucci", logo: gucci },
  ],
  H: [{ brand: "Hermes", logo: hermes }],
  I: [
    { brand: "Imaginary Authors", logo: imaginary_authors },
    { brand: "Initio Parfums Prives", logo: initio_parfums_prives },
  ],
  J: [
    { brand: "Jean Paul Gaultier", logo: jean_paul_gaultier },
    { brand: "Juliette Has A Gun", logo: juliette_has_a_gun },
  ],
  K: [{ brand: "Kilian", logo: kilian }],
  L: [
    { brand: "L’Orchestre Parfum", logo: lorchestre_parfum },
    { brand: "Lancome", logo: lancome },
    { brand: "Le Labo", logo: le_labo },
    { brand: "Les Soeurs de Noe", logo: les_soeurs_de_noe },
    { brand: "Liquides Imaginaires", logo: liquides_imaginaires },
    { brand: "Loewe", logo: loewe },
    { brand: "LV", logo: lv },
  ],
  M: [
    { brand: "Mad et Len", logo: mad_et_len },
    { brand: "Maison Matine", logo: maison_matine },
    { brand: "Maison Margiela Replica", logo: maison_margiela_replica },
    { brand: "Marc Jacobs", logo: marc_jacobs },
    { brand: "Marie Jeanne", logo: marie_jeanne },
    { brand: "Matiere Premiere", logo: matiere_premiere },
    { brand: "MCM", logo: mcm },
    { brand: "MFK", logo: mfk },
    { brand: "Missoni", logo: missoni },
    { brand: "Montale", logo: montale },
    { brand: "Montblanc", logo: montblanc },
    { brand: "Moschino", logo: moschino },
    { brand: "Mugler", logo: mugler },
  ],
  N: [
    { brand: "Narciso Rodriguez", logo: narciso_rodriguez },
    { brand: "Nasomatto", logo: nasomatto },
    { brand: "Nishane", logo: nishane },
  ],
  O: [
    { brand: "Once perfume", logo: once },
    { brand: "Orto Parisi", logo: orto_parisi },
  ],
  P: [
    { brand: "Paco Rabanne", logo: paco_rabanne },
    { brand: "Parfums de Marly", logo: parfums_de_marly },
    { brand: "Prada", logo: prada },
  ],
  Q: [],
  R: [{ brand: "Roja Parfums", logo: roja_parfums }],
  S: [{ brand: "Signorina", logo: signorina }],
  T: [
    { brand: "Tamburins", logo: tamburins },
    { brand: "Thomas Kosmala", logo: thomas_kosmala },
    { brand: "Tom Ford", logo: tom_ford },
  ],
  U: [],
  V: [
    { brand: "Valentino", logo: valentino },
    { brand: "Van Cleef & Arpels", logo: van_cleef_arpels },
    { brand: "Versace", logo: versace },
    { brand: "Viktor & Rolf", logo: viktor_rolf },
  ],
  W: [],
  X: [{ brand: "Xerjoff", logo: xerjoff }],
  Y: [{ brand: "YSL", logo: ysl }],
  Z: [],
};
